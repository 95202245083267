import { DataContextService } from './../../services/data-context.service';
import { GenotypeService } from './../genotype.service';
import { GenotypeBulkTemplatesComponent } from './genotype-bulk-templates.component';
import { BulkAddCommService } from '../../common/facet/bulk-add-comm.service';
import { BulkAddComponent } from '../../common/facet/bulk-add.component';
import { DroppableEvent } from '../../common/droppable-event';
import { AnimalService } from '../../animals/services/animal.service';
import { FacetLoadingStateService } from '../../common/facet/facet-loading-state.service';
import { BulkAddResult } from '../../common/facet/models/bulk-edit.classes';
import { BulkAddExitReason } from '../../common/facet/models/bulk-add-exit-reason.enum';
import { BulkEditSection } from '../../common/facet/models/bulk-edit-section.enum';
import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    ViewChild,
    AfterViewInit
} from '@angular/core';

import { notEmpty, uniqueArray, uniqueArrayFromPropertyPath, setSafeProp } from '../../common/util';
import { LoggingService } from '@services/logging.service';
import { CopyBufferService } from '../../common/services/copy-buffer.service';


@Component({
    selector: 'genotype-bulk-add',
    templateUrl: './genotype-bulk-add.component.html',
    styles: [`
        h5 {
            font-weight: bold;
        }
        .number-items-to-add-label {
            font-weight: normal;
        }
        .dropzone {
            max-height: 160px;
            overflow-y: scroll;
        }
    `]
})
export class GenotypeBulkAddComponent implements AfterViewInit {
    @Input() facet: any;
    @Output() exit: EventEmitter<BulkAddResult> = new EventEmitter<BulkAddResult>();
    @ViewChild('itemsToAddTmpl') itemsToAddTmpl: TemplateRef<any>;
    @ViewChild('bulkAdd') bulkAdd: BulkAddComponent;
    @ViewChild('bulkTemplates') bulkTemplates: GenotypeBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'genotype-bulk-add';

    BulkEditSection = BulkEditSection;

    sourceMaterials: any = [];

    constructor(
        private animalService: AnimalService,
        private bulkAddCommService: BulkAddCommService,
        private copyBufferService: CopyBufferService,
        private genotypeService: GenotypeService,
        private faceLoadingStateService: FacetLoadingStateService,
        private dataContext: DataContextService,
        private loggingService: LoggingService,
    ) {
    }

    /**
     * AfterViewInit is when bulkTemplates is fully initialized
     */
    ngAfterViewInit() {
        if (this.bulkTemplates) {
            this.bulkTemplates.bulkOptions.itemsToAddTemplate = this.itemsToAddTmpl;
        }
    }

    onDropSourceMaterials() {
        if (notEmpty(this.animalService.draggedAnimals)) {
            const draggedAnimals = this.animalService.draggedAnimals;
            this.addSourceMaterials(draggedAnimals);
            this.animalService.draggedAnimals = [];
        }
    }

    pasteSourceMaterials() {
        if (this.copyBufferService.hasAnimals()) {
            const pastedAnimals = this.copyBufferService.paste();
            this.addSourceMaterials(pastedAnimals);
        }
    }

    addSourceMaterials(newSourceMaterials: any[]) {
        newSourceMaterials = newSourceMaterials.slice();
        newSourceMaterials = this.sourceMaterials.concat(newSourceMaterials);
        this.sourceMaterials = uniqueArray(newSourceMaterials);

        this.syncItemsToAdd();

        this.genotypeService.ensureSourceMaterialsExpanded(
            this.sourceMaterials
        ).then(() => {
            // set C_Line_key
            const uniqueLineKeys = uniqueArrayFromPropertyPath(
                this.sourceMaterials, 'Material.C_Line_key'
            );
            if (uniqueLineKeys.length === 1 && this.bulkTemplates) {
                setSafeProp(
                    this.bulkTemplates.bulkOptions.__addObject,
                    'Material.C_Line_key',
                    uniqueLineKeys[0]
                );
            }
        });
    }

    /**
     * Set the numberItemsToAdd on BulkAddComponent.
     * It should be in sync with the sourceMaterials list
     */
    syncItemsToAdd() {
        if (!notEmpty(this.sourceMaterials) ||
            !this.bulkAdd
        ) {
            return;
        }

        this.bulkAdd.addState.numberItemsToAdd = this.sourceMaterials.length;
    }

    saveClicked(result: BulkAddResult) {
        const errMessage = this.bulkTemplates.validate();
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        result.newItems = [];
        let promise = Promise.resolve([]);
        switch (result.reason) {
            case BulkAddExitReason.Cancel:
                // do nothing on cancel
                break;
            case BulkAddExitReason.Save:
                promise = this.createNewGenotypes(
                    result.numberItemsToAdd,
                    result.initialValues
                ).then((newGenotypes) => {
                    this.faceLoadingStateService.changeLoadingState(true);
                    return this.dataContext.save().then(() => {
                        this.faceLoadingStateService.changeLoadingState(false);
                        if (result.clearForm) {
                            this.sourceMaterials = [];
                            this.bulkAdd.addState.numberItemsToAdd = 1;
                        }
                        return newGenotypes;
                    }).catch((error) => {
                        this.faceLoadingStateService.changeLoadingState(false);
                        for (const genotype of newGenotypes) {
                            this.genotypeService.cancelGenotype(genotype);
                        }
                        throw error;
                    });
                });
                break;
            case BulkAddExitReason.Edit:
                promise = this.createNewGenotypes(
                    result.numberItemsToAdd,
                    result.initialValues
                );
                break;
        }

        promise.then((newItems) => {
            result.newItems = newItems;
            this.bulkAddCommService.saveComplete();
        }).catch((error) => {
            this.bulkAddCommService.saveCanceled();
            throw error;
        });
    }

    exitClicked(result: BulkAddResult) {
        this.exit.emit(result);
    }

    createNewGenotypes(numberItemsToAdd: number, initialValues: any): Promise<any[]> {
        const genotypes: any[] = [];
        const promises: Promise<any>[] = [];

        // If we have source materials, add 1 sample per material
        if (notEmpty(this.sourceMaterials)) {
            const p = this.replaceSearchObjectsWithBreezeEntities().then(() => {
                return this.genotypeService.ensureSourceMaterialsExpanded(this.sourceMaterials);
            }).then(() => {
                const promises2: Promise<any>[] = [];
                for (const parentMaterial of this.sourceMaterials) {
                    const p2 = this.createNewGenotype(
                        initialValues, parentMaterial
                    ).then((newGenotype) => {
                        genotypes.push(newGenotype);
                    });
                    promises2.push(p2);
                }
                return Promise.all(promises2);
            });
            promises.push(p);
            
        // otherwise use the numberItemsToAdd count for each sample
        } else {
            for (let i = 0; i < numberItemsToAdd; i++) {
                const p = this.createNewGenotype(initialValues).then((newGenotype) => {
                    genotypes.push(newGenotype);
                });
                promises.push(p);
            }
    }
        
        return Promise.all(promises).then(() => {
          return genotypes;  
        });
    }

    async createNewGenotype(initialValues: any, parentMaterial?: any): Promise<any> {

        const newGenotype = this.genotypeService.create();

        // Set initial values from bulk-add panel
        newGenotype.C_GenotypeSymbol_key = initialValues.C_GenotypeSymbol_key;
        newGenotype.C_GenotypeAssay_key = initialValues.C_GenotypeAssay_key;
        newGenotype.DateGenotype = initialValues.DateGenotype;

        if (parentMaterial) {
            newGenotype.C_Material_key = parentMaterial.C_Material_key;
        }

        newGenotype.C_Plate_key = initialValues.C_Plate_key;

        return Promise.resolve(newGenotype);
    }
    
    private replaceSearchObjectsWithBreezeEntities(): Promise<any> {
        const promises: Promise<any>[] = [];

        const length = this.sourceMaterials.length;
        for (const sourceMaterial of this.sourceMaterials) {
            if (sourceMaterial.entityAspect) {
                continue;
            }

            // swap search object for breeze entity
            const materialKey = sourceMaterial.C_Material_key;
            const p = this.animalService.getAnimal(materialKey).then((breezeAnimal) => {
                for (let i = 0; i < length; i++) {
                    if (this.sourceMaterials[i].C_Material_key === materialKey) {
                        this.sourceMaterials[i] = breezeAnimal;
                        break;
                    }
                }
            });
            promises.push(p);
        }

        return Promise.all(promises);
    }
}
