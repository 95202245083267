import {
    Component,
    Input,
    OnInit
} from '@angular/core';
import { isNumber } from 'util';
import { DataManagerService } from '../services/data-manager.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

@Component({
    selector: 'output-flag',
    templateUrl: 'output-flag.component.html',
    styles: [
        `
            .remove-disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
            caption {
                caption-side: top;
            }
        `
    ],
})
export class OutputFlagComponent implements OnInit {
    @Input() taskOutput: any;
    @Input() facet: any;
    @Input() index: any;

    equals: number;
    minimum: number;
    maximum: number;
    betweenMin: number;
    betweenMax: number;

    currentOption: string;
    flagMessages: any[] = [];
    flagMessageKey: any = null;

    constructor(
        private vocabularyService: VocabularyService,
        private _dataManager: DataManagerService
    ) { }

    ngOnInit(): void {
        this.vocabularyService
            .getCV('cv_TaskFlagMessages') 
            .then((data: any) => {
                this.flagMessages = data;
            });
    }

    optionChange(option: string): void {
        this.currentOption = option;
        this.clearInputs();
    }

    renderFlag(flag: any): string {
        if (isNumber(flag.Minimum) && isNumber(flag.Maximum)) {
            return flag.Minimum === flag.Maximum
                ? `Χ = ${flag.Minimum}`
                : `${ flag.Minimum } ≤ Χ ≤ ${ flag.Maximum }`;
        } else if (isNumber(flag.Maximum)) {
            return `Χ ≤ ${flag.Maximum}`;
        } else if (isNumber(flag.Minimum)) {
            return `Χ ≥ ${flag.Minimum}`;
        } else {
            return '';
        }
    }

    addFlag(): void {
        const flag = this.buildFlag();

        this._dataManager.createEntity('OutputFlag', flag);

        this.flagMessageKey = null;
        this.clearInputs();
    }

    removeFlag(flag: any): void {
        this._dataManager.deleteEntity(flag);
    }

    isFlagRuleValid(currentOption: string): boolean {
        let result = false;

        if (currentOption === 'between' &&
            this.betweenMin !== null &&
            this.betweenMax !== null) {
            if (this.betweenMax > this.betweenMin) {
                result = true;
            }
        }
        if (currentOption === 'minimum' &&
            this.minimum !== null) {
            result = true;
        }
        if (currentOption === 'maximum' &&
            this.maximum !== null) {
            result = true;
        }
        if (currentOption === 'equals' &&
            this.equals !== null) {
            result = true;
        }

        return result;
    }

    betweenValuesChanged(minInput: any, maxInput: any) {
        const error = this.isFlagRuleValid('between') ? '' : 'between-invalid';
        minInput.setCustomValidity(error);
        maxInput.setCustomValidity(error);
    }

    taskFlagMessageFormatter = (value: any) => value.TaskFlagMessage;

    taskFlagMessageKeyFormatter = (value: any) => value.C_TaskFlagMessage_key;

    private buildFlag(): any {
        const taskFlagMessage = this.flagMessages.find((x: any) => x.C_TaskFlagMessage_key === +this.flagMessageKey);
        const min = this.currentOption === 'between'
            ? this.betweenMin
            : this.currentOption === 'minimum'
                ? this.minimum
                : this.equals;
        const max = this.currentOption === 'between'
            ? this.betweenMax
            : this.currentOption === 'maximum'
                ? this.maximum
                : this.equals;

        return {
            C_Output_key: this.taskOutput.C_Output_key,
            Maximum: max,
            Minimum: min,
            TaskFlagMessage: taskFlagMessage ? taskFlagMessage.TaskFlagMessage : null
        };
    }

    private clearInputs(): void {
        this.equals = null;
        this.minimum = null;
        this.maximum = null;
        this.betweenMin = null;
        this.betweenMax = null;
    }
}
