import { 
    Injectable, 
} from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Service for comminucating changes to customize workspace mode.
 * 
 *   "customize workspace" is also known as "Unlocked" workspace.
 */
@Injectable()
export class CustomizeWorkspaceService  {
    private customizeWorkspaceChangeSource = new Subject<boolean>();
    customizeWorkspaceChange$ = this.customizeWorkspaceChangeSource.asObservable();

    customizeWorkspaceActive = false;

    setCustomizeWorkspaceActive(newCustomizeWorkspaceActive: boolean) {
        if (this.customizeWorkspaceActive !== newCustomizeWorkspaceActive) {
            this.customizeWorkspaceActive = newCustomizeWorkspaceActive;
            this.customizeWorkspaceChangeSource.next(newCustomizeWorkspaceActive);
        }
    }

    isCustomizeWorkspaceActive(): boolean {
        return this.customizeWorkspaceActive;
    }
}
