import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import { notEmpty } from '../common/util/not-empty';
import { getIsActivePredicate } from '../services/queries';

import { DataManagerService } from '../services/data-manager.service';
import { QueryDef } from '../services/query-def';
import { BaseEntityService } from '../services/base-entity.service';
import { WebApiService } from '../services/web-api.service';
import { CurrentWorkgroupService } from '../services/current-workgroup.service';
import { SaveChangesService } from '../services/save-changes.service';
import { ScheduleType } from './../protocol/models/schedule-type.enum';
import { Protocol, ProtocolInstance } from '@common/types';
import { ProtocolTask } from '@common/types/models/protocol-task.interface';
import { WorkflowService } from '../workflow/services/workflow.service';

@Injectable()
export class ProtocolService extends BaseEntityService {

    draggedProtocols: any[];

    readonly ENTITY_TYPE = 'Protocols';
    readonly ENTITY_NAME = 'Protocol';
    readonly COMPONENT_LOG_TAG = 'protocol-detail';
    readonly ERROR_MSG_INVALID_NAME = " A protocol requires a name. Please enter a protocol name and try again.";

    constructor(
        private dataManager: DataManagerService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private webApiService: WebApiService,
        private saveChangesService: SaveChangesService,
        private workflowService: WorkflowService
    ) {
        super();

        this.draggedProtocols = [];
    }

    getProtocols(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
            predicates.push(Predicate.create('C_ParentProtocol_key', '==', null));

            if (notEmpty(predicates)) {
                query = query.where(Predicate.and(predicates));
            }
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    buildPredicates(filter: any): Predicate[] {
        const predicates: Predicate[] = [];

        if (!filter) {
            return predicates;
        }

        if (filter.ProtocolName) {
            predicates.push(
                Predicate.create('ProtocolName', FilterQueryOp.Contains, { value: filter.ProtocolName })
            );
        }

        if (filter.TaskName) {
            predicates.push(
                Predicate.create('ProtocolTask', FilterQueryOp.Any,
                    'WorkflowTask.TaskName', FilterQueryOp.Contains, { value: filter.TaskName })
            );
        }

        if (filter.TaskType) {
            predicates.push(
                Predicate.create('ProtocolTask', FilterQueryOp.Any,
                    'WorkflowTask.cv_TaskType.TaskType', 
                    FilterQueryOp.Contains, { value: filter.TaskType })
            );
        }

        if (filter.IsActive) {
            const isActivePredicate: Predicate = getIsActivePredicate(filter.IsActive);
            predicates.push(isActivePredicate);
        }

        if (notEmpty(filter.C_Protocol_keys)) {
            predicates.push(
                Predicate.create('C_Protocol_key', 'in', filter.C_Protocol_keys)
            );
        }

        return predicates;
    }

    searchProtocols(queryDef: QueryDef): Promise<any[]> {
        // don't need count of search results
        queryDef.inlineCount = false;

        let query = this.buildDefaultQuery('Protocols', queryDef);
        query = query.select('C_Protocol_key, ProtocolName');


        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }
        predicates.push(Predicate.create('C_ParentProtocol_key', '==', null));

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.returnQueryResults(query);
    }

    getProtocol(protocolKey: number, expands?: string[]): Promise<any> {
        let query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_Protocol_key', '==', protocolKey);

        if (notEmpty(expands)) {
            query = query.expand(expands.join(','));
        }

        return this.dataManager.returnSingleQueryResult(query);
    }

    getProtocolTasks(protocolKey: number): Promise<any[]> {
        const expands = [
            'WorkflowTask.cv_TaskType',
            'WorkflowTask.Input.cv_DataType',
            'WorkflowTask.Input.cv_DosingTable',
            'WorkflowTask.Input.cv_JobCharacteristicType',
            'InputDefault',
            'SampleGroup',
            'TaskGroup',
            'TaskGroup.Pattern'
        ];
        const query = EntityQuery.from('ProtocolTasks')
            .expand(expands.join(','))
            .where('C_Protocol_key', '==', protocolKey)
            .orderBy('SortOrder');

        return this.dataManager.returnQueryResults(query);
    }

    getLastUsedDate(protocolKey: number): Promise<Date> {
        // TODO: Get from cache
        const query = EntityQuery.from('TaskInstances')
            .where('ProtocolTask.C_Protocol_key', '==', protocolKey)
            .expand('ProtocolTask')
            .orderBy('DateCreated DESC');

        return this.dataManager.returnSingleQueryResult(query).then((result) => {
            return result != null ? result.DateCreated : null;
        });
    }

    createProtocol(): any {
        const protocol = this.dataManager.createEntity(this.ENTITY_NAME);

        // Defaults
        protocol.IsActive = true;
        protocol.lastUsedDate = '';

        return protocol;
    }

    createProtocolSection(initialValues: any): any {
        const entityName = 'ProtocolTaskSection';
        return this.dataManager.createEntity(entityName, initialValues);
    }

    createProtocolTask(initialValues: any): any {
        const entityName = 'ProtocolTask';
        return this.dataManager.createEntity(entityName, initialValues);
    }

    createInputDefault(initialValues: any): any {
        const entityName = 'InputDefault';
        return this.dataManager.createEntity(entityName, initialValues);
    }

    async deleteProtocol(protocol: Protocol): Promise<any> {
        const expands = ['StoredFileMap', 'TaskGroup', 'ProtocolTask', 'ChildProtocolTaskSection'];
        await this.dataManager.ensureRelationships([protocol], expands);
        this.deleteStoredFileMaps(protocol.StoredFileMap);
        // Delete TaskGroup
        while (protocol.TaskGroup.length > 0) {
            const taskGroup = protocol.TaskGroup[0];
            this.dataManager.deleteEntity(taskGroup);
        }
        // Delete ProtocolTasks
        while (protocol.ProtocolTask.length > 0) {
            const protocolTask = protocol.ProtocolTask[0];
            this.deleteProtocolTask(protocolTask);
        }
        // Delete ProtocolTaskSections
        while (protocol.ChildProtocolTaskSection.length > 0) {
            const protocolTaskSection = protocol.ChildProtocolTaskSection[0];
            this.deleteProtocolSection(protocolTaskSection);
        }
        this.dataManager.deleteEntity(protocol);
    }

    private deleteStoredFileMaps(storedFileMaps: any) {
        if (storedFileMaps) {
            while (storedFileMaps.length > 0) {
                const storedFileMap = storedFileMaps[0];
                this.dataManager.deleteEntity(storedFileMap);
                // Intentionally does not delete related StoredFile
            }
        }
    }

    checkIfMixedProtocol(protocolKey: any): Promise<any> {
        const apiUrl = 'api/jobdata/isprotocolmixed/' +
            protocolKey;
        return this.webApiService.callApi(apiUrl);
    }

    // copyProtocol(destWorkgroupKey: any, sourceWorkgroupName: any, protocolKey: any): Promise<any> {
    //     let apiUrl = 'api/copy/protocol/';
    //     let copyData = {
    //         sourceWorkgroupKey: this.currentWorkgroupService.getCurrentWorkgroupKey(),
    //         destWorkgroupKey,
    //         sourceWorkgroupName,
    //         objectKey: protocolKey
    //     };
    //     return this.webApiService.postApi(apiUrl, copyData);
    // }

    async copyToCurrentWorkgroup(fromProtocol: Protocol, toProtocol: Protocol): Promise<any> {
        // map from ProtocolTask keys to newly created ProtocolTasks
        const protocolTaskMap: any = {};

        // map from ProtocolTaskSection keys to newly created ProtocolTaskSections
        const protocolTaskSectionMap: any = {};

        // Ensure that the ProtocolTasks and InputDefaults are loaded
        const expands = [
            'ProtocolTask.InputDefault',
        ];

        await this.dataManager.ensureRelationships([fromProtocol], expands);
        // Copy protocol level details
        toProtocol.ProtocolName = fromProtocol.ProtocolName + ' (Copy)';
        toProtocol.Description = fromProtocol.Description;
        toProtocol.C_Workgroup_key = fromProtocol.C_Workgroup_key;
        toProtocol.IsActive = fromProtocol.IsActive;
        // Copy protocol task sections
        for (const fromProtocolTaskSection of fromProtocol.ChildProtocolTaskSection) {
            const toProtocolTaskSection: any = this.dataManager.createEntity('ProtocolTaskSection', {
                SectionName: fromProtocolTaskSection.SectionName,
                SortOrder: fromProtocolTaskSection.SortOrder,
                C_ParentProtocol_key: toProtocol.C_Protocol_key,
                C_Workgroup_key: fromProtocolTaskSection.C_Workgroup_key
            });
            protocolTaskSectionMap[fromProtocolTaskSection.C_ProtocolTaskSection_key] = toProtocolTaskSection.C_ProtocolTaskSection_key;
        }
        // Copy protocol tasks
        for (const fromProtocolTask of fromProtocol.ProtocolTask) {
            const toProtocolTask: any = this.dataManager.createEntity('ProtocolTask', {
                C_Workgroup_key: fromProtocolTask.C_Workgroup_key,
                C_Protocol_key: toProtocol.C_Protocol_key,
                C_ScheduleType_key: fromProtocolTask.C_ScheduleType_key,
                C_TimeRelation_key: fromProtocolTask.C_TimeRelation_key,
                C_TimeUnit_key: fromProtocolTask.C_TimeUnit_key,
                C_WorkflowTask_key: fromProtocolTask.C_WorkflowTask_key,
                TimeFromRelativeTask: fromProtocolTask.TimeFromRelativeTask,
                SortOrder: fromProtocolTask.SortOrder,
                TaskAlias: fromProtocolTask.TaskAlias,
                RelativeToStudyStart: fromProtocolTask.RelativeToStudyStart,
                C_ProtocolTaskSection_key: protocolTaskSectionMap[fromProtocolTask.C_ProtocolTaskSection_key]
            });
            protocolTaskMap[fromProtocolTask.C_ProtocolTask_key] = toProtocolTask;

            // Copy the InputDefaults
            for (const fromInputDefault of fromProtocolTask.InputDefault) {
                this.dataManager.createEntity('InputDefault', {
                    // Copy from old InputDefault
                    C_Workgroup_key: fromInputDefault.C_Workgroup_key,
                    C_Input_key: fromInputDefault.C_Input_key,
                    InputValue: fromInputDefault.InputValue,

                    // Map to new ProtocolTask
                    C_ProtocolTask_key: toProtocolTask.C_ProtocolTask_key,
                });
            }

            // Copy SampleGroups
            for (const sampleGroup of fromProtocolTask.SampleGroup) {
                toProtocolTask.SampleGroup.push(this.dataManager.createEntity('SampleGroup', {
                    DateCreated: new Date(),
                    C_TaskInstance_key: toProtocolTask.C_TaskInstance_key,
                    C_SampleType_key: sampleGroup.C_SampleType_key,
                    C_SampleStatus_key: sampleGroup.C_SampleStatus_key,
                    C_PreservationMethod_key: sampleGroup.C_PreservationMethod_key,
                    C_ContainerType_key: sampleGroup.C_ContainerType_key,
                    NumSamples: sampleGroup.NumSamples,
                    C_SampleSubtype_key: sampleGroup.C_SampleSubtype_key,
                    C_SampleProcessingMethod_key: sampleGroup.C_SampleProcessingMethod_key,
                    SendTo: sampleGroup.SendTo,
                    C_SampleAnalysisMethod_key: sampleGroup.C_SampleAnalysisMethod_key,
                    SpecialInstructions: sampleGroup.SpecialInstructions
                }));
            }

            // Copy TaskGroup
            if (fromProtocolTask.TaskGroup) {
                const fromTaskGroup = fromProtocolTask.TaskGroup;
                const newPattern = this.dataManager.createEntity('Pattern', {
                    DaysPattern: fromTaskGroup.Pattern.DaysPattern
                });
                const newTaskGroup = this.dataManager.createEntity('TaskGroup', {
                    Pattern: newPattern,
                    C_Protocol_key: toProtocol.C_Protocol_key,
                    C_Frequency_key: fromTaskGroup.C_Frequency_key,
                    C_ToEndFrequency_key: fromTaskGroup.C_ToEndFrequency_key,
                    TimeDifference: fromTaskGroup.TimeDifference,
                    Occurrences: fromTaskGroup.Occurrences,
                    Offset: fromTaskGroup.Offset,
                    ToEnd: fromTaskGroup.ToEnd
                });
                toProtocolTask.TaskGroup = newTaskGroup;
            }
        }
        await this.dataManager.saveEntities(['Protocol', 'ProtocolTaskSection', 'ProtocolTask', 'InputDefault', 'SampleGroup', 'TaskGroup', 'Pattern']);
        // Do a second pass now that we have ProtocolTask keys
        for (const fromProtocolTask of fromProtocol.ProtocolTask) {
            // Get the corresponding new ProtocolTask
            const toProtocolTask = protocolTaskMap[fromProtocolTask.C_ProtocolTask_key];

            // Map to the new relative task
            if (fromProtocolTask.C_RelativeProtocolTask_key) {
                const relativeProtocolTask = protocolTaskMap[fromProtocolTask.C_RelativeProtocolTask_key];
                toProtocolTask.C_RelativeProtocolTask_key = relativeProtocolTask.C_ProtocolTask_key;
            }
        }
        return await this.dataManager.saveEntities(['ProtocolTask']);
    }

    isProtocolSafeToDelete(protocol: Protocol): Promise<boolean> {
        const query1 = EntityQuery.from('Protocols')
            .where('C_ParentProtocol_key', '==', protocol.C_Protocol_key);

        return this.dataManager.returnQueryCount(query1).then((childCount) => {
            if (childCount > 0) {
                return false;
            }

            const query2 = EntityQuery.from('TaskInstances')
                .where('ProtocolTask.C_Protocol_key', '==', protocol.C_Protocol_key);

            return this.dataManager.returnQueryCount(query2).then((count) => {
                return count === 0;
            });
        });
    }

    isProtocolTaskSafeToDelete(protocolTask: any): Promise<boolean> {
        const query = EntityQuery.from('TaskInstances')
            .where('C_ProtocolTask_key', '==', protocolTask.C_ProtocolTask_key);

        return this.dataManager.returnQueryCount(query).then((count) => {
            return count === 0;
        });
    }
    
    deleteProtocolTask(protocolTask: any) {
        // Purge InputDefault
        while (protocolTask.InputDefault.length > 0) {
            this.dataManager.deleteEntity(protocolTask.InputDefault[0]);
        }

        // Purge SampleGroup
        while (protocolTask.SampleGroup.length > 0) {
            this.dataManager.deleteEntity(protocolTask.SampleGroup[0]);
        }

        this.dataManager.deleteEntity(protocolTask);
    }

    deleteProtocolSection(section: any) {
        this.dataManager.deleteEntity(section);
    }

    cancelProtocol(protocol: any) {
        if (!protocol) {
            return;
        }

        if (protocol.C_Protocol_key > 0) {
            this._cancelProtocolEdits(protocol);
        } else {
            this._cancelNewProtocol(protocol);
        }
    }

    private _cancelNewProtocol(protocol: any) {
        try {
            this.deleteProtocol(protocol);
        } catch (error) {
            console.error('Error cancelling new protocol: ' + error);
        }
    }

    private _cancelProtocolEdits(protocol: any) {
        this.dataManager.rejectEntityAndRelatedPropertyChanges(protocol);

        for (const protocolTask of protocol.ProtocolTask) {
            // Cancel the InputDefault edits
            this.dataManager.rejectChangesToEntityByFilter(
                'InputDefault', (item: any) => {
                    return item.C_ProtocolTask_key === protocolTask.C_ProtocolTask_key;
                }
            );
            // Cancel the SampleGroups edits
            this.dataManager.rejectChangesToEntityByFilter(
                'SampleGroup', (item: any) => {
                    return item.C_ProtocolTask_key === protocolTask.C_ProtocolTask_key;
                }
            );
        }
        
        this.dataManager.rejectChangesToEntityByFilter(
            'TaskGroup', (item: any) => {
                return item.C_Protocol_key === protocol.C_Protocol_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'ProtocolTaskSection', (item: any) => {
                return item.C_ParentProtocol_key === protocol.C_Protocol_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'ProtocolTask', (item: any) => {
                return item.C_Protocol_key === protocol.C_Protocol_key;
            }
        );

        const fileMaps = this.dataManager.rejectChangesToEntityByFilter(
            'StoredFileMap', (item: any) => {
                return item.C_Protocol_key === protocol.C_Protocol_key;
            }
        );
        // also reject files associated with each fileMap
        for (const fileMap of fileMaps) {
            this.dataManager.rejectChangesToEntityByFilter(
                'StoredFile', (item: any) => {
                    return item.C_StoredFile_key === fileMap.C_StoredFile_key;
                }
            );
        }
    }

    /**
     * Is the ScheduleType one of the relative ones?
     * 
     * @param scheduleType string or cv_ScheduleType object
     */
    isRelativeScheduleType(scheduleType: any | string): boolean {
        if (!scheduleType) {
            return false;
        }

        if (typeof (scheduleType) !== 'string') {
            scheduleType = scheduleType.ScheduleType;
            if (!scheduleType) {
                return false;
            }
        }

        return (scheduleType === ScheduleType.RelativeTaskDue) ||
            (scheduleType === ScheduleType.RelativeTaskComplete);
    }

    isNameValid(protocol: any): boolean {
        if (protocol.ProtocolName) {
            const name = protocol.ProtocolName.trim();
            if (name.length > 0) {
                return true;
            }
        }
        return false;
    }

    async ensureVisibleColumnsDataLoaded(protocols: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(protocols[0], visibleColumns);
        return this.dataManager.ensureRelationships(protocols, expands);
    }


    //Circular dependency checks
    detectCircularReference(currentProtocolTask: ProtocolTask): boolean {
        const visitedProtocolTaskKeys = new Set();

        while (currentProtocolTask) {
            if (visitedProtocolTaskKeys.has(currentProtocolTask.C_ProtocolTask_key)) {
                return true;  // Circular reference detected
            }

            visitedProtocolTaskKeys.add(currentProtocolTask.C_ProtocolTask_key);

            currentProtocolTask = currentProtocolTask.RelativeProtocolTask;
        }

        return false;  // No circular reference detected
    }

    removeProtocolInstance(protocolInstance: ProtocolInstance) {
        while (protocolInstance.TaskInstance.length > 0) {
            const taskInstance = protocolInstance.TaskInstance[0];
            while (taskInstance.MemberTaskInstance?.length > 0) {
                const memberTaskInstance = taskInstance.MemberTaskInstance[0];
                this.workflowService.deleteTask(memberTaskInstance);
            }
            this.workflowService.deleteTask(taskInstance);
        }
    }


    removeProtocolInstances(protocolInstances: ProtocolInstance[]) {
        for (const protocolInstance of protocolInstances) {
            this.removeProtocolInstance(protocolInstance);
        }
    }
}
