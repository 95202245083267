<div class="facet">
    <facet-header caption="Reports"
                  [facet]="facet">
        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refresh()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body"
         *ngIf="facet.Privilege !== 'None'">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <!-- Menu -->
        <div class="report-menu-container row mx-0 mt-3"
             *ngIf="facetView === FacetView.LIST_VIEW">

            <div class="col-md-6">
                <!-- 'Normal' Reports (by category) -->
                <ng-container *ngFor="let reportCategory of reportCategories">
                    <div class="category-wrapper"
                         *ngIf="reportCategory.reportTypes | notEmpty">
                        <h3>{{reportCategory.categoryName}}</h3>

                        <ul class="list-unstyled">
                            <li *ngFor="let reportType of reportCategory.reportTypes">
                                <a (click)="selectReportType(reportType)" class="report-type-link">
                                    {{reportType.text}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </ng-container>
            </div>


            <div class="col-md-6">
                <!-- Bulk Exports -->
                <div class="category-wrapper">
                    <h3>{{BULK_EXPORT_CATEGORY_NAME}}</h3>

                    <ul class="list-unstyled">
                        <li *ngFor="let reportType of bulkExportTypes">
                            <a (click)="selectBulkExport(reportType)" class="report-type-link">
                                {{reportType.text}}
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Standard 'Custom' Reports -->
                <div class="category-wrapper" *ngIf="globalReportTypes">
                    <h3>Standard</h3>

                    <ul class="list-unstyled">
                        <li *ngFor="let reportType of globalReportTypes">
                            <a (click)="selectCustomSearch(reportType)" class="report-type-link">
                                {{reportType.text}}
                            </a>
                        </li>
                    </ul>
                </div>

                <!-- Custom Reports -->
                <div class="category-wrapper">
                    <h3>Custom</h3>

                    <ul class="list-unstyled"
                        *ngIf="customReportTypes | notEmpty; else no_custom_report_types">
                        <li *ngFor="let reportType of customReportTypes">
                            <a (click)="selectCustomSearch(reportType)" class="report-type-link">
                                {{reportType.text}}
                            </a>
                        </li>
                    </ul>

                    <ng-template #no_custom_report_types>
                        <div class="no-custom-reports-container"
                             *ngIf="!loading">
                            <p>
                                Please contact
                                <a href="{{contactSupportUri}}" target="_blank" rel="noopener">Climb Support</a>
                                to discuss premium customized reporting options for this workgroup.
                            </p>
                        </div>
                    </ng-template>
                </div>
            </div>

        </div>

        <reporting-detail *ngIf="facetView === FacetView.DETAIL_VIEW"
                          [reportSource]="selectedReportSource"
                          [reportType]="selectedReportType"
                          (exit)="exitDetail()"></reporting-detail>

    </div>
</div>
