import { BulkAddCommService } from './../common/facet/bulk-add-comm.service';
import { SampleBulkEditComponent } from './bulkedit/sample-bulk-edit.component';
import { ConfirmService } from './../common/confirm/confirm.service';
import {
    Component,
    Input,
    OnInit,
    ViewChild,
    TemplateRef,
    OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CellFormatterService, ColumnsState, TableColumnDef } from '@common/datatable';
import { MaterialService } from '../services/material.service';
import { SampleService } from './sample.service';
import { SampleLogicService } from './sample-logic.service';
import { TranslationService } from '../services/translation.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { SampleFilterComponent } from './sample-filter.component';
import { SampleTableOptions } from './sample-table-options';

import {
    TableState,
    DataResponse
} from '@common/datatable/data-table.interface';
import { WsFilterEvent } from '../services/ws-filter-event';
import { filterToDate } from '../common/util';
import { QueryDef } from '../services/query-def';
import { DataContextService } from '../services/data-context.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ConfirmOptions } from '../common/confirm';
import { SettingService } from '../settings/setting.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { takeUntil } from "rxjs/operators";
import { JobService } from "../jobs/job.service";
import { CopyBufferService } from '@common/services';
import { arrowClockwise, brokenChain, chain, magnifier, squareOnSquare } from '@icons';

@Component({
    selector: 'sample-facet',
    templateUrl: './sample-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS.concat([
        BulkAddCommService
    ])
})
export class SampleFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facet: any;

    @ViewChild('bulkEdit') bulkEdit: SampleBulkEditComponent;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier, squareOnSquare };
    readonly componentName = 'sample';
    readonly COMPONENT_LOG_TAG = 'sample-facet';

    sampleTableOptions: SampleTableOptions;
    componentFilterSubscription: Subscription;

    requiredFields: string[];
    activeFields: string[];

    isGLP: boolean;
    isCRO: boolean;

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private confirmService: ConfirmService,
        private copyBufferService: CopyBufferService,
        private cellFormatterService: CellFormatterService,
        private dataContext: DataContextService,
        private materialService: MaterialService,
        private sampleService: SampleService,
        private sampleLogicService: SampleLogicService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private modalService: NgbModal,
        private settingService: SettingService,
        private featureFlagService: FeatureFlagService,
        private jobService: JobService,
        workspaceFilterService: WorkspaceFilterService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.sampleTableOptions = new SampleTableOptions(
            this.cellFormatterService,
            this.sampleLogicService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.sampleTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.supportedWorkspaceFilters = ['animal-filter', 'job-filter'];

        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
        this.createPaginator();
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);

        this.isGLP = this.featureFlagService.getFlag("IsGLP")
            && this.featureFlagService.getFlag("IsGLP").IsActive
            && this.featureFlagService.getFlag("IsGLP").Value.toLowerCase() === "true";
        this.isCRO = this.featureFlagService.getFlag("IsCRO")
            && this.featureFlagService.getFlag("IsCRO").IsActive
            && this.featureFlagService.getFlag("IsCRO").Value.toLowerCase() === "true";

        this.settingService.getFacetSettingsByType('sample', this.isGLP, this.isCRO).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFieldValues(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        });
    }

    refreshData() {
        this.initialize();
        this.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateHarvestStart = filterToDate(this.filter.DateHarvestStart);
                this.filter.DateHarvestEnd = filterToDate(this.filter.DateHarvestEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands = [
            'Material.JobMaterial.Job.Study',
            'Material.Line',
            'Material.Note',
            'Material.MaterialSourceMaterial.SourceMaterial.Animal',
            'Material.MaterialSourceMaterial.SourceMaterial.Sample',
            'SampleConstruct.Construct',
            'SampleOrder.Order',
        ];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.sampleService.getSamples(queryDef);

            const visibleColumns = this.getVisibleColumns(this.sampleTableOptions.options);
            await this.sampleService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    dragStart() {
        this.sampleService.draggedSamples = this.selectedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.sampleService.draggedSamples = [];
        }, 500);
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewSample().then((sample) => {
            this.stopLoading();
            this.itemToEdit = sample;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewSample(): Promise<any> {
        let newSample: any = null;

        return this.materialService.createAsType('Sample').then((newMaterial) => {
            newSample = this.sampleService.create();

            newSample.Material = newMaterial;
            newMaterial.C_Taxon_key = null;
            newSample.C_SampleType_key = null;
            newSample.SampleCharacteristics = [];
        }).then(() => {
            const p1 = this.vocabularyService.getCVDefault('cv_SampleStatuses')
                .then((value) => {
                    newSample.cv_SampleStatus = value;
                });
            const p2 = this.vocabularyService.getCVDefault('cv_Units')
                .then((value) => {
                    newSample.cv_Unit = value;
                });
            const p3 = this.vocabularyService.getCVDefault('cv_SampleTypes')
                .then((value) => {
                    newSample.cv_SampleType = value;
                });
            const p4 = this.vocabularyService.getCVDefault('cv_PreservationMethods')
                .then((value) => {
                    newSample.cv_PreservationMethod = value;
                });
            const p5 = this.vocabularyService.getCVContainerTypeDefault('Sample')
                .then((value) => {
                    newSample.Material.cv_ContainerType = value;
                });
            const p6 = this.vocabularyService.getCVDefault('cv_MaterialOrigins')
                .then((value) => {
                    newSample.Material.cv_MaterialOrigin = value;
                });
            const p7 = this.vocabularyService.getCVDefault('cv_SampleSubtypes')
                .then((value) => {
                    newSample.cv_SampleSubtype = value;
                });
            const p8 = this.vocabularyService.getCVDefault('cv_SampleProcessingMethods')
                .then((value) => {
                    newSample.cv_SampleProcessingMethod = value;
                });
            const p9 = this.vocabularyService.getCVDefault('cv_SampleAnalysisMethods')
                .then((value) => {
                    newSample.cv_SampleAnalysisMethod = value;
                });

            return Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9]);
        }).then(() => {
            return newSample;
        });
    }

    openFilter() {
        const ref = this.modalService.open(SampleFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as SampleFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    copySamples() {
        this.copyBufferService.copy(this.selectedRows);
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    selectedRowsChange(rows: any[]) {
        //
    }

    openLabelModal(labelmodal: TemplateRef<any>) {
        this.modalService.open(labelmodal);
    }

    doBulkDelete() {
        const modalTitle = 'Delete ' + (this.selectedRows.length === 1 ? 'Sample' : 'Samples');
        const modalText = 'Delete ' + this.selectedRows.length +
            ' selected ' + (this.selectedRows.length === 1 ? 'sample' : 'samples') + '? This action cannot be undone.';

        return this.confirmService.confirmDelete(modalTitle, modalText).then(
            // success
            () => {
                this.sampleService.bulkDeleteSamples(this.selectedRows).then((result) => {
                    if (result.data.HasAssociatedData) {
                        const title = (result.data.Names.length === 1 ? 'Sample' : 'Samples') + ' with Data';
                        const message = 'This ' + (result.data.Names.length === 1 ? 'sample has' : 'samples have') + ' associated data and cannot be removed:';
                        const details = result.data.Names;
                        const confirmOptions: ConfirmOptions = {
                            title,
                            message,
                            yesButtonText: 'OK',
                            onlyYes: true,
                            details
                        };
                        return this.confirmService.confirm(confirmOptions);
                    } else {
                        this.facetLoadingState.changeLoadingState(true);

                        for (const sample of this.selectedRows) {
                            this.sampleService.deleteSample(sample);

                            const material = sample.Material;
                            if (material) {
                                if (material.JobMaterial?.length > 0) {
                                    for (const jobMaterial of material.JobMaterial) {
                                        this.jobService.deleteJobMaterial(jobMaterial);
                                    }
                                }
                                this.materialService.deleteMaterial(material);
                            }
                        }

                        this.dataContext.save().then(() => {
                            this.reloadTable();
                        }).then(() => {
                            this.facetLoadingState.changeLoadingState(false);
                        }).catch((error) => {
                            this.facetLoadingState.changeLoadingState(false);
                            throw error;
                        });
                    }
                });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.sampleService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}
