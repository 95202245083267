import { WizardSharedModule } from './../wizard/wizard-shared.module';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig } from './auth.config';
import { SSOService } from './sso.service';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import OktaAuth, { OktaAuthOptions } from '@okta/okta-auth-js';
import { oktaClientId, oktaDomainUrl } from '../config/climb-web-settings';
import { RouteEnum } from "../routing/route.enum";

export function storageFactory(): OAuthStorage {
    return localStorage;
}

const oktaAuthOptions: OktaAuthOptions = {
    issuer: oktaDomainUrl,
    clientId: oktaClientId,
    redirectUri: window.location.origin + '/' + RouteEnum.LoginCallback
};

const oktaAuth = new OktaAuth(oktaAuthOptions);

/**
 * NOTE: The current way of implementing the Okta Auth Module is deprecated.
 * However, the recommended solution is in the 6 + version of this library, which requires a higher Angular version than what is currently installed.
 * This should be updated when the Angular version is updated.
 */
@NgModule({
    imports: [
        WizardSharedModule,
        OAuthModule.forRoot(),
        OktaAuthModule
    ],
    providers: [
        {
            provide: OKTA_CONFIG,
            useValue: { oktaAuth }
        },
        SSOService,
    ]
})
export class SSOModule {
    static forRoot(): ModuleWithProviders<SSOModule> {
        return {
            ngModule: SSOModule,
            providers: [
                { provide: AuthConfig, useValue: authConfig },
                { provide: OAuthStorage, useFactory: storageFactory },
            ]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: SSOModule) {
        if (parentModule) {
            throw new Error('SSOModule is already loaded. Import it in the AppModule only');
        }
    }
}
