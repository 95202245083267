import { FacetLoadingStateService } from './../common/facet/facet-loading-state.service';
import { map } from 'rxjs/operators';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
    Output,
    EventEmitter,
} from '@angular/core';

import { OrderService } from './order.service';
import { OrderVocabService } from './order-vocab.service';

import { PrivilegeService } from '../services/privilege.service';
import { ConfirmService } from '../common/confirm';
import { DataContextService } from '../services/data-context.service';
import { LineService } from '../lines/line.service';
import { AnimalVocabService } from '../animals/services/animal-vocab.service';
import { ReasonForChangeService } from '../common/reason-for-change/reason-for-change.service';

@Component({
    selector: 'order-animal-table',
    templateUrl: './order-animal-table.component.html',
    styles: [`
        .animals-table {
            margin-top: 10px;
           }
    `]
})
export class OrderAnimalTableComponent implements OnInit, OnChanges {
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* The entity to assign */
    @Input() entity: any;

    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    @Input() taxonKey: any;
    @Output() onAnimalCountChanged = new EventEmitter<number>();

    // CVs
    sexes: any[] = [];
    taxons: any[] = [];
    shipmentAnimals: any[] = [];
    shipmentContainers: any[] = [];

    // State
    readonly COMPONENT_LOG_TAG = 'animals-table';

    readwrite: boolean;
    readonly: boolean;
    animalsShown: boolean;

    bulk: any = null;

    constructor(
        private privilegeService: PrivilegeService,
        private facetLoadingStateService: FacetLoadingStateService,
        private orderService: OrderService,
        private orderVocabService: OrderVocabService,
        private confirmService: ConfirmService,
        private dataContext: DataContextService,
        private lineService: LineService,
        private animalVocabService: AnimalVocabService,
        private reasonForChangeService: ReasonForChangeService
    ) { }

    // lifecycle
    ngOnInit() {
        this.initBulkValues();
        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.entity && !changes.entity.firstChange) {
            this.initialize();
        }
        if (changes.taxonKey && !changes.taxonKey.firstChange) {
            for (const animal of this.entity.AnimalOrder) {
                if (!animal.C_Taxon_key) {
                    animal.C_Taxon_key = this.taxonKey;
                }
            }
        }
    }

    animalCountChanged() {
        let count;
        for (const animal of this.entity.AnimalOrder) {
            if (animal.AnimalCount != null) {
                count = (count || 0) + animal.AnimalCount;
            }
        }
        this.onAnimalCountChanged.emit(count);
    }

    initBulkValues() {
        this.bulk = {
            sexKey: null,
            taxonKey: null,
            lineKey: null,
            shipmentAnimalKey: null,
            shipmentContainerKey: null,
            force: false,
        };
    }

    initialize() {
        this.facetLoadingStateService.changeLoadingState(true);

        this.setPrivileges();

        return this.getCVs().then(() => {
            this.getAnimalDetails();
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {

        const p1 = this.animalVocabService.sexes$.pipe(map((data: any) => {
            this.sexes = data;
        })).toPromise();
        const p2 = this.orderVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();
        const p3 = this.orderVocabService.shipmentAnimals$.pipe(map((data) => {
            this.shipmentAnimals = data;
        })).toPromise();
        const p4 = this.orderVocabService.shipmentContainers$.pipe(map((data) => {
            this.shipmentContainers = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4]);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    // Formatters for <select> input
    sexKeyFormatter = (value: any) => {
        return value.C_Sex_key;
    }
    sexFormatter = (value: any) => {
        return value.Sex;
    }

    taxonKeyFormatter = (value: any) => {
        return value.C_Taxon_key;
    }
    taxonFormatter = (value: any) => {
        return value.CommonName;
    }

    shipmentAnimalKeyFormatter = (value: any) => {
        return value.C_ShipmentAnimalCondition_key;
    }
    shipmentAnimalFormatter = (value: any) => {
        return value.ShipmentAnimalCondition;
    }

    shipmentContainerKeyFormatter = (value: any) => {
        return value.C_ShipmentContainerCondition_key;
    }
    shipmentContainerFormatter = (value: any) => {
        return value.ShipmentContainerCondition;
    }

    lineChanged(lineKey: any, animalOrder: any) {
        if (lineKey) {
            this.lineService.getLine(lineKey).then((fullLine) => {
                animalOrder.cv_Taxon = fullLine.cv_Taxon;
            });
        }
    }

    bulkSexChanged() {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_Sex_key) {
                animal.C_Sex_key = this.bulk.sexKey;
            }
        }
        this.initBulkValues();
    }

    bulkTaxonChanged() {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_Taxon_key) {
                animal.C_Taxon_key = this.bulk.taxonKey;
            }
        }
        this.initBulkValues();
    }

    bulkLineChanged() {
        this.lineService.getLine(this.bulk.lineKey).then((fullLine) => {
            for (const animal of this.entity.AnimalOrder) {
                if (this.bulk.force || !animal.C_Line_key) {
                    animal.C_Line_key = this.bulk.lineKey;
                    animal.cv_Taxon = fullLine ? fullLine.cv_Taxon : null;
                }
            }
            this.initBulkValues();
        });
    }

    bulkShipmentAnimalChanged() {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_ShipmentAnimalCondition_key) {
                animal.C_ShipmentAnimalCondition_key = this.bulk.shipmentAnimalKey;
            }
        }
        this.initBulkValues();
    }

    bulkShipmentContainerChanged() {
        for (const animal of this.entity.AnimalOrder) {
            if (this.bulk.force || !animal.C_ShipmentContainerCondition_key) {
                animal.C_ShipmentContainerCondition_key = this.bulk.shipmentContainerKey;
            }
        }
        this.initBulkValues();
    }

    createAnimal() {
        this.orderService.createAnimalOrder(this.pkValue);
        this.animalsShown = true;
    }

    private getAnimalDetails() {
        if (this.pkValue > 0) {
            return this.orderService.getAnimalOrder(this.pkValue).then(
                (items) => {
                    if (items.length > 0) {
                        this.animalsShown = true;
                        this.animalCountChanged();
                    }
                });
        }
    }

    /**
     * Delete Animal on "x" click
     */
    removeAnimal(animal: any) {
        if (!this.readonly) {
            return this.confirmService.confirmDelete(
                'Delete Animal',
                'Are you sure you want to delete this animal?'
            ).then(
                // confirmed
                () => {
                    this.reasonForChangeService.markModification([animal.Order]);
                    this.orderService.deleteAnimalOrder(animal);
                    if (this.pkValue > 0 &&
                        animal.C_AnimalOrder_key > 0
                    ) {
                        this.dataContext.save().then(() => {
                            this.animalCountChanged();
                        });
                    }
                },
                // cancel
                () => { /* do nothing on cancel */ }
            );
        }
    }

    /**
     * Hide/show lots table contents
     */
    toggleAnimalsShown() {
        this.animalsShown = !this.animalsShown;
    }
}
