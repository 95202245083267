<div class="facet">
    <facet-header
        caption="Clinical"
        [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Health Records">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button data-automation-id="copy-animals-button" 
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                [disabled]="!selectedRows.length"
                (click)="copyAnimals()"
                title="Copy Animals">
            <svg [climbIcon]="icons.squareOnSquare"></svg>
        </button>

        <button climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">
        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            (click)="changeView(BULK_OBSERVATION_VIEW)"
                            appInsightsClick="click-observation-bulk-observations">
                        Add Observations
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="editObservations()"
                            appInsightsClick="click-observation-bulk-edit-observations">
                        Edit Observations
                    </button>
                    <button climbDropdownItem
                            (click)="changeView(BULK_TREATMENT_VIEW)"
                            appInsightsClick="click-clinical-bulk-treatments">
                        Add Treatments
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="editTreatments()"
                            appInsightsClick="click-observation-bulk-edit-treatments">
                        Edit Treatments
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [options]="clinicalTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="clinicalTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (dragStart)="dragStart()"
                              (dragStop)="dragStop()"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <ng-container *ngIf="facetView === NEW_RECORD_VIEW">
            <new-health-record *ngIf="facet.Privilege !== 'ReadOnly'; else message"
                               [isSyncItem]="isSyncItem"
                               [taskData]="taskData"
                               (cancel)="cancelHealthRecord()"
                               (create)="createHealthRecord($event)">
            </new-health-record>
            <ng-template #message>
                <h3 class="text-center  mt-3">No Clinical Record</h3>
            </ng-template>
        </ng-container>

        <clinical-detail *ngIf="facetView === DETAIL_VIEW"
                        [(isWorkflowAdd)]="isWorkflowAdd"
                        [facet]="facet"
                        [animal]="itemToEdit.Animal"
                        [pageState]="detailPaginator.pageState"
                        (exit)="exitDetail()"
                        (previous)="detailPreviousClicked()"
                        (next)="detailNextClicked()"
        ></clinical-detail>

        <observation-bulk-add *ngIf="facetView===BULK_OBSERVATION_VIEW"
                         [facet]="facet"
                         (exit)="handleBulkAddExit($event)"></observation-bulk-add>
        <observation-bulk-edit *ngIf="facetView === BULK_OBSERVATION_EDIT_VIEW"
                         [facet]="facet"
                         [animalHealthRecords]="itemsToEdit"
                         (exit)="exitBulkEdit($event, true)"></observation-bulk-edit>
        <treatment-plan-bulk-add *ngIf="facetView === BULK_TREATMENT_VIEW"
                                 [facet]="facet"
                                 (exit)="handleBulkAddExit($event)">
        </treatment-plan-bulk-add>
        <treatment-bulk-edit *ngIf="facetView === BULK_TREATMENT_EDIT_VIEW"
                         [facet]="facet"
                         [animalHealthRecords]="itemsToEdit"
                         (exit)="exitBulkEdit($event, true)"></treatment-bulk-edit>

    </div>
</div>
