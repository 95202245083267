<div class="facet">
    <facet-header
        caption="IoT Plots"
        [facet]="facet">
        <button climbFacetAction
                (click)="refresh()"
                title="Refresh Plot">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <!-- TODO figure out why facet privilege isn't working in device management or iot plots facet -->
    <!--    <div class="panel-body" *ngIf="facet.Privilege !== 'None'">-->
    <div class="facet-body">
        <div style="margin: 10px">
            <button type="button" data-toggle="collapse" data-target="#options-panel" class="btn btn-secondary">Show/Hide Plot Options</button>

            <div id="options-panel" class="card collapse show" style="padding: 10px; margin-top: 10px">
                <h3>Plot Options</h3>

                <div class="form-group">
                    <label><input type="checkbox" [(ngModel)]="updateRealTime"> Update Plot in Real-Time</label>
                </div>

                <div class="form-group row flex-wrap mx-0">
                    <label class="col-form-label mr-2 mb-1">Plot Date Range:</label>
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Plot Date Start"
                                    [(ngModel)]="plotDateStart"
                                    class="mb-1">
                    </climb-ngb-date>
                    <span class="mx-2 mb-1">to</span>
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Plot Date End"
                                    [(ngModel)]="plotDateEnd"
                                    class="mb-1">
                    </climb-ngb-date>

                    <small class="form-text text-muted w-100 mb-1"
                       *ngIf="showDateRangeLimitWarning">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        Plot range cannot exceed {{DATE_RANGE_LIMIT}} days
                    </small>
                </div>

                <div class="form-group">
                    <label>Device Output</label>
                    <select class="form-control" name="device-output-select"
                        [(ngModel)]="selectedDeviceOutput"
                        (change)="selectedOutputChanged()">
                        <option *ngFor="let deviceOutput of deviceOutputs"
                                [ngValue]="deviceOutput">{{deviceOutput.OutputType}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Devices</label>
                    <select class="form-control" name="device-select" multiple
                            [(ngModel)]="selectedDevices"
                            (change)="selectedDevicesChanged()">
                        <option *ngFor="let device of filteredDeviceChoices"
                                [ngValue]="device"
                                [ngClass]="{inactive: !device.IsActive}">{{device.C_Device_key}} ({{device.DeviceModel?.DeviceModelName}})</option>
                    </select>
                </div>


            </div>

            <div style="min-height: 450px; position: relative;" class="mt-1">
                <plotly-plot *ngIf="chartData.layout"
                             [data]="chartData.traces"
                             [layout]="chartData.layout">
                </plotly-plot>
                <loading-overlay *ngIf="dataRequestPending && !updateRealTime"></loading-overlay>
            </div>
        </div>
    </div>
</div>
