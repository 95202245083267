<div class="detail-form-wrapper">

    <div class="detail-header">
        <h3>Housing Unit</h3>

        <pager-toolbar [itemName]="'housing unit'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       [promptForSave]="false"
                       (previous)="onSwitchView('previous')"
                       (next)="onSwitchView('next')"></pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="onSwitchView('exit')"
                        [promptForSave]="false"
                        [addHousing]="true"
                        (saveHousing)="onSaveHousing()">

            <div class="btn-group" additional-groups>
                <div class="btn-group" view-notes-button
                    [pkName]="'C_MaterialPool_key'"
                    [pkValue]="materialPool.C_MaterialPool_key"
                    [objectName]="materialPool.MaterialPoolID"
                    [objectType]="'MaterialPool'"
                    [isObjectNew]="materialPool | isNew"
                    [readonly]="facet.Privilege === 'ReadOnly'"
                    appInsightsClick="click-notes-from-housing">
                </div>

                <button type="button"
                        class="btn btn-secondary dropdown-toggle"
                        title="Print Cage Card"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <i class="fa fa-print" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Card</span>
                    <span class="sr-only">Print Cage Card</span>
                </button>
                <div class="dropdown-menu">
                    <a
                        *ngFor="let item of reportTypes"
                        class="dropdown-item"
                        (click)="requestCageCard(item)"
                    >
                        {{item}}
                    </a>
                </div>
            </div>

        </detail-toolbar>
    </div>

    <form name="housingForm" id="housingForm"
          class="detail-form"
          #housingForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">ID</label>
                <div class="col-md-10" *ngIf="materialPool">
                    <input type="text"
                           name="materialPoolID"
                           #materialPoolID="ngModel"
                           [(ngModel)]="materialPool.MaterialPoolID"
                           [disabled]="(materialPool | isNew) && housingNamingActive"
                           [required]="!(materialPool | isNew) || !housingNamingActive"
                           class="form-control input-medium"
                           validateUnique
                           uniqueEntity="MaterialPools"
                           uniqueProperty="MaterialPoolID"
                           [entityPK]="materialPool.C_MaterialPool_key"
                           entityPKName="C_MaterialPool_key"
                           maxlength="75" />
                    <small class="form-text text-muted"
                       *ngIf="materialPoolID.errors?.unique && materialPoolID.dirty && materialPool.MaterialPoolID !== originalMaterialPoolID">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        This ID already exists.
                    </small>
                    <small class="form-text text-muted"
                       *ngIf="(materialPool | isNew) && housingNamingActive">
                        The ID will be assigned automatically.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Type')">
                <label class="col-md-2 col-form-label">Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="materialPool.C_MaterialPoolType_key"
                                         [vocabChoices]="materialPoolTypes"
                                         [keyFormatter]="materialPoolTypeKeyFormatter"
                                         [optionFormatter]="materialPoolTypeFormatter"
                                         [required]="true"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Status')">
                <label class="col-md-2 col-form-label">Status</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="materialPool.C_MaterialPoolStatus_key"
                                         [vocabChoices]="materialPoolStatuses"
                                         [keyFormatter]="materialPoolStatusKeyFormatter"
                                         [optionFormatter]="materialPoolStatusFormatter"
                                         [required]="requiredFields.includes('C_MaterialPoolStatus_key')"
                                         [nullable]="!requiredFields.includes('C_MaterialPoolStatus_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Date')">
                <label class="col-md-2 col-form-label">Date</label>
                <div class="col-md-4">
                    <climb-ngb-date #dateControl="ngModel"
                                    name="Date Pooled"
                                    [(ngModel)]="materialPool.DatePooled"
                                    [required]="requiredFields.includes('DatePooled')"></climb-ngb-date>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Owner')">
                <label class="col-md-2 col-form-label">Owner</label>
                <div class="col-md-10">
                    <user-select [(model)]="materialPool.Owner"
                                 [forceValidSelection]="requiredFields.includes('Owner')"
                                 [required]="requiredFields.includes('Owner')"></user-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Location')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('MaterialLocation[0]') && materialPool.MaterialLocation.length === 0}">Location</label>
                <div class="col-md-10">
                    <location-history #locationHistory
                                      [materialLocations]="materialPool.MaterialLocation"
                                      [facetPrivilege]="facet.Privilege"
                                      (createMaterialLocation)="addMaterialPoolLocation()"
                                      (removeMaterialLocation)="removeMaterialPoolLocation($event)"></location-history>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Container Type')">
                <label class="col-md-2 col-form-label">Container Type</label>
                <div class="col-md-10">
                    <active-vocab-select [(model)]="materialPool.C_ContainerType_key"
                                         [vocabChoices]="containerTypes"
                                         [keyFormatter]="containerTypeKeyFormatter"
                                         [optionFormatter]="containerTypeFormatter"
                                         [required]="requiredFields.includes('C_ContainerType_key')"
                                         [nullable]="!requiredFields.includes('C_ContainerType_key')"></active-vocab-select>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Comments')">
                <label class="col-md-2 col-form-label">Comments</label>
                <div class="col-md-10">
                    <textarea name="materialPoolComments"
                              [(ngModel)]="materialPool.Comments"
                              [required]="requiredFields.includes('Comments')"
                              class="form-control input-medium"
                              maxlength="100"
                              rows="2"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Species</label>
                <div class="col-md-10">
                    <ng-container *ngIf="materialPool?.MaterialPoolMaterial[0]?.Material?.cv_Taxon">
                        <input type="text" name="animalTaxon"
                               [(ngModel)]="materialPool?.MaterialPoolMaterial[0]?.Material.cv_Taxon.CommonName"
                               readonly="readonly"
                               class="form-control input-medium" />
                    </ng-container>
                    <small class="form-text text-muted" *ngIf="!materialPool?.MaterialPoolMaterial[0]?.Material?.cv_Taxon">
                        Please specify an animal.
                    </small>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Animals')">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : isAnimalsRequired()}">Animals</label>
                <div class="col-md-10">
                    <housing-animal-table [materialPool]="materialPool"
                                          [materialPoolMaterials]="materialPool?.MaterialPoolMaterial"
                                          [facetPrivilege]="facet.Privilege"></housing-animal-table>
                </div>
            </div>

            <div *ngIf="isGLP && activeFields.includes('Social Status')" class="form-group row">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('SocialGroupMaterial[0]') && materialPool.SocialGroupMaterial.length === 0}">Social Status</label>
                <div class="col-md-10">
                    <housing-social-table #socialTable
                                          [materialPool]="materialPool"
                                          [socialGroupMaterials]="materialPool?.SocialGroupMaterial"
                                          [facetPrivilege]="facet.Privilege"></housing-social-table>
                </div>
            </div>

            <div *ngIf="isGLP && activeFields.includes('Compatibility')" class="form-group row">
                <label class="col-md-2 col-form-label"
                       [ngClass]="{'required-text' : requiredFields.includes('CompatibilityMaterial[0]') && materialPool.CompatibilityMaterial.length === 0}">Compatibility</label>
                <div class="col-md-10">
                    <housing-social-table #socialTable
                                          [materialPool]="materialPool"
                                          [compatibilityMaterials]="materialPool?.CompatibilityMaterial"
                                          [facetPrivilege]="facet.Privilege"></housing-social-table>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <h4>
                        <a (click)="toggleHousingDetailsExpanded()"
                           class="toggle-details-link"
                           [attr.title]="housingDetailsExpanded ? 'Hide details' : 'Show details'">
                            Housing History/Details<i class="fa fa-fw"
                                                      [ngClass]="housingDetailsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                                      [attr.title]="housingDetailsExpanded ? 'Hide details' : 'Show details'"
                                                      aria-hidden="true"></i>
                        </a>
                    </h4>
                </div>
                <div class="col-md-12"
                     *ngIf="housingDetailsExpanded">
                    <div style="margin-top: 1em;">
                        <housing-details [materialPoolMaterials]="materialPool?.MaterialPoolMaterial"
                                         [facetPrivilege]="facet.Privilege"></housing-details>
                    </div>
                </div>
            </div>

            <!-- Hide the IoT Section until we have IoT -->
            <!--
        <div class="form-group row"
            droppable
            parentDroppable>
            <div class="col-md-12">
                <h4>
                    <a (click)="toggleDeviceDetailsExpanded()"
                       [attr.title]="deviceDetailsExpanded ? 'Hide details' : 'Show details'">
                        IoT Device Details<i class="fa fa-fw"
                                                  [ngClass]="deviceDetailsExpanded ? 'fa-chevron-up' : 'fa-chevron-down'"
                                                  [attr.title]="deviceDetailsExpanded ? 'Hide details' : 'Show details'"
                                                  aria-hidden="true"></i>
                    </a>
                </h4>
            </div>
            <ng-container *ngIf="deviceDetailsExpanded">
                <label class="col-md-2 col-form-label">Devices</label>
                <div class="col-md-10">
                    <div style="margin-top: 1em;">
                        <device-multiselect [facetPrivilege]="facet.Privilege"
                                            [materialPool]="materialPool"></device-multiselect>
                    </div>
                </div>
            </ng-container>
        </div>
    -->
        </fieldset>


        <!-- New fieldset that is never disabled so as not to affect controls in child tables -->
        <fieldset>

            <div class="form-group row">
                <div class="col-md-12">
                    <detail-task-table #detailTaskTable
                                       [parent]="materialPool"
                                       [taskAssociations]="materialPool.TaskMaterialPool | taskFilter:TaskType.Housing"
                                       [taskType]="TaskType.Housing"
                                       [readonly]="facet.Privilege === 'ReadOnly'"
                                       [facet]="facet"
                                       [tableOptions]="detailTaskTableOptions"
                                       [tableSort]="taskTableSort"
                                       [(inputsExpanded)]="materialPool.inputsExpanded"
                                       [(tasksExpanded)]="materialPool.tasksExpanded"
                                       (taskInstanceAdd)="addTaskHousing($event)"></detail-task-table>
                </div>
            </div>

        </fieldset>
    </form>
</div>
