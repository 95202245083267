import { IValidatable, OnSaveSuccessful, SaveChangesService } from './../services/save-changes.service';
import {
    Component,
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { NamingService } from '../services/naming.service';
import { ResourceService } from '../resources/resource.service';
import { SettingService } from './setting.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { InactivityTimeoutService } from '../services/inactivity-timeout.service';
import { LocalStorageKey } from './../config';
import { LocalStorageService } from './../services/local-storage.service';

import {
    sortObjectArrayByProperty,
} from '../common/util/sort-array';
import { TranslationService } from '../services/translation.service';
import { BaseFacet, BaseFacetService, FacetView, IFacet } from '../common/facet';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { Subscription } from 'rxjs';
import { arrowClockwise } from '@icons';

@Component({
    selector: 'settings-facet',
    templateUrl: './settings-facet.component.html',
    styles: [`
        .facet-form {
            padding-top: 1em;
        }
        .settings-container {
            margin-bottom: 2em;
        }
        table active-vocab-select ~ input,
        table input ~ input {
            margin-top: .25rem;
        }
    `]
})
export class SettingsFacetComponent extends BaseFacet
    implements OnInit, OnDestroy, IValidatable, OnSaveSuccessful {
    @Input() facet: IFacet;

    readonly icons = { arrowClockwise };

    nameFormats: any[];
    resources: any[];
    jobPrefixFields: any[];
    jobSuffixFields: any[];
    animalPrefixFields: any[];
    orderPrefixFields: any[];
    orderSuffixFields: any[];
    settings: any;
    defaultHealthTechSetting: any;
    defaultJobPrefixFieldKey: number;
    defaultJobSuffixFieldKey: number;
    defaultAnimalPrefixFieldKey: number;
    defaultOrderPrefixFieldKey: number;
    defaultOrderSuffixFieldKey: number;
    inactivityTimeoutSetting: any;
    isGLP = false;

    loading = false;

    private readonly subs = new Subscription();
    readonly COMPONENT_LOG_TAG = 'settings-facet';

    constructor(
        private baseFacetService: BaseFacetService,
        workspaceFilterService: WorkspaceFilterService,
        private saveChangesService: SaveChangesService,
        private namingService: NamingService,
        private resourceService: ResourceService,
        private settingService: SettingService,
        private vocabularyService: VocabularyService,
        private featureFlagService: FeatureFlagService,
        private inactivityTimeoutService: InactivityTimeoutService,
        private localStorageService: LocalStorageService,
        private translationService: TranslationService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );
    }

    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.subs.add(this.saveChangesService.saveSuccessful$.subscribe(() => {
          this.onSaveSuccessful();
        }));
        this.initialize();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.saveChangesService.unregisterValidator(this);
    }

    initialize() {
        this.setFeatureFlags();
        this.refreshData();
    }

    setFeatureFlags() {
        const isGLPFlag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (isGLPFlag && isGLPFlag.IsActive && isGLPFlag.Value.toLowerCase() === "true");
    }

    refreshData() {
        this.loading = true;

        const p1 = this.getSettings();
        const p2 = this.getCVs();
        const p3 = this.getNameFormats();

        Promise.all([p1, p2, p3])
            .then(() => {
                this.loading = false;
            })
            .catch((err: Error) => {
                this.loading = false;

                throw err;
            });
    }

    private getCVs(): Promise<any> {
        const p1 = this.resourceService.getAllResources().then((data: any) => {
            this.resources = data;
        });

        const p2 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_JobPrefixFields'),
        ]).then(() => {
            return this.vocabularyService.getCV('cv_JobPrefixFields').then((fields: any[]) => {
                this.jobPrefixFields = this.setTranslatedJobPrefixFields(fields);
            });
        }).then(() => {
            const preferLocal = true;
            return this.vocabularyService.getCVDefault(
                'cv_JobPrefixFields', preferLocal
            ).then((field) => {
                this.defaultJobPrefixFieldKey = field.C_JobPrefixField_key;
            });
        });

        const p3 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_JobSuffixFields'),
        ]).then(() => {
            return this.vocabularyService.getCV('cv_JobSuffixFields').then((fields: any[]) => {
                this.jobSuffixFields = fields;
            });
        }).then(() => {
            const preferLocal = true;
            return this.vocabularyService.getCVDefault(
                'cv_JobSuffixFields', preferLocal
            ).then((field) => {
                this.defaultJobSuffixFieldKey = field.C_JobSuffixField_key;
            });
        });

        const p4 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_AnimalPrefixFields'),
        ]).then(() => {
            return this.vocabularyService.getCV('cv_AnimalPrefixFields').then((fields: any[]) => {
                this.animalPrefixFields = this.setTranslatedAnimalPrefixFields(fields);
            });
        }).then(() => {
            const preferLocal = true;
            return this.vocabularyService.getCVDefault(
                'cv_AnimalPrefixFields', preferLocal
            ).then((field) => {
                this.defaultAnimalPrefixFieldKey = field.C_AnimalPrefixField_key;
            });
        });

        const p5 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_OrderPrefixFields'),
        ]).then(() => {
            return this.vocabularyService.getCV('cv_OrderPrefixFields').then((fields: any[]) => {
                this.orderPrefixFields = fields;
            });
        }).then(() => {
            const preferLocal = true;
            return this.vocabularyService.getCVDefault(
                'cv_OrderPrefixFields', preferLocal
            ).then((field) => {
                this.defaultOrderPrefixFieldKey = field.C_OrderPrefixField_key;
            });
        });

        const p6 = Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_OrderSuffixFields'),
        ]).then(() => {
            return this.vocabularyService.getCV('cv_OrderSuffixFields').then((fields: any[]) => {
                this.orderSuffixFields = fields;
            });
        }).then(() => {
            const preferLocal = true;
            return this.vocabularyService.getCVDefault(
                'cv_OrderSuffixFields', preferLocal
            ).then((field) => {
                this.defaultOrderSuffixFieldKey = field.C_OrderSuffixField_key;
            });
        });

        return Promise.all([p1, p2, p3, p4, p5, p6]);
    }

    setTranslatedAnimalPrefixFields(animalPrefixFields: any) {
        for (const animalPrefixField of animalPrefixFields) {
            animalPrefixField.AnimalPrefixFieldTranslated = this.translationService.translate(animalPrefixField.AnimalPrefixField);
        }
        return animalPrefixFields;
    }

    setTranslatedJobPrefixFields(jobPrefixFields: any) {
        for (const jobPrefixField of jobPrefixFields) {
            jobPrefixField.JobPrefixFieldTranslated = this.translationService.translate(jobPrefixField.JobPrefixField);
        }
        return jobPrefixFields;
    }

    private getNameFormats(): Promise<any> {
        return this.namingService.getNameFormats().then((data: any[]) => {
            this.nameFormats = sortObjectArrayByProperty(
                data,
                'FormatName'
            );
        });
    }

    private getSettings(): Promise<any> {
        const p1 = this.settingService.getDefaultHealthTechSetting().then((data: any) => {
            this.defaultHealthTechSetting = data;
            return Promise.resolve();
        });

        const p2 = this.settingService.getInactivityTimeoutSetting().then((data: any) => {
            this.inactivityTimeoutSetting = data;
            return Promise.resolve();
        });

        return Promise.all([p1, p2]).then(() => {
            if (!this.defaultHealthTechSetting || !this.inactivityTimeoutSetting) {
                const errorMessage = 'Workgroup settings could not be loaded.';
                this.loggingService.logError(errorMessage, null, this.COMPONENT_LOG_TAG, true);
                throw new Error(errorMessage);
            }
            return Promise.resolve();
        });
    }


    // <select> formatters
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }

    jobPrefixFieldKeyFormatter = (value: any) => {
        return value.C_JobPrefixField_key;
    }
    jobPrefixFieldFormatter = (value: any) => {
        return value.JobPrefixFieldTranslated;
    }

    jobSuffixFieldKeyFormatter = (value: any) => {
        return value.C_JobSuffixField_key;
    }
    jobSuffixFieldFormatter = (value: any) => {
        return value.JobSuffixField;
    }

    animalPrefixFieldKeyFormatter = (value: any) => {
        return value.C_AnimalPrefixField_key;
    }
    animalPrefixFieldFormatter = (value: any) => {
        return value.AnimalPrefixFieldTranslated;
    }
    orderPrefixFieldKeyFormatter = (value: any) => {
        return value.C_OrderPrefixField_key;
    }
    orderPrefixFieldFormatter = (value: any) => {
        return value.OrderPrefixField;
    }

    orderSuffixFieldKeyFormatter = (value: any) => {
        return value.C_OrderSuffixField_key;
    }
    orderSuffixFieldFormatter = (value: any) => {
        return value.OrderSuffixField;
    }

    async validate(): Promise<string> {


        const isValidNameFormats = this.nameFormats.every((nameFormat) => {
            const isEmptyCounter = nameFormat.Counter === null || nameFormat.Counter === undefined;
            return nameFormat.UseIdForCounter ? true : !isEmptyCounter;
        });

        if (!isValidNameFormats) {
            return 'The counter field requires a numeric value.';
        }

        if (this.inactivityTimeoutSetting.TimeoutMinutes < 5 || this.inactivityTimeoutSetting.TimeoutMinutes > 60) {
            return 'The inactivity timeout must be between 5 and 60.';
        }

        return '';
    }


    onSaveSuccessful() {
        this.loggingService.logDebug('Successful save reported in Settings', null, this.COMPONENT_LOG_TAG);

        if (this.isGLP) {
            this.inactivityTimeoutService.stopTimer();
            this.localStorageService.set(LocalStorageKey.INACTIVITY_TIMEOUT_MINUTES, this.inactivityTimeoutSetting.TimeoutMinutes);
            this.inactivityTimeoutService.startInactivityTimeout();
        }
    }

    // it needs to remove detail-toolbar
    // and completely rewrite the logic for saving settings
    onSave(): void {
      this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }
}
