import { BulkEditCommService } from './../../common/facet/bulk-edit-comm.service';
import { MaterialPoolService } from './../../services/material-pool.service';
import { DataManagerService } from '../../services/data-manager.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';

import { LoggingService } from '../../services/logging.service';
import { BulkEditToolbarComponent } from '../../common/toolbars';
import { SettingService } from '../../settings/setting.service';
import { IValidatable, SaveChangesService } from '../../services/save-changes.service';
import { FacetView } from '../../common/facet';
import { HousingBulkTemplatesComponent } from './housing-bulk-templates.component';

@Component({
    selector: 'housing-bulk-edit',
    templateUrl: './housing-bulk-edit.component.html',
    providers: [
        BulkEditCommService
    ]
})
export class HousingBulkEditComponent implements OnInit, OnDestroy, IValidatable {
    @Input() facet: any;
    @Input() facetView: FacetView;
    @Input() materialPools: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[] = [];
    @Input() inactiveFields: string[] = [];
    @Input() requiredFields: string[];
    @Output() exit: EventEmitter<any> = new EventEmitter<any[]>();
    @ViewChild('bulkEditToolbar') bulkEditToolBar: BulkEditToolbarComponent;
    @ViewChild('bulkTemplates') bulkTemplates: HousingBulkTemplatesComponent;

    readonly COMPONENT_LOG_TAG = 'housing-bulk-edit';

    constructor(
        private materialPoolService: MaterialPoolService,
        private loggingService: LoggingService,
        private dataManager: DataManagerService,
        private settingService: SettingService,
        private saveChangesService: SaveChangesService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.saveChangesService.registerValidator(this);
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);
    }

    initialize() {
         // Copy the input so we don't touch the grid data
        this.materialPools = this.materialPools.slice();
    }

    exitClicked() {
        if (this.dataManager.hasChanges()) {
            for (const materialPool of this.materialPools) {
                this.materialPoolService.cancelMaterialPool(materialPool);
            }

            this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
        }

        this.exit.emit(this.materialPools);
    }

    async onSaveHousings() {
        let errMsg;

        // Validate fields required by facet settings for each housing
        for (const materialPool of this.materialPools) {
            errMsg = await this.settingService.validateRequiredFields(this.requiredFields, materialPool, 'housing');
            break;
        }

        if (errMsg) {
            this.loggingService.logError(errMsg, "Validation Error", this.COMPONENT_LOG_TAG, true);
            return;
        }

        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }

    async validate(): Promise<string> {
        const dateErrorMessage = this.bulkTemplates.validate();
        if (dateErrorMessage) {
            return dateErrorMessage;
        }
        const bulkEditFields = [
            'C_MaterialPoolStatus_key',
            'DatePooled',
            'Owner',
            'Location',
            'C_ContainerType_key',
            'Comments'
        ];

        const filteredRequiredFields = this.requiredFields.filter((field: string) => bulkEditFields.includes(field));

        return this.settingService.bulkValidate(this.materialPools, filteredRequiredFields, 'housing');
    }
}
