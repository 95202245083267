import { CreateSampleGroupsProtocolModalComponent } from './modals';
import { ProtocolSelectComponent } from './protocol-select/protocol-select.component';
import { ProtocolMultiselectComponent } from './protocol-multiselect/protocol-multiselect.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { ProtocolService } from './protocol.service';
import { ProtocolVocabService } from './protocol-vocab.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule
    ],
    declarations: [
        ProtocolSelectComponent,
        ProtocolMultiselectComponent,
        CreateSampleGroupsProtocolModalComponent
    ],
    exports: [
        ProtocolSelectComponent,
        ProtocolMultiselectComponent,
        CreateSampleGroupsProtocolModalComponent
    ]
})
export class ProtocolsSharedModule {
    static forRoot(): ModuleWithProviders<ProtocolsSharedModule> {
        return {
          ngModule: ProtocolsSharedModule,
          providers: [
            ProtocolService,
            ProtocolVocabService
          ]
        };
    }
 }
