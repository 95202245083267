<div class="facet">
    <facet-header
        caption="Constructs"
        [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Constructs">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [options]="constructTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="constructTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (addItemClick)="addItemClick()"
                              (deleteItemClick)="deleteItemClick($event)"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <construct-detail *ngIf="facetView === DETAIL_VIEW"
                          [facet]="facet"
                          [construct]="itemToEdit"
                          [pageState]="detailPaginator.pageState"
                          (exit)="exitDetail()"
                          (previous)="detailPreviousClicked()"
                          (next)="detailNextClicked()"></construct-detail>
    </div>
</div>
