import { DataContextService } from './../services/data-context.service';
import { CustomizeWorkspaceService } from './../services/customize-workspace.service';
import { WorkspaceManagerService } from './../services/workspace-manager.service';
import { RoutingService } from './../routing/routing.service';
import { WorkspaceService } from './workspace.service';
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { RoleService } from '../services/role.service';
import { IGridsterOptions, GridsterComponent } from 'angular2gridster';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../services/logging.service';
import { ScrollEventService } from '@common/services';
import { ScrollTypes } from '@common/export';
import { FacetSelectorComponent } from './components/facet-selector/facet-selector.component';


@Component({
    selector: 'workspace-view',
    templateUrl: './workspace-view.component.html',
    styleUrls: ['./workspace-view.component.scss'],
})
export class WorkspaceViewComponent implements OnInit, OnDestroy {
    @ViewChild("gridster") gridster: GridsterComponent;
    @ViewChild('sidebar') facetSelector: FacetSelectorComponent;

    gridsterOptions: IGridsterOptions;

    loading = false;
    workspace: any;
    workspaceName = '';
    userRole: any;

    private _subscriptions: Subscription[] = [];

    visibility = false;

    toggleVisibility() {
        this.visibility = !this.visibility;
    }

    constructor(
        private dataContext: DataContextService,
        private workspaceManager: WorkspaceManagerService,
        private customizeWorkspaceService: CustomizeWorkspaceService,
        private roleService: RoleService,
        private routingService: RoutingService,
        private workspaceService: WorkspaceService,
        private route: ActivatedRoute,
        private loggingService: LoggingService,
        private scrollEventService: ScrollEventService,
    ) {
        // Nothing to do
    }

    async ngOnInit() {
        this.setGridsterOptions();

        this.registerSubscriptions();

        await Promise.all([this.dataContext.init(), this.workspaceManager.init()]);
        const ID = Number(this.route.snapshot.paramMap.get('id'));
        if (ID) {
            try {
                let workspaces = this.workspaceService.workspaces;
                if (!workspaces) {
                    workspaces = await this.workspaceService.fetchWorkspaces();
                }
                const currentWorkspace = workspaces.find(workspace => workspace.C_Workspace_key === ID);
                if (currentWorkspace) {
                    this.workspaceService.setCurrentWorkspace(currentWorkspace);
                    return;
                }
                await this.routingService.navigateToDashboard();
            } catch (_) {
                await this.routingService.navigateToDashboard();
            }
        } else {
            await this.routingService.navigateToDashboard();
        }

    }

    ngOnDestroy() {
        for (const subscription of this._subscriptions) {
            subscription.unsubscribe();
        }
    }

    registerSubscriptions() {
        const s1 = this.customizeWorkspaceService.customizeWorkspaceChange$.subscribe((active: boolean) => {
            this.facetSelector.reset();
            if (active) {
                this.facetSelector.focus();
            }
            this._toggleResizeHandles(active);
            // waiting for the end of the animation of the facet-selector panel
            setTimeout(() => this.reflowGridster(), 480);
        });

        const s2 = this.workspaceService.gridsterReflow$.subscribe(() => {
            this.reflowGridster();
        });

        // subscribe to workspace changes
        const s3 = this.workspaceService.currentWorkspace$
            .subscribe((workgroupKey) => {
                this.workspace = null;
                this.initialize();
            });

        const s4 = this.workspaceService.workspaceConnectivity$.subscribe((animalName) => {
            // Check if have clinical facet opened or not
            for (const facet of this.workspace.WorkspaceDetail) {
                if (facet.FacetName.toLowerCase() === "clinical") {
                    return;
                }
            }
            this.loggingService.logWarning(`Please add the Clinical facet to your workspace and try again.`, null, "Workspace View", true);
        });

        this._subscriptions = [s1, s2, s3, s4];
    }

    setGridsterOptions() {
        const showHandles = this.customizeWorkspaceService.isCustomizeWorkspaceActive();

        this.gridsterOptions = {
            // how many lines (grid cells) dashboard has
            lanes: 6, 
            cellHeight: 200,
            // items floating direction: vertical/horizontal/none
            direction: 'vertical',
            // default=true - prevents items to float according to the direction (gravity) 
            floating: true, 
            // possible to change items position by drag n drop
            dragAndDrop: showHandles, 
            // possible to resize items by drag n drop by item edge/corner
            resizable: showHandles, 
            resizeHandles: {
                w: true,
                ne: true,
                se: true,
                sw: true,
                nw: true
            },
             // Uses CSS3 translate() instead of position top/left - significant performance boost.
            useCSSTransforms: true,
            responsiveDebounce: 10,
        };
    }

    disableResizeHandles() {
        this._toggleResizeHandles(false);
    }

    enableResizeHandles() {
        this._toggleResizeHandles(true);
    }

    private _toggleResizeHandles(showHandles: boolean) {
        if (!this.gridster) {
            return;
        }

        this.gridsterOptions.dragAndDrop = showHandles;
        this.gridsterOptions.resizable = showHandles;

        this.gridster.setOption('resizable', showHandles);
        this.gridster.setOption('dragAndDrop', showHandles);
    }

    reflowGridster() {
        if (this.gridster) {
            this.gridster.reload();
        }
    }

    initialize(): Promise<any> {
        this.loading = true;

        if (!this.workspaceService.currentWorkspace) {
            this.routingService.navigateToDashboard();
            return;
        }

        const workspace = this.workspaceService.currentWorkspace;

        return this.getAndAssignPrivileges(workspace).then(() => {
            // wait until role Privileges are assigned
            // before activating workspace
            this.workspace = workspace;
            this.loading = false;
            
            // time a delayed reflow to compensate for bug in gridster library causing cutoff
            setTimeout(() => {
                this.reflowGridster();
            }, 500);
        }).catch((error) => {
            this.loading = false;
            throw error;
        });
    }

    getAndAssignPrivileges(workspace: any): Promise<any> {
        return this.roleService.getUserRole().then((role) => {
            this.userRole = role;
            const promises: Promise<any>[] = [];
            if (workspace && workspace.WorkspaceDetail) {
                for (const detail of workspace.WorkspaceDetail) {
                    // TODO make this separate function
                    const rolePromise = this.roleService.getFacetPrivilegeByRole(
                        detail.FacetName,
                        this.userRole
                    ).then((facetRole) => {
                        // default to None
                        detail.Privilege = "None";

                        if (facetRole) {
                            if (facetRole.HasWriteAccess) {
                                detail.Privilege = "ReadWrite";
                            } else if (facetRole.HasReadAccess) {
                                detail.Privilege = "ReadOnly";
                            }
                        }
                    });

                    promises.push(rolePromise);
                }
            }

            return Promise.all(promises);
        });
    }

    addFacet(facetSelection: any) {
        const currentWorkspaceKey = this.workspaceService.currentWorkspace.C_Workspace_key;
        const newWorkspaceDetail = this.workspaceService.createWorkspaceDetail({
            C_Workspace_key: currentWorkspaceKey,
            FacetName: facetSelection.facetName,
            FacetDisplayName: facetSelection.facetDisplayName,
            // let Gridster determine new col, row position
            Column: null,
            Row: null,
            SizeX: 3,
            SizeY: 2
        });

        this.workspaceService.autoSizeFacets();

        if (facetSelection.hasWriteAccess) {
            newWorkspaceDetail.Privilege = "ReadWrite";

        } else if (facetSelection.hasReadAccess) {
            newWorkspaceDetail.Privilege = "ReadOnly";

        } else {
            newWorkspaceDetail.Privilege = "None";
        }

        this.workspaceService.currentWorkspace.WorkspaceDetail.push(newWorkspaceDetail);

        // time a delayed reflow to compensate for bug in gridster library causing cutoff
        setTimeout(() => {
            this.reflowGridster();
        }, 500);
    }

    get isCustomizeWorkspaceActive(): boolean {
        return this.customizeWorkspaceService.isCustomizeWorkspaceActive();
    }

    isWorkspaceEmpty(): boolean {
        const currentWorkspace = this.workspaceService.currentWorkspace;
        if (!currentWorkspace || !currentWorkspace.WorkspaceDetail) {
            return false;
        }
        return currentWorkspace.WorkspaceDetail.length === 0;
    }

    /**
     * Called when Facet size or position was manually changed
     * @param event 
     */
    facetChanged(workspaceDetail: any) {
        workspaceDetail.isManuallyChanged = true;
    }

    public scrollEvent(event: Event) {
        this.scrollEventService.scrollEventEmit = {event, name: ScrollTypes.WorkspaceViewComponent};
    }
}
