import { map } from 'rxjs/operators';
import { LocationService } from './../../locations/location.service';
import { MaterialPoolService } from './../../services/material-pool.service';
import { HousingVocabService } from '../services/housing-vocab.service';
import { QueryDef } from './../../services/query-def';
import {
    BulkEditOptions, 
    BulkEditSection 
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { notEmpty } from '../../common/util';

import { FacetLoadingStateService } from '../../common/facet';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';
import { MaterialPool } from '@common/types';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'housing-bulk-templates',
    templateUrl: './housing-bulk-templates.component.html'
})
export class HousingBulkTemplatesComponent implements OnInit, AfterViewInit {
    @ViewChildren('dateControl') dateControls: NgModel[];

    @Input() materialPools: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    // bulk edit input templates
    @ViewChild('idTmpl') idTmpl: TemplateRef<any>;
    @ViewChild('typeTmpl') typeTmpl: TemplateRef<any>;
    @ViewChild('statusTmpl') statusTmpl: TemplateRef<any>;
    @ViewChild('datePooledTmpl') datePooledTmpl: TemplateRef<any>;
    @ViewChild('ownerTmpl') ownerTmpl: TemplateRef<any>;
    @ViewChild('locationTmpl') locationTmpl: TemplateRef<any>;
    @ViewChild('containerTypeTmpl') containerTypeTmpl: TemplateRef<any>;
    @ViewChild('commentsTmpl') commentsTmpl: TemplateRef<any>;

    // vocabs
    containerTypes: any[] = [];
    materialPoolTypes: any[] = [];
    materialPoolStatuses: any[] = [];

    rootLocationPositionPromise: Promise<any>;
    rootLocationPosition: any;

    readonly COMPONENT_LOG_TAG = 'housing-bulk-edit';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    constructor(
        private housingVocabService: HousingVocabService,
        private facetLoadingState: FacetLoadingStateService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
    ) {
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Housing Unit",
            itemTypeLabelPlural: "Housing Units",
            clearForm: false,
            fields: [
                {
                    label: "ID",
                    modelPath: 'MaterialPoolID',
                    template: this.idTmpl,
                    hideFromAddScreen: true,
                    hideFromEditHeader: true
                },
                {
                    label: 'Housing Type',
                    modelPath: 'C_MaterialPoolType_key',
                    template: this.typeTmpl
                },
                {
                    label: 'Housing Status',
                    modelPath: 'C_MaterialPoolStatus_key',
                    template: this.statusTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Status')
                },
                {
                    label: 'Date Housed',
                    modelPath: 'DatePooled',
                    template: this.datePooledTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Date'),
                    onItemInit: (item: Partial<MaterialPool>) => {
                        item.DatePooled = new Date();
                    },
                },
                {
                    label: 'Owner',
                    modelPath: 'Owner',
                    template: this.ownerTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Owner')
                },
                {
                    label: 'Location',
                    modelPath: 'locationPosition',
                    template: this.locationTmpl,
                    onItemInit: (item) => {
                        this.getRootLocationPosition().then((rootLocationPosition) => {
                            item.locationPosition = rootLocationPosition;
                        });
                    },
                    onUpdateItem: (item, value) => {
                        this.fillDownLocation(item.locationPosition);
                    },
                    inactive: this.activeFields && !this.activeFields.includes('Location')
                },
                {
                    label: 'Container Type',
                    modelPath: 'C_ContainerType_key',
                    template: this.containerTypeTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Container Type')
                },
                {
                    label: 'Comments',
                    modelPath: 'Comments',
                    template: this.commentsTmpl,
                    inactive: this.activeFields && !this.activeFields.includes('Comments')
                }
            ]
        };
    }

    initialize() {

         // Copy the input so we don't touch the grid data
        this.materialPools = this.materialPools.slice();
    }

    getData() {
        this.facetLoadingState.changeLoadingState(true);

        return this.getDetails().then(() => {
            return this.getCVs();
        }).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    getDetails(): Promise<any[]> {
        if (notEmpty(this.materialPools)) {
            const queryDef: QueryDef = {
                page: 0,
                size: this.materialPools.length,
                filter: {
                    materialPoolKeys: this.materialPools.map((materialPool) => {
                        return materialPool.C_MaterialPool_key;
                    })
                }
            };

            return this.materialPoolService.getMaterialPools(queryDef).then((result) => {
                return result.results;
            });
        }

        return Promise.resolve(this.materialPools);
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.housingVocabService.containerTypes$.pipe(map((data) => {
            this.containerTypes = data;
        })).toPromise();

        const p2: Promise<any> = this.housingVocabService.materialPoolTypes$.pipe(map((data) => {
            this.materialPoolTypes = data;
        })).toPromise();

        const p3: Promise<any> = this.getRootLocationPosition();

        const p4: Promise<any> = this.housingVocabService.materialPoolStatuses$.pipe(map((data) => {
            this.materialPoolStatuses = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4]);
    }

    fillDownLocation(locationPosition: any) {
        if (locationPosition) {
            for (const materialPool of this.materialPools) {
                this.locationService.processMaterialPoolLocationChange(
                    materialPool,
                    locationPosition
                );
            }
        }
    }

    getRootLocationPosition(): Promise<any> {
        if (!this.rootLocationPositionPromise) {
            this.rootLocationPositionPromise = this.locationService.getRootLocationPositions()
                .then((data) => {
                    if (notEmpty(data)) {
                        // when setting a default, there can only be one root location
                        const rootLocationPosition = data[0];
                        this.rootLocationPosition = rootLocationPosition;
                        return rootLocationPosition;
                    }
                    return null;
                });
        }
        return this.rootLocationPositionPromise;
    }


    // <select> formatters
    materialPoolTypeKeyFormatter = (value: any) => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypeFormatter = (value: any) => {
        return value.MaterialPoolType;
    }
    materialPoolStatusKeyFormatter = (value: any) => {
        return value.C_MaterialPoolStatus_key;
    }
    materialPoolStatusFormatter = (value: any) => {
        return value.MaterialPoolStatus;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }

    validate() {
        return dateControlValidator(this.dateControls);
    }
}
