import {
    Injectable,
} from '@angular/core';

import {
    NgbModal,
    NgbModalOptions
} from '@ng-bootstrap/ng-bootstrap';

import {
    UnsavedChangesModalComponent
} from './unsaved-changes-modal.component';
import { DataContextService } from '../../services/data-context.service';

/**
 * Service to view the unsaved-changes modal.
 */
@Injectable()
export class ViewUnsavedChangesModalService {

    // Prevent exit other than thru buttons
    modalOptions: NgbModalOptions = {
        backdrop: "static",
        keyboard: false
    };

    private readonly facetsForFacetLevelSave = ['animal-detail'] as const;

    constructor(private modalService: NgbModal,
                private dataContextService: DataContextService) {}

    // TODO: remove log
    openComponent(logTag?: string): Promise<'save' | 'discard'> {
        return this.modalService.open(UnsavedChangesModalComponent, this.modalOptions)
            .result.then(
            (result) => {
                // Closed -> save
                return 'save';
            },
            (reason) => {
                // TODO: Remove backwards compatibility flag once all facets have separate saving/discard
                const isFacetLevelSaveSupported = this.facetsForFacetLevelSave.some(facet => facet === logTag);
                if (!isFacetLevelSaveSupported) {
                    this.dataContextService.cancel(true);
                }
                // Dismissed -> discard
                return 'discard';
            });
    }
}
