import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LineService } from './line.service';
import { TranslationService } from '../services/translation.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { LineTableOptions } from './line-table-options';
import { LineFilterComponent } from './line-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Entity, Line } from '../common/types';
import { DataContextService } from '@services/data-context.service';
import { takeUntil } from 'rxjs/operators';
import { arrowClockwise, magnifier } from '@icons';

@Component({
    selector: 'line-facet',
    templateUrl: './line-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class LineFacetComponent extends BaseFacet<Entity<Line>> implements OnInit, OnDestroy {
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    componentName = 'line';

    lineTableOptions: LineTableOptions;

    componentFilterSubscription: Subscription;
    private notifier$ = new Subject<void>();

    readonly COMPONENT_LOG_TAG = 'line-facet';

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private lineService: LineService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService,
        private vocabularyService: VocabularyService,
        private dataContext: DataContextService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.lineTableOptions = new LineTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.lineTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();

        this.initialize();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = ['Note'];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.lineService.getLines(queryDef);

            const visibleColumns = this.getVisibleColumns(this.lineTableOptions.options);
            await this.lineService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<Line>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        this.setLoadingState();
        this.createNewItem().then((item) => {
            this.stopLoading();
            this.itemToEdit = item;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewItem(): Promise<Entity<Line>> {
        const newItem = this.lineService.create();

        this.vocabularyService.getCVDefault('cv_LineStatuses').then((value) => {
            newItem.cv_LineStatus = value;
        });
        this.vocabularyService.getCVDefault('cv_LineTypes').then((value) => {
            newItem.cv_LineType = value;
        });

        return Promise.resolve(newItem);
    }

    openFilter() {
        const ref = this.modalService.open(LineFilterComponent);
        const component = ref.componentInstance as LineFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.lineService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}
