import { DataContextService } from './../services/data-context.service';
import { BulkAddCommService } from './../common/facet/bulk-add-comm.service';
import { ConfirmService } from './../common/confirm/confirm.service';
import {
    Component,
    Input,
    OnInit,
    OnDestroy
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GenotypeService } from './genotype.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { WsFilterEvent } from '../services/ws-filter-event';

import { 
    BaseFacet,
    BaseFacetService 
} from '../common/facet';

import { GenotypeFilterComponent } from './genotype-filter.component';
import { GenotypeTableOptions } from './genotype-table-options';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';

import { filterToDate } from '../common/util';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { Entity, Genotype } from '../common/types';
import { takeUntil } from "rxjs/operators";
import { arrowClockwise, brokenChain, chain, magnifier } from '@icons';

@Component({
    selector: 'genotype-facet',
    templateUrl: './genotype-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS.concat(
        BulkAddCommService
    )
})
export class GenotypeFacetComponent extends BaseFacet<Entity<Genotype>>
    implements OnInit, OnDestroy {
    @Input() facet: any;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier };

    componentName = 'genotype';

    genotypeTableOptions: GenotypeTableOptions;
    componentFilterSubscription: Subscription;

    readonly COMPONENT_LOG_TAG = 'genotype-facet';
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private confirmService: ConfirmService,
        private dataContext: DataContextService,
        private genotypeService: GenotypeService,
        private cellFormatterService: CellFormatterService,
        private modalService: NgbModal,
        workspaceFilterService: WorkspaceFilterService,
        private translationService: TranslationService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.genotypeTableOptions = new GenotypeTableOptions(this.cellFormatterService, this.translationService);

        this.dataTableColumns = new BehaviorSubject(this.genotypeTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();

        this.supportedWorkspaceFilters = ['animal-filter'];

        this.initialize();
        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.DateGenotypeStart = filterToDate(this.filter.DateGenotypeStart);
                this.filter.DateGenotypeEnd = filterToDate(this.filter.DateGenotypeEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        this.setLoadingState(tableState.loadingMessage);

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        let sort = tableState.sort || 'Animal.AnimalName DESC';
        const mandatorySorts = [
            'Animal.AnimalName DESC',
            'cv_GenotypeAssay.GenotypeAssay DESC',
            'cv_GenotypeSymbol.GenotypeSymbol DESC'
        ];
        sort += ', ' + mandatorySorts.join(', ');

        const expands: string[] = [];

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.genotypeService.getGenotypes(queryDef);

            const visibleColumns = this.getVisibleColumns(this.genotypeTableOptions.options);
            await this.genotypeService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results as Entity<Genotype>[];
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    openFilter() {
        const ref = this.modalService.open(GenotypeFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as GenotypeFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    selectedRowsChange(rows: any[]) {
        //
    }

    async doBulkDelete() {
        const modalTitle = 'Delete Genotypes';
        const modalText = `Delete ${this.selectedRows.length} selected genotypes? This action cannot be undone.`;

        try {
            await this.confirmService.confirmDelete(modalTitle, modalText);

            this.facetLoadingState.changeLoadingState(true);

            for (const item of this.selectedRows) {
                this.genotypeService.deleteGenotype(item);
            }

            await this.dataContext.save();

            this.reloadTable();
            this.facetLoadingState.changeLoadingState(false);
        } catch (error) {
            this.facetLoadingState.changeLoadingState(false);
        }
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.genotypeService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}
