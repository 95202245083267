<div class="facet">
    <facet-header caption="Monitoring"
                  [facet]="facet">
    </facet-header>

    <div class="facet-body" style="overflow-y: hidden !important;" *ngIf="facet.Privilege!=='None'">
        <climb-facet-toolbar>
            <button *ngIf="facet.Privilege === 'ReadWrite'"
                    climbSaveButton
                    [disabled]="detailToolbar.isSaveDisabled"
                    (click)="onSaveMonitoring()">
            </button>
        </climb-facet-toolbar>
        <div class="detail-header d-none">
            <detail-toolbar #detailToolbar
                            [showListViewButton] ="false"
                            [facetPrivilege]="facet.Privilege"
                            [logTag]="COMPONENT_LOG_TAG"
                            [addMonitoring]="true"
                            (saveMonitoring)="onSaveMonitoring()">
            </detail-toolbar>
        </div>
        <div style="overflow-y:auto;
                    height: calc(100% - 40px) !important;">
            <div style="padding-top:-20px;">
                <nav ngbNav #nav="ngbNav" class="nav-tabs pt-0" [destroyOnHide]="false">
                    <ng-container ngbNavItem>
                        <a ngbNavLink>Simple</a>
                        <ng-template ngbNavContent>
                            <div class="col-md-12">
                                <!-- The table that defines most of the facet -->
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>Subscribe</th>
                                            <th><a (click)="reverseSort()">Alert</a></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <ng-container *ngFor="let service of services">
                                            <tr>
                                                <td class="icon-cell">
                                                    <input type="checkbox"
                                                           class="mt-1"
                                                           [ngModel]="service.selected"
                                                           (ngModelChange)="checkServiceChanged(service)"
                                                           [id]="getSubscribeControlId(service)" />
                                                </td>
                                                <td>
                                                    <p class="form-control-plaintext">
                                                        <label [attr.for]="getSubscribeControlId(service)"
                                                               class="label-service-name">
                                                            {{service.ServiceNameTranslated}}
                                                        </label>
                                                        <ng-container *ngIf="service.MonitoringServiceSubscription?.MonitoringServiceSubscriptionParameter && service.selected">
                                                            <ng-container *ngFor="let param of service.MonitoringServiceSubscription.MonitoringServiceSubscriptionParameter">
                                                                <ng-container [ngSwitch]="param?.MonitoringServiceParameter?.ParameterName">
                                                                    <button *ngSwitchCase="ParameterName.JobStatus"
                                                                            type="submit"
                                                                            class="btn btn-primary btn-lg"
                                                                            (click)="showModal(service, param)">
                                                                        Edit
                                                                    </button>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </p>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ng-container>

                    <ng-container ngbNavItem>
                        <a ngbNavLink>IoT Alerts</a>
                        <ng-template ngbNavContent>
                            <iot-monitoring-tab></iot-monitoring-tab>
                        </ng-template>
                    </ng-container>
                </nav>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>
