import { map } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { OrderService } from './order.service';
import { OrderVocabService } from './order-vocab.service';
import { ViewOrderAuditReportComponentService } from './audit';
import { PrivilegeService } from '@services/privilege.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { LocationService } from '../locations/location.service';
import { ISupportIdGeneration, IValidatable, OnSaveResult, SaveChangesService } from '@services/save-changes.service';
import { NamingService } from '@services/naming.service';
import { ReasonForChangeService } from '@common/reason-for-change/reason-for-change.service';
import { SettingService } from '../settings/setting.service';

import { BaseDetail, BaseDetailService, FacetView, IFacet, PageState } from '@common/facet';
import { Subscription } from 'rxjs';
import { empty, testBreezeIsNew } from '@common/util';
import { TranslationService } from '@services/translation.service';
import { FeatureFlagService } from '@services/feature-flags.service';
import {
    cv_MaterialOrigin,
    cv_MaterialType,
    cv_SampleCondition,
    cv_Taxon,
    Entity,
    Institution,
    Order,
    Site
} from '@common/types';
import { JobOrderService } from '../jobs/job-order.service';
import { dateControlValidator } from '@common/util/date-control.validator';

@Component({
    selector: 'order-detail',
    templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent extends BaseDetail
    implements OnInit, OnChanges, OnDestroy, IValidatable, OnSaveResult, ISupportIdGeneration {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() order: Entity<Order>;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("OrderForm") orderForm: NgForm;
    @ViewChildren('dateControl') dateControls: NgModel[];

    subOrder: any = null;

    // CVs
    materialTypes: Entity<cv_MaterialType>[] = [];
    taxons: Entity<cv_Taxon>[] = [];
    sampleConditions: Entity<cv_SampleCondition>[] = [];
    materialOrigins: Entity<cv_MaterialOrigin>[] = [];
    quarantineFacilities: any[] = [];

    institutions: Entity<Institution>[] = [];
    sites: Entity<Site>[] = [];

    // State
    saving = false;
    
    printPreviewId: string;

    readonly COMPONENT_LOG_TAG = 'order-detail';

    readwrite: boolean;
    readonly: boolean;
    hide: boolean;
    // current item edited in detail
    itemToEdit: Entity<Order> = {} as Entity<Order>;

    currentWorkgroupKey: number;
    tableAnimalCount: any;
    orderNamingActive = false;
    jobOrderKey: number | null;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];
    animalOrderActiveFields: string[] = [];
    animalOrderRequiredFields: string[] = [];

    isGLP: boolean;

    private readonly subs = new Subscription();

    constructor(
        baseDetailService: BaseDetailService,
        private privilegeService: PrivilegeService,
        private orderService: OrderService,
        
        private jobOrderService: JobOrderService,
        private orderVocabService: OrderVocabService,
        private viewOrderAuditReportComponentService: ViewOrderAuditReportComponentService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private vocabularyService: VocabularyService,
        private locationService: LocationService,
        private saveChangesService: SaveChangesService,
        private namingService: NamingService,
        private reasonForChangeService: ReasonForChangeService,
        private settingService: SettingService,
        private translationService: TranslationService,
        private featureFlagService: FeatureFlagService
    ) {
        super(baseDetailService);
    }

    async generateId(): Promise<void> {
        if (this.order.OrderID == null) {
            this.order.OrderID = await this.orderService.autoGenerateOrderID(this.order);
        }
    }

    // lifecycle
    ngOnInit() {
        this.saveChangesService.registerIdGenerator(this);
        this.saveChangesService.registerValidator(this);
        this.subs.add(this.saveChangesService.saveResult$.subscribe(() => {
          this.onSaveResult();
        }));

        this.initialize().then(() => {
            this.loggingService.logDebug('Order detail view is re-initialized', null, this.COMPONENT_LOG_TAG);
        });
    }

    ngOnChanges(changes: any) {
        if (changes.order) {
            if (this.order && !changes.order.firstChange) {
                if (this.orderForm) {
                    this.orderForm.form.markAsPristine();
                }
                this.initialize().then(() => {
                    this.loggingService.logDebug('Order detail view is re-initialized', null, this.COMPONENT_LOG_TAG);
                });
            }
        }
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
        this.saveChangesService.unregisterIdGenerator(this);
        this.saveChangesService.unregisterValidator(this);
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initialize(): Promise<void> {
        this.setLoading(true);

        this.initIsGLP();

        this.setPrivileges();

        return this.settingService.getFacetSettingsByType('order').then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        }).then(() => {
            return this.settingService.getFacetSettingsByType('order-animal').then((facetSettings) => {
                this.animalOrderActiveFields = this.settingService.getActiveFields(facetSettings);
                this.animalOrderRequiredFields = this.settingService.getRequiredFields(facetSettings);
            });
        }).then(() => {
            return this.getCVs();
        }).then(() => {
            this.currentWorkgroupKey = this.currentWorkgroupService.getCurrentWorkgroupKey();
            this.getInstitutions();
            this.institutionChanged(this.order.C_Institution_key);
            this.getSubOrder();
            this.isNamingActive();
            this.getJobOrderKey();
            if (this.isGLP) {
                // force to have OrderType = animal
                const animalOrderType = this.materialTypes.find((item) => {
                    return item.MaterialType.toLowerCase() === 'animal';
                });
                this.order.cv_MaterialType = animalOrderType;
                this.orderTypeChanged();
            }
            return this.getDetails();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    private isNamingActive(): Promise<void> {
        return this.namingService.isOrderNamingActive()
            .then((active: boolean) => {
                this.orderNamingActive = active;
            });
    }

    private getCVs(): Promise<any> {
        const p1 = this.orderVocabService.materialTypes$.pipe(map((data) => {
            this.materialTypes = data;
        })).toPromise();

        const p2 = this.orderVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();

        const p3 = this.orderVocabService.sampleConditions$.pipe(map((data) => {
            this.sampleConditions = data;
        })).toPromise();

        const p4 = this.orderVocabService.materialOrigins$.pipe(map((data) => {
            this.materialOrigins = data;
        })).toPromise();

        const p5 = this.orderVocabService.quarantineFacilities$.pipe(map((data) => {
            this.quarantineFacilities = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5]);
    }

    private getDetails(): Promise<Entity<Order>> {
        if (this.order && this.order.C_Order_key > 0) {

            return this.orderService.getOrder(this.order.C_Order_key);
        }

        return Promise.resolve(this.order);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    onCancel() {
        this.orderService.cancelOrder(this.order);
    }
    
    viewAuditReport() {
        this.viewOrderAuditReportComponentService
            .openComponent(this.order.C_Order_key);
    }

    // Formatters for <select> input
    materialTypeKeyFormatter = (value: any) => {
        return value.C_MaterialType_key;
    }
    materialTypeFormatter = (value: any) => {
        return value.MaterialType;
    }

    orderStateKeyFormatter = (value: any) => {
        return value.C_State_key;
    }
    orderStateFormatter = (value: any) => {
        return value.StateName;
    }

    sampleConditionKeyFormatter = (value: any) => {
        return value.C_SampleCondition_key;
    }
    sampleConditionFormatter = (value: any) => {
        return value.SampleCondition;
    }

    materialOriginKeyFormatter = (value: any) => {
        return value.C_MaterialOrigin_key;
    }
    materialOriginFormatter = (value: any) => {
        return value.MaterialOrigin;
    }

    quarantineFacilityKeyFormatter = (value: any) => {
        return value.C_QuarantineFacility_key;
    }
    quarantineFacilityFormatter = (value: any) => {
        return value.QuarantineFacility;
    }

    taxonKeyFormatter = (value: any) => {
        return value.C_Taxon_key;
    }
    taxonFormatter = (value: any) => {
        return value.CommonName;
    }

    getInstitutions() {
        this.orderService.getInstitutions().then((data) => {
            this.institutions = data;
        });
    }

    institutionChanged(institutionKey: any) {
        this.orderService.getInstitutionSites(institutionKey).then((data) => {
            this.sites = data;
        });
    }

    orderTypeChanged() {
        // Check for current order type
        if (this.order.cv_MaterialType && this.order.cv_MaterialType.MaterialType === 'Sample') {
            // Create new sample order
            this.subOrder = this.orderService.createSampleOrder(this.order.C_Order_key);
            this.vocabularyService.getCVDefault('cv_SampleTypes').then((value) => {
                this.subOrder.cv_SampleType = value;
            });
            this.vocabularyService.getCVDefault('cv_SampleConditions').then((value) => {
                this.subOrder.cv_SampleCondition = value;
            });
        } else {
            // Delete the current sample order
            if (this.subOrder) {
                this.reasonForChangeService.markModification([this.subOrder.Order]);
                this.orderService.deleteSampleOrder(this.subOrder);
            }
        }
    }

    getSubOrder() {
        // Get Sample order details if necessary
        if (this.order.cv_MaterialType &&
            this.order.cv_MaterialType.MaterialType === 'Sample') {
                this.orderService.getSampleOrder(this.order.C_Order_key).then((data) => {
                    this.subOrder = data;
                });
        }
    }

    addOrderLocation() {
        this.locationService.getDefaultLocation().then((defaultLocation) => {
            const dateIn = new Date();
            this.orderService.createOrderLocation({
                C_LocationPosition_key: defaultLocation.C_LocationPosition_key,
                C_Order_key: this.order.C_Order_key,
                DateIn: dateIn
            });
        });
    }

    removeOrderLocation(orderLocation: any) {
        this.reasonForChangeService.markModification([orderLocation.Order]);
        this.orderService.deleteOrderLocation(orderLocation);
    }

    getJobOrderKey() {
        this.jobOrderKey = (this.order.JobOrder.length !== 0) ? this.order.JobOrder[0].C_Job_key : null;
        this.order.C_Job_key = this.jobOrderKey;
    }

    async onSelectJob(item: any) {
        if (this.order.JobOrder.length !== 0) {
            await this.jobOrderService.deleteJobOrder(this.order.JobOrder[0]);
        }
        if (item) {
            const initialValues = {
                C_Job_key: item,
                C_Order_key: this.order.C_Order_key,
            };
            await this.jobOrderService.createJobOrder(initialValues);
        }
    }

    async validate(): Promise<string> {
        const translatedOrder = this.translationService.translate('Order');

        const dateErrorMessage = dateControlValidator(this.dateControls);
        if (dateErrorMessage) {
            return dateErrorMessage;
        }

        // Check that auto-naming field has value
        if (this.orderNamingActive && testBreezeIsNew(this.order)) {
            const invalidField = await this.orderService.validateOrderNamingField(this.order);
            if (invalidField) {
                return `The ${this.translationService.translate(invalidField)} field is required for automatic naming.`;
            }
        } else if (empty(this.order.OrderID)) {
            return `A ${translatedOrder} requires an ID.`;
        }

        // Also validate fields required by facet settings for each animal order
        for (const animalOrder of this.order.AnimalOrder) {
            const errorMessage = await this.settingService.validateRequiredFields(this.animalOrderRequiredFields, animalOrder, 'order-animal');
            if (errorMessage) {
                return errorMessage;
            }
        }

        const areLocationsValid = (this.order.OrderLocation ?? []).every((location: any) => location.C_LocationPosition_key);

        if (!areLocationsValid) {
            return 'Ensure that all required fields within Locations are filled.';
        }

        return await this.settingService.validateRequiredFields(this.requiredFields, this.order, 'order');
    }

    onSaveResult() {
        this.loggingService.logDebug('Save result reported in Order Details (may have succeeded or failed)', null, this.COMPONENT_LOG_TAG);
        this.initialize().then(() => {
            this.loggingService.logDebug('Order detail view is re-initialized', null, this.COMPONENT_LOG_TAG);
        });
    }

    onAnimalSectionClick(type: any, property: any) {
        this.order[property] = this.order[property] === type ? null : type;
    }

    updateOrderID(field: string) {
        // Apply new number only if is an update
        this.isNamingActive().then(() => { 
            if (this.orderService.getIsGLPFlag() && this.order.OrderID && this.orderNamingActive) {
                this.orderService.getOrderPrefixField().then((orderPrefixField: string) => {
                    if (orderPrefixField.toLowerCase() === field.toLowerCase()) {
                        // Automatically regenerate JobID
                        this.orderService.autoGenerateOrderID(this.order).then((newID: string) => {
                            if (newID !== this.order.OrderID) {
                                this.order.OrderID = newID;
                                // Alert user of automatic change
                                this.loggingService.logWarning(
                                    `The Order ID field has been automatically changed due to changing the ${orderPrefixField} field.`,
                                    null, this.COMPONENT_LOG_TAG, true);
                            }
                        });
                    }
                });
            }
        });
    }
}
