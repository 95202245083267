import { AnimalService } from '../../animals/services/animal.service';
import { ClinicalVocabService } from './../clinical-vocab.service';
import { map } from 'rxjs/operators';
import { 
    BulkEditOptions, 
    BulkEditSection 
} from '../../common/facet/models';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    AfterViewInit,
    ViewChildren,
} from '@angular/core';

import { FacetLoadingStateService } from '../../common/facet';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ClinicalAlertSenderComponent } from '../clinical-alert-sender/clinical-alert-sender.component';
import { LoggingService } from '../../services/logging.service';
import { SettingService } from '../../settings/setting.service';
import { TranslationService } from '../../services/translation.service';
import { uniqueArrayFromPropertyPath } from '../../common/util';
import { ClinicalService } from '../clinical.service';
import { NgModel } from '@angular/forms';
import { dateControlValidator } from '@common/util/date-control.validator';

/**
 * Shared component and configuration templates
 * for BulkAdd and BulkEdit tables
 * 
 * 
 */
@Component({
    selector: 'observation-bulk-templates',
    templateUrl: './observation-bulk-templates.component.html'
})
export class ObservationBulkTemplatesComponent implements OnInit, AfterViewInit {
    @Input() animals: any[];
    @Input() observations: any[];
    @Input() isEdit: boolean;

    @ViewChildren('dateControl') dateControls: NgModel[];
    // bulk edit input templates
    @ViewChild('animalStatusTmpl', { static: false }) animalStatusTmpl: TemplateRef<any>;
    @ViewChild('healthTechTmpl', { static: false }) healthTechTmpl: TemplateRef<any>;
    @ViewChild('urgentTmpl', { static: false }) urgentTmpl: TemplateRef<any>;
    @ViewChild('identifierTmpl', { static: false }) identifierTmpl: TemplateRef<any>;
    @ViewChild('animalNameTmpl', { static: false }) animalNameTmpl: TemplateRef<any>;
    @ViewChild('animalLocationTmpl', { static: false }) animalLocationTmpl: TemplateRef<any>;
    @ViewChild('animalStudyTmpl', { static: false }) animalStudyTmpl: TemplateRef<any>;
    @ViewChild('animalBirthDateTmpl', { static: false }) animalBirthDateTmpl: TemplateRef<any>;
    @ViewChild('animalSexTmpl', { static: false }) animalSexTmpl: TemplateRef<any>;
    @ViewChild('animalSpeciesTmpl', { static: false }) animalSpeciesTmpl: TemplateRef<any>;
    @ViewChild('observedDateTmpl', { static: false }) observedDateTmpl: TemplateRef<any>;
    @ViewChild('observedByTmpl', { static: false }) observedByTmpl: TemplateRef<any>;
    @ViewChild('observationStatusTmpl', { static: false }) observationStatusTmpl: TemplateRef<any>;
    @ViewChild('observationsTmpl', { static: false }) observationsTmpl: TemplateRef<any>;
    @ViewChild('commentsTmpl', { static: false }) commentsTmpl: TemplateRef<any>;
    @ViewChild('animalBodyConditionTmpl', { static: false }) animalBodyConditionTmpl: TemplateRef<any>;
    @ViewChild('animalExitDateTmpl', { static: false }) animalExitDateTmpl: TemplateRef<any>;
    @ViewChild('animalExitReasonTmpl', { static: false }) animalExitReasonTmpl: TemplateRef<any>;
    @ViewChild('observationmodal', { static: false }) observationmodal: TemplateRef<any>;
    
    // CVs
    animalStatuses: any[] = null;
    resources: any[] = null;
    clinicalObservationStatuses: any[] = null;
    bodyConditionScores: any[] = null;
    exitReasons: any[] = null;

    readonly COMPONENT_LOG_TAG = 'observation-bulk-add';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    healthRecord: any;

    defaultResourceKey: number;

    // state variables
    selectedObservation: any;
    clinicalObservationDetail: any[] = [];

    section: any;

    constructor(
        private animalService: AnimalService,
        private loggingService: LoggingService,
        private settingService: SettingService,
        private clinicalVocabService: ClinicalVocabService,
        private facetLoadingState: FacetLoadingStateService,
        private modalService: NgbModal,
        private translationService: TranslationService,
        private clinicalService: ClinicalService,
    ) {}

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.getData();
    }

    /**
     * Configuration with TemplateRefs can only be assigned
     *   after "ngAfterViewInit".
     * Otherwise they will be undefined.
     */
    ngAfterViewInit() {
        // assign all the BulkAdd and BulkEdit configuration options
        this.bulkOptions = {
            itemTypeLabel: "Observation",
            itemTypeLabelPlural: "Observations",
            clearForm: true,
            hideSaveAndEdit: true,
            saveButtonValidators: [() => {
                return this.isEdit || (this.animals.length > 0 && this.observations.length > 0);
            }],
            fields: [
                {
                    label: "Urgent",
                    modelPath: 'IsUrgent',
                    template: this.urgentTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Animal ID",
                    modelPath: 'Identifier',
                    template: this.identifierTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true
                },
                {
                    label: "Animal Name",
                    modelPath: 'AnimalName',
                    template: this.animalNameTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true
                },
                {
                    label: "Location",
                    modelPath: 'CurrentLocationPath',
                    template: this.animalLocationTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: this.translationService.translate('Job'),
                    modelPath: 'Animal.Material.JobMaterial.Job',
                    template: this.animalStudyTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Birth Date",
                    modelPath: 'DateBorn',
                    template: this.animalBirthDateTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Sex",
                    modelPath: 'Sex',
                    template: this.animalSexTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Species",
                    modelPath: 'CommonName',
                    template: this.animalSpeciesTmpl,
                    hideFromAddScreen:  true,
                    hideFromEditHeader: true,
                    visible: false
                },
                {
                    label: "Observation Date",
                    modelPath: 'DateObserved',
                    template: this.observedDateTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Observed By",
                    modelPath: 'C_Resource_key',
                    template: this.observedByTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Observation Status",
                    modelPath: 'C_ClinicalObservationStatus_key',
                    template: this.observationStatusTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Observations",
                    modelPath: 'ClinicalObservationDetail',
                    template: this.observationsTmpl,
                    hideFromAddScreen:  true,
                    skipDropdown: true,
                    caretClicked: () => {
                        this.openObservationChooser(this.observationmodal, {}, null);
                    }
                },
                {
                    label: "Comments",
                    modelPath: 'Comments',
                    template: this.commentsTmpl,
                    hideFromAddScreen:  true,
                },
                {
                    label: "Animal Status",
                    modelPath: 'C_AnimalStatus_key',
                    template: this.animalStatusTmpl,
                    visible: false,
                    hideFromEditScreen: true
                },
                {
                    label: "Health Tech",
                    modelPath: 'C_Resource_key',
                    template: this.healthTechTmpl,
                    visible: false,
                    hideFromEditScreen: true
                },
                ...this.isEdit ? [] : [{
                    label: "Urgent",
                    modelPath: 'IsUrgent',
                    template: this.urgentTmpl
                }],
            ]
        };
    }

    initialize() {

         // Copy the input so we don't touch the grid data
        this.observations = this.observations.slice();
    }

    getData() {
        this.facetLoadingState.changeLoadingState(true);

        Promise.all([
            this.getSettings(),
            this.getCVs()
        ]).then(() => {
            this.facetLoadingState.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingState.changeLoadingState(false);
            throw error;
        });
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.clinicalVocabService.animalStatuses$.pipe(map((data) => {
            this.animalStatuses = data;
        })).toPromise();

        const p2: Promise<any> = this.clinicalVocabService.resources$.pipe(map((data) => {
            this.resources = data;
        })).toPromise();

        const p3: Promise<any> = this.clinicalVocabService.clinicalObservationStatuses$.pipe(map((data) => {
            this.clinicalObservationStatuses = data;
        })).toPromise();

        const p4: Promise<any> = this.clinicalVocabService.bodyConditionScores$.pipe(map((data) => {
            this.bodyConditionScores = data;
        })).toPromise();

        const p5: Promise<any> = this.clinicalVocabService.exitReasons$.pipe(map((data) => {
            this.exitReasons = data;
        })).toPromise();

        return Promise.all([p1, p2, p3, p4, p5]);
    }


    isUrgentChanged(healthRecord: any) {
        this.healthRecord = healthRecord;
        if (this.healthRecord.IsUrgent === true) {
            this.openAlertSender();
        }
    }

    private openAlertSender() {
        const modalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            size: 'lg',
        };

        const ref = this.modalService.open(ClinicalAlertSenderComponent, modalOptions);
        const component = ref.componentInstance as ClinicalAlertSenderComponent;
        component.healthRecord = this.healthRecord;
    }

    private getSettings(): Promise<any> {
        return this.settingService.getDefaultHealthTechSetting().then((data: any) => {
            if (data) {
                this.defaultResourceKey = data.C_DefaultHealthTechnician_key;
            } else {
                const errorMessage = 'Workgroup settings could not be loaded.';

                this.loggingService.logError(
                    errorMessage,
                    null,
                    this.COMPONENT_LOG_TAG,
                    true
                );

                throw new Error(errorMessage);
            }
        });
    }

    // formatters for <select> inputs
    animalStatusKeyFormatter = (value: any) => {
        return value.C_AnimalStatus_key;
    }
    animalStatusFormatter = (value: any) => {
        return value.AnimalStatus;
    }
    resourceKeyFormatter = (value: any) => {
        return value.C_Resource_key;
    }
    resourceNameFormatter = (value: any) => {
        return value.ResourceName;
    }
    observationStatusKeyFormatter = (value: any) => {
        return value.C_ClinicalObservationStatus_key;
    }
    observationStatusFormatter = (value: any) => {
        return value.ClinicalObservationStatus;
    }
    bodyScoreKeyFormatter = (value: any) => {
        return value.C_BodyConditionScore_key;
    }
    bodyScoreFormatter = (value: any) => {
        return value.BodyConditionScore;
    }
    exitReasonKeyFormatter = (value: any) => {
        return value.C_ExitReason_key;
    }
    exitReasonFormatter = (value: any) => {
        return value.ExitReason;
    }


    openObservationChooser(observationmodal: TemplateRef<any>, observation: any, section: any) {
        this.section = section;
        this.selectedObservation = observation;
        this.modalService.open(observationmodal);
    }

    onSelectObservations(selected: any[]) {
        // create new observations based on selections
        const selectedObservations = this.observations.filter((obs) => !!obs.Animal)
                                                      .map((obs) => obs.Animal.AnimalClinicalObservation[0]);
        const observations = this.section === BulkEditSection.InputCell ? [this.selectedObservation] : this.observations;
        observations.forEach((selectedObservation: any) => {
            this.deleteUnselectedObservationDetails(selectedObservation, selected);
            this.createNewObservationDetails(selectedObservation, selected);
        });
    }

    /**
     * Delete those observation statuses that are in the current observation
     *   but not in the set of selected observation statuses
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
    deleteUnselectedObservationDetails(observation: any, observationCVs: any[]) {
        // find observations missing from selection
        const currentDetails = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail'
        );
        const missingObservationDetails = currentDetails.filter((current) => {
            return observationCVs.indexOf(current.cv_ClinicalObservation) < 0;
        });

        // delete observations missing from selection 
        for (const observationDetail of missingObservationDetails) {
            this.clinicalService.deleteObservationDetail(observationDetail);
        }
    }

    /**
     * Create new observations for those in selected that
     *   are not in current observation
     * @param observation - current AnimalClinicalObservation
     * @param observationCVs - selected cv_ClinicalObservationStatuses
     */
    createNewObservationDetails(observation: any, observationCVs: any[]) {
        // find observations in selection missing from current
        const currentObservationCVs = uniqueArrayFromPropertyPath(
            observation, 'ClinicalObservationDetail.cv_ClinicalObservation'
        );
        const missingObservationCVs = observationCVs.filter((selected) => {
            return currentObservationCVs.indexOf(selected) < 0;
        });

        // create observations missing from current
        for (const observationCV of missingObservationCVs) {
            this.clinicalService.createObservationDetail(
                {
                    C_AnimalClinicalObservation_key: observation ? observation.C_AnimalClinicalObservation_key : null,
                    C_ClinicalObservation_key: observationCV.C_ClinicalObservation_key
                }
            );
        }
    }
    
    validate() {
        return dateControlValidator(this.dateControls);
    }
}
