import { OBJECT_TYPE_MAPPING } from './models/object-type-mapping';
import { EntityState, Entity } from 'breeze-client';
import { AdminManagerService } from './admin-manager.service';
import { Injectable } from '@angular/core';

import { DataManagerService } from './data-manager.service';
import { BaseEntityService } from './base-entity.service';
import { getSafeProp } from '../common/util';

@Injectable()
export class DeletionService extends BaseEntityService {

    constructor(
        private dataManager: DataManagerService,
        private adminManager: AdminManagerService,
    ) {
        super();
    }
    
    create(initialValues: any): any {
        return this.dataManager.createEntity('Deletion', initialValues);
    }

    /**
     * Creates deletion records for anything deleted in the batch.
     *   Returns the Deletion records to be saved
     */
    handleDeletedItemsInBatch(batch: Entity[]): Entity[] {
        const deletionRecords: Entity[] = [];
        for (const entity of batch) {
            if (entity &&
                entity.entityAspect &&
                entity.entityAspect.entityState === EntityState.Deleted
            ) {
                const deletionRecord = this.createDeletionRecord(entity);
                if (deletionRecord) {
                    deletionRecords.push(deletionRecord);
                }
            }
        }

        return deletionRecords;
    }

    /**
     * Creates deletion records for any available deleted entities
     */
    handleDeletedItemsForEntities(entityNames: string[]): Entity[] {
        const deletionRecords: Entity[] = [];
        
        // create a deletion record for any records that have been deleted
        const _dataManager = this.dataManager.getManager();
        const _adminManager = this.adminManager.getManager();

        let entities: any[] = _dataManager.getEntities(null, EntityState.Deleted);
        entities.concat(_adminManager.getEntities(null, EntityState.Deleted));

        entities = entities.filter((entity) => {
            const entityName = getSafeProp(entity, 'entityAspect.entityType.shortName');
            return entityNames.indexOf(entityName) >= 0;
        });

        for (const entity of entities) {
            deletionRecords.push(this.createDeletionRecord(entity));
        }

        return deletionRecords;
    }

    /**
     * Creates deletion records for any available deleted entities
     */
    handleDeletedItems() {
        // create a deletion record for any records that have been deleted
        const _dataManager = this.dataManager.getManager();
        const _adminManager = this.adminManager.getManager();

        let entities: any[] = _dataManager.getEntities(null, EntityState.Deleted);
        entities = entities.concat(_adminManager.getEntities(null, EntityState.Deleted));

        for (const entity of entities) {
            this.createDeletionRecord(entity);
        }
    }

    createDeletionRecord(entity: Entity, reasonForChange?: string): Entity {
        let deletionRecord: Entity = null;

        // todo: get object type key, get record key
        const recordKey = entity.entityAspect.getKey();
        const objectTypeKey = this.getEntityObjectType(recordKey.entityType.shortName);
        const recordKeyValue = recordKey.values[0];

        // verify that deletion record doesn't already exist; 
        //   record could exist if previous save attempt failed.
        if (!this.dataManager.deletionRecordExists(objectTypeKey, recordKeyValue)) {
            deletionRecord = this.create({
                C_ObjectType_key: objectTypeKey,
                C_Record_key: recordKeyValue,
                ReasonForChange: reasonForChange
            });
        }

        return deletionRecord;
    }

    getEntityObjectType(entityName: string): number {
        if (OBJECT_TYPE_MAPPING.hasOwnProperty(entityName.toLowerCase())) {
            return OBJECT_TYPE_MAPPING[entityName.toLowerCase()];
        }
        return 0;
    }
}
