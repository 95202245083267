<ng-template #filterModal let-c="close">
    <import-filter [filter]="filter"
                   (close)="c('Close')"
                   (onFilter)="runImportFilter($event)"></import-filter>
</ng-template>

<ng-template ngbModalContainer></ng-template>

<div class="facet">
    <facet-header
        caption="Import"
        [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openModal(filterModal)"
                title="Filter Data">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [options]="importTableOptions.options"
                                       [dataService]="dataService"
                                       componentName="import">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="importTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <new-import *ngIf="facetView === NEW_IMPORT_VIEW"
                    class="d-block h-100"
                    [facetPrivilege]="facet.Privilege"
                    [username]="getUsername()"
                    (cancel)="cancelNewImport()"
                    (createdImportLog)="createdImportLog($event)"></new-import>

        <import-detail *ngIf="facetView === DETAIL_VIEW"
                       [importLog]="itemToEdit"
                       (cancel)="changeView(LIST_VIEW)"></import-detail>
    </div>
</div>
