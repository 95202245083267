<div class="facet">
    <facet-header
        caption="Matings"
        [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Matings">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <button climbCageCardButton
                        (click)="requestCageCards()"
                        [disabled]="selectedRows.length === 0"
                        title="Print cage cards for selected mating units"
                        appInsightsClick="click-mating-listview-cagecards">
                    Card
                </button>

                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="sendSelectedToBulkEdit()"
                            [appInsightsClick]="'click-bulk-edit-' + componentName">
                        Edit
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkAssignTasks()"
                            appInsightsClick="click-mating-bulk-assign-task">
                        Assign Task
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkAssignProtocol()"
                            appInsightsClick="click-mating-bulk-assign-protocol">
                        Assign Protocol
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [expandExport]="true"
                                       [options]="matingTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="matingTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              [(selectedRows)]="selectedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <mating-detail *ngIf="facetView === DETAIL_VIEW"
                       [facet]="facet"
                       [mating]="itemToEdit"
                       [pageState]="detailPaginator.pageState"
                       [activeFields]="activeFields"
                       [requiredFields]="requiredFields"
                       (exit)="exitDetail()"
                       (previous)="detailPreviousClicked()"
                       (next)="detailNextClicked()"
                       (reload)="refreshData()"></mating-detail>

        <mating-bulk-edit *ngIf="facetView === BULK_EDIT_VIEW"
                           [facet]="facet"
                           [facetView]="facetView"
                           [matings]="itemsToEdit"
                           [activeFields]="activeFields"
                           [inactiveFields]="inactiveFields"
                           [requiredFields]="requiredFields"
                           (exit)="exitBulkEdit($event)"></mating-bulk-edit>
    </div>
</div>
