import { ChangeDetectorRef, Component } from '@angular/core';
import { padlockClosedFilled, padlockOpenedFilled } from '@icons';
import { CustomizeWorkspaceService } from '@services/customize-workspace.service';
import { WorkspaceService } from '../../../workspaces/workspace.service';
import { WorkspaceManagerService } from '@services/workspace-manager.service';
import { ConfirmOptions, ConfirmService } from '@common/confirm';
import { notEmpty } from '@common/util';
import { LoggingService } from '@services/logging.service';

@Component({
    selector: 'climb-lock-toggle',
    templateUrl: 'lock-toggle.component.html',
    styleUrls: ['lock-toggle.component.scss'],
})
export class LockToggleComponent {
    icons = { padlockOpenedFilled, padlockClosedFilled };
    private LOG_TAG = 'climb-lock-toggle';

    constructor(
        private customizeWorkspaceService: CustomizeWorkspaceService,
        private workspaceService: WorkspaceService,
        private workspaceManagerService: WorkspaceManagerService,
        private confirmService: ConfirmService,
        private loggingService: LoggingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    async handleClick() {
        this.isCustomizeWorkspaceActive() ? await this.exitWorkspaceCustomization() : this.customizeWorkspaceLayout();
        this.changeDetectorRef.markForCheck();
    }

    isCustomizeWorkspaceActive(): boolean {
        return this.customizeWorkspaceService.isCustomizeWorkspaceActive();
    }

    private async exitWorkspaceCustomization(): Promise<void> {
        this.workspaceService.collapseAllFacets();

        const changesCount = this.workspaceManagerService.getChangesCount();

        if (!changesCount) {
            this.cancelWorkspaceCustomization();
            return;
        }

        const confirmOptions: ConfirmOptions = {
            title: 'Save Workspace Changes',
            message: 'Save your workspace changes?',
            yesButtonText: 'Save Changes',
            noButtonText: 'Discard Changes'
        };
        try {
            await this.confirmService.confirm(confirmOptions);
            await this.saveWorkspace();
        } catch (_) {
            this.cancelWorkspaceCustomization();
        }
    }

    private customizeWorkspaceLayout() {
        this.customizeWorkspaceService.setCustomizeWorkspaceActive(true);
    }

    private async saveWorkspace(): Promise<void> {
        this.workspaceService.validateWorkspaceName(this.workspaceService.currentWorkspace, this.workspaceService.workspaces);

        try {
            await this.workspaceManagerService.save();
            this.customizeWorkspaceService.setCustomizeWorkspaceActive(false);
            this.loggingService.logSuccess(
                "Workspace changes saved",
                null,
                this.LOG_TAG,
                true
            );
        } catch (error) {
            let message = error.message;
            if (notEmpty(error.entityErrors)) {
                message = error.entityErrors[0].errorMessage;
            }
            this.loggingService.logError(
                "Workspace save failed: " + message,
                error,
                this.LOG_TAG,
                true
            );
        }
    }

    private cancelWorkspaceCustomization(): void {
        const anyChangesReverted = this.workspaceManagerService.cancel();
        this.customizeWorkspaceService.setCustomizeWorkspaceActive(false);
        if (anyChangesReverted) {
            const workspace = this.workspaceService.currentWorkspace;
            this.workspaceService.navigateToWorkspace(workspace);
        }
    }
}
