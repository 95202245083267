import { Component, Injector, Input, OnInit } from '@angular/core';
import { Workspace } from '@common/types/models/workspace.interface';
import { AddWorkspaceModalService } from '../../../workspaces/add-workspace-modal.service';
import { DataContextService } from '@services/data-context.service';
import { ConfirmService } from '@common/confirm';
import { CustomizeWorkspaceService } from '@services/customize-workspace.service';
import { RoutingService } from '../../../routing/routing.service';
import { WorkspaceManagerService } from '@services/workspace-manager.service';
import { WorkspaceService } from '../../../workspaces/workspace.service';
import { MixinUnsubscribeClass } from '@common/mixins';
import { RouteEnum } from '../../../routing/route.enum';
import { padlockClosedFilled, padlockOpenedFilled, plusCircleFilled, trashcan } from '@icons';
import { Entity } from '@common/types';
import { startWith } from 'rxjs/operators';
import { PopoverOptions } from '@common/components/popover/popover-options.interface';
import { ViewUnsavedChangesModalService } from '@common/toolbars';
import { SaveChangesService } from '@services/save-changes.service';

@Component({
    selector: 'climb-workspace-selector',
    templateUrl: 'workspace-selector.component.html',
    styleUrls: ['workspace-selector.component.scss'],
})
export class WorkspaceSelectorComponent extends MixinUnsubscribeClass implements OnInit {
    @Input() workspaces: Entity<Workspace>[];
    currentWorkspaceKey: number | null = null;
    currentWorkspaceName: string | null = null;

    popoverOptions: PopoverOptions = {
        positions: [
            {
                originX: 'start',
                originY: 'bottom',
                overlayX: 'start',
                overlayY: 'top',
                offsetY: 4,
            }
        ]
    };
    readonly icons = { plusCircleFilled, trashcan, padlockOpenedFilled, padlockClosedFilled };

    constructor(
        private addWorkspaceModalService: AddWorkspaceModalService,
        private dataContext: DataContextService,
        private confirmService: ConfirmService,
        private customizeWorkspaceService: CustomizeWorkspaceService,
        private routingService: RoutingService,
        private workspaceManagerService: WorkspaceManagerService,
        public workspaceService: WorkspaceService,
        private viewUnsavedChangesModalService: ViewUnsavedChangesModalService,
        private saveChangesService: SaveChangesService,
        injector: Injector,
    ) {
        super(injector);
    }

    async ngOnInit() {
        super.ngOnInit();
        await Promise.all([this.dataContext.init(), this.workspaceManagerService.init()]);
        this.initialize();
    }

    get currentWorkspaceNameLength() {
        return this.currentWorkspaceName?.length;
    }

    initialize() {
        this.subscribe(
            this.workspaceService.currentWorkspace$
                .pipe(startWith(this.workspaceService.currentWorkspace))
                .subscribe((workspace) => {
                    this.currentWorkspaceKey = workspace?.C_Workspace_key;
                    this.currentWorkspaceName = workspace?.WorkspaceName;
                })
        );
    }

    isWorkspaceState(): boolean {
        return this.routingService.workspaceStateIs(RouteEnum.Workspaces);
    }

    async navigateToDashboard(): Promise<void> {
        if (this.saveChangesService.hasChanges) {
            try {
                await this.handleSaveBeforeNavigate();
            } catch (error) {
                return;
            }
        }
        this.routingService.navigateToDashboard();
    }

    handleAddingNewWorkspace(newWorkspace: Entity<Workspace>) {
        const newWorkspacesList = this.workspaceService.getSortedWorkspaces([...this.workspaces, newWorkspace]);
        this.workspaceService.updateWorkspaces(newWorkspacesList);
        this.navigateToWorkspace(newWorkspace);
        if (newWorkspace.WorkspaceDetail.length === 0) {
            this.customizeWorkspaceService.setCustomizeWorkspaceActive(true);
        }
    }

    async navigateToWorkspace(workspace: Entity<Workspace>): Promise<void> {
        if (this.saveChangesService.hasChanges) {
            try {
                await this.handleSaveBeforeNavigate();
            } catch (error) {
                return;
            }
        }
        this.workspaceService.navigateToWorkspace(workspace);
    }

    async openAddWorkspaceModal(): Promise<void> {
        if (this.saveChangesService.hasChanges) {
            try {
                await this.handleSaveBeforeNavigate();
            } catch (error) {
                return;
            }
        }
        const newWorkspace = await this.addWorkspaceModalService.openAddWorkspaceModal();
        if (!newWorkspace) {
            return;
        }
        this.handleAddingNewWorkspace(newWorkspace);
    }

    async deleteWorkspace(): Promise<void> {
        if (this.saveChangesService.hasChanges) {
            try {
                await this.handleSaveBeforeNavigate();
            } catch (error) {
                return;
            }
        }
        await this.confirmService.confirmDelete(
            'Delete Workspace',
            'Are you sure you want to delete this workspace?'
        );
        await this.confirmDelete();
    }

    async confirmDelete() {
        const workspace = this.workspaceService.currentWorkspace;
        try {
            this.workspaceService.deleteWorkspace(workspace);
            await this.workspaceManagerService.save();
            this.customizeWorkspaceService.setCustomizeWorkspaceActive(false);
            const newWorkspaces = this.workspaces
                .filter((ws: Entity<Workspace>) => ws.C_Workspace_key !== workspace.C_Workspace_key);
            this.workspaceService.updateWorkspaces(newWorkspaces);
            if (newWorkspaces.length > 0) {
                this.navigateToWorkspace(newWorkspaces[0]);
            } else {
                this.routingService.navigateToDashboard();
                this.workspaceService.setCurrentWorkspace(null);
            }
        } catch (_) {
            workspace.entityAspect.setUnchanged();
        }
    }

    customizeWorkspaceLayout() {
        this.customizeWorkspaceService.setCustomizeWorkspaceActive(true);
    }

    async handleSaveBeforeNavigate(): Promise<void> {
        const result = await this.viewUnsavedChangesModalService.openComponent();
        if (result === 'save') {
            try {
                await this.saveChangesService.saveChanges('GLOBAL');
            } catch (error) {
                throw Promise.reject(error);
            }
        } else {
            this.dataContext.cancel(true);
        }
    }
}
