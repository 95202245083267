import { IoTPlotFilter } from './models/iot-plot-filter';
import { 
    Component, 
    Input,
    OnDestroy,
    OnInit,
    NgZone,
    ViewChildren,
 } from '@angular/core';

import { IoTService } from './iot.service';
import { DateFormatterService } from '@common/util/date-time-formatting';
import {
    uniqueArrayFromPropertyPath, 
    notEmpty,
    uniqueArrayOnProperty, 
    getSafeProp,
    daysSince,
} from '@common/util';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { NgModel } from '@angular/forms';
import { LoggingService } from '@services/logging.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { arrowClockwise } from '@icons';

type ChartData = {
    traces: Plotly.Data[];
    layout: Plotly.Layout
};

@Component({
    selector: 'iot-plots-facet',
    templateUrl: './iot-plots-facet.component.html',
    styles: [`
        .inactive {
            color: #888;
        }
    `]
})
export class IoTPlotsFacetComponent implements OnInit, OnDestroy {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @Input() facet: any;

    readonly icons = { arrowClockwise };

    devices: any[];
    registrationRequests: any;
    selectedDevices: any[] = [];
    selectedDeviceOutput: any = null;
    deviceOutputs: any[] = [];
    filteredDeviceChoices: any[] = [];
    updateRealTime = false;
    chartData: ChartData = {
        traces: [],
        layout: null,
    };

    private _plotDateStart: Date = null;
    private _plotDateEnd: Date = null;

    private intervalId: any;
    dataRequestPending = false;
    private dataRequestDeferred = false;
    showDateRangeLimitWarning = false;

    // 7 day limit for chart range
    readonly DATE_RANGE_LIMIT = 7;

    constructor(
        private iotService: IoTService,
        private ngZone: NgZone,
        private dateFormatterService: DateFormatterService,
        private loggingService: LoggingService,
    ) { }

    ngOnInit() {
        this._plotDateStart = new Date();
        this._plotDateEnd = this.addDays(this._plotDateStart, 1);
        this.initialize();

        this.ngZone.runOutsideAngular(() => {
            this.intervalId = setInterval(() => {
                if (this.updateRealTime && !this._plotDateStart) {
                    this.updatePlots();
                }
            }, 15000);
        });
    }

    ngOnDestroy() {
        clearInterval(this.intervalId);
    }

    initialize(): Promise<any> {
        return this.iotService.getDeviceModelOutputs().then((outputs: any[]) => {
            if (!outputs) {
                return;
            }
            this.deviceOutputs = uniqueArrayOnProperty(outputs, 'OutputName');
        }).then(() => {
            return this.iotService.getDevices({
                page: 1,
                size: 1000,
                filter: {},
                expands: ['DeviceModel.DeviceModelOutput']
            });
        }).then((devices) => {
            this.devices = devices.results;
        }).then(() => {
            if (notEmpty(this.deviceOutputs)) {
                this.selectedDeviceOutput = this.deviceOutputs[0];
                this.filterDeviceChoices();
            }
        });
    }

    refresh() {
        this.initialize();
        this.updatePlots();
    }

    get plotDateStart() {
        return this._plotDateStart;
    }

    set plotDateStart(newPlotDate: any) {
        this._plotDateStart = newPlotDate;
        if (!this.isPlotDateRangeValid()) {
            this.showDateRangeLimitWarning = true;
            // reset to a valid date range if invalid
            this._plotDateEnd = this.addDays(this._plotDateStart, this.DATE_RANGE_LIMIT);
        } else {
            this.showDateRangeLimitWarning = false;
        }
        this.updatePlots();
    }

    get plotDateEnd() {
        return this._plotDateEnd;
    }

    set plotDateEnd(newPlotDate: any) {
        this._plotDateEnd = newPlotDate;

        if (!this.isPlotDateRangeValid()) {
            this.showDateRangeLimitWarning = true;
            // reset to a valid date range if invalid
            this._plotDateStart = this.addDays(this._plotDateEnd, -this.DATE_RANGE_LIMIT);
        } else {
            this.showDateRangeLimitWarning = false;
        }
        this.updatePlots();
    }

    selectedDevicesChanged() {
        this.updatePlots();
    }

    selectedOutputChanged() {
        this.filterDeviceChoices();
        this.selectedDevices = this.selectedDevices.filter((device) => {
            return this.filteredDeviceChoices.indexOf(device) >= 0;
        });
        this.updatePlots();
    }

    isPlotDateRangeValid() {
        const plotDateDiff = daysSince(this.plotDateEnd, this.plotDateStart);
        return plotDateDiff == null || plotDateDiff < this.DATE_RANGE_LIMIT;
    }

    filterDeviceChoices() {
        const selectedOutputName = getSafeProp(this.selectedDeviceOutput, 'OutputName');
        this.filteredDeviceChoices = this.devices.filter((device) => {
            const outputNames = uniqueArrayFromPropertyPath(
                device, 'DeviceModel.DeviceModelOutput.OutputName'
            );
            return outputNames.indexOf(selectedOutputName) >= 0;
        });
    }

    updatePlots() {
        const errMessage = dateControlValidator(this.dateControls);
        if (errMessage) {
            this.loggingService.logError(errMessage, null, '', true);
            return;
        }

        // copy selections in case they change during promise resolution
        const currSelectedDevices = this.selectedDevices.slice(0);
        const currOutputName = getSafeProp(this.selectedDeviceOutput, 'OutputName');

        if (!currOutputName || !notEmpty(currSelectedDevices)) {
            return;
        }
    
        if (this.dataRequestPending) {
            this.dataRequestDeferred = true;
        } else {
            this.dataRequestDeferred = false;
            this.dataRequestPending = true;

            const deviceOutputPromises = currSelectedDevices.map((dev: any) => {

                const filter: IoTPlotFilter = {
                    deviceId: dev.C_Device_key,
                    sensorName: currOutputName,
                    startTime: this.dateFormatterService.formatDateOnly(this.plotDateStart),
                    endTime: this.dateFormatterService.formatDateOnly(this.plotDateEnd)
                };

                return this.iotService.getIoTPlotData(filter);
            });
            Promise.all(deviceOutputPromises).then((deviceOutputsByDevice: any) => {
                // reset the pending state since we got our result
                this.dataRequestPending = false;
                if (this.dataRequestDeferred) {
                    // if a previous request was deferred, kick it off now
                    this.updatePlots();
                }

                // format our device output as plotly trace objects
                const traces = deviceOutputsByDevice.map((deviceOutputs: any[], i: number) => {
                    return {
                        x: deviceOutputs.map((output: any) =>  convertValueToLuxon(output.x).setZone("UTC").toJSDate()),
                        y: deviceOutputs.map((output: any) => parseFloat(output.y)),
                        type: 'scatter',
                        name: currSelectedDevices[i].C_Device_key,
                    };
                });

                // add some titles etc. to plotly
                let yAxisTitle = this.selectedDeviceOutput.OutputType;
                if (this.selectedDeviceOutput.OutputUnits) {
                    yAxisTitle += ' (' + this.selectedDeviceOutput.OutputUnits + ')';
                }
                const layout = {
                    title: 'Device Sensor Readings',
                    yaxis: {
                        title: yAxisTitle,
                    },
                } as ChartData['layout'];
                this.chartData = { traces, layout };
            }, (reason: any) => {
                // on error we still need to reset the pending state
                this.dataRequestPending = false;
                if (this.dataRequestDeferred) {
                    // if a previous request was deferred, kick it off now
                    this.updatePlots();
                }
            });
        }
    }

    addDays(date: Date, days: number): Date {
        return convertValueToLuxon(date).plus({"days": days}).toJSDate();
    }
}
