import { JobsSharedModule } from './../jobs/jobs-shared.module';
import { AuditSharedModule } from './../audit/audit-shared.module';
import { ExportOrderAuditService } from './audit/export-order-audit.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ClimbCommonModule } from '../common/climb-common.module';
import { ClimbServicesModule } from '../services/climb-services.module';

import { OrderService } from './order.service';
import { OrderVocabService } from './order-vocab.service';
import { OrderFilterComponent } from './order-filter.component';
import { OrderAuditReportComponent, ViewOrderAuditReportComponentService } from './audit';
import { LotSelectComponent } from './lot-select.component';
import { OrderSelectComponent } from './order-select.component';
import { OrderMultiselectComponent } from './order-multiselect.component';
import { UsersSharedModule } from '../user/users-shared.module';
import { LinesSharedModule } from '../lines/lines-shared.module';
import { JobOrderService } from '../jobs/job-order.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ClimbCommonModule,
        ClimbServicesModule,
        AuditSharedModule,
        JobsSharedModule,
        LinesSharedModule,
        UsersSharedModule
    ],
    declarations: [
        OrderAuditReportComponent,
        OrderFilterComponent,
        LotSelectComponent,
        OrderSelectComponent,
        OrderMultiselectComponent
    ],
    exports: [
        OrderAuditReportComponent,
        OrderFilterComponent,
        LotSelectComponent,
        OrderSelectComponent,
        OrderMultiselectComponent
    ]
})
export class OrdersSharedModule { 
    static forRoot(): ModuleWithProviders<OrdersSharedModule> {
        return {
          ngModule: OrdersSharedModule,
          providers: [
            OrderService,
            JobOrderService,
            OrderVocabService,
            ExportOrderAuditService,
            ViewOrderAuditReportComponentService
          ]
        };
    }
}
