import { DataContextService } from './../../services/data-context.service';
import { BulkAddCommService } from './../../common/facet/bulk-add-comm.service';
import { FacetLoadingStateService } from './../../common/facet/facet-loading-state.service';
import { BulkEditSection } from '../../common/facet/models/bulk-edit-section.enum';
import { BulkEditOptions } from '../../common/facet/models';
import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    OnDestroy,
} from '@angular/core';

import { LocationService } from '../../locations/location.service';
import { MaterialPoolService } from '../../services/material-pool.service';
import { NamingService } from '../../services/naming.service';
import { VocabularyService } from '../../vocabularies/vocabulary.service';
import { AnimalLogic } from '../animal-logic.shared';
import {
    SaveChangesService
} from '../../services/save-changes.service';
import {
    NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { ConfirmHousingModalComponent, HousingOption } from './confirm-housing-modal.component';
import { LoggingService } from './../../services/logging.service';
import { Subscription } from 'rxjs';

class BulkHousingUnit {
    // Housing ID
    id: string;
    // Number of housed animals
    size: number;

    constructor(size: number) {
        this.size = size;
    }
}
class BulkHousing {
    // External State
    //
    // Number of animals, updated by the bulk-add component
    itemsToAdd = 1;
    // Is Housing auto-naming active?
    namingActive = false;

    // Internal State
    //
    // Has the Create Housing section been initialized?
    enabled = false;
    // Number of unhoused animals
    unhoused = 0;
    // Housing unit config (size, ID)
    units: BulkHousingUnit[] = new Array<BulkHousingUnit>();

    // Form Elements
    //
    // Are new housing units being created?
    add = true;
    // Number of animals per unit
    size = 5;
    // Housing Type
    materialPoolTypeKey: number;
    materialPoolTypes: any[] = [];
    // Date
    date: any = new Date();
    // Owner
    owner: any;
    // Location (read-only)
    location: any;
    // Container Type
    containerTypeKey: number;
    containerTypes: any[] = [];

    materialPoolStatusKey: number;
}

@Component({
    selector: 'animal-bulk-housing',
    templateUrl: './animal-bulk-housing.component.html',
    providers: [
        BulkAddCommService
    ],
    styles: [`
      .housing .form-group {
          margin-bottom: 8px !important;
      }
      .housing-size {
          direction: rtl;
          padding: 5px;
      }
      .housing-size,
      .housing-unit-size {
          width: 50px;
          max-width: 50px;
      }
      .housing-unit-id { 
          width: 100px; 
      }
      .input-group-md > .input-group-append > .add-item-link {
          padding: 6px 14px 6px 9px;
      }
      @media screen and (min-width: 768px) {
          .housing .form-group .col-md-3 {
              text-align: right;
              padding-right: 0;
          }
      }
    `],
})
export class AnimalBulkHousingComponent implements OnInit, OnDestroy {
    @Input() facet: any;
    @Input() animals: any[];
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];
    @Output() exit: EventEmitter<any[]> = new EventEmitter<any[]>();

    // shared logic for animal facet
    animalLogic: AnimalLogic;

    bulkHousing: BulkHousing;

    readonly COMPONENT_LOG_TAG = 'animal-bulk-housing';

    bulkOptions: BulkEditOptions;
    BulkEditSection = BulkEditSection;

    saveChangesService: SaveChangesService;
    canWrite: boolean;
    hasChanges = false;
    housingAnimals = false;

    _subs: Subscription[] = [];

    constructor(
        private faceLoadingStateService: FacetLoadingStateService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private namingService: NamingService,
        private vocabularyService: VocabularyService,
        private dataContext: DataContextService,
        saveChangesService: SaveChangesService,
        private modalService: NgbModal,
        private loggingService: LoggingService,
    ) {
        this.saveChangesService = saveChangesService;
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
    }

    ngOnDestroy() {
        for (const s of this._subs) {
            s.unsubscribe();
        }
    }

    initialize() {
        // Copy the input so we don't touch the grid data
        this.animals = this.animals.slice();
        this.initBulkHousing();
        const s1 = this.dataContext.checkChanges$.subscribe((hasChanges: boolean) => {
            this.hasChanges = hasChanges;
        });
        this._subs.push(s1);
        this.initPrivilege();
    }

    initPrivilege(): void {
        this.canWrite = (this.facet.Privilege === 'ReadWrite');
    }

    /**
     * The "Save and Clear Form" button has been used.
     * 
     * @param options
     */
    formClear(options: BulkEditOptions) {
        // Reset the Bulk Housing
        this.initBulkHousing();
    }

    // Check if the animals are already housed
    checkForHousing(animals: any[]) {
        const housedAnimals = [];
        for (const animal of animals) {
            const animalAssocs = animal.Material.MaterialPoolMaterial.filter((materialPoolMaterial: any) => {
                return !materialPoolMaterial.DateOut;
            });
            if (animalAssocs.length > 0) {
                housedAnimals.push(animal);
            }
        }
        return housedAnimals;
    }

    // Filter out the animals that already have housing
    skipHoused(housedAnimals: any[]) {
        return this.animals.filter(
            function (e) {
                return this.indexOf(e) < 0;
            },
            housedAnimals
        );
    }

    // Create housing units, save, and exit bulk housing view
    async createAndSave(animalsToHouse: any[]) {
        const newHousings = await this.createNewHousings(this.bulkHousing, animalsToHouse);
        this.faceLoadingStateService.changeLoadingState(true);
        try {
            await this.dataContext.save();
            if (newHousings.length > 0) {
                this.faceLoadingStateService.changeLoadingState(false);
                this.loggingService.logSuccess(
                    "Added new housing",
                    null, 'animal', true
                );
            }
            this.housingAnimals = false;
            this.exit.emit(this.animals);
            return newHousings;
        } catch (error) {
            this.faceLoadingStateService.changeLoadingState(false);
            for (const housing of newHousings) {
                this.materialPoolService.cancelMaterialPool(housing);
            }
            throw error;
        }
    }

    saveClicked(): void {
        // Disable save button while thinking
        this.housingAnimals = true;
        // Check if the animals are already housed
        const housedAnimals = this.checkForHousing(this.animals);
        let animalsToHouse: any[];
        if (housedAnimals.length > 0) {
            // Open modal asking if user really want to move animals that already have housing
            const ref = this.modalService.open(ConfirmHousingModalComponent);
            const component = ref.componentInstance as ConfirmHousingModalComponent;
            component.housedAnimals = housedAnimals;
            let moveAnimals: any;
            // Modal has three option: move animals, skip animals, and cancel
            const s2 = component.exit.subscribe((modalOption: any) => {
                moveAnimals = modalOption;
                if (moveAnimals === HousingOption.Move) {
                    // Move animals option. Put all selected animals into new housing
                    animalsToHouse = this.animals;
                    this.createAndSave(animalsToHouse);
                } else if (moveAnimals === HousingOption.Skip) {
                    // Skip option. Put all unhoused animals in housing
                    animalsToHouse = this.skipHoused(housedAnimals);
                    this.createAndSave(animalsToHouse);
                } else {
                    // Cancel. Don't save and go back to grid view
                    this.housingAnimals = false;
                    console.log('Save canceled');
                    this.exit.emit(this.animals);
                }
            });
            this._subs.push(s2);
        } else {
            // If there are no previously housed animals, house all selected animals
            animalsToHouse = this.animals;
            this.createAndSave(animalsToHouse);
        } 
    }

    exitClicked() {
        this.exit.emit(this.animals);
    }
    
    itemsToAddChanged(itemsToAdd: number) {
        // Changed the number of animals - start over
        this.bulkHousing.itemsToAdd = itemsToAdd;
        this.housingRebuildUnits();
    }

    initBulkHousing() {
        this.bulkHousing = new BulkHousing();
        this.bulkHousing.unhoused = this.animals.length;
        this.bulkHousing.itemsToAdd = this.animals.length;
        const preferLocal = true;

        const promises: Promise<void>[] = [
            // Check if housing is automatically named.
            this.namingService.isHousingNamingActive().then((active: boolean) => {
                this.bulkHousing.namingActive = active;
            }),

            // Housing Type
            this.vocabularyService.getCV(
                'cv_MaterialPoolTypes', 'MaterialPoolType', preferLocal
            ).then((data) => {
                this.bulkHousing.materialPoolTypes = data;
            }),
            this.vocabularyService.getCVDefault('cv_MaterialPoolTypes').then((data) => {
                this.bulkHousing.materialPoolTypeKey = data.C_MaterialPoolType_key;
            }),

            // Container Type
            this.locationService.getContainerTypes('Animal').then((data) => {
                this.bulkHousing.containerTypes = data;
            }),            
            this.vocabularyService.getCVDefault('cv_ContainerTypes').then((data) => {
                this.bulkHousing.containerTypeKey = data.C_ContainerType_key;
            }),

            // Location
            this.locationService.getDefaultLocation().then((data) => {
                this.bulkHousing.location = data;
            }),

            this.vocabularyService.getCVDefault('cv_MaterialPoolStatuses').then((data) => {
                this.bulkHousing.materialPoolStatusKey = data.C_MaterialPoolStatus_key;
            })
        ];

        return Promise.all(promises).then(() => {
            // All set, enable the checkbox
            this.bulkHousing.enabled = true;
        });
    }

    housingAddChanged() {
        // Distribute the animals
        this.housingRebuildUnits();
    }

    housingSizeChanged() {
        // Redistribute the animals
        this.housingRebuildUnits();
    }

    housingRebuildUnits() {
        if (!this.bulkHousing.add) {
            // Not adding housing, so nothing to do
            return;
        }

        if (!this.bulkHousing.size) {
            // Can't fill units without a size
            return;
        }

        const units = new Array<BulkHousingUnit>();

        // Distribute the animals by filling housing units.
        // Note: this leaves animals unhoused rather than partially fill a
        // housing unit. The user will need to decide what to do with those
        // unhoused animals.
        let items = this.bulkHousing.itemsToAdd;
        while (items >= this.bulkHousing.size) {
            // Fill up a new unit
            const unit = new BulkHousingUnit(this.bulkHousing.size);
            units.push(unit);

            // Account for the now-housed animals
            items -= unit.size;
        }

        // Use these new units
        this.bulkHousing.units = units;

        // Count the unhoused (again, this time officially)
        this.housingUpdateUnhoused();
    }

    housingUnitChanged(unit: BulkHousingUnit, index: number) {
        // Deal with typed-in negative numbers or clearing the input
        unit.size = Math.max(0, unit.size);

        // How many still left?
        this.housingUpdateUnhoused();

        if (this.bulkHousing.unhoused < 0) {
            // Too many animals have been moved into this housing
            unit.size += this.bulkHousing.unhoused;

            // Look for unhoused animals - just in case... should be 0.
            this.housingUpdateUnhoused();
        }
    }

    housingUpdateUnhoused() {
        // Add up all the housed animals
        const housed = this.bulkHousing.units.reduce(
            (sum: number, unit: BulkHousingUnit) => sum + unit.size
            , 0);

        // So, how many did not get a house?
        this.bulkHousing.unhoused = this.bulkHousing.itemsToAdd - housed;
    }

    housingAddUnit() {
        // Add a new unit, and try to fill it with the remaining animals
        this.bulkHousing.units.push(
            new BulkHousingUnit(Math.min(this.bulkHousing.unhoused, this.bulkHousing.size))
        );

        // See who else needs a house
        this.housingUpdateUnhoused();
    }

    housingDeleteUnit(unit: BulkHousingUnit, index: number) {
        // Remove the unit
        this.bulkHousing.units.splice(index, 1);

        // See if any animals got kicked out
        this.housingUpdateUnhoused();
    }

    createNewHousings(bulkHousing: BulkHousing, newAnimals: any[]): Promise<any[]> {
        if (!bulkHousing.add || newAnimals.length === 0) {
            // Nothing to do
            return Promise.resolve([]);
        }

        // Going to need to keep track of which animal is being housed.
        let animalIndex = 0;

        // Create each housing unit sequentially so the animal names are in the
        // same order as the housing unit names. Promises are weird.
        const newHousings: any[] = [];
        for (const unit of bulkHousing.units) {
            // Create a new housing unit, aka MaterialPool
            const materialPool = this.materialPoolService.createMaterialPool({
                MaterialPoolID: unit.id,
                C_MaterialPoolType_key: bulkHousing.materialPoolTypeKey,
                C_ContainerType_key: bulkHousing.containerTypeKey,
                DatePooled: bulkHousing.date,
                Owner: bulkHousing.owner,
                C_MaterialPoolStatus_key: bulkHousing.materialPoolStatusKey
            });

            // Put the housing unit somewhere
            this.locationService.createMaterialLocation({
                C_MaterialPool_key: materialPool.C_MaterialPool_key,
                C_LocationPosition_key: bulkHousing.location.C_LocationPosition_key,
            });

            // Put animals in this housing unit
            let items = unit.size;
            while (items > 0) {
                // Get the next animal
                const animal = newAnimals[animalIndex];
                if (!animal) {
                    // Ran out of animals... weird
                    break;
                }
                
                // If animal is already housed, set current MPM dateOut value to now
                const materialPoolMaterials = animal.Material.MaterialPoolMaterial;
                if (materialPoolMaterials.length > 0) {
                    for (const materialPoolMaterial of materialPoolMaterials) {
                        if (materialPoolMaterial.DateOut === null) {
                            materialPoolMaterial.DateOut = new Date();
                            break;
                        }
                    }
                }

                // Put the animal in the housing unit
                const mpm = this.materialPoolService.createMaterialPoolMaterial({
                    C_MaterialPool_key: materialPool.C_MaterialPool_key,
                    C_Material_key: animal.C_Material_key,
                    DateIn: bulkHousing.date,
                });
                animal.Material.MaterialPoolMaterial.unshift(mpm);

                // Account for the used-up space and housed animal
                items -= 1;
                animalIndex += 1;
            }

            // All done with this one.
            newHousings.push(materialPool);
        }
        
        // All done
        return Promise.resolve(newHousings);
    }

    // <select> formatters
    materialPoolTypeKeyFormatter = (value: any) => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypeFormatter = (value: any) => {
        return value.MaterialPoolType;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
}
