import { 
    Component, 
    Input,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { 
    BaseFacet,
    BaseFacetService
} from '../common/facet';
import {
    TableState,
    DataResponse,
    DataService,
    TableOptions,
    TableColumnDef,
} from '@common/datatable/data-table.interface';
import { IoTService } from './iot.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataContextService } from '@services/data-context.service';
import { takeUntil } from 'rxjs/operators';
import { CellFormatterService } from '@common/datatable';
import { addAuditColumnsToOptions } from '@common/datatable/utils';
import { arrowClockwise } from '@icons';


@Component({
    selector: 'iot-alert-definition-facet',
    templateUrl: './iot-alert-definition-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class IoTAlertDefinitionFacetComponent extends BaseFacet implements OnInit, OnDestroy {
    @Input() facet: any;

    readonly icons = { arrowClockwise };

    componentName = 'iot-alert-definition';
    dataTableOptions: TableOptions = {
        csvFileName: 'iot-alert-definitions.csv',
        enableDetailColumn: true,
        enableDraggable: false,
        enableSelectable: false,
        columns: [
            {
                displayName: 'Name',
                field: 'AlertDefinitionName',
            },
            {
                displayName: 'Definition Type',
                field: 'AlertDefinitionType',
            },
            {
                displayName: 'Description',
                field: 'AlertDefinitionDescription',
            },
        ],
    };
    dataService: DataService = {
        run: (tableState: TableState) => {
            return this.loadAlertDefinitions(tableState);
        }
    };
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private iotService: IoTService,
        private dataContext: DataContextService,
        private cellFormatterService: CellFormatterService,
    ) {
        super(baseFacetService);

        this.dataTableOptions = addAuditColumnsToOptions(cellFormatterService)(this.dataTableOptions);

        this.dataTableColumns = new BehaviorSubject(this.dataTableOptions.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.changeView(this.LIST_VIEW);
    }

    ngOnInit() {
        super.ngOnInit();

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    private loadAlertDefinitions(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;
        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;

        this.setLoadingState(tableState.loadingMessage);

        return this.iotService.getAlertDefinitions({
            page,
            size: pageSize,
            filter: {}
        }).then((queryResult) => {
            this.stopLoading();

            this.data = queryResult.results;
            this.totalCount = queryResult.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount,
            };
        }).catch((err: Error) => {
            this.stopLoading();
            throw err;
        });
    }

    addItemClick() {
        this.setLoadingState();
        this.itemToEdit = this.iotService.createIoTAlertDefinition();
        this.stopLoading();
        this.changeView(this.DETAIL_VIEW);
    }

    refreshData() {
        this.reloadTable();
    }

    ngOnDestroy(): void {
        this.notifier$.next();
        this.notifier$.complete();
    }
}
