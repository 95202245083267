import { zip } from '@lodash';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { WorkspaceService } from '../../workspaces/workspace.service';
import { AddWorkspaceModalService } from '../../workspaces/add-workspace-modal.service';
import { CustomizeWorkspaceService } from '@services/customize-workspace.service';
import type { Entity } from '@common/types';
import type { Workspace } from '@common/types/models/workspace.interface';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable()
export class DashboardDataService {
    private workspaces$$ = new BehaviorSubject<Entity<Workspace>[]>([]);
    workspaces$ = this.workspaces$$.asObservable();

    private workspacesLoading$$ = new BehaviorSubject<boolean>(false);
    workspacesLoading$ = this.workspacesLoading$$.asObservable();

    constructor(
        private httpClient: HttpClient,
        private addWorkspaceModalService: AddWorkspaceModalService,
        private workspaceService: WorkspaceService,
        private customizeWorkspaceService: CustomizeWorkspaceService
    ) {}

    uploadWorkspaces() {
        this.workspacesLoading$$.next(true);
        this.workspaceService.fetchWorkspaces()
            .then(value => {
                this.workspaces$$.next(value);
                this.workspacesLoading$$.next(false);
            });
    }

    async addWorkspace() {
        const newWorkspace = await this.addWorkspaceModalService.openAddWorkspaceModal();
        if (!newWorkspace) {
            return;
        }

        const workspaces = this.workspaces$$.getValue();
        workspaces.push(newWorkspace);
        this.workspaces$$.next(workspaces);
        this.workspaceService.updateWorkspaces(workspaces);

        this.openWorkspace(newWorkspace);

        if (newWorkspace.WorkspaceDetail.length === 0) {
            this.customizeWorkspaceService.setCustomizeWorkspaceActive(true);
        }
    }

    toggleFavorite(key: number) {
        const workspace = this.workspaces$$.getValue().find(item => item.C_Workspace_key === key);
        if (!workspace) return;
        
        const isFavorite = !workspace.IsFavourite;
        const updateIsFavorite = () => {
            const workspaces = this.workspaces$$.getValue()
            .map(item => {
                if (item.C_Workspace_key === workspace.C_Workspace_key) {
                    item.IsFavourite = isFavorite;
                }
                return item;
            });
            this.workspaces$$.next(workspaces);
        }
        return fromPromise(this.workspaceService.markWorkspaceAsFavourite(workspace.C_Workspace_key, isFavorite))
            .pipe(
                tap(() => updateIsFavorite()),
                catchError((error) => {
                    updateIsFavorite();
                    return error;
                })
            );
    }

    openWorkspace(workspace: Entity<Workspace>) {
        this.workspaceService.navigateToWorkspace(workspace);
    }

    getColonyStatsByStrain(): Observable<[string, number][]> {
        return this.httpClient.get<[string, string][]>('/api/charts/colonyStatsByStrain/10').pipe(
            map((colonyStats) => colonyStats.map(([label, value]) => [label, parseInt(value, 10)])),
        );
    }

    getColonyStats(): Observable<[string, number][]> {
        return this.httpClient.get<[string, string][]>('/api/charts/colonyStats').pipe(
            map((colonyStats) => colonyStats.map(([label, value]) => [label, parseInt(value, 10)])),
        );
    }

    currentJobsByType(): Observable<[string, number][]> {
        type Response = {
            data: number[];
            labels: string[];
        };
        return this.httpClient.get<Response>('/api/charts/currentJobsByType').pipe(
            map(({ data, labels }) => zip(labels, data)),
        );
    }

    getWorkflowStats(): Observable<[string, number][]> {
        return this.httpClient.get<[string, string][]>('/api/charts/workflowStats').pipe(
            map((workflowStats) => workflowStats.map((item) => [item[0], parseInt(item[1], 10)])),
        );
    }
}
