import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { IRoleFacet, RoleService } from '@services/role.service';
import { TranslationService } from '@services/translation.service';
import * as escapeStringRegexp from 'escape-string-regexp';
import { FacetSelection } from '../../facet-selection';
import { magnifier } from '@common/icons';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { WorkspaceService } from '../../workspace.service';
import { FormControl } from '@angular/forms';
import { MixinUnsubscribeClass } from '@common/mixins';
import { combineLatest, concat, from, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


/*
 * Displays filterable menu of facets for the current role.
 */

@Component({
    selector: 'facet-selector',
    templateUrl: './facet-selector.component.html',
    styleUrls: ['./facet-selector.component.scss'],
    providers: [{ provide: CLIMB_SURFACE_MODE, useValue: 'dark' }],
})
export class FacetSelectorComponent extends MixinUnsubscribeClass implements OnInit {
    @ViewChild('input') input: ElementRef<HTMLInputElement>;

    @Output() facetSelected = new EventEmitter<FacetSelection>();
    
    icons = { magnifier };
    termControl = new FormControl('');
    loading: boolean;
    facets: IRoleFacet[] = [];


    constructor(
        private roleService: RoleService,
        private translationService: TranslationService,
        private workspaceService: WorkspaceService,
        injector: Injector,
    ) {
        super(injector);
    }

    ngOnInit() {
        this.loading = true;

        const fromData = from(this.roleService.getCurrentUserRoleFacets()).pipe(
            map((roleFacets) => roleFacets.map(roleFacet => {
                roleFacet.Facet.FacetDisplayName = this.translationService.translate(roleFacet.Facet.FacetDisplayName);
                return roleFacet;
            })),
        );

        const fromValue = concat(of(this.termControl.value), this.termControl.valueChanges);

        const subs = combineLatest([fromData, fromValue])
            .pipe(
                map(([facets, value]) => {
                    const term = String(value);
                    return term ? facets.filter(facet => this.matchFilterTerm(facet.Facet.FacetDisplayName, term)) : facets;
                }),
            )
            .subscribe(
                (facets) => {
                    this.facets = facets;
                    this.loading = false;
                },
                (error) => {
                    console.error(error);
                    this.loading = false;
                },
            );

        this.subscribe(subs);
    }

    get workspaceIcons() {
        return this.workspaceService.facetIcons;
    }

    selectFacet(facet: IRoleFacet) {
        const facetSelection = {
            facetName: facet.Facet.FacetName,
            facetDisplayName: facet.Facet.FacetDisplayName,
            hasReadAccess: facet.HasReadAccess,
            hasWriteAccess: facet.HasWriteAccess
        };
        this.facetSelected.emit(facetSelection);
        this.input.nativeElement.blur();
    }

    preventFocus(event: Event) {
        event.preventDefault();
    }

    reset() {
        this.termControl.setValue('');
    }

    focus() {
        this.input.nativeElement.focus();
    }

    private matchFilterTerm(facetName: string, term: string): boolean {
        if (!term) {
            return true;
        }
        const escapedSearch = escapeStringRegexp(term);
        return new RegExp(escapedSearch, 'gi').test(facetName);
    }
}
