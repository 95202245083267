import { Observable } from 'rxjs';
import { FacetLoadingStateService } from '@common/facet/facet-loading-state.service';
import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { CLIMB_SURFACE_MODE } from '@common/tokens';
import { WorkspaceService } from '../../../../workspaces/workspace.service';
import { SaveChangesService } from '@services/save-changes.service';
import { arrowsIn, arrowsOut, crossBig, IconContent } from '@icons';

@Component({
    selector: 'facet-header',
    templateUrl: './facet-header.component.html',
    styleUrls: ['./facet-header.component.scss'],
    providers: [{ provide: CLIMB_SURFACE_MODE, useValue: 'dark' }],
})
export class FacetHeaderComponent implements OnInit {
    @Input() caption: string;
    @Input() facet: any;

    @Output() close = new EventEmitter<void>();

    readonly icons = { arrowsIn, arrowsOut, crossBig };

    // can be triggered by child components in facet to indicate to user
    // that related data is still loading
    facetLoading: Observable<boolean>;

    get facetIcon(): IconContent {
        return this.workspaceService.facetIcons.get(this.facet?.FacetName)?.header;
    }

    get isCustomizeWorkspaceActive(): boolean {
        return this.workspaceService.isCustomizeWorkspaceActive;
    }

    constructor(
        private facetLoadingState: FacetLoadingStateService,
        private workspaceService: WorkspaceService,
        private saveChangesService: SaveChangesService
    ) {}

    ngOnInit() {
        this.facetLoading = this.facetLoadingState.changeLoadingState$;
    }

    async onClose(): Promise<void> {
        const isSaveFacetLevelSupported = this.facet.FacetName === 'Animals';
        // TODO: left if statement here for backwards compatibility, needs to be removed in future
        if (isSaveFacetLevelSupported) {
            this.close.emit();
        } else {
            await this.saveChangesService.promptForUnsavedChanges(this.facet.FacetName);
            this.workspaceService.deleteWorkspaceDetail(this.facet);
            this.workspaceService.autoSizeFacets();
        }
    }

    onExpand(): void {
        this.workspaceService.expandFacet(this.facet);
    }

    onCollapse(): void {
        this.workspaceService.collapseFacet(this.facet);
    }
}
