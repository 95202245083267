<div class="facet">
    <facet-header [caption]="'Protocols' | translate"
                  [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter {{'Protocols' | translate}}">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                [disabled]="!selectedRows.length"
                (click)="copyProtocols()"
                title="Copy {{'Protocols' | translate}}">
            <svg [climbIcon]="icons.squareOnSquare"></svg>
        </button>
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege!=='None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-export-dropdown #exportDropdown
                                       [options]="protocolTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table data-automation-id="protocols-table"
                              #dataTable
                              [dataService]="dataService"
                              [options]="protocolTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              [(draggedRows)]="draggedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (dragStart)="dragStart()"
                              (dragStop)="dragStop()"
                              (addItemClick)="addItemClick()"
                              (deleteItemClick)="deleteItemClick($event)"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <protocol-detail *ngIf="facetView === DETAIL_VIEW"
                         [facet]="facet"
                         [facetView]="facetView"
                         [protocol]="itemToEdit"
                         [pageState]="detailPaginator.pageState"
                         (exit)="exitDetail()"
                         (previous)="detailPreviousClicked()"
                         (next)="detailNextClicked()"
                         (modelCopy)="modelCopied($event)"
                         (reload)="refreshData()">
        </protocol-detail>
    </div>
</div>
