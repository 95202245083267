<div class="facet">
    <facet-header
        caption="Births"
        [facet]="facet">
        <button *ngIf="facetView===LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Births">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView===LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView===LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="sendSelectedToBulkEdit()"
                            [appInsightsClick]="'click-bulk-edit-' + componentName">
                        Edit
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="doBulkDelete()"
                            [appInsightsClick]="'click-bulk-delete-' + componentName">
                        Delete
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkAssignTasks()"
                            appInsightsClick="click-birth-bulk-assign-task">
                        Assign Task
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkAssignProtocol()"
                            appInsightsClick="click-birth-bulk-assign-protocol">
                        Assign Protocol
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [expandExport]="true"
                                       [options]="birthTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="birthTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <birth-detail *ngIf="facetView === DETAIL_VIEW"
                      [facet]="facet"
                      [birth]="itemToEdit"
                      [pageState]="detailPaginator.pageState"
                      (exit)="exitDetail()"
                      (previous)="detailPreviousClicked()"
                      (next)="detailNextClicked()"
                      (reload)="refreshData()"></birth-detail>

        <birth-bulk-edit *ngIf="facetView === BULK_EDIT_VIEW"
                         [facet]="facet"
                         [births]="itemsToEdit"
                         (exit)="exitBulkEdit($event)"
                         #bulkEdit></birth-bulk-edit>
    </div>
</div>
