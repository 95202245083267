<div class="facet">
    <facet-header
        caption="Search"
        [facet]="facet">
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege!=='None'">
        <div class="mt-2">
            <nav ngbNav #nav="ngbNav" class="nav-tabs pt-0"
                 [destroyOnHide]="false"
                 (navChange)="onNavChange($event)">
                <ng-container [ngbNavItem]="SIMPLE_TAB_ID"
                              class="container"
                              [class.container]="true">
                    <a ngbNavLink>Simple</a>
                    <ng-template ngbNavContent>
                        <form role="form">
                            <div id="custom-search-input" class="mx-0 mb-3">
                                <div class="input-group">
                                    <input
                                           type="text"
                                           class="form-control"
                                           placeholder="Search&hellip;"
                                           [(ngModel)]="simpleSearchTerm"
                                           [ngModelOptions]="{standalone: true}" />
                                    <div class="input-group-append">
                                        <button type="submit"
                                                class="btn btn-primary"
                                                (click)="runSimpleSearch()"
                                                appInsightsClick="click-run-simple-search">
                                            <i *ngIf="!searching" class="fa fa-fw fa-search" title="Search"></i>
                                            <i *ngIf="searching" class="fa fa-fw fa-spinner fa-spin" title="Searching&hellip;"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-md-3 col-lg-2">
                                <!--List of Simple Search Results Counts-->
                                <div style="margin-top: 10px;" *ngFor="let entity of entities">
                                    <div class="search-field-entity text-nowrap">{{ entity.displayName }}</div>
                                    <div *ngFor="let searchField of entity.searchFields">
                                        <a class="search-field"
                                            [ngClass]="{'search-field-selected': searchField.selected}"
                                            data-target=""
                                            *ngIf="searchField.resultsCount > 0"
                                            (click)="selectResults(entity, searchField); false">{{ searchField.displayName }}&nbsp;({{ searchField.resultsCount }})</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-9 col-lg-10" [hidden]="!showingResults">
                                <!--Search results table-->
                                <div style="margin: 10px 0;">
                                    <ag-grid-angular style="width: 100%; height: 500px;"
                                                        class="climb-grid ag-theme-alpine"
                                                        [rowHeight]="rowHeight"
                                                        [headerHeight]="rowHeight"
                                                        [gridOptions]="gridOptions"
                                                        [columnDefs]="columnDefs"
                                                        [defaultColDef]="gridOptions.defaultColDef"
                                                        [rowModelType]="'infinite'"
                                                        [pagination]="true"
                                                        [paginationPageSize]="50"
                                                        [enableCellTextSelection]="true"></ag-grid-angular>
                                </div>
                                <div *ngIf="!generatingFile">
                                    <a class="btn-sm" title="Export Results" (click)="exportResults(); false">
                                        <i class="fa fa-fw fa-download" aria-hidden="true"></i>Export Results
                                    </a>
                                </div>
                                <div *ngIf="generatingFile">
                                    <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                    Exporting file&hellip;
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
                <ng-container [ngbNavItem]="ADVANCED_TAB_ID">
                    <a ngbNavLink>{{ 'Jobs'| translate }}</a>
                    <ng-template ngbNavContent>
                        <advanced-search></advanced-search>
                    </ng-template>
                </ng-container>
            </nav>
            <div [ngbNavOutlet]="nav" class="p-3"></div>
        </div>
    </div>
</div>
