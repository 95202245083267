<climb-sidebar [opened]="isCustomizeWorkspaceActive">
    <facet-selector sidebar #sidebar (facetSelected)="addFacet($event)">
    </facet-selector>

    <div content id="scroll-parent" class="workspace-container"
         (scroll)="scrollEvent($event)"
         [ngClass]="{'workspace': !isCustomizeWorkspaceActive, 'workspace-customizing': isCustomizeWorkspaceActive}">
        <loading-overlay *ngIf="loading" [message]="'Initializing Workspace'"></loading-overlay>

        <climb-onboarding-banner *ngIf="isCustomizeWorkspaceActive && isWorkspaceEmpty()"></climb-onboarding-banner>

        <ngx-gridster #gridster
                      *ngIf="workspace"
                      [options]="gridsterOptions"
                      [draggableOptions]="{ handlerClass: 'draggable-title' }">
            <ngx-gridster-item *ngFor="let facet of workspace.WorkspaceDetail"
                            [(x)]="facet.Column"
                            [(y)]="facet.Row"
                            [(w)]="facet.SizeX"
                            [(h)]="facet.SizeY"
                            (end)="facetChanged(facet)">
                <facet-factory [facet]="facet"></facet-factory>
            </ngx-gridster-item>
        </ngx-gridster>
    </div>
</climb-sidebar>
