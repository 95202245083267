import { FacetLoadingStateService } from './../common/facet/facet-loading-state.service';
import { map } from 'rxjs/operators';
import {
    Component,
    Input,
    OnInit,
    OnChanges,
} from '@angular/core';

import { OrderService } from './order.service';
import { OrderVocabService } from './order-vocab.service';

import { PrivilegeService } from '../services/privilege.service';
import { ConfirmService } from '../common/confirm';
import { VocabularyService } from './../vocabularies/vocabulary.service';
import { DataContextService } from '../services/data-context.service';
import { ReasonForChangeService } from '../common/reason-for-change/reason-for-change.service';

@Component({
    selector: 'lots-table',
    templateUrl: './lots-table.component.html',
    styles: [`
        .lots-table {
            margin-top: 10px;
           }
    `]
})
export class LotsTableComponent implements OnInit, OnChanges {
    /* The name of the object's primary key database field */
    @Input() pkName: string;
    /* The value of the object's primary key */
    @Input() pkValue: number;
    /* The entity to assign */
    @Input() entity: any;

    // CVs
    sampleTypes: any[] = [];
    taxons: any[] = [];

    // State
    readonly COMPONENT_LOG_TAG = 'lots-table';

    readwrite: boolean;
    readonly: boolean;
    lotsShown: boolean;

    constructor(
        private privilegeService: PrivilegeService,
        private facetLoadingStateService: FacetLoadingStateService,
        private orderService: OrderService,
        private orderVocabService: OrderVocabService,
        private confirmService: ConfirmService,
        private vocabularyService: VocabularyService,
        private dataContext: DataContextService,
        private reasonForChangeService: ReasonForChangeService,
    ) { }

    // lifecycle
    ngOnInit() {

        this.initialize();
    }

    ngOnChanges(changes: any) {
        if (changes.entity && !changes.entity.firstChange) {
            this.initialize();
        }
    }

    initialize() {
        this.facetLoadingStateService.changeLoadingState(true);

        this.setPrivileges();

        return this.getCVs().then(() => {
            this.getLotDetails();
            this.facetLoadingStateService.changeLoadingState(false);
        }).catch((error) => {
            this.facetLoadingStateService.changeLoadingState(false);
            throw error;
        });
    }

    private getCVs(): Promise<any> {

        const p1 = this.orderVocabService.sampleTypes$.pipe(map((data) => {
            this.sampleTypes = data;
        })).toPromise();
        const p2 = this.orderVocabService.taxons$.pipe(map((data) => {
            this.taxons = data;
        })).toPromise();

        return Promise.all([p1, p2]);
    }

    /**
     * Sets privilege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    // Formatters for <select> input
    sampleTypeKeyFormatter = (value: any) => {
        return value.C_SampleType_key;
    }
    sampleTypeFormatter = (value: any) => {
        return value.SampleType;
    }

    taxonKeyFormatter = (value: any) => {
        return value.C_Taxon_key;
    }
    taxonFormatter = (value: any) => {
        return value.CommonName;
    }

    createLot(): Promise<any> {
        const newLot = this.orderService.createLot(this.pkName, this.pkValue);

        const p1 = this.vocabularyService.getCVDefault('cv_SampleTypes').then((value) => {
            newLot.cv_SampleType = value;
        });

        return Promise.all([p1]).then(() => {
            this.lotsShown = true;
            return newLot;
        });
    }

    private getLotDetails(): Promise<any> {
        if (this.pkName && this.pkValue > 0) {
            return this.orderService.getLots(this.pkName, this.pkValue).then(
                (items) => {
                    if (items.length > 0) {
                        this.lotsShown = true;
                    }
                });
        }
        return Promise.resolve(this.pkName);
    }

    /**
     * Delete Lot on "x" click
     */
    removeLot(lot: any) {
        if (!this.readonly) {
            return this.confirmService.confirmDelete(
                'Delete Lot',
                'Are you sure you want to delete this lot?'
            ).then(
                // confirmed
                () => {
                    this.reasonForChangeService.markModification([lot.SampleOrder.Order]);
                    this.orderService.deleteLot(lot);
                    if (this.pkValue > 0 &&
                        lot.C_Lot_key > 0
                    ) {
                        this.dataContext.save();
                    }
                },
                // cancel
                () => { /* do nothing on cancel */ }
            );
        }
    }

    /**
     * Hide/show lots table contents
     */
    toggleLotsShown() {
        this.lotsShown = !this.lotsShown;
    }
}
