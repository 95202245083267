<ng-template #observationmodal let-c="close">
    <observation-chooser [selectedObservations]="selectedObservation.ClinicalObservationDetail"
                         [selectedObservationForGLP]="selectedObservation.C_ClinicalObservation_key"
                         [selectedBodySystemForGLP]="selectedObservation.C_BodySystem_key"
                         (close)="c('Close')"
                         (onSelect)="onSelectObservations($event)">
    </observation-chooser>
</ng-template>

<ng-template #Diagnosticobservationmodal let-c="close">
    <observation-chooser [diagnosticObservation]="true"
                         [selectedObservations]="selectedObservation.ClinicalObservationDetail"
                         [selectedObservationForGLP]="selectedObservation.C_ClinicalObservation_key"
                         [selectedBodySystemForGLP]="selectedObservation.C_BodySystem_key"
                         (close)="c('Close')"
                         (onSelect)="onSelectObservations($event)">
    </observation-chooser>
</ng-template>

<div class="detail-form-wrapper">
    <div class="detail-header">
        <h3>Clinical Record</h3>

        <pager-toolbar *ngIf="!noPaginator"
                       [itemName]="'clinical'"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <div class="btn-group" role="group" aria-label="Clinical record actions" additional-groups>
                <button type="button"
                        class="btn btn-secondary dropdown-toggle"
                        title="Export to CSV or PDF"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                    <i class="fa fa-download" aria-hidden="true"></i>
                    <span class="gridster-d-sm-none gridster-d-md-inline-block">Export</span>
                    <span class="sr-only">Export</span>
                </button>
                <div class="dropdown-menu">
                    <a (click)="export(exportTypes.CSV)"
                       class="dropdown-item">
                        CSV
                    </a>
                    <a (click)="export(exportTypes.PDF)"
                       class="dropdown-item">
                        PDF
                    </a>
                </div>
            </div>
        </detail-toolbar>
    </div>

    <div *ngIf="animal && animal | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This clinical record has been deleted.
    </div>

    <form *ngIf="animal && !(animal | isDeleted) && healthRecord" name="healthRecordForm" id="healthRecordForm"
          class="detail-form" #clinicalForm="ngForm">

        <a class="{{scrollTopElementClass}}"></a>

        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">
            <div class="form-group row">
                <ng-container *ngIf="activeFields.includes('Urgent')">
                    <label class="col-md-3 col-form-label">
                        <i class="fa fa-exclamation-triangle text-danger"
                           title="Urgent"
                           *ngIf="healthRecord.IsUrgent"></i>
                        Urgent
                    </label>
                    <div class="col-md-1">
                        <input type="checkbox"
                               name="isUrgent"
                               class="mt-1"
                               [(ngModel)]="healthRecord.IsUrgent"
                               (ngModelChange)="isUrgentChanged()" />
                    </div>
                </ng-container>
                <label class="col-md-2 col-form-label">Animal ID</label>
                <div class="col-md-2">
                    <p class="form-control-plaintext">{{healthRecord.Animal.Material?.Identifier}}</p>
                </div>
                <label class="col-md-2 col-form-label">Animal Name</label>
                <div class="col-md-2">
                    <p class="form-control-plaintext">{{healthRecord.Animal.AnimalName}}</p>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-md-3 col-form-label">More</label>
                <div style="margin-top: 5px;">
                    <info-tooltip [text]="'You can save the state of this caret by locking your Workspace.'"></info-tooltip>
                    <climb-expand [(model)]="detailsExpanded"
                                  (modelChange)="onDetailsExpand()"
                                  label="Details">
                    </climb-expand>
                </div>
            </div>
            <div *ngIf="detailsExpanded">
                <div class="form-group row" *ngIf="activeFields.includes('Line')">
                    <label class="col-md-3 col-form-label">{{'Line' | translate}}</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext" *ngIf="healthRecord.Animal.Material?.Line">
                            {{healthRecord.Animal.Material?.Line.LineName}}
                        </p>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Location')">
                    <label class="col-md-3 col-form-label">Location</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext">{{healthRecord.Animal.Material?.CurrentLocationPath}}</p>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Job')">
                    <label class="col-md-3 col-form-label">{{'Job' | translate}} Name{{isGLP ? '' : 's'}}</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext">
                            <span *ngIf="healthRecord.Animal.Material?.JobMaterial | notEmpty">
                                {{healthRecord | healthRecordJobNameFormat : isGLP}}
                            </span>
                        </p>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Birth Date')">
                    <label class="col-md-3 col-form-label">Birth Date</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext">{{healthRecord.Animal.DateBorn | formatShortDate}}</p>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Sex')">
                    <label class="col-md-3 col-form-label">Sex</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext" *ngIf="healthRecord.Animal.cv_Sex">
                            {{healthRecord.Animal.cv_Sex.Sex}}
                        </p>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Species')">
                    <label class="col-md-3 col-form-label">Species</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext" *ngIf="healthRecord.Animal.Material?.cv_Taxon">
                            {{healthRecord.Animal.Material?.cv_Taxon.CommonName}}
                        </p>
                    </div>
                </div>
                <div class="form-group row" *ngIf="isGLP && activeFields.includes('Animal Classification')">
                    <label class="col-md-3 col-form-label">Animal Classification</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext" *ngIf="healthRecord.Animal.Material?.cv_Taxon">
                            {{healthRecord.Animal.cv_AnimalClassification?.AnimalClassification}}
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Animal Status</label>
                    <div class="col-md-9">
                        <div style="width: 150px;">
                            <active-vocab-select [(model)]="healthRecord.Animal.C_AnimalStatus_key"
                                                 (modelChange)="animalStatusChanged()"
                                                 [vocabChoices]="animalStatuses"
                                                 [keyFormatter]="animalStatusKeyFormatter"
                                                 [optionFormatter]="animalStatusFormatter">
                            </active-vocab-select>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Body Condition Score')">
                    <label class="col-md-3 col-form-label">Body Condition Score</label>
                    <div class="col-md-9">
                        <div style="width: 150px;">
                            <active-vocab-select [(model)]="healthRecord.Animal.C_BodyConditionScore_key"
                                                 [vocabChoices]="bodyConditionScores"
                                                 [keyFormatter]="bodyScoreKeyFormatter"
                                                 [optionFormatter]="bodyScoreFormatter"
                                                 [required]="requiredFields.includes('Animal.C_BodyConditionScore_key')"
                                                 [nullable]="!requiredFields.includes('Animal.C_BodyConditionScore_key')">
                            </active-vocab-select>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Exit Date')">
                    <label class="col-md-3 col-form-label">Exit Date</label>
                    <div class="col-md-9">
                        <div style="width: 150px;">
                            <climb-ngb-date #dateControl="ngModel"
                                            name="Exit Date"
                                            [(ngModel)]="healthRecord.Animal.DateExit"
                                            [required]="requiredFields.includes('Animal.DateExit')"></climb-ngb-date>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Death/Exit Reason')">
                    <label class="col-md-3 col-form-label">Death/&#8203;Exit Reason</label>
                    <div class="col-md-9">
                        <div style="width: 150px;">
                            <active-vocab-select [(model)]="healthRecord.Animal.C_ExitReason_key"
                                                 [vocabChoices]="exitReasons"
                                                 [keyFormatter]="exitReasonKeyFormatter"
                                                 [optionFormatter]="exitReasonFormatter"
                                                 [required]="requiredFields.includes('Animal.C_ExitReason_key')"
                                                 [nullable]="!requiredFields.includes('Animal.C_ExitReason_key')">
                            </active-vocab-select>
                        </div>
                    </div>
                </div>
                <div class="form-group row" *ngIf="activeFields.includes('Microchip ID')">
                    <label class="col-md-3 col-form-label">Microchip ID</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext">{{healthRecord.Animal.Material?.MicrochipIdentifier}}</p>
                    </div>
                </div>
                <hr />
                <div class="form-group row" *ngIf="activeFields.includes('Health Tech')">
                    <label class="col-md-3 col-form-label">Health Tech</label>
                    <div class="col-md-9">
                        <div style="width: 150px;">
                            <active-vocab-select [(model)]="healthRecord.C_Resource_key"
                                                 [vocabChoices]="resources"
                                                 [keyFormatter]="resourceKeyFormatter"
                                                 [optionFormatter]="resourceNameFormatter"
                                                 [required]="requiredFields.includes('C_Resource_key')"
                                                 [nullable]="!requiredFields.includes('C_Resource_key')">
                            </active-vocab-select>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Due Date</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext">
                            <span>{{healthRecord.DerivedDateDue | formatShortDateOrTime}}</span>
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-md-3 col-form-label">Created Date</label>
                    <div class="col-md-9">
                        <p class="form-control-plaintext">{{healthRecord.DateCreated | formatShortDate}}</p>
                    </div>
                </div>
            </div>

            <!-- Clinical Observations Table -->
            <observations-table
            id="clinical-observations-table"
            *ngIf="activeFields.includes('Clinical Observations')"
            [facet]="facet"
            [healthRecord]="healthRecord"
            [observations]="healthRecord.Animal.AnimalClinicalObservation"
            [observationType]="'clinical'">
            </observations-table>
            <br />

            <!-- Diagnostic Observations Table -->
            <observations-table
            id="diagnostic-observations-table"
            *ngIf="largeAnimalEnabled && activeFields.includes('Diagnostic Observations')"
            [facet]="facet"
            [healthRecord]="healthRecord"
            [observations]="healthRecord.Animal.AnimalDiagnosticObservation"
            [observationType]="'diagnostic'">
            </observations-table>
            <br /> 

            <!-- Treatment Plans table -->
            <div class="form-group row" *ngIf="activeFields.includes('Treatment Plan')">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr class="detail-grid-header">
                                <th style="width:35px;">
                                    <a class="add-item-link detail-grid-button-label"
                                       *ngIf="facet.Privilege !== 'ReadOnly'" (click)="addTask()">
                                        <i data-automation-id="add-new-treatment-plan-button" class="fa fa-plus-circle" title="Add new treatment plan"></i>
                                    </a>
                                </th>
                                <th colspan="9">
                                    <div class="detail-grid-button-label">Treatment Plan</div>
                                </th>
                            </tr>
                            <tr *ngIf="healthRecord.TaskAnimalHealthRecord | notEmpty" class="detail-grid-header">
                                <th>&nbsp;</th>
                                <th>{{'Job' | translate}} Name{{isGLP ? '' : 's'}} </th>
                                <th>Plan</th>
                                <th>Due Date</th>
                                <th>Assigned To</th>
                                <th>Comments</th>
                                <th>&nbsp;</th>
                                <th>Completed Date</th>
                                <th>Completed By</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let task of healthRecord.TaskAnimalHealthRecord | map:'TaskInstance' | orderBy:'DateDue':true;let i = index">
                                <td class="icon-cell">
                                    <div class="form-control-plaintext">
                                        <a *ngIf="facet.Privilege !== 'ReadOnly'"
                                           (click)="removeTask(task)">
                                            <i class="fa fa-remove remove-item" title="Delete this task"></i>
                                        </a>
                                    </div>
                                </td>
                                <td style="width: 140px">{{healthRecord | clinicalJobNameFormat : 'treatment' : '' : i}}</td>
                                <td>
                                    <textarea name="inputValue-{{task.C_TaskInstance_key}}"
                                              class="form-control input-large"
                                              *ngIf="task.TaskInput | notEmpty"
                                              [(ngModel)]="task.TaskInput[0].InputValue">
                                    </textarea>
                                </td>
                                <td style="width:130px;">
                                    <climb-ngb-date #dateControl="ngModel"
                                                    [(ngModel)]="task.DateDue"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    [allowTime]="true"
                                                    [disabled]="facet.Privilege === 'ReadOnly'">
                                    </climb-ngb-date>
                                </td>
                                <td style="min-width: 85px;">
                                    <climb-assigned-to-select [(model)]="task.C_AssignedTo_key"></climb-assigned-to-select>
                                </td>
                                <td>
                                    <textarea name="taskComments-{{task.C_TaskInstance_key}}"
                                              class="form-control input-large"
                                              [(ngModel)]="task.Comments">
                                    </textarea>
                                </td>
                                <td class="text-center" style="vertical-align:top;">
                                    <task-complete-button [task]="task"></task-complete-button>
                                </td>
                                <td style="width:100px;">
                                    <climb-ngb-date #dateControl="ngModel"
                                                    [(ngModel)]="task.DateComplete"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    [allowTime]="true"
                                                    [disabled]="facet.Privilege === 'ReadOnly'"
                                                    [required]="false">
                                    </climb-ngb-date>
                                </td>
                                <td>
                                    <active-vocab-select [(model)]="task.C_CompletedBy_key"
                                                         [vocabChoices]="resources"
                                                         [keyFormatter]="resourceKeyFormatter"
                                                         [optionFormatter]="resourceNameFormatter">
                                    </active-vocab-select>
                                </td>
                                <td>
                                    <active-vocab-select [(model)]="task.C_TaskStatus_key"
                                                         [vocabChoices]="taskStatuses"
                                                         [keyFormatter]="taskStatusKeyFormatter"
                                                         [optionFormatter]="taskStatusFormatter">
                                    </active-vocab-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />

            <div class="form-group row">
                <div class="col-md-12">
                    <h3>Files</h3>
                    <facet-detail-file-upload [pkName]="'C_AnimalHealthRecord_key'"
                                              [pkValue]="healthRecord.C_Material_key"
                                              [facetPrivilege]="facet.Privilege">
                    </facet-detail-file-upload>
                </div>
            </div>

        </fieldset>
    </form>
</div>
<loading-overlay *ngIf="loading"></loading-overlay>
