import { Injectable } from '@angular/core';
import {
    EntityQuery,
    FilterQueryOp,
    Predicate,
    QueryResult
} from 'breeze-client';

import {
    notEmpty,
    softCompare
} from '../common/util';
import { getDateRangePredicates } from './queries';

import { DataManagerService } from './data-manager.service';
import { QueryDef } from './query-def';
import { BaseEntityService } from './base-entity.service';

import { AnimalService } from '../animals/services/animal.service';
import { VocabularyService } from "../vocabularies/vocabulary.service";
import { ConfirmService } from '../common/confirm';
import { Animal } from '@common/types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmHousingModalComponent, HousingOption } from '../animals/bulkedit/confirm-housing-modal.component';

@Injectable()
export class MaterialPoolService extends BaseEntityService {

    readonly ENTITY_TYPE = 'MaterialPools';
    readonly ENTITY_NAME = 'MaterialPool';

    readonly WORKSPACE_ANIMAL_FILTER_KEY = 'animal-filter';
    readonly WORKSPACE_JOB_FILTER_KEY = 'job-filter';

    // state variables
    draggedPools: any[];

    constructor(
        private animalService: AnimalService,
        private dataManager: DataManagerService,
        private vocabularyService: VocabularyService,
        private confirmService: ConfirmService,
        private modalService: NgbModal
    ) {
        super();
        this.draggedPools = [];
    }

    getMaterialPool(materialPoolKey: number, expands?: string[]): Promise<any> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .where('C_MaterialPool_key', '==', materialPoolKey);

        if (notEmpty(expands)) {
            query.expand(expands.join(','));
        }

        return this.dataManager.returnSingleQueryResult(query);
    }

    getMaterialPools(queryDef: QueryDef): Promise<QueryResult> {
        let query = this.buildDefaultQuery(this.ENTITY_TYPE, queryDef);

        const filter: any = queryDef.filter;
        if (filter && (filter.JobID || (this.WORKSPACE_JOB_FILTER_KEY in filter))) {
            this.ensureDefExpanded(queryDef, 'MaterialPoolMaterial.Material.JobMaterial');
        }
        this.ensureDefExpanded(queryDef, 'MaterialLocation.LocationPosition');
        this.ensureDefExpanded(queryDef, 'Note');
        query = query.expand(queryDef.expands.join(','));

        let predicates: Predicate[] = [];
        if (queryDef.filter) {
            predicates = predicates.concat(this.buildPredicates(queryDef.filter));
        }

        if (notEmpty(predicates)) {
            query = query.where(Predicate.and(predicates));
        }

        return this.dataManager.executeQuery(query)
            .catch(this.dataManager.queryFailed) as Promise<QueryResult>;
    }

    getDevices(materialPool: any): Promise<void> {
        if (!materialPool) {
            return Promise.resolve();
        }
        return this.dataManager.ensureRelationships([materialPool], ['Device']);
    }

    buildPredicates(filter: any): Predicate[] {
        let predicates: Predicate[] = [];
        if (!filter) {
            return predicates;
        }
        if (filter.MaterialPoolID) {
            predicates.push(
                this.createLiteralPredicate(
                    'MaterialPoolID',
                    FilterQueryOp.Contains,
                    filter.MaterialPoolID
                ));
        }
        if (notEmpty(filter.MaterialPoolIDs)) {
            const materialPoolKeys = filter.MaterialPoolIDs.map((item: any) => {
                return item.C_MaterialPool_key;
            });

            predicates.push(
                Predicate.create('C_MaterialPool_key', 'in', materialPoolKeys)
            );
        }
        if (filter.C_MaterialPool_key) {
            predicates.push(Predicate.create(
                'C_MaterialPool_key', 'eq', filter.C_MaterialPool_key
            ));
        }
        if (filter.C_MaterialPoolType_key) {
            predicates.push(Predicate.create(
                'C_MaterialPoolType_key', 'eq', filter.C_MaterialPoolType_key
            ));
        }
        if (filter.MaterialPoolType) {
            predicates.push(Predicate.create(
                'cv_MaterialPoolType.MaterialPoolType', 'eq',
                "'" + filter.MaterialPoolType + "'"
            ));
        }

        if (notEmpty(filter.C_MaterialPoolStatus_keys)) {
            predicates.push(Predicate.create(
                'C_MaterialPoolStatus_key', 'in', filter.C_MaterialPoolStatus_keys
            ));
        }

        if (filter.DatePooledStart || filter.DatePooledEnd) {
            const datePredicates: Predicate[] = getDateRangePredicates(
                'DatePooled',
                filter.DatePooledStart,
                filter.DatePooledEnd
            );
            if (notEmpty(datePredicates)) {
                predicates = predicates.concat(datePredicates);
            }
        }

        if (filter.Owner) {
            predicates.push(Predicate.create('Owner', 'eq', filter.Owner));
        }
        if (filter.Location) {
            predicates.push(Predicate.create(
                'CurrentLocationPath', FilterQueryOp.Contains, { value: filter.Location },
            ));
        }
        if (filter.JobID) {
            const jobPredicate: Predicate[] = [];

            jobPredicate.push(Predicate.create(
                'Material.JobMaterial', FilterQueryOp.Any, 'Job.JobID', FilterQueryOp.Contains, { value: filter.JobID },
            ));

            predicates.push(Predicate.create(
                'MaterialPoolMaterial', 'any',
                jobPredicate
            ));
        }

        if (filter.AnimalKeys && filter.AnimalKeys.length > 0) {
            predicates.push(Predicate.create(
                'MaterialPoolMaterial', 'any',
                'Material.C_Material_key', 'in', filter.AnimalKeys
            ));
        }

        if (filter.ExcludeMatingPools) {
            // MaterialPools that do NOT have a Mating record
            predicates.push(Predicate.create(
                'Mating.C_MaterialPool_key', '!=', null
            ).not());
        }

        if (filter.IncludeOnlyMatingPools) {
            // ONLY MaterialPools that have a Mating record
            predicates.push(Predicate.create(
                'Mating.C_MaterialPool_key', '!=', null
            ));
        }

        if (filter.IncludeOnlyEmptyPools) {
            // ONLY MaterialPools that are empty
            predicates.push(Predicate.create(
                'MaterialPoolMaterial', 'all', 'DateOut', '!=', null
            ));
        }

        if (filter.CountPooledStart || filter.CountPooledEnd) {
            if (filter.CountPooledStart) {
                predicates.push(Predicate.create(
                    'Count', '>=', filter.CountPooledStart
                ));
            }
            if (filter.CountPooledEnd) {
                predicates.push(Predicate.create(
                    'Count', '<=', filter.CountPooledEnd
                ));
            }
        }

        // handle workspace filters
        if (this.WORKSPACE_JOB_FILTER_KEY in filter) {
            const jobPredicate: Predicate[] = [];

            jobPredicate.push(Predicate.create(
                'Material.JobMaterial', 'any', 'Job.C_Job_key', 'in',
                filter[this.WORKSPACE_JOB_FILTER_KEY]
            ));

            predicates.push(Predicate.create(
                'MaterialPoolMaterial', 'any',
                jobPredicate
            ));
        }

        if (filter.C_Taxon_key) {
            predicates.push(Predicate.create(
                'MaterialPoolMaterial', 'any',
                'Material.C_Taxon_key', 'eq', filter.C_Taxon_key
            ));
        }

        if (this.WORKSPACE_ANIMAL_FILTER_KEY in filter) {
            const subPredicate = Predicate.and([
                // Only *active* MaterialPoolMaterials
                Predicate.create('DateOut', '==', null),
                Predicate.create(
                    'C_Material_key', 'in',
                    filter[this.WORKSPACE_ANIMAL_FILTER_KEY]
                )
            ]);

            predicates.push(Predicate.create(
                'MaterialPoolMaterial', 'any',
                subPredicate
            ));
        }

        return predicates;
    }

    getMaterialPoolMaterials(materialPoolKey: number): Promise<any[]> {
        const expands = [
            'Material.Animal.Birth.Mating.MaterialPool',
            'Material.Animal.cv_AnimalStatus',
            'Material.Animal.cv_Sex',
            'Material.Animal.Genotype',
            'Material.Line',
            'Material.Sample'
        ];
        const query = EntityQuery.from("MaterialPoolMaterials")
            .expand(expands.join(','))
            .where('C_MaterialPool_key', '==', materialPoolKey)
            .orderBy('DateIn');

        return this.dataManager.returnQueryResults(query);
    }

    getSocialGroupMaterials(materialPoolKey: number): Promise<any[]> {
        const expands = [
            'Material.Animal.cv_Sex',
        ];
        const query = EntityQuery.from("SocialGroupMaterials")
            .expand(expands.join(','))
            .where('C_MaterialPool_key', '==', materialPoolKey)
            .orderBy('DateIn');

        return this.dataManager.returnQueryResults(query);
    }

    getCompatibilityMaterials(materialPoolKey: number): Promise<any[]> {
        const expands = [
            'Material.Animal.cv_Sex',
        ];
        const query = EntityQuery.from("CompatibilityMaterials")
            .expand(expands.join(','))
            .where('C_MaterialPool_key', '==', materialPoolKey)
            .orderBy('DateDocumented');

        return this.dataManager.returnQueryResults(query);
    }

    getHousingTasks(materialPoolKey: number, extraExpands?: string[]): Promise<any[]> {
        const predicates = [
            new Predicate('TaskMaterialPool', 'any', 'C_MaterialPool_key', '==', materialPoolKey),
            new Predicate('WorkflowTask.cv_TaskType.TaskType', 'eq', '"Housing"')
        ];

        const query = EntityQuery.from('TaskInstances')
            .where(Predicate.and(predicates))
            .orderBy('ProtocolTask.SortOrder');

        let expandClauses = [
            'WorkflowTask',
            'ProtocolTask.Protocol',
            'ProtocolInstance.Protocol',
            'TaskInput.Input',
            'TaskMaterialPool.MaterialPool'
        ];

        if (notEmpty(extraExpands)) {
            expandClauses = expandClauses.concat(extraExpands);
        }

        let tasks: any[] = [];
        return this.dataManager.returnQueryResults(query).then((results) => {
            tasks = results;
            const promises: Promise<any>[] = [
                this.vocabularyService.ensureCVLoaded('cv_TimeUnits'),
                this.vocabularyService.ensureCVLoaded('cv_TimeRelations'),
                this.vocabularyService.ensureCVLoaded('cv_DataTypes'),
                this.vocabularyService.ensureCVLoaded('cv_TaskTypes')
            ];

            return Promise.all(promises);
        }).then(() => {
            return this.dataManager.ensureRelationships(tasks, expandClauses);
        }).then(() => {
            return tasks;
        });
    }

    /**
     * Returns Taxon key for a MaterialPool
     * @param materialPoolKey
     */
    getMaterialPoolTaxon(materialPoolKey: number): Promise<number> {
        const query = EntityQuery.from(this.ENTITY_TYPE)
            .expand('MaterialPoolMaterial.Material')
            .where('C_MaterialPool_key', '==', materialPoolKey);

        return this.dataManager.returnQueryResults(query).then((results) => {
            let taxonKey = 0;
            if (notEmpty(results) &&
                notEmpty(results[0].MaterialPoolMaterial)
            ) {
                const firstMaterialAssoc = results[0].MaterialPoolMaterial[0];
                taxonKey = firstMaterialAssoc.Material.C_Taxon_key;
            }
            return taxonKey;
        });
    }

    createMaterialPool(initialValues: any) {
        return this.dataManager.createEntity('MaterialPool', initialValues);
    }

    /**
     * Looks up MaterialPoolType key from string
     * to set initial value for new MaterialPool
     * @param materialPoolType
     */
    createMaterialPoolAsType(materialPoolType: string, initialValues?: any): Promise<any> {
        if (!initialValues) {
            initialValues = {};
        }

        const query = EntityQuery.from('cv_MaterialPoolTypes')
            .select('C_MaterialPoolType_key')
            .where('MaterialPoolType', '==', materialPoolType);
        const preferLocal = true;
        return this.dataManager.returnSingleQueryResult(query, preferLocal)
            .then((typeEntity) => {
                initialValues.C_MaterialPoolType_key = typeEntity.C_MaterialPoolType_key;
                return this.createMaterialPool(initialValues);
            });
    }

    createMaterialPoolMaterial(initialValues: any): any {
        // Removes this material from any existing material pools (default behavior)
        const retainExistingPools = false;
        return this._createMaterialPoolMaterial(initialValues, retainExistingPools);
    }

    createTaskMaterialPool(
        materialPoolKey: number,
        taskInstanceKey: number,
        sequence: number
    ): Promise<any> {
        const initialValues = {
            C_MaterialPool_key: materialPoolKey,
            C_TaskInstance_key: taskInstanceKey,
            Sequence: sequence
        };

        return this.dataManager.createEntity('TaskMaterialPool', initialValues);
    }

    createMaterialPoolMaterialRetainExistingPools(initialValues: any): any {
        // Leaves this material in any existing material pools
        const retainExistingPools = true;
        return this._createMaterialPoolMaterial(initialValues, retainExistingPools);
    }

    private _createMaterialPoolMaterial(initialValues: any, retainExistingPools: boolean): any {
        const manager = this.dataManager.getManager();

        // Filter duplicate MaterialPoolMaterials
        const initialMaterialPoolKey = initialValues.C_MaterialPool_key;
        const initialMaterialKey = initialValues.C_Material_key;

        const materialPoolMaterials: any[] = manager.getEntities('MaterialPoolMaterial');
        const duplicates = materialPoolMaterials.filter((materialPoolMaterial) => {
            return softCompare(materialPoolMaterial.C_MaterialPool_key, initialMaterialPoolKey) &&
                softCompare(materialPoolMaterial.C_Material_key, initialMaterialKey) &&
                !materialPoolMaterial.DateOut;
        });

        // Not a duplicate
        if (duplicates.length === 0) {

            if (!retainExistingPools) {
                // Clear any MaterialPools for this Material
                this.clearExistingMaterialPools(initialValues.C_Material_key);
            }

            // Create new MaterialPool for this Material
            initialValues.DateIn = new Date();
            return this.dataManager.createEntity('MaterialPoolMaterial', initialValues);
        }
        return null;
    }

    clearExistingMaterialPools(materialKey: number): void {
        const p1 = Predicate.create('C_Material_key', '==', materialKey);
        const p2 = Predicate.create('DateOut', '==', null);
        const predicates: Predicate = Predicate.and([p1, p2]);

        const query = EntityQuery.from('MaterialPoolMaterials')
            .where(predicates);

        // Set DateOut on any existing pools
        this.dataManager.returnQueryResults(query).then((existingPools: any[]) => {
            const now: Date = new Date();

            for (const materialPoolMaterial of existingPools) {
                materialPoolMaterial.DateOut = now;
            }
        });
    }

    materialHasExistingMaterialPools(materialKey: number): Promise<boolean> {
        const p1 = Predicate.create('C_Material_key', '==', materialKey);
        const p2 = Predicate.create('DateOut', '==', null);
        const predicates: Predicate = Predicate.and([p1, p2]);

        const query = EntityQuery.from('MaterialPoolMaterials')
            .where(predicates)
            .noTracking(true)
            .take(0)
            .inlineCount(true);

        return this.dataManager.returnQueryCount(query).then((count: number) => {
            return count !== 0;
        });
    }

    createSocialGroupMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();

        // Filter duplicate materials
        const initialMaterialPoolKey = initialValues.C_MaterialPool_key;
        const initialMaterialKey = initialValues.C_Material_key;

        const socialGroupMaterials: any[] = manager.getEntities('SocialGroupMaterial');
        const duplicates = socialGroupMaterials.filter((socialGroupMaterial) => {
            return softCompare(socialGroupMaterial.C_MaterialPool_key, initialMaterialPoolKey) &&
                softCompare(socialGroupMaterial.C_Material_key, initialMaterialKey) &&
                !socialGroupMaterial.DateOut;
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity('SocialGroupMaterial', initialValues);
        }
        return null;
    }

    createCompatibilityMaterial(initialValues: any): any {
        const manager = this.dataManager.getManager();

        // Filter duplicate materials
        const initialMaterialPoolKey = initialValues.C_MaterialPool_key;
        const initialMaterialKey = initialValues.C_Material_key;

        const socialGroupMaterials: any[] = manager.getEntities('CompatibilityMaterial');
        const duplicates = socialGroupMaterials.filter((socialGroupMaterial) => {
            return softCompare(socialGroupMaterial.C_MaterialPool_key, initialMaterialPoolKey) &&
                softCompare(socialGroupMaterial.C_Material_key, initialMaterialKey);
        });

        // Not a duplicate
        if (duplicates.length === 0) {
            return this.dataManager.createEntity('CompatibilityMaterial', initialValues);
        }
        return null;
    }

    deleteMaterialPool(materialPool: any) {
        while (materialPool.MaterialPoolMaterial.length > 0) {
            this.dataManager.deleteEntity(materialPool.MaterialPoolMaterial[0]);
        }
        while (materialPool.MaterialLocation.length > 0) {
            this.dataManager.deleteEntity(materialPool.MaterialLocation[0]);
        }

        if (materialPool.Mating) {
            while (materialPool.Mating.length > 0) {
                this.dataManager.deleteEntity(materialPool.Mating[0]);
            }
        }
        this.dataManager.deleteEntity(materialPool);
    }

    deleteMaterialPoolMaterial(materialPoolMaterial: any) {
        this.dataManager.deleteEntity(materialPoolMaterial);
    }

    deleteMaterialPoolLocation(materialPoolLocation: any) {
        this.dataManager.deleteEntity(materialPoolLocation);
    }

    deleteSocialGroupMaterial(socialGroupMaterial: any) {
        this.dataManager.deleteEntity(socialGroupMaterial);
    }

    deleteCompatibilityMaterial(compatibilityMaterial: any) {
        this.dataManager.deleteEntity(compatibilityMaterial);
    }

    cancelMaterialPool(materialPool: any) {
        if (!materialPool) {
            return;
        }

        if (materialPool.C_MaterialPool_key > 0) {
            this._cancelMaterialPoolEdits(materialPool);
        } else {
            this._cancelNewMaterialPool(materialPool);
        }
    }

    private _cancelNewMaterialPool(materialPool: any) {
        try {
            this._cancelMaterialPoolEdits(materialPool);
        } catch (error) {
            console.error('Error cancelling new material pool: ' + error);
        }
    }

    private _cancelMaterialPoolEdits(materialPool: any) {

        // reject changes to animals in this pool
        if (materialPool.MaterialPoolMaterial) {
            for (const materialPoolMaterial of materialPool.MaterialPoolMaterial) {

                const material = materialPoolMaterial.Material;
                if (material && material.Animal) {
                    const animal = material.Animal;
                    this.animalService.cancelAnimal(animal);
                }

            }
        }
        const taskMaterialPools = this.dataManager.rejectChangesToEntityByFilter(
            'TaskMaterialPool', (item: any) => {
                return item.C_MaterialPool_key === materialPool.C_MaterialPool_key;
            }
        );
        for (const taskMaterialPool of taskMaterialPools) {
            const taskInstances = this.dataManager.rejectChangesToEntityByFilter(
                'TaskInstance', (item: any) => {
                    return item.C_TaskInstance_key === taskMaterialPool.C_TaskInstance_key;
                }
            );
            for (const taskInstance of taskInstances) {
                this.dataManager.rejectChangesToEntityByFilter(
                    'TaskInput', (item: any) => {
                        return item.C_TaskInstance_key === taskInstance.C_TaskInstance_key;
                    }
                );

            }
        }
        this.dataManager.rejectChangesToEntityByFilter(
            'Device', (item: any) => {
                // there is no way to tell which Devices may have been removed
                // It is fairly safe to just reject any changes to Device objects
                return true;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialLocation', (item: any) => {
                return item.C_MaterialPool_key === materialPool.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'MaterialPoolMaterial', (item: any) => {
                return item.C_MaterialPool_key === materialPool.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'SocialGroupMaterial', (item: any) => {
                return item.C_MaterialPool_key === materialPool.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectChangesToEntityByFilter(
            'CompatibilityMaterial', (item: any) => {
                return item.C_MaterialPool_key === materialPool.C_MaterialPool_key;
            }
        );

        this.dataManager.rejectEntityAndRelatedPropertyChanges(materialPool);
    }

    ensureTasksLoaded(materialPools: any[]): Promise<any[]> {
        const expands = [
            'TaskMaterialPool.TaskInstance.ProtocolInstance',
            'TaskMaterialPool.TaskInstance.ProtocolTask',
        ];
        return this.dataManager.ensureRelationships(materialPools, expands);
    }

    async ensureVisibleColumnsDataLoaded(materialPools: any[], visibleColumns: string[]): Promise<void> {
        const expands = this.generateExpandsFromVisibleColumns(materialPools[0], visibleColumns);
        return this.dataManager.ensureRelationships(materialPools, expands);
    }

    findAnimalsWithHousing(animals: Animal[]) {
        const animalsWithHousing = [];
        for (const animal of animals) {
            const hasHouse = animal.Material.MaterialPoolMaterial.some(mpm => !mpm.DateOut);
            if (hasHouse) {
                animalsWithHousing.push(animal);
            }
        }
        return animalsWithHousing;
    }

    openConfirmHousingModal(animals: Animal[]): Promise<HousingOption> {
        const ref = this.modalService.open(ConfirmHousingModalComponent);
        const component = ref.componentInstance as ConfirmHousingModalComponent;
        component.housedAnimals = animals;
        return ref.result;
    }
}
