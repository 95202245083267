<!-- <ng-template #filterModal let-c="close">
    <characteristics-filter [filter]="filter"
                            [isCRO]="isCRO"
                            [typeName]="currentTypeName"
                            (close)="c('Close')"
                            (onFilter)="runFilterModel($event)"></characteristics-filter>
</ng-template> -->
<ng-template ngbModalContainer></ng-template>

<div class="facet facet-characteristics">
    <facet-header
        caption="Characteristics"
        [facet]="facet">
        <button *ngIf="!isEditing"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Characteristics">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="!isEditing"
                climbFacetAction
                (click)="refresh()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <loading-overlay *ngIf="loading"></loading-overlay>

        <!-- Grids (in tabs) -->
        <nav ngbNav
             class="nav-tabs"
             (navChange)="onNavChange($event)"
             [hidden]="isEditing"
             #gridTabs="ngbNav">

            <ng-container [ngbNavItem]="'animals-tab-' + domIdAddition"
                          *ngIf="!isEditing">
                <a ngbNavLink>Animals</a>
                <ng-template ngbNavContent>
                    <characteristic-type-list [typeName]="CharacteristicTypeNameEnum.Taxon"
                                              categoryName="Species"
                                              [characteristics]="characteristics.taxon"
                                              [allowEdit]="allowEdit"
                                              (editBegin)="editBegin(CharacteristicTypeNameEnum.Taxon, $event)"
                                              [totalCount]="totalTaxonCount"
                                              (pageChange)="onPageChange($event, CharacteristicTypeNameEnum.Taxon)"
                                              (rowChanged)="onRowChanged($event, CharacteristicTypeNameEnum.Taxon)"
                                              [pageIndex]="currentPageIndex"
                                              [pageSize]="pageSize"></characteristic-type-list>
                </ng-template>
            </ng-container>

            <ng-container [ngbNavItem]="'samples-tab-' + domIdAddition"
                          *ngIf="!isEditing">
                <a ngbNavLink>Samples</a>
                <ng-template ngbNavContent>
                    <characteristic-type-list [typeName]="CharacteristicTypeNameEnum.Sample"
                                              categoryName="Sample"
                                              [characteristics]="characteristics.sample"
                                              [allowEdit]="allowEdit"
                                              (editBegin)="editBegin(CharacteristicTypeNameEnum.Sample, $event)"
                                              [totalCount]="totalSampleCount"
                                              (pageChange)="onPageChange($event, CharacteristicTypeNameEnum.Sample)"
                                              (rowChanged)="onRowChanged($event, CharacteristicTypeNameEnum.Sample)"
                                              [pageIndex]="currentPageIndex"
                                              [pageSize]="pageSize"></characteristic-type-list>
                </ng-template>
            </ng-container>

            <ng-container [ngbNavItem]="'jobs-tab-' + domIdAddition"
                          *ngIf="!isEditing">
                <a ngbNavLink>{{ 'Jobs' | translate }}</a>
                <ng-template ngbNavContent>
                    <characteristic-type-list *ngIf="!isCRO"
                                              [typeName]="CharacteristicTypeNameEnum.Job"
                                              categoryName="{{('Job' | translate) + ' Type'}}"
                                              [characteristics]="characteristics.job"
                                              [allowEdit]="allowEdit"
                                              [jobCharacteristicLink]="'type'"
                                              (editBegin)="editBegin(CharacteristicTypeNameEnum.Job, $event)"
                                              [totalCount]="totalJobCount"
                                              (pageChange)="onPageChange($event, CharacteristicTypeNameEnum.Job)"
                                              (rowChanged)="onRowChanged($event, CharacteristicTypeNameEnum.Job)"
                                              [pageIndex]="currentPageIndex"
                                              [pageSize]="pageSize"></characteristic-type-list>
                    <characteristic-type-list *ngIf="isCRO"
                                              [typeName]="CharacteristicTypeNameEnum.Job"
                                              categoryName="{{('Job' | translate) + ' Subtype'}}"
                                              [characteristics]="characteristics.job"
                                              [jobCharacteristicLink]="'subtype'"
                                              [allowEdit]="allowEdit"
                                              (editBegin)="editBegin(CharacteristicTypeNameEnum.Job, $event)"
                                              [totalCount]="totalJobCount"
                                              (pageChange)="onPageChange($event, CharacteristicTypeNameEnum.Job)"
                                              (rowChanged)="onRowChanged($event, CharacteristicTypeNameEnum.Job)"
                                              [pageIndex]="currentPageIndex"
                                              [pageSize]="pageSize"></characteristic-type-list>
                </ng-template>
            </ng-container>

            <ng-container [ngbNavItem]="'studies-tab-' + domIdAddition"
                          *ngIf="!isEditing">
                <a ngbNavLink>{{ 'Studies' | translate }}</a>
                <ng-template ngbNavContent>
                    <characteristic-type-list [typeName]="CharacteristicTypeNameEnum.Study"
                                              categoryPath="StudyCharacteristicStudy"
                                              [characteristics]="characteristics.study"
                                              [allowEdit]="allowEdit"
                                              (editBegin)="editBegin(CharacteristicTypeNameEnum.Study, $event)"
                                              [totalCount]="totalStudyCount"
                                              (pageChange)="onPageChange($event, CharacteristicTypeNameEnum.Study)"
                                              (rowChanged)="onRowChanged($event, CharacteristicTypeNameEnum.Study)"
                                              [pageIndex]="currentPageIndex"
                                              [pageSize]="pageSize"></characteristic-type-list>
                </ng-template>
            </ng-container>

        </nav>
        <div *ngIf="!isEditing" [ngbNavOutlet]="gridTabs" class="mt-2 mb-n3"></div>


        <!-- Edit/Details -->
        <ng-container *ngIf="isEditing"
                      [ngSwitch]="editTypeName">
            <div>
                <taxon-characteristic-edit *ngSwitchCase="CharacteristicTypeNameEnum.Taxon"
                                            displayName="Animal"
                                            displayTypeName="Species"
                                            [facet]="facet"
                                            [facetView]="facetView"
                                            [facetPrivilege]="facet.Privilege"
                                            [taxonCharacteristic]="currentCharacteristic.characteristic"
                                            (editEnd)="editEnd($event)">
                </taxon-characteristic-edit>

                <sample-characteristic-edit *ngSwitchCase="CharacteristicTypeNameEnum.Sample"
                                            displayName="Sample"
                                            [facet]="facet"
                                            [facetView]="facetView"
                                            [facetPrivilege]="facet.Privilege"
                                            [sampleCharacteristic]="currentCharacteristic.characteristic"
                                            (editEnd)="editEnd($event)"></sample-characteristic-edit>

                <job-characteristic-edit *ngSwitchCase="CharacteristicTypeNameEnum.Job"
                                         displayName="Job"
                                         [facet]="facet"
                                         [facetView]="facetView"
                                         [facetPrivilege]="facet.Privilege"
                                         [jobCharacteristic]="currentCharacteristic.characteristic"
                                         (editEnd)="editEnd($event)">
                </job-characteristic-edit>

                <study-characteristic-edit *ngSwitchCase="CharacteristicTypeNameEnum.Study"
                                           displayName="Study"
                                           [facet]="facet"
                                           [facetView]="facetView"
                                           [facetPrivilege]="facet.Privilege"
                                           [studyCharacteristic]="currentCharacteristic.characteristic"
                                           (editEnd)="editEnd($event)"></study-characteristic-edit>

                <p class="text-danger" *ngSwitchDefault>
                    Error - Invalid characteristic type: {{editTypeName}}
                </p>
            </div>
        </ng-container>
    </div>
</div>
