<div class="facet">
    <facet-header
        caption="Plates"
        [facet]="facet">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Plates">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>

        <button data-automation-id="copy-animals-button" 
                *ngIf="facetView === DETAIL_VIEW && facet.Privilege === 'ReadWrite'"
                climbFacetAction
                (click)="copyPlate()"
                title="Copy Animals">
            <svg [climbIcon]="icons.squareOnSquare"></svg>
        </button>

        <button data-automation-id="paste-animals-button"
                *ngIf="facetView === DETAIL_VIEW && facet.Privilege === 'ReadWrite'"
                climbFacetAction
                (click)="pasteAnimals()"
                title="Paste Animals">
            <svg [climbIcon]="icons.squareOnSquareWithArrow"></svg>
        </button>
    </facet-header>

    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="doBulkDelete()"
                            [appInsightsClick]="'click-bulk-delete-' + componentName">
                        Delete
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [options]="plateTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table #dataTable
                              [dataService]="dataService"
                              [options]="plateTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <plate-detail *ngIf="facetView === DETAIL_VIEW"
                        #plateDetail
                        [facet]="facet"
                        [plate]="itemToEdit"
                        [pageState]="detailPaginator.pageState"
                        (exit)="exitDetail()"
                        (previous)="detailPreviousClicked()"
                        (next)="detailNextClicked()"></plate-detail>
    </div>
</div>
