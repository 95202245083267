import {
    Component,
    Input
} from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

/*
* Provides custom configured charts in Climb
*/
@Component({
    selector: 'charts-facet',
    templateUrl: './charts-facet.component.html',
    styleUrls: ['./charts-facet.component.scss'],
})
export class ChartsFacetComponent {
    @Input() facet: any;

    chartKind = 'kaplan-meier';

    onNavChange(event: NgbNavChangeEvent): void {
        this.chartKind = event.activeId;
    }

}
