<div class="bulk-edit-wrapper">
    <div class="bulk-add-toolbar-wrap">
        <h3>Create Housing</h3>
        <div class="detail-toolbar" aria-label="Details toolbar">

            <ng-container>
                        <span *ngIf="bulkHousing.add && (bulkHousing.unhoused > 0)"
                              class="text-warning"
                              title="There are unhoused animals.">
                            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                        </span>
                <button data-automation-id="save-button"
                        type="button"
                        class="btn btn-fw"
                        [ngClass]="hasChanges ? 'btn-warning' : 'btn-secondary'"
                        [disabled]="saveChangesService.saving || bulkHousing.units.length === 0 || housingAnimals"
                        (click)="saveClicked()"
                        *ngIf="canWrite">
                            <span *ngIf="!saveChangesService.saving || !housingAnimals">
                                <i data-automation-id="save-item"
                                   class="fa fa-check"
                                   aria-hidden="true"
                                   title="Save">
                                </i>
                                Save
                            </span>
                    <span *ngIf="saveChangesService.saving || housingAnimals">
                                <i class="fa fa-spinner fa-spin" title="Saving&hellip;"></i>
                            </span>
                </button>
            </ng-container>

            <button data-automation-id="list-view-button"
                    type="button"
                    class="btn btn-secondary"
                    [disabled]="saveChangesService.saving"
                    (click)="exitClicked()">
                <i class="fa fa-table" aria-hidden="true" title="List View"></i>
                List <span class="gridster-d-sm-none gridster-d-md-inline-block">View</span>
                <span class="sr-only">List View</span>
            </button>

        </div>
    </div>

    <div class="bulk-edit-grid">
        <div>
            <div class="bulk-add-section-wrap">
                <h3 *ngIf="animals.length > 1">{{ animals.length }} Animals Selected</h3>
                <h3 *ngIf="animals.length === 1">{{ animals.length }} Animal Selected</h3>
                <ng-container extra-after>
                    <div class="housing">
                        <div class="form-group row">
                            <div class="col-3 pr-0 text-right">
                                <input type="number"
                                       class="housing-size"
                                       name="housing_size"
                                       step="1"
                                       min="1"
                                       [(ngModel)]="bulkHousing.size"
                                       (ngModelChange)="housingSizeChanged()" />
                            </div>
                            <div class="col-9 align-self-center">
                                Animals per unit
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-3 pr-0 text-right">{{bulkHousing.unhoused}}</div>
                            <div class="col-9">
                                Unhoused Animals
                                <a *ngIf="bulkHousing.unhoused"
                                   class="add-item-link"
                                   (click)="housingAddUnit()">
                                    <i class="fa fa-plus-circle" title="Add Unit"></i>
                                </a>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-3">
                                <label>Units</label>
                            </div>
                            <div class="col-md-9 housing-units">
                                <p *ngIf="bulkHousing.namingActive">
                                    Housing units will be named automatically
                                </p>

                                <div *ngFor="let unit of bulkHousing.units; let index = index; let last = last;"
                                     class="input-group input-group-md mb-1">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">Unit {{index + 1}}</span>
                                    </div>
                                    <input *ngIf="!bulkHousing.namingActive"
                                           type="text"
                                           class="form-control input-small housing-unit-id"
                                           name="housing_unit_{{index}}_id"
                                           placeholder="ID"
                                           [required]="true"
                                           [(ngModel)]="unit.id" />
                                    <input type="number"
                                           class="form-control input-small housing-unit-size"
                                           name="housing_unit_{{index}}_size"
                                           step="1"
                                           min="0"
                                           [(ngModel)]="unit.size"
                                           (change)="housingUnitChanged(unit, index)" />
                                    <a *ngIf="unit.size === 0"
                                       class="align-self-center py-1 pl-1"
                                       (click)="housingDeleteUnit(unit, index)">
                                        <i class="fa fa-remove remove-item" title="Delete item"></i>
                                    </a>

                                </div>
                                <div class="input-group input-group-md">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text border-right-0">Add Unit</span>
                                    </div>
                                    <div class="input-group-append housing-unit-size">
                                        <a class="input-group-text add-item-link border-left-0" (click)="housingAddUnit()">
                                            <i class="fa fa-plus-circle" title="Add Unit"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-3">
                                <label>Housing Type</label>
                            </div>
                            <div class="col-md-9">
                                <active-vocab-select [(model)]="this.bulkHousing.materialPoolTypeKey"
                                                     [vocabChoices]="this.bulkHousing.materialPoolTypes"
                                                     [keyFormatter]="materialPoolTypeKeyFormatter"
                                                     [optionFormatter]="materialPoolTypeFormatter"
                                                     [required]="true"></active-vocab-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-3">
                                <label>Date</label>
                            </div>
                            <div class="col-md-9">
                                <climb-ngb-date #dateControl="ngModel" name="Date" [(ngModel)]="bulkHousing.date"></climb-ngb-date>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-3">
                                <label>Owner</label>
                            </div>
                            <div class="col-md-9">
                                <user-select [(model)]="bulkHousing.owner"
                                             [forceValidSelection]="false"></user-select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-3">
                                <label>Location</label>
                            </div>
                            <div class="col-md-9" *ngIf="bulkHousing.location !== undefined">
                                {{bulkHousing.location.PositionName}}
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-3">
                                <label>Container Type</label>
                            </div>
                            <div class="col-md-9">
                                <active-vocab-select [(model)]="this.bulkHousing.containerTypeKey"
                                                     [vocabChoices]="this.bulkHousing.containerTypes"
                                                     [keyFormatter]="containerTypeKeyFormatter"
                                                     [optionFormatter]="containerTypeFormatter"
                                                     [nullable]="true"></active-vocab-select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<animal-bulk-templates #bulkTemplates
                       [animals]="animals"
                       [loadDefaults]="false"
                       [activeFields]="activeFields"
                       [requiredFields]="requiredFields">
</animal-bulk-templates>
