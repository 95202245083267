import { Injectable } from '@angular/core';
import { Entity, TaskInstance } from '@common/types';
import { batchArray } from '@common/util';
import { EntityQuery } from 'breeze-client';
import { LoggingService } from './logging.service';
import { DataManagerService } from './data-manager.service';
import { WebApiService } from './web-api.service';

interface TaskInstanceUpdateResponse {
  Success: boolean;
  ErrorMessage: string;
  AffectedTaskInstanceKeys: number[];
  IsSystemError: boolean;
}

interface Source {
  source: string;
}

export interface TaskBulkStatusUpdateParams extends Source {
  TaskStatusKey?: number;
  ModifiedByKey: number;
  TaskInstanceKeys: number[];
}

export interface TaskBulkDueDateUpdateParams extends Source {
  ModifiedByKey: number;
  TaskDateDues: {
    TaskInstanceKey: number;
    DateDue?: Date
  }[],
}

export interface TaskBulkCompletedDateUpdateParams extends Source {
  DateComplete?: Date;
  ModifiedByKey: number;
  TaskInstanceKeys: number[];
}

export interface TaskUpdateParams extends Source {
  TaskStatusKey?: number;
  DateComplete?: Date;
  DateDue?: Date;
  AssignedToKey?: number;
  Duration?: number;
  TaskInstanceKey: number;
  ModifiedByKey: number;
}

export class WorkflowTaskDataError extends Error {}

@Injectable({
  providedIn: 'root'
})
export class TaskInstanceService {
  private readonly DEFAULT_ERROR = 'Could not update dependent tasks.';

  constructor(
    private webApiService: WebApiService,
    private loggingService: LoggingService,
    private dataManager: DataManagerService,
  ) { }


  async fetchTasks(taskKeys: number[]): Promise<Entity<TaskInstance>[]> {
    // Split the tasks into batches
    const BATCH_SIZE = 50;
    const promises = batchArray(taskKeys, BATCH_SIZE).map(keys => this.fetchBatchTasks(keys));
    return (await Promise.all(promises)).flat()
  }

  /**
   * Bulk task status change
   */
  taskBulkStatusChange(data: TaskBulkStatusUpdateParams): Promise<number[]> {
    return this.postApi('/api/workflowTaskData/updateTaskStatus', data, data.TaskInstanceKeys);
  }

  /**
   * Bulk due date change
   */
  taskBulkDueDateChange(data: TaskBulkDueDateUpdateParams): Promise<number[]> {
    return this.postApi('api/workflowTaskData/updateTaskDateDue', data, data.TaskDateDues.map(item => item.TaskInstanceKey));
  }

  /**
   * Bulk due date change
   */
  taskBulkCompletedDateChange(data: TaskBulkCompletedDateUpdateParams): Promise<number[]> {
    return this.postApi('/api/workflowTaskData/updateTaskDateComplete', data, data.TaskInstanceKeys);
  }

  /**
   * Bulk due date change
   */
  taskUpdate(data: TaskUpdateParams): Promise<number[]> {
    return this.postApi('api/workflowTaskData/updateTask', data, [data.TaskInstanceKey]);
  }

  private async postApi<T extends Source>(path: string, { source, ...data }: T, keys: number[]): Promise<number[]> {
    try {
      const response = await this.webApiService.postApi<TaskInstanceUpdateResponse>(path, data);
      if (!response.data.Success) {
        throw new WorkflowTaskDataError(
          !response.data.IsSystemError && response.data?.ErrorMessage ? response.data.ErrorMessage : this.DEFAULT_ERROR
        );
      }
      const affectedTaskKeys = response.data?.AffectedTaskInstanceKeys;
      this.showDependentChangedToast(source, keys, affectedTaskKeys);
      return affectedTaskKeys;
    } catch (error) {
      const newError = error instanceof WorkflowTaskDataError ? error : new Error(this.DEFAULT_ERROR);
      this.loggingService.logError(newError.message, null, source, true);
      throw newError;
    }
  }

  private showDependentChangedToast(source: string, keys: number[], dependantKeys: number[] = []) {
    const keysSet = new Set(keys);
    if (dependantKeys.every(key => keysSet.has(key))) {
      return;
    }
    this.loggingService.logWarning(
      'Dependent due dates/times have been updated.',
      null,
      source,
      true
    );
  }

  /**
   * Fetch TaskInstances for a batch of provided task keys.
   * 
   * Note: The expand() forces Breeze to reload the related entities from the DB.
   */
  private fetchBatchTasks(taskKeys: number[]): Promise<Entity<TaskInstance>[]> {
    const expands = [
      'TaskOutputSet.TaskOutput',
      'TaskOutputSet.TaskOutput.Output.InheritedFromOutput',
      'TaskOutputSet.TaskOutput.Output.CalculatedOutputExpression.ExpressionInputMapping',
      'TaskOutputSet.TaskOutput.Output.CalculatedOutputExpression.ExpressionOutputMapping',
      'TaskOutputSet.TaskOutput.Output.OutputFlag',
      'TaskCohort',
    ];
    const query = EntityQuery.from('TaskInstances')
      .expand(expands.join(','))
      .where('C_TaskInstance_key', 'in', taskKeys)
      .orderBy('DateDue');

    return this.dataManager.returnQueryResults(query) as Promise<Entity<TaskInstance>[]>;
  }
}
