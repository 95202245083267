import { BehaviorSubject, Subject } from 'rxjs';
import { Entity, EntityState } from 'breeze-client';
import { DataManagerService } from './data-manager.service';
import { LoggingService } from './logging.service';
import { FeatureFlagService } from './feature-flags.service';
import { ReasonForChangeService } from '../common/reason-for-change/reason-for-change.service';
import { DeletionService } from './deletion.service';

export abstract class BaseEntitySaveService {
    protected saveEntityLoadingSource = new BehaviorSubject<boolean>(false);
    saveEntityLoading$ = this.saveEntityLoadingSource.asObservable();

    protected saveSuccessfulSource = new Subject<void>();
    saveSuccessful$ = this.saveSuccessfulSource.asObservable();

    protected saveFailedSource = new Subject<void>();
    saveFailed$ = this.saveFailedSource.asObservable();

    protected abstract saveSource = '';

    constructor(protected dataManagerService: DataManagerService,
                protected loggingService: LoggingService,
                protected featureFlagService: FeatureFlagService,
                protected deletionService: DeletionService,
                protected reasonForChangeService: ReasonForChangeService) {
    }

    async save(entity: any): Promise<void> {
        let changes = this.getAllRelatedChanges(entity);

        try {
            if (this.featureFlagService.isFlagOn('IsGLP')) {
                const modifiedOrDeletedEntities = this.dataManagerService.filterEntitiesByEntityState(changes, [EntityState.Modified, EntityState.Deleted]);
                if (modifiedOrDeletedEntities.length > 0) {
                    changes = [...changes, ...await this.reasonForChangeService.handleReasonForChangeForEntities(modifiedOrDeletedEntities)];
                }
            }
            const deletionEntities: Entity[] = this.deletionService.handleDeletedItemsInBatch(changes);
            changes = [...changes, ...deletionEntities];
            const batches: Entity[][] = this.dataManagerService.prepareSaveBatches(changes);
            this.saveEntityLoadingSource.next(true);
            await this.dataManagerService.saveChangeBatches(batches);
            this.loggingService.logFacetSaveSuccess(this.saveSource, true);
            this.saveSuccessfulSource.next();
        } catch (error) {
            if (error?.status !== '401') {
                this.loggingService.logError(error.message, error, this.saveSource, true);
                this.saveFailedSource.next();
            }
        } finally {
            this.saveEntityLoadingSource.next(false);
        }
    }

    abstract getAllRelatedChanges(entity: any): Entity[];

    hasChanges(entity: any) {
        const changes = this.getAllRelatedChanges(entity);

        return changes.length > 0;
    }
}
