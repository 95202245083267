<div class="facet">
    <facet-header
        caption="Charts"
        [facet]="facet">
    </facet-header>
    <div class="facet-body">
        <nav ngbNav
             #nav="ngbNav"
             class="nav-tabs"
             [(activeId)]="chartKind"
             (navChange)="onNavChange($event)">
            <ng-container [ngbNavItem]="'kaplan-meier'">
                <a ngbNavLink>Kaplan Meier Chart</a>
                <ng-template ngbNavContent>
                    <kaplan-meier-chart></kaplan-meier-chart>
                </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="'breeding-efficiency'">
                <a ngbNavLink>Breeding Efficiency</a>
                <ng-template ngbNavContent>
                    <breeding-efficiency-chart></breeding-efficiency-chart>
                </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="'task-output'">
                <a ngbNavLink>Task Output</a>
                <ng-template ngbNavContent>
                    <task-output-chart></task-output-chart>
                </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="'jobs-gantt'">
                <a ngbNavLink>{{ ('jobs' | translate) + ' Gantt Chart' }}</a>
                <ng-template ngbNavContent>
                    <jobs-gantt-chart></jobs-gantt-chart>
                </ng-template>
            </ng-container>
            <ng-container [ngbNavItem]="'task-output-by-name'">
                <a ngbNavLink>Task Output By Name</a>
                <ng-template ngbNavContent>
                    <task-output-by-name-chart></task-output-by-name-chart>
                </ng-template>
            </ng-container>
        </nav>
        <div [ngbNavOutlet]="nav" class="my-2 mx-3"></div>
    </div>
</div>
