import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { IFacet } from '@common/facet';
import { AppInsightsService } from '@services/app-insights.service';
import { DynamicComponentLoader } from '../../../dynamic/dynamic-component-loader.service';
import { DynamicComponentType } from '../../../dynamic/injection-token-map';

/**
 * Factory for rendering the different facet copmonents
 */
@Component({
    selector: 'facet-factory',
    templateUrl: './facet-factory.component.html',
    styleUrls: ['./facet-factory.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FacetFactoryComponent implements OnInit {
    @Input() facet: IFacet;

    @ViewChild('facetOutlet', {
    read: ViewContainerRef
}) testOutlet: ViewContainerRef;

    dynamicallyLoaded = false;

    /* eslint-disable quote-props */
    readonly dynamicComponentMap: any = {
        'cohorts': DynamicComponentType.CohortFacet,
        'jobs': DynamicComponentType.JobFacet,
        'jobs pharma': DynamicComponentType.JobPharmaFacet,
        'orders': DynamicComponentType.OrderFacet,
        'resources': DynamicComponentType.ResourceFacet,
        'schedule': DynamicComponentType.ScheduleFacet,
    };
    /* eslint-enable quote-props */

    constructor(
        private appInsightsService: AppInsightsService,
        private dynamicComponentLoader: DynamicComponentLoader
    ) {}

    ngOnInit() {
        this.dynamicallyLoaded = false;
        if (!this.facet || !this.facet.FacetName) {
            return;
        }
        
        // track every time a facet is loaded
        this.appInsightsService.trackEvent('load-facet-' + this.facet.FacetName);

        const facetName = (<string> this.facet.FacetName).toLowerCase();

        if (this.dynamicComponentMap.hasOwnProperty(facetName)) {
            this.dynamicallyLoaded = true;
            this.loadComponent(this.dynamicComponentMap[facetName]);
        }
    }

    loadComponent(type: DynamicComponentType) {
        this.dynamicComponentLoader
          .getComponentFactory<any>(type)
          .then((componentFactory) => {
                const c = this.testOutlet.createComponent(componentFactory);
                c.instance.facet = this.facet;

          }, (error) => {
            console.warn(error);
          });
      }
}
