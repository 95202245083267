<ng-template #cagecardmodal let-c="close">
    <cage-card-chooser [animals]="selectedRows"
                       (close)="c('Close')"></cage-card-chooser>
</ng-template>

<div class="facet">
    <facet-header
        caption="Animals"
        [facet]="facet"
        (close)="onRemoveFacet()">
        <button *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Animals">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && !workspaceFilterActive"
                climbFacetAction
                (click)="filterWorkspaceByAnimal()"
                title="Filter Workspace by Selected Animals">
            <svg [climbIcon]="icons.viceVersaArrowsHorizontal"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && workspaceFilterActive"
                climbFacetAction
                (click)="clearWorkspaceFilter()"
                title="Stop Filtering Workspace">
            <svg [climbIcon]="icons.brokenChain"></svg>
        </button>

        <button data-automation-id="copy-animals-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                [disabled]="!selectedRows.length"
                (click)="copyAnimals()"
                title="Copy Animals">
            <svg [climbIcon]="icons.squareOnSquare"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege!=='None'">

        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <button climbCageCardButton
                        (click)="openCageCardModal(cagecardmodal)"
                        [disabled]="selectedRows.length === 0"
                        title="Print cage cards for selected animals"
                        appInsightsClick="click-animal-listview-cagecards">
                    Cards
                </button>

                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            (click)="changeView(BULK_ADD_VIEW)"
                            [appInsightsClick]="'click-bulk-add-' + componentName">
                        Add
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="sendSelectedToBulkEdit()"
                            [appInsightsClick]="'click-bulk-edit-' + componentName">
                        Edit
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="doBulkDelete()"
                            [appInsightsClick]="'click-bulk-delete-' + componentName">
                        Delete
                    </button>

                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickCreateHousing()"
                            [appInsightsClick]="'click-bulk-edit-' + componentName">
                        Create Housing
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkAssignTasks()"
                            [appInsightsClick]="'click-bulk-delete-' + componentName">
                        Assign Task
                    </button>
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkAssignProtocol()"
                            [appInsightsClick]="'click-bulk-delete-' + componentName">
                        Assign Protocol
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [expandExport]="true"
                                       [options]="animalTableOptions$ | async"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onChange)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>

            <climb-data-table data-automation-id="animals-table"
                              #dataTable
                              [dataService]="dataService"
                              [options]="animalTableOptions$ | async"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedRowsChange)="selectedRowsChange($event)"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (dragStart)="dragStart()"
                              (dragStop)="dragStop()"
                              (addItemClick)="addItemClick()"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <animal-detail *ngIf="facetView === DETAIL_VIEW"
                       [facet]="facet"
                       [facetView]="facetView"
                       [animal]="itemToEdit"
                       [activeFields]="activeFields"
                       [requiredFields]="requiredFields"
                       [isSyncItem]="isSyncItem"
                       [taskData]="taskData"
                       [pageState]="detailPaginator.pageState"
                       (exit)="exitDetail()"
                       (previous)="detailPreviousClicked()"
                       (next)="detailNextClicked()">
        </animal-detail>

        <animal-bulk-add *ngIf="facetView === BULK_ADD_VIEW"
                         [facet]="facet"
                         [facetView]="facetView"
                         [activeFields]="activeFields"
                         [requiredFields]="requiredFields"
                         (exit)="handleBulkAddExit($event)"></animal-bulk-add>

        <animal-bulk-edit *ngIf="facetView === BULK_EDIT_VIEW"
                          [facet]="facet"
                          [facetView]="facetView"
                          [animals]="itemsToEdit"
                          [activeFields]="activeFields"
                          [inactiveFields]="inactiveFields"
                          [requiredFields]="requiredFields"
                          (exit)="exitBulkEdit($event)">
        </animal-bulk-edit>

        <animal-bulk-housing *ngIf="facetView === BULK_HOUSING_VIEW"
                             [facet]="facet"
                             [animals]="itemsToEdit"
                             (exit)="handleBulkHousingExit($event)">
        </animal-bulk-housing>
    </div>
</div>
