import { map } from 'rxjs/operators';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ViewChildren
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { HousingVocabService } from '../../services/housing-vocab.service';
import { LocationService } from '../../../locations/location.service';
import { MaterialPoolService } from '../../../services/material-pool.service';
import { NamingService } from '../../../services/naming.service';
import { ReportingService } from '../../../reporting/reporting.service';
import { TranslationService } from '../../../services/translation.service';
import { DetailTaskTableComponent, DetailTaskTableOptions } from '../../../tasks/tables';

import { maxSequence, notEmpty } from '../../../common/util';
import { TaskType } from '../../../tasks/models';

import {
    BaseDetail,
    BaseDetailService,
    PageState
} from '../../../common/facet';
import { TableSort } from "../../../common/models";
import { SaveChangesService, UnsavedChanges } from '../../../services/save-changes.service';
import { FeatureFlagService } from '../../../services/feature-flags.service';
import { SettingService } from '../../../settings/setting.service';
import { dateControlValidator } from '@common/util/date-control.validator';
import { HousingSocialTableComponent } from '../social-table/housing-social-table.component';
import { LocationHistoryComponent } from 'src/app/locations/location-history.component';

@Component({
    selector: 'housing-detail',
    templateUrl: './housing-detail.component.html'
})
export class HousingDetailComponent extends BaseDetail implements OnChanges, OnInit {
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChildren('socialTable') socialTables: HousingSocialTableComponent[];
    @ViewChildren('locationHistory') locationHistories: LocationHistoryComponent[];
    @ViewChildren('detailTaskTable') detailTaskTables: DetailTaskTableComponent[];

    @Input() facet: any;
    @Input() materialPool: any;
    @Input() pageState: PageState;
    // Active and required fields set by facet settings
    @Input() activeFields: string[];
    @Input() requiredFields: string[];

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild("housingForm") housingForm: NgForm;

    // CVs
    containerTypes: any[] = [];
    materialPoolTypes: any[] = [];
    reportTypes: any[] = [];
    materialPoolStatuses: any[] = [];

    // Table options
    detailTaskTableOptions: DetailTaskTableOptions;

    // Table sorting
    taskTableSort: TableSort = new TableSort();

    // State
    housingDetailsExpanded = false;
    deviceDetailsExpanded = false;
    housingNamingActive = false;
    saving = false;

    // Feature flags
    isGLP = false;

    readonly COMPONENT_LOG_TAG = 'housing-detail';

    // Export enum to template
    TaskType = TaskType;

    originalMaterialPoolID = '';

    constructor(
        private baseDetailService: BaseDetailService,
        private housingVocabService: HousingVocabService,
        private locationService: LocationService,
        private materialPoolService: MaterialPoolService,
        private namingService: NamingService,
        private reportingService: ReportingService,
        private translationService: TranslationService,
        private saveChangesService: SaveChangesService,
        private featureFlagService: FeatureFlagService,
        private settingService: SettingService,
    ) {
        super(baseDetailService);
    }

    // lifecycle
    ngOnInit() {
        this.initialize();
        this.initTableOptions();
    }

    ngOnChanges(changes: any) {
        if (changes.materialPool) {
            if (this.materialPool && !changes.materialPool.firstChange) {
                if (this.housingForm) {
                    this.housingForm.form.markAsPristine();
                }

                this.initialize();
            }
        }
    }

    initialize() {
        this.setLoading(true);

        this.initReportTypes();
        this.initIsGLP();

        return this.getCVs().then(() => {
            return this.isNamingActive();
        }).then(() => {
            return this.getDetails();
        }).then(() => {
            return this.getMaterialPoolMaterials();
        }).then(() => {
            return this.getSocialGroupMaterials();
        }).then(() => {
            return this.getCompatibilityMaterials();
        }).then(() => {
            return this.getTasks();
        }).then(() => {
            return this.getDevices();
        }).then(() => {
            this.setLoading(false);
        }).catch((error) => {
            this.setLoading(false);
            throw error;
        });
    }

    initIsGLP() {
        const flag = this.featureFlagService.getFlag("IsGLP");
        this.isGLP = (flag && flag.IsActive && flag.Value.toLowerCase() === "true");
    }

    initReportTypes() {
        this.reportTypes = [
            'Mating',
            this.translationService.translate('Wean'),
            this.translationService.translate('Job')
        ];
    }

    private initTableOptions() {
        // Detail Task Table
        this.detailTaskTableOptions = new DetailTaskTableOptions();
        this.detailTaskTableOptions.allowLocking = false;
        this.detailTaskTableOptions.showAnimals = false;
        this.detailTaskTableOptions.showSamples = false;
    }

    getCVs(): Promise<any> {
        const p1: Promise<any> = this.housingVocabService.containerTypes$.pipe(map((data) => {
            this.containerTypes = data;
        })).toPromise();

        const p2: Promise<any> = this.housingVocabService.materialPoolTypes$.pipe(map((data) => {
            this.materialPoolTypes = data;
        })).toPromise();

        const p3: Promise<any> = this.housingVocabService.materialPoolStatuses$.pipe(map((data) => {
            this.materialPoolStatuses = data;
        })).toPromise();

        return Promise.all([p1, p2, p3]);
    }

    private isNamingActive(): Promise<any> {
        return this.namingService.isHousingNamingActive()
            .then((active: boolean) => {
                this.housingNamingActive = active;
            });
    }

    private getDetails(): Promise<any> {
        if (this.materialPool && this.materialPool.C_MaterialPool_key > 0) {
            this.originalMaterialPoolID = this.materialPool.MaterialPoolID;
            return this.materialPoolService.getMaterialPool(
                this.materialPool.C_MaterialPool_key,
                ['TaskMaterialPool.TaskInstance']
            );
        }

        return Promise.resolve(this.materialPool);
    }

    private getMaterialPoolMaterials(): Promise<any[]> {
        return this.materialPoolService.getMaterialPoolMaterials(
            this.materialPool.C_MaterialPool_key
        );
    }

    private getSocialGroupMaterials(): Promise<any[]> {
        return this.materialPoolService.getSocialGroupMaterials(
            this.materialPool.C_MaterialPool_key
        );
    }

    private getCompatibilityMaterials(): Promise<any[]> {
        return this.materialPoolService.getCompatibilityMaterials(
            this.materialPool.C_MaterialPool_key
        );
    }

    private getTasks(): Promise<any> {
        return this.materialPoolService.getHousingTasks(this.materialPool.C_MaterialPool_key);
    }

    private getDevices(): Promise<void> {
        return this.materialPoolService.getDevices(this.materialPool);
    }

    onCancel() {
        this.materialPoolService.cancelMaterialPool(this.materialPool);
    }



    // Location
    addMaterialPoolLocation() {
        this.locationService.getDefaultLocation().then((defaultLocation) => {
            this.locationService.createMaterialLocation({
                C_LocationPosition_key: defaultLocation.C_LocationPosition_key,
                C_MaterialPool_key: this.materialPool.C_MaterialPool_key
            });
        });
    }

    removeMaterialPoolLocation(materialPoolLocation: any) {
        this.materialPoolService.deleteMaterialPoolLocation(materialPoolLocation);
    }


    // Housing Details
    toggleHousingDetailsExpanded() {
        this.housingDetailsExpanded = !this.housingDetailsExpanded;
    }

    // Device Details
    toggleDeviceDetailsExpanded() {
        this.deviceDetailsExpanded = !this.deviceDetailsExpanded;
    }


    // Cage Cards
    requestCageCard(reportType: string) {
        const jobTranslated = this.translationService.translate('Job');
        const weanTranslated = this.translationService.translate('Wean');

        switch (reportType) {
            case jobTranslated:
                const materialKeys: number[] = this.getAllAnimalMaterialKeys();
                if (notEmpty(materialKeys)) {
                    this.reportingService.requestCageCardJobByAnimalKeys(materialKeys);
                }
                break;
            case 'Mating':
                this.reportingService.requestCageCardMating([this.materialPool.C_MaterialPool_key]);
                break;
            case weanTranslated:
                this.reportingService.requestCageCardWean([this.materialPool.C_MaterialPool_key]);
                break;
        }
    }

   private getAllAnimalMaterialKeys(): number[] {
       return this.materialPool.MaterialPoolMaterial
            .filter((material: any) => {
                return material.DateOut === null;
            })
            .map((material: any) => {
                return material.C_Material_key;
            });
    }

    // Tasks
    addTaskHousing(taskInstance: any) {
        const taskSequence = maxSequence(this.materialPool.TaskMaterialPool) + 1;

        this.materialPoolService.createTaskMaterialPool(
            this.materialPool.C_MaterialPool_key,
            taskInstance.C_TaskInstance_key,
            taskSequence
        );
    }


    // <select> formatters
    materialPoolTypeKeyFormatter = (value: any) => {
        return value.C_MaterialPoolType_key;
    }
    materialPoolTypeFormatter = (value: any) => {
        return value.MaterialPoolType;
    }
    containerTypeKeyFormatter = (value: any) => {
        return value.C_ContainerType_key;
    }
    containerTypeFormatter = (value: any) => {
        return value.ContainerType;
    }
    materialPoolStatusKeyFormatter = (value: any) => {
        return value.C_MaterialPoolStatus_key;
    }
    materialPoolStatusFormatter = (value: any) => {
        return value.MaterialPoolStatus;
    }

    async onSaveHousing() {
        let errMsg;

        // Validate fields required by facet settings
        if (!errMsg) {
            errMsg = dateControlValidator(this.dateControls)
                || this.socialTables.map(item => item.validate()).find(msg => msg)
                || this.detailTaskTables.map(item => item.validate()).find(msg => msg)
                || this.locationHistories.map(item => item.validate()).find(msg => msg)
                || await this.settingService.validateRequiredFields(this.requiredFields, this.materialPool, 'housing');
        }

        if (errMsg) {
            this.loggingService.logError(errMsg, 'Validation Error', this.COMPONENT_LOG_TAG, true);
            return;
        }

        if (this.locationService.locationsValid(this.materialPool.MaterialLocation)) {
            const errors = this.housingForm.controls.materialPoolID.errors;
            if (errors && errors.required) {
                this.loggingService.logError('A housing requires an ID. Please enter a housing ID' +
                    ' and try again.', null, this.COMPONENT_LOG_TAG, true);
                return;
            }
            if (errors && errors.unique && this.materialPool.MaterialPoolID !== this.originalMaterialPoolID) {
                this.loggingService.logError('A housing requires a unique ID. Please enter' +
                    ' a new housing ID and try again.', null, this.COMPONENT_LOG_TAG, true);
                return;
            }

            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
        } else {
            this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
        }
    }

    onSwitchView(buttonName: string): any {
        if (this.saveChangesService.hasChanges) {
            // If there are unsaved changes, prompt the user to save or discard
            return this.viewUnsavedChangesModalService.openComponent().then((result: string) => {
                if (result === 'save') {
                    // Throw error & don't save if location dates are invalid
                    if (this.locationService.locationsValid(this.materialPool.MaterialLocation)) {
                        return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG).then(() => {
                            // Emits reload event when save a new record by clicking on next
                            if (buttonName === 'next') {
                                this.reload.emit();
                            }
                            this.emitViewChange(buttonName, UnsavedChanges.save);
                        });
                    } else {
                        this.loggingService.logError("Locations dates are not valid.", null, this.COMPONENT_LOG_TAG, true);
                    }
                } else {
                    if (this.dataContext.hasChanges()) {
                        this.onCancel();
                        this.loggingService.logFacetUndoSuccess(this.COMPONENT_LOG_TAG);
                    }
                    this.emitViewChange(buttonName, UnsavedChanges.discard);
                }
            });
        } else {
            this.emitViewChange(buttonName, UnsavedChanges.noChanges);
        }
    }

    private emitViewChange(buttonName: string, changes: UnsavedChanges) {
        switch (buttonName) {
            case 'previous':
                super.previousClicked(changes);
                this.previous.emit();
                break;
            case 'next':
                super.nextClicked(changes);
                this.next.emit();
                break;
            case 'exit':
                super.exitClicked(changes);
                this.exit.emit();
                break;
            default:
                break;
        }
    }

    isAnimalsRequired() {
        return this.requiredFields.includes('MaterialPoolMaterial[0]')
            && (this.materialPool.MaterialPoolMaterial.length === 0
                || !this.materialPool.MaterialPoolMaterial.find((material: any) => material.DateOut === null));
    }
}
