import {
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmService } from '../common/confirm';
import { ConstructService } from './construct.service';
import { SaveChangesService } from '../services/save-changes.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService
} from '../common/facet';

import { ConstructTableOptions } from './construct-table-options';
import { ConstructFilterComponent } from './construct-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../services/query-def';
import { TranslationService } from '../services/translation.service';
import { arrowClockwise, magnifier } from '@icons';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
    selector: 'construct-facet',
    templateUrl: './construct-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class ConstructFacetComponent extends BaseFacet implements OnInit {
    @Input() facet: any;

    readonly icons = { arrowClockwise, magnifier };

    componentName = 'construct';

    constructTableOptions: ConstructTableOptions;

    readonly COMPONENT_LOG_TAG = 'construct-facet';

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private confirmService: ConfirmService,
        private constructService: ConstructService,
        private cellFormatterService: CellFormatterService,
        private modalService: NgbModal,
        private saveChangesService: SaveChangesService,
        workspaceFilterService: WorkspaceFilterService,
        private translationService: TranslationService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.constructTableOptions = new ConstructTableOptions(this.cellFormatterService);

        this.dataTableColumns = new BehaviorSubject(this.constructTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemsList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {
                this.filter = {};
            }
        }
    }

    async loadItemsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: string[] = [];

        this.setLoadingState(tableState.loadingMessage);

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.constructService.getConstructs(queryDef);

            const visibleColumns = this.getVisibleColumns(this.constructTableOptions.options);
            await this.constructService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    async addItemClick() {
        this.setLoadingState();
        try {
            const construct = await this.createNewConstruct();
            this.stopLoading();
            this.itemToEdit = construct;
            this.changeView(this.DETAIL_VIEW);
        } catch (error) {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        }
    }

    async createNewConstruct(): Promise<any> {
        const newConstruct = this.constructService.createConstruct();
        return Promise.resolve(newConstruct);
    }

    async deleteItemClick(construct: any) {
        if (this.privilegeService.readwrite) {
            const isSafe = await this.constructService.isConstructSafeToDelete(construct);
            if (isSafe) {
                this.deleteItem(construct);
            } else {
                const message = 'Cannot delete construct: it is associated with ' + this.translationService.translate('Lines') + '.';
                this.loggingService.logWarning(message, null, this.COMPONENT_LOG_TAG, true);
            }
        }
    }

    /**
     * Prompts for delete then deletes protocol, saves changes, reloads grid.
     *
     * @param protocol
     */
    private async deleteItem(construct: any): Promise<any> {
        const itemName = construct.ConstructID;
        const modalTitle = 'Delete Construct';
        const modalText = `Delete "${itemName}"? This action cannot be undone.`;

        try {
            await this.confirmService.confirmDelete(modalTitle, modalText);
            this.constructService.deleteConstruct(construct);
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            this.reloadTable();
        } catch (error) {
            console.error(error);
        }
    }

    openFilter() {
        const ref = this.modalService.open(ConstructFilterComponent);
        const component = ref.componentInstance as ConstructFilterComponent;
        component.filter = this.filter;
        component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.constructService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}
