<div class="detail-form-wrapper">
    <loading-overlay *ngIf="loading || saveChangesService.saving" [message]="loadingMessage"></loading-overlay>
    <div class="detail-header">
        <h3>{{'Protocol' | translate}}</h3>

        <pager-toolbar [itemName]="'protocol' | translate"
                       [logTag]="COMPONENT_LOG_TAG"
                       [first]="pageState.isFirst"
                       [last]="pageState.isLast"
                       (previous)="previousClicked($event)"
                       (next)="nextClicked($event)">
        </pager-toolbar>

        <detail-toolbar [facetPrivilege]="facet.Privilege"
                        [logTag]="COMPONENT_LOG_TAG"
                        (exit)="exitClicked($event)">

            <div class="btn-group"
                 role="toolbar"
                 aria-label="Edit actions"
                 additional-groups
                 *ngIf="facet.Privilege === 'ReadWrite'">
                <button type="button"
                        class="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        [disabled]="copying === true">
                    <ng-container *ngIf="!copying">
                        <i class="fa fa-copy"
                           aria-hidden="true"
                           title="Copy {{'Protocol' | translate}}"></i>
                        <span class="gridster-d-sm-none gridster-d-md-inline-block">Copy</span>
                        <span class="sr-only">Copy {{'Protocol' | translate}}</span>
                    </ng-container>
                    <ng-container *ngIf="copying === true">
                        <i class="fa fa-2x fa-spinner fa-spin" aria-hidden="true" title="Copying&hellip;"></i>
                        Copying&hellip;
                    </ng-container>
                </button>
                <div class="dropdown-menu dropdown-menu-right"
                    role="menu"
                    aria-labelledby="Target workgroups">
                    <a class="dropdown-item"
                       (click)="copyToCurrentWorkgroup()"
                       role="menuitem">
                        Copy to Current Workgroup
                    </a>
                    <!-- Copying protocols between workgroups can result in data loss -->
                    <!-- It has therefore been disabled until a permanent fix can be developed -->
                    <!--<h6 *ngIf="userWorkgroups.length > 1" class="dropdown-header">To Workgroup:</h6>
                    <ng-container *ngFor="let workgroup of userWorkgroups">
                        <a *ngIf="workgroup.WorkgroupName !== currentWorkgroupName"
                           class="dropdown-item"
                           (click)="copyProtocol(workgroup.C_Workgroup_key)"
                           role="menuitem">
                            {{workgroup.WorkgroupName}}
                        </a>
                    </ng-container>-->
                </div>
            </div>
        </detail-toolbar>
    </div>

    <div *ngIf="protocol | isDeleted"
         class="alert alert-warning"
         role="alert">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        This {{'protocol' | translate}} has been deleted.
    </div>

    <form *ngIf="!(protocol | isDeleted)"
          name="protocolForm" id="protocolForm"
          class="detail-form"
          style="position: relative;"
          #protocolForm="ngForm">
        <fieldset [disabled]="facet.Privilege === 'ReadOnly'">

            <div class="form-group row">
                <label class="col-md-2 col-form-label">Name</label>
                <div class="col-md-10">
                    <input data-automation-id="protocol-name-input"
                           type="text"
                           name="protocolName"
                           [(ngModel)]="protocol.ProtocolName"
                           class="form-control input-medium"
                           [required]="true" />
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Description')">
                <label class="col-md-2 col-form-label">Description</label>
                <div class="col-md-10">
                    <textarea name="description"
                              [(ngModel)]="protocol.Description"
                              maxlength="1000"
                              rows="2"
                              class="form-control"
                              [required]="requiredFields.includes('Description')"></textarea>
                </div>
            </div>

            <div class="form-group row" *ngIf="activeFields.includes('Active')">
                <label class="col-md-2 col-form-label">Active</label>
                <div class="col-md-10 pt-1">
                    <input type="checkbox"
                           name="isActive"
                           [(ngModel)]="protocol.IsActive" />
                </div>
            </div>

            <div class="form-group row"
                 *ngIf="protocol.lastUsedDate > 0">
                <label class="col-md-2 col-form-label">Last Used</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        {{protocol.lastUsedDate | formatShortDate}}
                    </p>
                </div>
            </div>

            <div class="form-group row" *ngIf="isCRO">
                <label class="col-md-2 col-form-label">Show Task Groups</label>
                <div class="col-md-10">
                    <p class="form-control-plaintext">
                        <label class="switch">
                            <input type="checkbox" name="task-rows-switch" (click)="openTaskRows()">
                            <span class="slider"></span>
                        </label>
                    </p>
                </div>
            </div>
        </fieldset>

        <div class="form-group row">
            <protocol-task-table 
                [protocol]="protocol"
                [isCRO]="isCRO"
                [protocolTasks]="freeTasks"
                [protocolSection]="sections"
                [taskRowsOpen]="taskRowsOpen"
                [readonly]="facet.Privilege === 'ReadOnly'">
            </protocol-task-table>
        </div>

        <div class="form-group row">
            <label class="col-md-2 col-form-label">Files</label>
            <div class="col-md-10">
                <div class="form-control-plaintext">
                    <facet-detail-file-upload [pkName]="'C_Protocol_key'"
                                              [pkValue]="protocol.C_Protocol_key"
                                              [facetPrivilege]="facet.Privilege">
                    </facet-detail-file-upload>
                </div>
            </div>
        </div>
    </form>
</div>
