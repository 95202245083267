import { AppInsightsService } from './../services/app-insights.service';
import { Component, Input, OnInit, OnDestroy } from '@angular/core';

import { GridOptions, IDatasource, IGetRowsParams } from 'ag-grid-community';
import {
    Observable,
    forkJoin,
    from,
    throwError,
    Subscription
} from 'rxjs';

import {
    catchError
} from 'rxjs/operators';

import { CsvExporter } from '../common/export/csv-exporter';
import { TranslationService } from '../services/translation.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';
import { SearchService } from './search.service';

import { randomId } from '../common/util';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'search-facet',
    templateUrl: './search-facet.component.html',
    styleUrls: ['../common/datatable/climb-data-table.component.scss'],
})
export class SearchFacetComponent implements IDatasource, OnInit, OnDestroy {
    @Input() facet: any = null;

    searching = false;
    searchPerformed = false;
    generatingFile = false;
    filter: any = {};
    userSearches: any[] = [];
    resultsCount = 0;
    columnDefs: any[] = [];
    entities: any[] = [];
    entity: any = null;
    selectedSearchField: any = null;
    showingResults = false;
    simpleSearchTerm: string = null;
    rowHeight = 26;
    gridOptions: GridOptions = {
        cacheBlockSize: 500,
        defaultColDef: {
            resizable: true,
            filter: false,
            sortable: true,
            suppressMovable: true,
        },
        onModelUpdated: () => {
            this.sizeToFit();
        },
        suppressCellFocus: true,
        suppressRowClickSelection: true,
        suppressRowHoverHighlight: true,
        // suppressRowHoverClass: true,
        localeText: {
            to: '&#8211;'
        },
    };

    // tab IDs
    readonly SIMPLE_TAB_ID = 'simple_' + randomId();
    readonly ADVANCED_TAB_ID = 'advanced_' + randomId();

    componentSubscription: Subscription;
    csvExporter: CsvExporter;

    constructor(
        private appInsightsService: AppInsightsService,
        private searchService: SearchService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
    ) {
        this.csvExporter = new CsvExporter();
    }

    ngOnInit() {
        this.entities = initialSearchEntities(this.translationService, this.vocabularyService);
    }

    ngOnDestroy() {
        if (this.componentSubscription) {
            this.componentSubscription.unsubscribe();
        }
    }

    async getRows(params: IGetRowsParams): Promise<void> {
        if (!this.entity) {
            return;
        }

        try {
            this.searching = true;
            const sort = {
                sortColumn: null as string,
                sortDirection: null as string,
            };
            if (params.sortModel && params.sortModel.length) {
                // we only sort by a single column so just grab the 1st index
                const sortCol = params.sortModel[0];
                sort.sortColumn = sortCol.colId;
                sort.sortDirection = sortCol.sort;
            }

            const pageNumber = params.startRow / this.gridOptions.cacheBlockSize;
            const numberRecords = this.gridOptions.cacheBlockSize;

            const searchQueryDef = {
                entity: this.entity.entityName,
                page: pageNumber,
                size: numberRecords,
                sortColumn: sort.sortColumn,
                sortDirection: sort.sortDirection,
                filter: this.filter
            };

            const results = await this.searchService.getEntitiesBySearch(searchQueryDef);
            // pull column labels out of the associative array keys
            const colStrings: string[] = [];
            for (const key in results.data[0]) {
                if (key.indexOf('Key') < 0) {
                    colStrings.push(key);
                }
            }

            // don't update the columns unless one of the header names has changed. This will
            // preserve sorting and layout in the case that the labels match
            if (colStrings.length !== this.columnDefs.length
                || this.columnDefs.some((def, i) => def.field !== colStrings[i])) {
                this.columnDefs = colStrings.map((colString) => {
                    return {
                        headerName: colString,
                        field: colString,
                    };
                });
            }

            this.showingResults = true;
            params.successCallback(results.data, this.resultsCount);
        }
        finally {
            this.searching = false;
        }
    }

    runSimpleSearch() {
        this.appInsightsService.trackEvent('run-simple-search');

        this.searching = true;
        this.clearResults();

        const obsArr: Observable<any>[] = [];
        this.entities.forEach((entity: any) => {
            obsArr.push(this.getCountsByField(entity));
        });

        this.componentSubscription = forkJoin(obsArr).pipe(
            catchError((error) => {
                this.searching = false;
                return throwError(error);
            })
        ).subscribe(() => {
            this.searching = false;
        });
    }

    getCountsByField(entity: any) {
        const obsArr: Observable<any>[] = [];
        for (const searchField of entity.searchFields) {
            const filter: any = {};
            filter[searchField.fieldName] = this.simpleSearchTerm;
            if (searchField.typeName) {
                filter[searchField.typeName] = searchField.typeValue;
            }
            const currPromise = this.searchService.getCountBySearch(entity.entityName, filter)
                .then((results) => {
                    searchField.resultsCount = results;
                });
            obsArr.push(from(currPromise));
        }

        return forkJoin(obsArr);
    }

    private clearResults() {
        this.showingResults = false;
        this.entities.forEach((entity: any) => {
            entity.searchFields.forEach((searchField: any) => {
                searchField.resultsCount = 0;
                searchField.selected = false;
                searchField.pageNumber = 1;
                searchField.results = [];
            });
        });
    }

    selectResults(selectedEntity: any, selectedSearchField: any) {
        this.gridOptions.api.paginationGoToFirstPage();
        this.gridOptions.columnApi.resetColumnState();

        this.entity = selectedEntity;
        this.resultsCount = selectedSearchField.resultsCount;
        this.selectedSearchField = selectedSearchField;

        this.filter = {};
        this.filter[this.selectedSearchField.fieldName] = this.simpleSearchTerm;
        if (this.selectedSearchField.typeName) {
            this.filter[this.selectedSearchField.typeName] = this.selectedSearchField.typeValue;
        }

        this.entities.forEach((entity: any) => {
            entity.searchFields.forEach((searchField: any) => {
                if (searchField === this.selectedSearchField) {
                    searchField.selected = true;
                } else {
                    searchField.selected = false;
                }
            });
        });

        this.performSearch();
    }

    performSearch() {
        if (this.gridOptions.api) {
            if (this.searchPerformed) {
                this.gridOptions.api.purgeInfiniteCache();
            } else {
                this.gridOptions.api.setDatasource(this);
                this.searchPerformed = true;
            }
        }
    }

    async exportResults(): Promise<void> {
        this.generatingFile = true;

        const sortModel = this.gridOptions.columnApi
            .getColumnState()
            .filter((columnState) => columnState.sort);
        const sort = {
            sortColumn: null as string,
            sortDirection: null as string,
        };
        if (sortModel && sortModel.length) {
            // we only sort by a single column so just grab the 1st index
            const sortCol = sortModel[0];
            sort.sortColumn = sortCol.colId;
            sort.sortDirection = sortCol.sort;
        }

        const searchQueryDef = {
            entity: this.entity.entityName,
            page: 1,
            size: 5000,
            sortColumn: sort.sortColumn,
            sortDirection: sort.sortDirection,
            filter: this.filter
        };

        const results: any = await this.searchService.getEntitiesBySearch(searchQueryDef);
        const headers = [];
        for (const header in results.data[0]) {
            if (results.data[0].hasOwnProperty(header)) {
                headers.push(header);
            }
        }
        const rows = [headers];
        results.data.forEach((result: any) => {
            const row = [];
            for (const val in results.data[0]) {
                if (results.data[0].hasOwnProperty(val)) {
                    row.push(result[val]);
                }
            }
            rows.push(row);
        });
        const exporterCsvFilename = "SearchResults.csv";
        this.csvExporter.download(rows, exporterCsvFilename);
        this.generatingFile = false;
    }

    onNavChange(tabEvent: NgbNavChangeEvent) {
        const tabId = tabEvent.nextId;
        let tabName: string;
        // need to convert from id to event name
        if (tabId === this.SIMPLE_TAB_ID) {
            tabName = 'Simple';
        } else if (tabId === this.ADVANCED_TAB_ID) {
            tabName = 'Advanced';
        }

        if (tabName) {
            this.appInsightsService.trackEvent('search-tab-to-' + tabName);
        }
    }

    sizeToFit() {
        this._makeApiCall(() => {
            this.gridOptions.api.sizeColumnsToFit();
        });
    }

    private _makeApiCall(callFunc: () => void) {
        if (!this.gridOptions.api) {
            return;
        }
        setTimeout(callFunc, 0);
    }
}

/**
 * The purpose of this function is just to create and initialize an array
 * of search entities. This requires many lines of code but doesn't have much
 * in the way of logic.
 * @param translationService
 * @param vocabularyService
 */
function initialSearchEntities(
    translationService: TranslationService,
    vocabularyService: VocabularyService,
): any[] {
    const searchEntities: any[] = [
        {
            entityName: 'Animals',
            displayName: 'Animals',
            sortOrder: 'Identifier',
            searchFields: []
        },
        {
            entityName: 'Samples',
            displayName: 'Samples',
            sortOrder: 'Identifier',
            searchFields: []
        },
        {
            entityName: 'Births',
            displayName: 'Births',
            sortOrder: 'BirthID',
            searchFields: [
                {
                    fieldName: 'BirthID',
                    displayName: 'ID',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'MatingID',
                    displayName: 'Mating ID',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'LineName',
                    displayName: translationService.translate('Line'),
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
            ]
        },
        {
            entityName: 'Matings',
            displayName: 'Matings',
            sortOrder: 'MatingID',
            searchFields: [
                {
                    fieldName: 'MatingID',
                    displayName: 'Mating ID',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'HousingID',
                    displayName: 'Housing ID',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'BirthID',
                    displayName: 'Birth ID',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'AnimalName',
                    displayName: 'Animal Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'LineName',
                    displayName: translationService.translate('Line'),
                    selected: false,
                    resultsCount: 0,
                    results: [],
                }
            ]
        },
        {
            entityName: 'Jobs',
            displayName: translationService.translate('Jobs'),
            sortOrder: 'JobID',
            types: [],
            searchFields: []
        },
        {
            entityName: 'Studies',
            displayName: translationService.translate('Studies'),
            sortOrder: 'StudyName',
            types: [],
            searchFields: []
        },
        {
            entityName: 'Tasks',
            displayName: 'Tasks',
            sortOrder: 'WorkflowTask',
            searchFields: [
                {
                    fieldName: 'WorkflowTask',
                    displayName: 'Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'ResourceName',
                    displayName: 'Resource',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'AnimalName',
                    displayName: 'Animal Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'SampleName',
                    displayName: 'Sample Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'Input',
                    displayName: 'Input',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'Output',
                    displayName: 'Output',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                }
            ]
        },
        {
            entityName: 'Locations',
            displayName: 'Locations',
            sortOrder: 'PositionName',
            searchFields: [
                {
                    fieldName: 'PositionName',
                    displayName: 'Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'HousingUnitName',
                    displayName: 'Housing Unit',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'AnimalName',
                    displayName: 'Animal Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'SampleName',
                    displayName: 'Sample Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                }
            ]
        },
        {
            entityName: 'Observations',
            displayName: 'Observations',
            sortOrder: 'AnimalName',
            searchFields: [
                {
                    fieldName: 'ClinicalObservation',
                    displayName: 'Observation',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'AnimalName',
                    displayName: 'Animal Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'AssignedTo',
                    displayName: 'Assigned To',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
                {
                    fieldName: 'LineName',
                    displayName: translationService.translate('Line') + ' Name',
                    selected: false,
                    resultsCount: 0,
                    results: [],
                },
            ]
        },
    ];

    // These four entities have "types" and are subclassed with different sets
    // of characteristic data depending on type because the characteristics
    // are pivoted into columsn and each type has own set of columns, the
    // search fields are split out by type
    vocabularyService.getCV('cv_Taxons', 'CommonName').then((data: any) => {
        const currEntity = searchEntities[0];
        data.forEach((type: any) => {
            currEntity.searchFields.push({
                fieldName: 'Identifier',
                typeName: 'TaxonKey',
                typeValue: type.C_Taxon_key,
                displayName: '(' + type.CommonName + ') ID',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'ExternalIdentifier',
                typeName: 'TaxonKey',
                typeValue: type.C_Taxon_key,
                displayName: '(' + type.CommonName + ') External ID',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'AnimalName',
                typeName: 'TaxonKey',
                typeValue: type.C_Taxon_key,
                fieldValue: type.C_Taxon_key,
                displayName: '(' + type.CommonName + ') Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'LineName',
                typeName: 'TaxonKey',
                typeValue: type.C_Taxon_key,
                fieldValue: type.C_Taxon_key,
                displayName: '(' + type.CommonName + ') Line',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'JobID',
                typeName: 'TaxonKey',
                typeValue: type.C_Taxon_key,
                fieldValue: type.C_Taxon_key,
                displayName: '(' + type.CommonName + ') Job',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Characteristic',
                typeName: 'TaxonKey',
                typeValue: type.C_Taxon_key,
                fieldValue: type.C_Taxon_key,
                displayName: '(' + type.CommonName + ') Characteristic',
                selected: false,
                resultsCount: 0,
                results: [],
            });
        });
    });
    vocabularyService.getCV('cv_SampleTypes', 'SampleType').then((data: any) => {
        const currEntity = searchEntities[1];
        data.forEach((type: any) => {
            currEntity.searchFields.push({
                fieldName: 'Identifier',
                typeName: 'SampleTypeKey',
                typeValue: type.C_SampleType_key,
                displayName: '(' + type.SampleType + ') ID',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'SampleName',
                typeName: 'SampleTypeKey',
                typeValue: type.C_SampleType_key,
                displayName: '(' + type.SampleType + ') Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'LineName',
                typeName: 'SampleTypeKey',
                typeValue: type.C_SampleType_key,
                displayName: '(' + type.SampleType + ') Line',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'JobID',
                typeName: 'SampleTypeKey',
                typeValue: type.C_SampleType_key,
                displayName: '(' + type.SampleType + ') Job',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Characteristic',
                typeName: 'SampleTypeKey',
                typeValue: type.C_SampleType_key,
                displayName: '(' + type.SampleType + ') Characteristic',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'SourceName',
                typeName: 'SampleTypeKey',
                typeValue: type.C_SampleType_key,
                displayName: '(' + type.SampleType + ') Source',
                selected: false,
                resultsCount: 0,
                results: [],
            });
        });
    });
    vocabularyService.getCV('cv_JobTypes', 'JobType').then((data: any) => {
        const currEntity = searchEntities[4];
        data.forEach((type: any) => {
            currEntity.searchFields.push({
                fieldName: 'JobID',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName:
                    '(' + type.JobType + ') ' +
                    translationService.translate('Job') + ' Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'WorkflowTask',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Task Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Title',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Title',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Description',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Description',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'AnimalName',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Animal Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'SampleName',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Sample Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'JobType',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName:
                    '(' + type.JobType + ')' +
                    translationService.translate('Job') + ' Type',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Characteristic',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Characteristic',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Input',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Input',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Output',
                typeName: 'JobTypeKey',
                typeValue: type.C_JobType_key,
                displayName: '(' + type.JobType + ') Output',
                selected: false,
                resultsCount: 0,
                results: [],
            });
        });
    });
    vocabularyService.getCV('cv_StudyTypes', 'StudyType').then((data: any) => {
        const currEntity = searchEntities[5];
        data.forEach((type: any) => {
            currEntity.searchFields.push({
                fieldName: 'StudyName',
                typeName: 'StudyTypeKey',
                typeValue: type.C_StudyType_key,
                displayName:
                    '(' + type.StudyType + ') ' +
                    translationService.translate('Study') + ' Name',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Description',
                typeName: 'StudyTypeKey',
                typeValue: type.C_StudyType_key,
                displayName: '(' + type.StudyType + ') Description',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'LineName',
                typeName: 'StudyTypeKey',
                typeValue: type.C_StudyType_key,
                displayName: '(' + type.StudyType + ') Line',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'StudyType',
                typeName: 'StudyTypeKey',
                typeValue: type.C_StudyType_key,
                displayName:
                    '(' + type.StudyType + '' +
                    translationService.translate('Job') + ' Type',
                selected: false,
                resultsCount: 0,
                results: [],
            });
            currEntity.searchFields.push({
                fieldName: 'Characteristic',
                typeName: 'StudyTypeKey',
                typeValue: type.C_StudyType_key,
                displayName: '(' + type.StudyType + ') Characteristic',
                selected: false,
                resultsCount: 0,
                results: [],
            });
        });
    });

    return searchEntities;
}
