import { ConfirmService } from '@common/confirm';
import { DialogService } from '@common/dialog/deprecated';
import { DialogService as NewDialogService } from '@common/dialog/dialog.service';
import {
    JobPharmaSamplesGroupsTableComponent
} from './tables';
import { CreateSampleGroupsModalService } from './modals';
import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    TemplateRef,
    ViewChildren,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { NgbNavChangeEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IValidatable, OnSaveSuccessful, SaveChangesService } from '@services/save-changes.service';
import { EnumerationService } from '../../enumerations/enumeration.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { LineService } from '../../lines/line.service';
import { WebApiService } from '@services/web-api.service';
import { AuthService } from '@services/auth.service';
import { CurrentWorkgroupService } from '@services/current-workgroup.service';
import { FacetLoadingStateService } from '@common/facet';
import { LoggingService } from '@services/logging.service';
import { ExportJobDetailService } from '../export-job-detail.service';
import { ViewJobAuditReportComponentService } from '../view-job-audit-report-component.service';
import { DataType } from '../../data-type';

import {
    BaseDetail,
    BaseDetailService,
    FacetView,
    IFacet,
    PageState
} from '@common/facet';
import {
    TableSort
} from '@common/models';
import {
    randomId,
    testBreezeIsNew,
    uniqueArray,
    uniqueArrayOnProperty,
    uniqueArrayFromPropertyPath,
    getSafeProp,
    daysSinceAsString,
    empty,
} from '@common/util';

import { EntityChangeService } from '../../entity-changes/entity-change.service';
import { NamingService } from '@services/naming.service';
import { PrivilegeService } from '@services/privilege.service';

import { JobLogicService } from '../job-logic.service';
import { JobService } from '../job.service';
import { JobVocabService } from '../job-vocab.service';
import { JobPharmaDetailService } from './services/job-pharma-detail.service';
import { OrderService } from '../../orders/order.service';
import { DotmaticsService } from '../../dotmatics/dotmatics.service';
import { SaveRecordsOverlayEvent } from './tables';
import { ReportingService } from '../../reporting/reporting.service';

import { FeatureFlagService } from '@services/feature-flags.service';
import { ConfirmModalComponent } from '@common/confirm';
import { SettingService } from '../../settings/setting.service';
import { TranslationService } from '@services/translation.service';
import { UserService } from '../../user/user.service';
import { IFacetSetting } from '../../settings/facet-settings/facet-settings.interface';
import {
    cv_Compliance,
    cv_IACUCProtocol,
    cv_JobReport,
    cv_JobStatus,
    cv_JobType,
    cv_StandardPhrase,
    cv_JobSubtype,
    Entity,
    Job,
    Order,
    Site,
    Study,
    ExtendedJob,
    Cohort,
    TaskInstance,
    Material, CohortMaterial, SampleGroup,
    cv_SampleType,
    cv_VariablePhraseType,
} from '@common/types';
import { convertValueToLuxon } from '@common/util/date-time-formatting/convert-value-to-luxon';
import { dateControlValidator } from '@common/util/date-control.validator';
import { CharacteristicInputComponent } from 'src/app/characteristics/characteristic-input/characteristic-input.component';
import { validateJobGroupNExisting, validateJobGroupNMax, validateJobGroupsExisting, validateJobGroupsUnique } from '../utils/validators';
import { MAX_DOSING_TABLE_N_AMOUNT } from '../components/job-group-table/job-group-table.component';
import { ClimbNgbDateComponent } from '@common/date/climb-ngb-date/climb-ngb-date.component';
import { SampleVocabService } from '../../samples';
import { isEmpty } from 'lodash';
import { JobPharmaReportTableComponent } from '../reports/job-pharma-report-table.component';
import { pencil } from '@common/icons';
import { VariablePhraseModalComponent } from './modals/variable-phrase-modal.component';
import { DataManagerService } from '@services/data-manager.service';

interface EntityArrayChangeDetector {
    entityType: string;
    keyName: string;
    lastKeys: string;
    subject: Subject<void>;
}

type FilteredKeys<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];
type GetKeysOfArrays<T> = FilteredKeys<T, unknown[]>;

const pluralize = (count: number, singular: string, plural?: string): string => count === 1 ? singular : plural;

@Component({
    selector: 'job-pharma-detail',
    templateUrl: './job-pharma-detail.component.html',
    styles: [`
        .tool-tip {
            margin-left: auto;
            margin-bottom: -2px;
        }

        .standard-phrase-box {
            margin: 0 0 0 5px;
            padding: 3px 5px;
            max-width: 550px;
            min-width: 84px;
            border: 1px solid #ddd;
            background-color: #eeeeee;
            box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
        }

        .standard-phrase-row {
            display: flex;
        }

        .standard-phrase-button {
            height:25px;
            width:25px;
            margin:1px 0 0 15px;
            padding:0;
            float:left;
        }
    `]
})
export class JobPharmaDetailComponent extends BaseDetail
    implements AfterViewInit, OnInit, OnChanges, OnDestroy, IValidatable, OnSaveSuccessful {
    @Input() facet: IFacet;
    @Input() facetView: FacetView;
    @Input() job: Entity<ExtendedJob>;
    @Input() isCRO: boolean;
    @Input() isGLP: boolean;
    @Input() jobDetailsOrder: any;
    @Input() pageState: PageState;

    @Output() exit: EventEmitter<void> = new EventEmitter<void>();
    @Output() next: EventEmitter<void> = new EventEmitter<void>();
    @Output() previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() modelCopy: EventEmitter<any> = new EventEmitter<any>();

    loading = false;
    private loadingState: boolean[] = [];
    loadingMessage: string = null;
    facetLoadingState: FacetLoadingStateService;
    loggingService: LoggingService;

    @ViewChildren('characteristicInput') characteristicInputs: CharacteristicInputComponent[];
    @ViewChildren('dateControl') dateControls: NgModel[];
    @ViewChild('jobForm') jobForm: NgForm;

    @ViewChild("samplesGroupsTable")
        samplesGroupsTable: JobPharmaSamplesGroupsTableComponent;
    
    @ViewChild("jobPharmaReportsTable") jobPharmaReportsTable: JobPharmaReportTableComponent;
    readwrite: boolean;
    readonly: boolean;
    isStudyDirector = false;
    jobNamingActive = false;
    sampleNamingActive = false;
    originalJobName = '';
    jobPrefixField: string;
    isCodeFieldRequired: boolean;
    isLineFieldRequired: boolean;
    isIACUCProtocolFieldRequired: boolean;
    isComplianceFieldRequired: boolean;
    disableDuration: boolean;
    isCopyed = false;
    // Dotmatics workgroup flag
    isDotmatics: boolean;
    sendingJobReport = false;
    isJobDetailsLoaded = false;
    isJobResolved = false;
    isJobStandardPhrasesLoaded = false;
    isJobVariablePhrasesLoaded = false;

    isCRL = false;

    sendingStudyToProduction = false;

    // selected Individual Sample rows
    selectedISRows: any[] = [];

    // CVs
    jobStatuses: Entity<cv_JobStatus>[] = [];
    jobTypes: Entity<cv_JobType>[] = [];
    jobSubtypes: Entity<cv_JobSubtype>[] = [];
    iacucProtocols: Entity<cv_IACUCProtocol>[] = [];
    compliances: Entity<cv_Compliance>[] = [];
    jobReports: Entity<cv_JobReport>[] = [];
    orders: Entity<Order>[] = [];
    standardPhrases: Entity<cv_StandardPhrase>[] = [];
    variablePhrasesTypes: cv_VariablePhraseType[];
    studies: Entity<Study>[] = [];

    sites: Entity<Site>[] = [];
    sampleTypes: Entity<cv_SampleType>[] = [];
    dotmaticsTestArticles: any[];

    // Table sorting
    animalTableSort: TableSort = new TableSort();
    cohortTableSort: TableSort = new TableSort();
    sampleGroupTableSort: TableSort = new TableSort();
    sampleTableSort: TableSort = new TableSort();
    taskTableSort: TableSort = new TableSort();

    domIdAddition: string;
    detailsExpanded = false;

    // Keep track of previous DurationDays value (use when extending ToEnd tasks)
    previousDurationDays: number = null;
    // Keep track of previous DateStarted value (use when scheduling tasks dependent on Study Day)
    previousDateStarted: Date = null;

    // Which tab in each set is currently active
    activeTabs: { [index: string]: string } = {
        tasks: 'list',
        animals: 'cohorts',
        samples: 'groups',
    };

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    requiredFields: string[] = [];

    samplesActiveFields: string[] = [];
    samplesRequiredFields: string[] = [];

    // Tab set expand flags
    numExpanded = 1;
    expand: { [index: string]: boolean } = {
        tasks: true,
        animals: false,
        samples: false,
    };
    // Going to watch for changes to Job-related entity arrays (TaskJob,
    // JobCohort, JobMaterial)
    entityArrayChangeDetectors: {
        [index: string]: EntityArrayChangeDetector;
    } = {};

    // All subscriptions
    subs = new Subscription();
    public icons = { pencil };

    readonly COMPONENT_LOG_TAG = 'job-pharma-detail';
    readonly AMOUNT_ENTITIES_LIMIT = 100;
    readonly SAVING_MESSAGE = 'Saving. It may take a minute or longer to save a large number of records.';

    readonly INFO_TOOLTIP_TASKS = 'Add and schedule protocols and tasks, assign materials and inputs to tasks.';
    readonly INFO_TOOLTIP_ANIMALS = 'All enrolled cohorts and animals available to assign to protocols and tasks. Individual animals may be reordered and renamed.';
    readonly INFO_TOOLTIP_SAMPLES = 'All planned sample groups per task. Individual sample records may be created and sample labels can be printed.';

    constructor(
        private elementRef: ElementRef,
        baseDetailService: BaseDetailService,
        private confirmService: ConfirmService,
        private entityChangeService: EntityChangeService,
        private createSampleGroupsModalService: CreateSampleGroupsModalService,
        private jobLogicService: JobLogicService,
        public jobPharmaDetailService: JobPharmaDetailService,
        private jobService: JobService,
        private jobVocabService: JobVocabService,
        private namingService: NamingService,
        private privilegeService: PrivilegeService,
        public saveChangesService: SaveChangesService,
        private enumerationService: EnumerationService,
        private orderService: OrderService,
        private lineService: LineService,
        private webApiService: WebApiService,
        private authService: AuthService,
        private currentWorkgroupService: CurrentWorkgroupService,
        private viewJobAuditReportComponentService: ViewJobAuditReportComponentService,
        protected modalService: NgbModal,
        private dialogService: DialogService,
        private exportJobDetailService: ExportJobDetailService,
        private dotmaticsService: DotmaticsService,
        private reportingService: ReportingService,
        private featureFlagService: FeatureFlagService,
        private settingService: SettingService,
        private translationService: TranslationService,
        private userService: UserService,
        private sampleVocabService: SampleVocabService,
        private newDialogService: NewDialogService,
        private dataManager: DataManagerService,
    ) {
        super(baseDetailService);
    }

    ngOnInit() {
        this.saveChangesService.registerValidator(this);
        this.subs.add(this.saveChangesService.saveSuccessful$.subscribe(() => {
            this.onSaveSuccessful();
        }));

        this.subs.add(this.saveChangesService.saveResult$.subscribe(() => {
            this.actualizePlaceholderNamesAfterCopying();
        }));


        // Register this facet with the service so column selections can be
        // loaded and saved.
        this.jobPharmaDetailService.registerFacet(this.facet);

        // Generate a random ID to use in the DOM
        this.domIdAddition = randomId();

        this.jobService.getJobPrefixField().then((jobPrefix: string) => {
            this.jobPrefixField = jobPrefix;
        });

        // Initialize the Job etc.
        return this.initialize().then(() => {
            // Start watching for changes
            this.initChangeDetection();
        });
    }

    ngAfterViewInit() {
        // Add jQuery listeners
        this.bindJQuery();
    }

    ngOnChanges(changes: any) {
        if (changes.job) {
            // Show details for new jobs
            if (testBreezeIsNew(this.job)) {
                this.detailsExpanded = true;
            }

            if (this.job && !changes.job.firstChange) {
                if (this.jobForm) {
                    this.jobForm.form.markAsPristine();
                }
                return this.initialize();
            }
        }
    }

    ngOnDestroy() {
        this.saveChangesService.unregisterValidator(this);

        // Clear all the subscriptions
        this.subs.unsubscribe();

        // Remove jQuery listeners
        this.unbindJQuery();
    }

    /**
     * Add jQuery listeners
     */
    bindJQuery() {
        if (!this.elementRef.nativeElement) {
            // Just in case
            return;
        }

        // Horrible hack to disable the wrapper <A> tag in the tab nav.
        // This inteferes with the climb-column-select and could cause the
        // browser to navigate to "/" if the user clicked in the gaps between
        // the column options.
        jQuery(this.elementRef.nativeElement).find(
            '.nav-tabs > li.nav-item:last-child > a.disabled'
        ).attr('href', 'javascript:;');

        /* TODO: I feel this should work
        jQuery(this.elementRef.nativeElement).on(
            'click.JobPharmaDetail',
            '.nav-tabs > li.nav-item:last-child > a.disabled',
            (event: JQuery.Event) => {
                event.preventDefault();
            }
        );
        */

        jQuery(this.elementRef.nativeElement).find('.nav-tabs')
            .on('click', (event: JQuery.TriggeredEvent) => {
                this.onClickTab(event);
            });
    }

    /**
     * Remove jQuery listeners
     */
    unbindJQuery() {
        if (!this.elementRef.nativeElement) {
            // Just in case
            return;
        }

        // Remove all listeners for our namespace.
        jQuery(this.elementRef.nativeElement).off('.JobPharmaDetail');
    }

    /**
     * Start listening for external changes to the Job-related entities.
     */
    initChangeDetection() {

        // Listen for calls to refresh the view
        this.subs.add(
            this.jobPharmaDetailService.tabRefresh$.subscribe((event) => {
                if (event.tabset === 'job' && event.tab === 'main') {
                    this.initJob(true);
                }
            })
        );

        this.entityChangeService.onPropertyChange(
            'Job', 'DurationDays', (entity: any) => {
                if (document.activeElement.id !== 'duration-days') {
                    this.extendJob();
                }
            }
        );

        this.entityChangeService.onPropertyChange(
            'Job', 'DateStarted', (entity: any) => {
                this.updateStudyDayTasks();
            }
        );

        this.entityChangeService.onPropertyChange(
            'Study', 'ApprovalNumber', (entity: any) => {
                if (entity.C_Job_key === this.job.C_Job_key) {
                    this.job.Study = entity;
                }
            }
        );

        // Start listening for changes to those arrays
        this.subs.add(this.entityChangeService.onAnyChange((entityChange: any) => {
            this.onBreezeChange(entityChange);
        }));
    }

    /**
     * Setup change detection for an array of Job-related entities
     *
     * @param entityType Type of the entity in the array
     */
    initEntityArrayChangeDetector(entityType: GetKeysOfArrays<Job>) {
        // Assemble the standard entity key
        const keyName = `C_${entityType}_key`;

        // Get the initial keys
        const lastKeys = this.getEntityArrayKeys(entityType, keyName);

        // This Subject will be poked whereever an entity change for thie
        // entity type occurs.
        const subject = new Subject<void>();

        // Store the info
        this.entityArrayChangeDetectors[entityType] = {
            entityType,
            keyName,
            subject,
            lastKeys,
        };

        // Create an observer to listen for entity changes
        const obs = subject.asObservable().pipe(
            // Only trigger occasionally
            debounceTime(1000),
            filter(() => {
                // Check if the entities in the array have changed
                return this.hasEntityArrayChanged(entityType);
            })
        );

        // When the entity array actually changes, tell the service which will
        // then notify the tables.
        this.subs.add(obs.subscribe(() => {
            this.jobPharmaDetailService.notifyJobArrayChanged(entityType);
        }));
    }

    /**
     * Check if the entity array has changed.
     *
     * For our purposes "changed" means the entity keys have changed.
     *
     * @param entityType Type of the entity in the array
     */
    hasEntityArrayChanged(entityType: GetKeysOfArrays<Job>): boolean {
        const detector = this.entityArrayChangeDetectors[entityType];
        const newKeys = this.getEntityArrayKeys(entityType, detector.keyName);
        if (newKeys === detector.lastKeys) {
            // Still the same
            return false;
        }

        // Remember the new keys
        detector.lastKeys = newKeys;

        // Something changed
        return true;
    }

    /**
     * Build a string of all the keys in the entity array.
     * @param entityType Type of the entity in the array
     * @param keyName Name of the key property
     */
    getEntityArrayKeys(entityType: GetKeysOfArrays<Job>, keyName: string): string {
        const entities = this.job[entityType];
        if (!entities || (entities.length === 0)) {
            // Nothing to do
            return '';
        }

        // Fetch all the keys
        const keys = (entities as unknown[]).map((entity) => entity[keyName]);

        // Sort to ensure a consistent order
        keys.sort();

        // Mash them together for easier comparison
        return keys.join(';');
    }

    /**
     * Check for Job-related Breeze changes
     */
    onBreezeChange(entityChange: any) {
        this.onEntityArrayChange(entityChange);
    }

    /**
     * Check if the changed entity is part of one of the array attached to the current Job.
     */
    onEntityArrayChange(entityChange: any) {
        if (!entityChange.entity || !entityChange.entity.entityType) {
            // Just in case
            return;
        }

        const entityType = entityChange.entity.entityType.shortName;

        const detector = this.entityArrayChangeDetectors[entityType];
        if (!detector) {
            // Not an entity type that is being watched
            return;
        }

        const entity = entityChange.entity;
        if (entity.C_Job_key !== this.job.C_Job_key) {
            // Not the current Job
            return;
        }

        // Let the detector check for changes.
        detector.subject.next();
    }

    async initialize(): Promise<void> {
        this.isJobStandardPhrasesLoaded = false;
        this.isJobVariablePhrasesLoaded = false;
        this.setPrivileges();
        // set feature flags for workgroup
        this.setFeatureFlags();
        try {
            const p1 = this.getStudyAdminStatus();
            const p2 =
                this.settingService.getFacetSettingsByType('job', this.isCRO, this.isGLP, this.isCRL)
                    .then(facetSettingsForJob => {
                        this.activeFields = this.settingService.getActiveFields(facetSettingsForJob);
                        this.requiredFields = this.settingService.getRequiredFields(facetSettingsForJob);
                    });

            const p3 = this.getStudyDirectorField();

            const p4 = this.settingService.getFacetSettingsByType('sample')
                .then(facetSettingsForSamples => {
                    this.samplesActiveFields = this.settingService.getActiveFieldValues(facetSettingsForSamples);
                    this.samplesRequiredFields = this.settingService.getRequiredFields(facetSettingsForSamples);
                })

            const p5 = this.getCVs();
            const p6 = this.isSampleNamingActive();
            const p7 = this.isNamingActive();
            const p8 = this.jobPharmaDetailService
                .loadJobStandardPhrases(this.job.C_Job_key)
                .then(() => {
                    this.isJobStandardPhrasesLoaded = true;
                });
            const p9 = this.jobPharmaDetailService
                .loadJobVariablePhrases(this.job.C_Job_key)
                .then(() => {
                    this.isJobVariablePhrasesLoaded = true;
                });
            
            this.getSites(this.job.C_Institution_key);
            await Promise.all([
                p1,
                p2,
                p3,
                p4,
                p5,
                p6,
                p7,
                p8,
                p9
            ]);
            if (this.isDotmatics) {
                await this.getDotmaticsTestArticles();
            }
        } catch (error) {
            // try to parse out the error in JSON format, otherwise log the error to console.
            try {
                const errorObj = JSON.parse(error.toString());
                this.loggingService.logError(errorObj.Message, errorObj, this.COMPONENT_LOG_TAG);
            } catch {
                console.error(error);
            }
            // we'll want to continue initializing the job regardless.
            await Promise.resolve();
        }

        await this.initJob();
        this.isJobDetailsLoaded = true;
        this.isJobResolved = true;

        if (this.isDotmatics && this.job.JobMaterial.length > 0) {
            await this.syncDotmaticsJobAnimals();
        }

    }

    private getStudyDirectorField(): Promise<void> {
        if (this.isGLP || this.isCRO) {
            return this.settingService.getFacetSettingsByTypeAndField('job', 'StudyDirector').then((results: IFacetSetting[]) => {
                const facetSetting = results[0];
                if (facetSetting.IsActive) {
                    this.activeFields.push('Job Director');
                }
                if (facetSetting.IsRequired) {
                    this.requiredFields.push('StudyDirector');
                }
            });
        }
        return Promise.resolve();
    }

    /**
     * If the workgroup has the IsGLP OR IsCRO feature flags enabled, add the study director field to the active/required fields.
     * This is the first instance (as of January 26th, 2023) of a field needing to show OR logic rather than AND logic, which is what the settingsService does.
     * If more instances such as this come up, the database schema responsible for FacetSettings should be refactored to be more flexible.
     */
  /*  private addStudyDirectorField() {
        // first, remove the initial Facet Setting from the row.
        let studyDirectorActiveIndex = this.activeFields.findIndex((fs: string) => fs === "Study Director");
        let studyDirectorRequiredIndex = this.requiredFields.findIndex((fs: string) => fs === "Study Director");

        if (studyDirectorActiveIndex > 0) {
            this.activeFields.splice(studyDirectorActiveIndex, 1);
        }
        if (studyDirectorRequiredIndex > 0) {
            this.requiredFields.splice(studyDirectorRequiredIndex, 1);
        }
        // then, if isGLP or isCRO is enabled
        if (this.isGLP || this.isCRO) {
            // and the field is present in the active/required arrays before
            if (studyDirectorActiveIndex > 0) {
                this.activeFields.push("Study Director");
            }

            if (studyDirectorRequiredIndex > 0) {
                this.requiredFields.push("Study Director");
            }
        }
    } */

    private getCVs(): Promise<void> {
        this.jobVocabService.jobTypes$.subscribe((jobTypes) => {
            this.jobTypes = jobTypes;
        });

        // Bug 23028: Job Subtype selector doesn't cause Breeze to cache cv_JobSubtype entities.
        // It makes autonaming validation fail when Subtype is used to make a new Job name.
        this.jobVocabService.jobSubtypes$.subscribe((jobSubtypes) => {
            this.jobSubtypes = jobSubtypes;
        });

        this.jobVocabService.jobStatuses$.subscribe((jobStatuses) => {
            this.jobStatuses = jobStatuses;
        });

        this.jobVocabService.iacucProtocols$.subscribe((iacucProtocols) => {
            this.iacucProtocols = iacucProtocols;
        });

        this.jobVocabService.compliances$.subscribe((compliances) => {
            this.compliances = compliances;
        });

        this.jobVocabService.jobReports$.subscribe((jobReports) => {
            this.jobReports = jobReports;
        });

        this.jobVocabService.orders$.subscribe((orders) => {
            this.orders = orders;
        });

        this.jobVocabService.studies$.subscribe((studies) => {
            this.studies = studies;
        });

        this.jobVocabService.getStandardPhrasesWithCategory().then((standardPhrases) => {
            this.standardPhrases = standardPhrases;
        });

        this.jobVocabService.cv_VariablePhraseTypes$.subscribe((variablePhrasesTypes: cv_VariablePhraseType[]) => {
            this.variablePhrasesTypes = variablePhrasesTypes;
        });

        this.sampleVocabService.sampleTypes$.subscribe((sampleTypes: Entity<cv_SampleType>[]) => {
            this.sampleTypes = sampleTypes;
        });
        return Promise.resolve();
    }

    private async isNamingActive(): Promise<void> {
        this.jobNamingActive = await this.namingService.isJobNamingActive();
        if (this.jobNamingActive) {
            this.setAutoNamingRequiredFields();
        }
    }

    private isSampleNamingActive(): Promise<void> {
        return this.namingService.isSampleNamingActive().then((active: boolean) => {
            this.sampleNamingActive = active;
        });
    }

    /**
     * Sets privislege variables.
     */
    private setPrivileges() {
        this.readonly = this.privilegeService.readonly;
        this.readwrite = this.privilegeService.readwrite;
    }

    private setAutoNamingRequiredFields() {
        this.isCodeFieldRequired = this.jobPrefixField === 'Code';
        this.isLineFieldRequired = this.jobPrefixField === 'Line';
        this.isIACUCProtocolFieldRequired = this.jobPrefixField === 'IACUC Protocol';
        this.isComplianceFieldRequired = this.jobPrefixField === 'Compliance';
    }

    /**
     * Sets workgroup feature flags necessary for jobs
     */
    private setFeatureFlags() {
        // Sets isDotmatics flag
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();

        // isCRL feature flag
        const isCRLFlag = this.featureFlagService.getFlag("IsCRL");
        this.isCRL = (isCRLFlag && isCRLFlag.IsActive && isCRLFlag.Value.toLowerCase() === "true");
    }

    /**
     * Handle changes to the tabset expand flags
     */
    onExpandChange() {
        // How many are expanded?
        this.numExpanded =
            (this.expand.tasks ? 1 : 0) +
            (this.expand.animals ? 1 : 0) +
            (this.expand.samples ? 1 : 0);
    }

    private async initJob(loadTasks = false): Promise<Entity<ExtendedJob> | void> {
        if (this.job && this.job.C_Job_key > 0) {
            this.setInitialDuration();
            this.originalJobName = this.job.JobID;
            await this.jobService.getJobPharmaDetails(this.job.C_Job_key, loadTasks);
            await this.initJobCharacteristics();
            this.setUsedTreatments();
            this.setUsedProtocols();
        }
        return Promise.resolve(this.job);
    }

    getStudyAdminStatus(): Promise<void> {
        if (this.isGLP) {
            if (!this.job.StudyDirector) {
                return this.userService.getThisWorkgroupUser().then((workgroupUser: any) => {
                    this.isStudyDirector = workgroupUser.StudyAdministrator;
                });
            }
            if (this.job.StudyDirector === this.authService.getCurrentUserName()) {
                return this.userService.getThisWorkgroupUser().then((workgroupUser: any) => {
                    this.isStudyDirector = workgroupUser.StudyAdministrator;
                });
            } else {
                this.isStudyDirector = false;
                return Promise.resolve();
            }
        } else {
            return this.privilegeService.getCurrentUserStudyAdministratorStudies().then((studyAdminStudies: any[]) => {
                if (this.job.C_Study_key !== null) {
                    this.isStudyDirector = studyAdminStudies.find((x) => x.C_Study_key === this.job.C_Study_key) != null;
                } else {
                    this.isStudyDirector = studyAdminStudies.length > 0;
                }
            });
        }
    }

    onStudyChange() {
        this.getStudyAdminStatus();
    }

    // Locking
    onJobLockChange() {
        this.jobPharmaDetailService.changeJobLock(this.job);
    }

    initJobCharacteristics(): Promise<any> {
        return this.jobService.getJobCharacteristics(
            this.job.C_Job_key
        ).then(() => {
            return this.jobLogicService.attachCharacteristicEnumerations(
                this.job.JobCharacteristicInstance
            );
        });
    }

    onCancel() {
        this.jobService.cancelJob(this.job);
    }

    updateJobTypeCharacteristics(): Promise<any> {
        this.setBusy(true);
        // Delete the existing Job Type characteristics
        const numRemainingCharacteristics = this.job.JobCharacteristicInstance.filter((jci: any) => {
            return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') !== 'Job Type';
        }).length;
        while (this.job.JobCharacteristicInstance.length > numRemainingCharacteristics) {
            const jobCharacteristicInstance = this.job.JobCharacteristicInstance.find((jci: any) => {
                return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') === 'Job Type';
            });
            this.jobService.deleteJobCharacteristic(jobCharacteristicInstance);
        }

        // Create and initialize characteristics for the new type
        return this.jobService.createJobTypeCharacteristics(this.job).then((jobCharactersticInstances: any) => {
            this.clearAndInitializeCharacteristicInputs(jobCharactersticInstances);
        }).then(() => {
            return this.jobLogicService.attachCharacteristicEnumerations(
                this.job.JobCharacteristicInstance
            );
        }).finally(() => {
            this.setBusy(false);
        });
    }

    updateJobIacucCharacteristics(): Promise<any> {
        this.setBusy(true);
        // Delete the existing IACUC Protocol characteristics
        const numRemainingCharacteristics = this.job.JobCharacteristicInstance.filter((jci: any) => {
            return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') !== 'IACUC Protocol';
        }).length;

        while (this.job.JobCharacteristicInstance.length > numRemainingCharacteristics) {
            const jobCharacteristicInstance = this.job.JobCharacteristicInstance.find((jci: any) => {
                return getSafeProp(jci, 'JobCharacteristic.cv_JobCharacteristicLinkType.JobCharacteristicLinkType') === 'IACUC Protocol';
            });
            this.jobService.deleteJobCharacteristic(jobCharacteristicInstance);
        }

        // Create and initialize characteristics for the new IACUC Protocol
        return this.jobService.createJobIacucCharacteristics(this.job).then((jobCharactersticInstances: any) => {
            this.clearAndInitializeCharacteristicInputs(jobCharactersticInstances);
        }).then(() => {
            return this.jobLogicService.attachCharacteristicEnumerations(
                this.job.JobCharacteristicInstance
            );
        }).finally(() => {
            this.setBusy(false);
        });
    }

    typeChanged(): Promise<any> {
        if (this.job.C_JobType_key) {
            this.updateJobName('type');
        }
        if (!this.isCRO) {
            return this.updateJobTypeCharacteristics();
        }
        if (this.isCRL) {
            return this.updateStandardPhrases();
        }
    }

    async subtypeChanged(): Promise<any> {
        if (this.job.C_JobSubtype_key) {
            this.updateJobName('subtype');

            if (this.isCRL) {
                return this.updateStandardPhrases().then(() => {
                    return this.updateJobTypeCharacteristics();
                });
            } else if (this.isCRO) {
                return this.updateJobTypeCharacteristics();
            }
        }
    }

    reportChanged(): Promise<any> {
        if (this.isCRL) {
            return this.updateStandardPhrases();
        }
    }

    jobStatusChanged() {
        if (this.job) {
            this.jobService.jobStatusChanged(this.job, true, this.facet.Privilege === 'ReadOnly');
        }
    }

    jobCodeChanged() {
        if (this.job.JobCode) {
            this.updateJobName('code');
        }
    }

    iacucProtocolChanged(): Promise<any> {
        if (this.job.C_IACUCProtocol_key) {
            this.updateJobName('iacuc protocol');
        }
        if (this.isCRL) {
            this.updateStandardPhrases();
        }
        return this.updateJobIacucCharacteristics();
    }

    complianceChanged() {
        if (this.job.C_Compliance_key) {
            this.updateJobName('compliance');
        }
    }

    updateJobName(field: string) {
        // Apply new number only if is an update
        if (this.job.JobID) {
            this.jobService.getJobPrefixField().then((jobPrefixField: string) => {
                if (jobPrefixField.toLowerCase() === field.toLowerCase()) {
                    // Automatically regenerate JobID
                    this.jobService.autoGenerateJobID(this.job).then((newID: string) => {
                        if (newID !== this.job.JobID) {
                            this.job.JobID = newID;
                            if (this.isCRO) {
                                this.jobPharmaDetailService.updatePlaceholderNames(this.job);
                            }
                            // Alert user of automatic change
                            this.loggingService.logWarning(
                                `The Name field has been automatically changed due to changing the ${this.translationService.translate(jobPrefixField)} field.`,
                                null, this.COMPONENT_LOG_TAG, true);
                        }
                    });
                }
            });
        }
    }

    updateStandardPhrases(): Promise<any> {
        const ref = this.modalService.open(ConfirmModalComponent, {
            size: "md"
        });
        const component = ref.componentInstance as ConfirmModalComponent;
        component.options = {
            onlyYes: true,
            yesButtonText: "OK",
            title: "Updating Standard Phrases",
            message: "Currently selected standard phrases will be removed. Default phrases will be automatically applied based on new field selection."
        };

        return this.jobVocabService.getStandardPhrasesWithDefaults().then((standardPhrases: any) => {
            const matchIACUCProtocol = (job: any, phrase: any) =>
                job.C_IACUCProtocol_key && phrase.cv_StandardPhraseIACUCProtocol.find((p: any) => p.C_IACUCProtocol_key === job.C_IACUCProtocol_key);

            if (standardPhrases && standardPhrases.length) {
                // Filter standardPhrases based on imaging & given field (report/subtype/type/IACUC protocol)
                const filteredPhrases = standardPhrases.filter((phrase: any) => {
                    // Filter out inactive phrases
                    if (!phrase.IsActive) {
                        return false;
                    }
                    // Filter out report type phrases that don't match the current JobReportKey (we don't need to consider Type/Subtype for Report type phrases)
                    if (phrase.cv_StandardPhraseCategory.StandardPhraseCategory.toLowerCase() === 'report') {
                        if (this.isCRL && matchIACUCProtocol(this.job, phrase)) {
                            return true;
                        }
                        return this.job.C_JobReport_key && this.job.C_JobReport_key === phrase.C_JobReport_key;
                    }
                    // Filter out imaging phrases if this.job.Imaging is false. Otherwise, continue to check JobType/JobSubtype
                    if (phrase.cv_StandardPhraseCategory.StandardPhraseCategory.toLowerCase() === 'imaging') {
                        if (this.isCRL && matchIACUCProtocol(this.job, phrase)) {
                            return true;
                        }
                        if (!this.job.Imaging) {
                            return false;
                        }
                    }
                    // Filter out phrases that don't have any default JobTypes, JobSubtypes and IACUC Protocols selected
                    if (phrase.cv_StandardPhraseJobSubtype.length < 1 &&
                        phrase.cv_StandardPhraseJobType.length < 1 &&
                        phrase.cv_StandardPhraseIACUCProtocol.length < 1) {
                        return false;
                    }
                    // Filter based on JobType, JobSubtype and IACUC Protocol
                    return (this.job.C_JobType_key && phrase.cv_StandardPhraseJobType.find((t: any) => t.C_JobType_key === this.job.C_JobType_key))
                        || (this.job.C_JobSubtype_key && phrase.cv_StandardPhraseJobSubtype.find((t: any) => t.C_JobSubtype_key === this.job.C_JobSubtype_key))
                        || matchIACUCProtocol(this.job, phrase);
                });

                // Remove standardPhrases that aren't in filteredPhrases & add ones that are
                this.onSelectStandardPhrase(filteredPhrases);
            }
        });
    }

    async viewOverviewModal(): Promise<void> {
        if (this.job) {
            await this.jobPharmaDetailService.ensureMaterialDataLoaded(this.job);
            await this.jobService.jobShowOverview(this.job, true, this.facet.Privilege === 'ReadOnly');
            this.jobPharmaDetailService.notifyJobArrayChanged('TaskInstance');
        }
    }

    getSites(institutionKey: number) {
        this.orderService.getInstitutionSites(institutionKey).then((data) => {
            this.sites = data;
        });
    }

    institutionChanged(institutionKey: number) {
        this.job.C_Site_key = null;
        this.getSites(institutionKey);
    }

    /**
     * Called when a tab is selected
     *
     * @param tabset Key for the tab set (e.g. 'tasks', 'animals', 'samples')
     * @param $event
     */
    onNavChange(tabset: string, $event: NgbNavChangeEvent) {
        // Parse the tab name from the event: tab-TABSET-TAB-RANDOM
        const tokens = $event.nextId.split(/-/);
        this.activeTabs[tabset] = tokens[2];
    }

    // Allow tabs to be expanded on click
    onClickTab(event: JQuery.TriggeredEvent) {
        const id: string = jQuery(event.target).attr('id');
        if (id) {
            const tokens = id.split(/-/);
            const tabset: string = tokens[1];
            const tab: string = tokens[2];
            if (tab !== 'label') {
                if (tabset === 'tasks') {
                    this.expand.tasks = true;
                } else if (tabset === 'animals') {
                    this.expand.animals = true;
                } else if (tabset === 'samples') {
                    this.expand.samples = true;
                }
                this.onExpandChange();
            }
        }
    }

    /**
     * Handle the "Create Sample Groups" button click
     */
    tabActionCreateSampleGroups() {
        this.createSampleGroups();
    }

    /**
     * Handle the "Create Placeholder Association" button click
     */
    tabActionCreatePlaceholderAssociation() {
        this.createPlaceholderAssociation();
    }

    hasSelectedPlaceholders() {
        // Check for selected placeholders
        const selectedPlaceholders = this.getSelectedPlaceholders();
        return selectedPlaceholders && selectedPlaceholders.length > 0;
    }

    /**
     * Present a modal to assign cohorts to placeholders
     */
    private async createPlaceholderAssociation(): Promise<void> {
        // Check for selected placeholders
        const selectedPlaceholders = this.getSelectedPlaceholders();
        if (!selectedPlaceholders || (selectedPlaceholders.length === 0)) {
            // Nothing to do
            return Promise.resolve();
        }
        
        try {
            // Show the modal
            const create = await this.createSampleGroupsModalService.openCreatePlaceholderAssociationsModal(selectedPlaceholders, this.job);
            if (!create) {
                return Promise.resolve();
            }
    
            this.setBusy(true);
            this.loadingMessage = "Creating Placeholder Associations";
    
            for (const placeholder of create) {
                if (placeholder.cohort !== "null") {
                    // assign jobCohort to placeholder
                    const placeholderIndex = this.job.Placeholder.findIndex((plc: any) => plc.C_Placeholder_key === placeholder.C_Placeholder_key);
                    this.job.Placeholder[placeholderIndex].C_JobCohort_key = parseInt(placeholder.cohort, 10);
    
                    // assign animals from the cohort of job cohort to animal placeholders of the placeholder
                    const jobCohortIndex = this.job.JobCohort.findIndex((jc: any) => jc.C_JobCohort_key === parseInt(placeholder.cohort, 10));
                    const animals = this.job.JobCohort[jobCohortIndex].Cohort.CohortMaterial;
                    for (let i = 0; i < this.job.Placeholder[placeholderIndex].JobGroup.AnimalPlaceholder.length; i++) {
                        if (animals.length > i) {
                            const animalPlaceholder = this.job.Placeholder[placeholderIndex].JobGroup.AnimalPlaceholder[i];
                            animalPlaceholder.C_Material_key = animals[i].C_Material_key;
                            for (const taskPlaceholder of animalPlaceholder.TaskPlaceholder) {
                                if (taskPlaceholder.TaskInstance && animalPlaceholder.Material.Animal) {
                                    await this.jobPharmaDetailService.legacyAddAnimalsToTask(this.job, taskPlaceholder.TaskInstance, [animalPlaceholder.Material.Animal], false, true)
                                }
                            }
                        } else {
                            break;
                        }
                    }
                }
            }
                
            this.jobPharmaDetailService.tabRefresh('animals', 'placeholders');
            // Update taskinputs for cohorts already assigned to tasks with DosingTable type inputs
            const filteredPlaceholders = create.filter((placeholder: any) => placeholder.cohort != null);
            // add placeholders to cohort tasks if placeholders arent already assigned to the tasks. craete placeholder inputs.
            await this.jobPharmaDetailService.updateTaskCohortInputsFromPlaceholders(filteredPlaceholders);
            await this.findProtocolsAndAssignCohorts(filteredPlaceholders);
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, true);
        } catch (err) {
            console.error(err);
        } finally {
            this.setBusy(false);
            this.loadingMessage = "";
        }
    }

    /**
     * Present a modal to configure new SampleGroups to be added to the
     * selected tasks.
     */
    private async createSampleGroups(): Promise<void> {
        // Check for selected tasks
        const selectedTasks = this.getSelectedGroupTasks();
        if (!selectedTasks || (selectedTasks.length === 0)) {
            // Nothing to do
            return;
        }

        const hasEndStateTask = selectedTasks.some(tasks => tasks.MemberTaskInstance.some(ti => ti.cv_TaskStatus?.IsEndState));
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag() && hasEndStateTask) {
            this.loggingService.logError("You cannot add sample groups to tasks with end state statuses.", null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        try {
            // Show the modal
            const create = await this.createSampleGroupsModalService.openCreateSampleGroupsModal(selectedTasks);
            if (!create) {
                return;
            }
            this.setBusy(true);
            this.loadingMessage = "Creating Sample Groups";

            await this.jobPharmaDetailService.createSampleGroups(create, this.job);

            // Refresh the sample individuals table
            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
            this.jobPharmaDetailService.tabRefresh('tasks', 'inputs');
            this.jobPharmaDetailService.tabRefresh('samples', 'groups');
            this.jobPharmaDetailService.tabRefresh('samples', 'individuals');
        } finally {
            this.setBusy(false);
            this.loadingMessage = '';
        }
    }

    async tabActionCreateIndividualSamples() {
        const selectedGroups = this.getSelectedSampleGroups();
        if (!selectedGroups) {
            return;
        }

        const hasEndStateTask = selectedGroups.flatMap(sg => sg.TaskInstance).some(tasks => tasks.MemberTaskInstance.some(ti => ti.cv_TaskStatus?.IsEndState));
        if (this.jobPharmaDetailService.isSampleGroupsEditableFlag() && hasEndStateTask) {
            this.loggingService.logError("You cannot create individal samples from sample groups for tasks with end state statuses.", null, this.COMPONENT_LOG_TAG, true);
            return;
        }

        const creationFields = [
            "C_PreservationMethod_key",
            "DateHarvest",
            "DateExpiration",
            "TimePoint",
            "C_SampleProcessingMethod_key",
            "SendTo",
            "C_SampleAnalysisMethod_key",
            "SpecialInstructions"
        ];

        const filteredRequiredFields = this.samplesRequiredFields.filter((field: string) => creationFields.includes(field));
        if (this.samplesRequiredFields.includes("Material.C_ContainerType_key")) {
            // since the data present is actually a SampleGroup, the required field needs to be formatted slightly differently.
            filteredRequiredFields.push("C_ContainerType_key");
        }
        const errorMessage = await this.settingService.bulkValidate(selectedGroups, filteredRequiredFields, 'sample');
        if (errorMessage) {
            this.loggingService.logError(errorMessage, undefined, 'jobs-pharma', true);
            return;
        }

        let valid = true;
        selectedGroups.forEach((element) => {
            if (!element.C_SampleType_key) {
                this.loggingService.logError("Type is required", "Validation Error",
                    this.COMPONENT_LOG_TAG, true);
                valid = false;
                return;
            }
            if (!element.C_SampleStatus_key) {
                this.loggingService.logError("Status is required", "Validation Error",
                    this.COMPONENT_LOG_TAG, true);
                valid = false;
            }
        });
        if (!valid) {
            return;
        }

        const batches: any = [];
        let numSamplesToCreate = 0;

        numSamplesToCreate = this.jobPharmaDetailService
            .calculateNumberOfSamplesToCreate(selectedGroups);

        if (numSamplesToCreate <= 0) {
            return;
        }
        console.log("Entities to create: ", numSamplesToCreate * 5);

        if (this.job.C_Job_key < 0) {
            console.log("C_Job_key invalid");
            return Promise.resolve();
        }

        if (numSamplesToCreate < this.AMOUNT_ENTITIES_LIMIT) {
            // ORIGINAL CODE
            this.confirmService.confirm({
                title: "",
                message: `Create ${numSamplesToCreate} samples?`,
                yesButtonText: "Yes"
            })
            .then(() => {
                this.setBusy(true);
                return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG)
                    .then(() => {
                        const newSamplesPromises: Promise<any>[] = [];
                        for (const sampleGroup of selectedGroups) {
                            const promise = this.jobPharmaDetailService
                                .createIndividualSamplesFromGroup(sampleGroup)
                                .then((newSamples) => {
                                    batches.push({ sampleGroup, newSamples });
                                });
                            newSamplesPromises.push(promise);
                        }
                        return Promise.all(newSamplesPromises);
                    })
                    .then(() => {
                        // save all changes before adding TaskMaterials
                        return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
                    })
                    .then(() => {
                        return this.jobPharmaDetailService.createAllSampleGroupTaskAssociations(batches);
                    })
                    .then(() => {
                        // Refresh the sample individuals table
                        this.jobPharmaDetailService.tabRefresh('tasks', 'list');
                        this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
                        this.jobPharmaDetailService.tabRefresh('samples', 'groups');
                        this.jobPharmaDetailService.tabRefresh('samples', 'individuals');
                        this.jobPharmaDetailService.notifyJobArrayChanged('JobMaterial');
                    })
                    .then(() => {
                        this.setBusy(false);
                    });
            })
            .catch(() => {
                this.setBusy(false);
            });
        } else {
            // NEW CODE
            return this.dialogService.confirmYesNo({
                yesButtonTitle: 'Continue',
                noButtonTitle: 'Discard Changes',
                title: 'Creating Sample Records',
                bodyText: `
                    Climb will create ${numSamplesToCreate} sample ${pluralize(numSamplesToCreate, 'instance')}. This may take several minutes.
                    Do you wish to save changes and continue?
                `,
            }).then((isContinue: boolean) => {
                if (!isContinue) {
                    return;
                }

                if (this.canSave) {
                    this.setBusy(true);
                    this.loadingMessage = this.SAVING_MESSAGE;

                    return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG)
                        .then(() => {
                            const sampleGroupKeys: any[] = [];
                            $.each(selectedGroups, (element) => {
                                sampleGroupKeys.push(
                                    selectedGroups[element].C_SampleGroup_key
                                );
                            });
                            const requestBody = {
                                CreatedBy: this.authService.getCurrentUserName(),
                                Workgroup: this.currentWorkgroupService.getWorkgroupName(),
                                Job_key: this.job.C_Job_key,
                                SampleGroup_keys: sampleGroupKeys,
                            };
                            return this.saveSamples('api/ApiSamples/SaveSamples', requestBody);
                        }).finally(() => {
                            this.setBusy(false);
                            this.loadingMessage = '';
                        });
                }
            });
        }
    }

    saveSamples(apiUrl: string, requestBody: any): Promise<void> {
        return this.webApiService
            .postApi(apiUrl, requestBody, "application/json")
            .then(() => {
                this.reloadData().then(() => {
                    this.jobPharmaDetailService.tabRefresh('samples', 'groups');
                });
            })
            .catch((error: any) => {
                console.error(error);
            });
    }

    getSelectedPlaceholders(): any[] {
        return this.jobPharmaDetailService.getJobPlaceholders(this.job).filter((placeholder: any) => {
            return placeholder.isSelected && placeholder.C_JobCohort_key === null;
        });
    }

    getSelectedGroupTasks(): TaskInstance[] {
        return this.jobPharmaDetailService.getJobPharmaTaskRows(this.job).filter((task) => {
            return task.isSelected;
        });
    }

    getSelectedSampleGroups(): SampleGroup[] {
        if (!this.samplesGroupsTable) {
            console.warn("Cannot load JobsPharmaSampleGroupsTableComponent to get selected rows");
            return [];
        }
        return this.samplesGroupsTable.getSelectedSampleGroups();
    }

    // <select> formatters
    jobStatusKeyFormatter = (value: any) => {
        return value.C_JobStatus_key;
    }
    jobStatusFormatter = (value: any) => {
        return value.JobStatus;
    }
    jobTypeKeyFormatter = (value: any) => {
        return value.C_JobType_key;
    }
    jobTypeFormatter = (value: any) => {
        return value.JobType;
    }
    iacucProtocolKeyFormatter = (value: any) => {
        return value.C_IACUCProtocol_key;
    }
    iacucProtocolFormatter = (value: any) => {
        return value.IACUCProtocol;
    }
    complianceKeyFormatter = (value: any) => {
        return value.C_Compliance_key;
    }
    complianceFormatter = (value: any) => {
        return value.Compliance;
    }
    jobReportKeyFormatter = (value: any) => {
        return value.C_JobReport_key;
    }
    jobReportFormatter = (value: any) => {
        return value.JobReport;
    }

    async copyJob() {
        await this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG);

        try {
            const fromJob = this.job;

            this.setLoading(true);
            // Create new job
            const newJob = await this.jobService.createJob();
            const toJob = newJob;

            // Copy from current job to a newly created job
            await this.jobService.copyJob(fromJob, toJob, true, this.isCRO);
            // Set the current job to the newly created job
            this.job = toJob;

            // Set derived characteristics and tasks values
            // (this mimics edit job, some of this could be refactored out)
            this.jobLogicService.attachCharacteristicEnumerations(
                this.job.JobCharacteristicInstance
            );

            this.job.TaskJob = toJob.TaskJob;
            for (const taskJob of this.job.TaskJob) {
                this.enumerationService.attachInputEnumerations(
                    taskJob.TaskInstance.TaskInput
                );
            }
            this.isCopyed = true;

            this.modelCopy.emit(this.job);

            this.setLoading(false);
        } catch (error) {
            this.setLoading(false);
            console.error(error);
            this.loggingService.logError("Copy operation failed", null,
                this.COMPONENT_LOG_TAG, true);
        }
    }

    lineChanged() {
        this.updateJobLine();
        if (this.job.C_Line_key) {
            this.lineService.getLine(this.job.C_Line_key, ['cv_Taxon']);
            this.updateJobName('line');
        }
    }

    updateJobLine() {
        if (this.job.JobLine && this.job.JobLine.length > 0) {
            const jobLine = this.job.JobLine[0];
            jobLine.C_Line_key = this.job.C_Line_key;
        } else {
            const initialValues = {
                C_Job_key: this.job.C_Job_key,
                SortOrder: this.job.JobLine.length + 1,
                C_Line_key: this.job.C_Line_key
            };
            this.jobService.createJobLine(initialValues);
        }
    }

    onSelectedRowsChange(selectedIndividualRows: any[]) {
        this.selectedISRows = selectedIndividualRows;
    }
    openLabelModal(labelmodal: TemplateRef<any>) {
        this.modalService.open(labelmodal);
    }

    async reloadData() {
        return this.initialize();
    }

    canSave(): boolean {
        return this.saveChangesService.hasChanges && !this.saveChangesService.saving;
    }

    getJobClientReport() {
        this.reportingService.requestJobClientReportByJobKey(this.isCRO, this.job.C_Job_key);
    }

    getJobSummaryReport() {
        this.reportingService.requestJobSummaryReportByJobKey(this.isCRO, this.job.C_Job_key);
    }

    async exportJob(): Promise<void> {
        let sampleGroups: SampleGroup[] = [];
        for (const taskJob of this.job.TaskJob) {
            const task = taskJob.TaskInstance;
            if (!task.SampleGroup || (task.SampleGroup.length === 0)) {
                continue;
            }
            for (const sampleGroup of task.SampleGroup) {
                sampleGroups.push(
                    sampleGroup
                );
            }
        }
        sampleGroups = uniqueArray(sampleGroups);
        await this.jobPharmaDetailService.ensureMaterialDataLoaded(this.job);

        this.exportJobDetailService.exportJobPharmaToCsv(
            this.isCRL,
            this.isCRO,
            this.isGLP,
            this.job,
            sampleGroups,
            this.animalTableSort,
            this.cohortTableSort,
            this.sampleGroupTableSort,
            this.sampleTableSort,
            this.taskTableSort
        );
    }

    viewAuditReport() {
        this.viewJobAuditReportComponentService.openComponent(this.job.C_Job_key, true, this.isCRL, this.isCRO, this.isGLP);
    }

    async onSendToDotmatics(sync = true) {
        this.setBusy(true);
        try {
            await this.saveChangesService.promptForUnsavedChanges(this.COMPONENT_LOG_TAG);

            if (!this.job.ExternalIdentifier) {
                await this.dotmaticsService.createDotmaticsExperiment(this.job);
            }

            await this._sendJobReport();
            if (sync) {
                await Promise.all([
                    this.syncDotmaticsJobAnimals(),
                    this.syncDotmaticsJobSamples()
                ]);
            }
        } catch(err) {
            throw err;
        } finally {
            this.setBusy(false);
        }
    }

    _sendJobReport() {
        return this.dotmaticsService.sendDotmaticsJobReport(this.job).then((result) => {
            this.setBusy(false);
            this.sendingJobReport = false;

            if (result.data) {
                // If api returns filepath, success
                console.log('saved filepath: ', result.data);
                this.loggingService.logSuccess(
                    "Dotmatics report sent",
                    null, this.COMPONENT_LOG_TAG, true
                );
            } else {
                // Api did not return filepath, and must have failed on dotmatics side
                this.loggingService.logError(
                    "Sync failed. We were unable to reach Dotmatics.",
                    null, this.COMPONENT_LOG_TAG, true
                );
            }
        }).catch((err) => {
            this.setBusy(false);
            this.sendingJobReport = false;
            this.loggingService.logError(
                err,
                null, this.COMPONENT_LOG_TAG, true
            );
        });
    }

    setBusy(state: boolean) {
        const isLoading = this.getLoadingState(state);
        this.saveChangesService.isLocked = isLoading;
        this.loading = isLoading;
        this.facetLoadingState.changeLoadingState(isLoading);
        this.setLoading(isLoading);
    }

    onLoadingChanged(event: SaveRecordsOverlayEvent) {
        const isLoading = this.getLoadingState(event.state);
        this.saveChangesService.isLocked = isLoading;
        this.loading = isLoading;
        this.loadingMessage = event.message;
    }

    private getLoadingState(state: boolean): boolean {
        state ? this.loadingState.push(true) : this.loadingState.pop();
        return this.loadingState.length > 0;
    }

    syncDotmaticsJobAnimals(): Promise<any> {
        return this.dotmaticsService.syncDotmaticsJobAnimals(this.job);
    }

    postDotmaticsJobAnimals(): Promise<any> {
        return this.dotmaticsService.postDotmaticsJobAnimals(this.job);
    }

    syncDotmaticsJobSamples(): Promise<any> {
        return this.dotmaticsService.syncDotmaticsJobSamples(this.job);
    }

    async validate(): Promise<string> {
        const translatedJob = this.translationService.translate('Job');

        // Check that auto-naming field has value
        if (this.jobNamingActive && testBreezeIsNew(this.job)) {
            const invalidField = await this.jobLogicService.validateJobNamingField(this.job);
            if (invalidField) {
                return `The ${this.translationService.translate(invalidField)} field is required for automatic naming.`;
            }
        } else if (empty(this.job.JobID)) {
            return `A ${translatedJob} requires a Name.`;
        }

        // Check that status has value
        if (empty(this.job.C_JobStatus_key) || this.job.C_JobStatus_key === 0) {
            return `A ${translatedJob} requires a Status.`;
        }

        // Check that type has value
        if (empty(this.job.C_JobType_key) || empty(this.job.cv_JobType)) {
            return `A ${translatedJob} requires a Type.`;
        }

        // Check that subtype has value if isCRO
        if (this.isCRO && empty(this.job.C_JobSubtype_key)) {
            if (this.activeFields.includes('Subtype')) {
                return `A ${translatedJob} requires a Subtype.`;
            } else {
                return `Please set a Default value for Vocabularies: ${translatedJob} Subtypes, then reload Climb.`;
            }
        }

        const errMessage = dateControlValidator(this.dateControls)
            || this.samplesGroupsTable?.validate()
            || this.characteristicInputs?.map(input => input.validate()).find(msg => msg);
        if (errMessage) {
            return errMessage;
        }
        if (this.isCRO) {
            const groups = this.job.JobGroup;
            if (!validateJobGroupsExisting(groups)) {
                return 'Dosing Table Group is required.';
            }
            if (!validateJobGroupsUnique(groups)) {
                return 'Dosing Table Groups must be unique.';
            }
            if (!validateJobGroupNExisting(groups)) {
                return 'Dosing Table N is required.';
            }
            // Check that job groups have values for N less than 250 if isCRO
            if (validateJobGroupNMax(groups)) {
                return `Save Failed: Please enter a value for N that is less than or equal to ${MAX_DOSING_TABLE_N_AMOUNT}.`;
            }
        }

        const testArticles = (this.job.JobTestArticle ?? []).filter((item: any) => !item.cv_TestArticle?.IsSystemGenerated);
        const jobTestArticleValid = testArticles.every((item: any) => item.C_TestArticle_key);

        if (!jobTestArticleValid) {
            return `Ensure that all required fields within Test Articles are filled.`;
        }

        const jobInstitutionValid = (this.job.JobInstitution ?? []).every((item: any) => item.C_Institution_key);

        if (!jobInstitutionValid) {
            return `Ensure that all required fields within ${this.translationService.translate('Institutions')} are filled.`;
        }

        const jobLocationValid = (this.job.JobLocation ?? []).every((item: any) => item.LocationPosition);

        if (!jobLocationValid) {
            return `Ensure that all required fields within Locations are filled.`;
        }

        // Validate fields required by facet settings
        return await this.settingService.validateRequiredFields(this.requiredFields, this.job, 'job');
    }

    onSaveSuccessful() {
        this.loggingService.logDebug('Successful save reported in Job Pharma Details', null, this.COMPONENT_LOG_TAG);

        // Sync with Dotmatics after a sucessful save if applicable
        if (this.isDotmatics) {
            const shouldSyncMaterials = this.job.JobMaterial.length > 0;
            this.onSendToDotmatics(shouldSyncMaterials);
        }
    }

    setInitialDuration() {
        if (this.job.DateStarted !== null && this.job.DateEnded !== null) {
            let startMoment = convertValueToLuxon(this.job.DateStarted);
            startMoment = startMoment.startOf('day');
            if (this.job.DateEnded !== null) {
                const endMoment = convertValueToLuxon(this.job.DateEnded);
                this.job.DurationDays = daysSinceAsString(endMoment, startMoment);
            }
        }
        this.previousDateStarted = this.job.DateStarted;
        this.previousDurationDays = this.job.DurationDays as number;
    }

    setUsedTreatments() {
        if (this.job.JobTestArticle !== null) {
            this.job.JobTestArticle.forEach((jobTestArticle: any) => {
                jobTestArticle.UsedInTreatment = false;
                if (this.job.JobGroup) {
                    this.job.JobGroup.forEach((entity: any) => {
                        entity.JobGroupTreatment.forEach((jobGroupTreatment: any) => {
                            if (jobTestArticle.C_JobTestArticle_key === jobGroupTreatment.C_JobTestArticle_key) {
                                jobTestArticle.UsedInTreatment = true;
                            }
                        });
                    });
                }
            });
        }
    }

    setUsedProtocols() {
        this.job.TaskJob
            .filter((taskJob: any) => taskJob.TaskInstance && taskJob.TaskInstance.ProtocolInstance)
            .forEach((taskJob: any) => taskJob.TaskInstance.ProtocolInstance.UsedInProtocol = false);

        this.job.JobGroup.forEach((jobGroup: any) => {
            jobGroup.JobGroupTreatment.forEach((jobGroupTreatment: any) => {

                this.job.TaskJob
                    .filter((taskJob: any) => taskJob.TaskInstance && taskJob.TaskInstance.ProtocolInstance)
                    .map((taskJob: any) => taskJob.TaskInstance.ProtocolInstance)
                    .forEach((protocolInstance: any) => {
                        const protocolInstanceKey = +jobGroupTreatment.C_ProtocolInstance_key;

                        if (protocolInstanceKey === protocolInstance.C_ProtocolInstance_key) {
                            protocolInstance.UsedInProtocol = true;
                        }
                    });
            });
        });
    }

    startDateChanged(dateStarted: ClimbNgbDateComponent) {
        if (this.job.DateStarted === null) {
            this.job.DurationDays = null;
            return;
        }

        let startMoment = convertValueToLuxon(this.job.DateStarted);
        startMoment = startMoment.startOf('day');
        if (this.job.DurationDays !== null && this.job.DurationDays !== '') {
            startMoment = startMoment.plus({ days: this.job.DurationDays as number });
            this.job.DateEnded = startMoment.toJSDate();
        } else if (this.job.DateEnded !== null) {
            const endMoment = convertValueToLuxon(this.job.DateEnded);
            if (this.job.DurationDays === null) {
                // Need previous startMoment before isGLP calculation
                let prevStartMoment = convertValueToLuxon(this.job.DateStarted);
                prevStartMoment = prevStartMoment.startOf('day');
                if (endMoment < prevStartMoment) {
                    this.job.DateStarted = null;
                    setTimeout(() => dateStarted.clear());
                    this.loggingService.logError("Start date cannot be after end date", "Validation Error",
                        this.COMPONENT_LOG_TAG, true);
                    return;
                }
            }
            this.job.DurationDays = daysSinceAsString(endMoment, startMoment);
        }
    }

    durationDaysChanged() {
        if ((this.job.DurationDays === null || this.job.DurationDays === '') && this.job.DateStarted !== null) {
            this.job.DateEnded = null;
        } else if (this.job.DateStarted !== null) {
            let startMoment = convertValueToLuxon(this.job.DateStarted);
            startMoment = startMoment.startOf('day');
            startMoment = startMoment.plus({days: this.job.DurationDays as number});
            this.job.DateEnded = startMoment.toJSDate();
        } else if (this.job.DateEnded !== null && (this.job.DurationDays !== null && this.job.DurationDays !== '')) {
            let endMoment = convertValueToLuxon(this.job.DateEnded);
            endMoment = endMoment.minus({days: this.job.DurationDays as number});
            this.job.DateStarted = endMoment.toJSDate();
        }
    }

    endDateChanged(dateEnded: ClimbNgbDateComponent) {
        if (this.job.DateEnded === null && this.job.DateStarted != null) {
            this.job.DurationDays = null;
            return;
        }
        
        if (this.job.DateStarted !== null) {
            let startMoment = convertValueToLuxon(this.job.DateStarted);
            startMoment = startMoment.startOf('day');
            if (this.job.DateEnded !== null) {
                const endMoment = convertValueToLuxon(this.job.DateEnded);
                // Need previous startMoment before isGLP calculation
                let prevStartMoment = convertValueToLuxon(this.job.DateStarted);
                prevStartMoment = prevStartMoment.startOf('day');
                if (endMoment <= prevStartMoment) {
                    this.job.DateEnded = null;
                    setTimeout(() => dateEnded.clear());
                    this.loggingService.logError("End date cannot precede start date", "Validation Error",
                        this.COMPONENT_LOG_TAG, true);
                    return;
                }
                this.job.DurationDays = daysSinceAsString(endMoment, startMoment);
            }
        } else if (this.job.DateEnded !== null && this.job.DurationDays != null && this.job.DurationDays !== '') {
            let endMoment = convertValueToLuxon(this.job.DateEnded);
            endMoment = endMoment.minus({day: this.job.DurationDays as number});
            this.job.DateStarted = endMoment.toJSDate();
        }
    }

    canViewOverview() {
        if (!this.job.TaskJob || this.job.TaskJob.length === 0) {
            return true;
        }

        // get uncompleted tasks to open modal with
        let tasks = this.job.TaskJob.map((taskJob: any) => {
            return taskJob.TaskInstance;
        });
        // filter out end state tasks
        tasks = tasks.filter((task: any) => {
            return !(task && task.cv_TaskStatus &&
                task.cv_TaskStatus?.IsEndState);
        });

        // show only child tasks
        tasks = tasks.filter((task: any) => {
            return task && (task.C_GroupTaskInstance_key);
        });
        return empty(tasks);
    }

    openStandardPhraseChooser(standardPhraseModal: TemplateRef<any>) {
        this.modalService.open(standardPhraseModal);
    }

    openVariablePhraseChooser(): void {
        this.newDialogService.open(VariablePhraseModalComponent, { data: { job: this.job, variablePhrasesTypes: this.variablePhrasesTypes } });
    }

    onSelectStandardPhrase(selected: any[]) {
        // create new associations based on selections
        this.deleteUnselectedJobStandardPhrases(selected);
        this.createNewJobStandardPhrases(selected);
    }

    /**
     * Delete those observation statuses that are in the current observation
     *   but not in the set of selected observation statuses
     * @param standardPhrases - selected cv_ClinicalObservationStatuses
     */
    deleteUnselectedJobStandardPhrases(standardPhrases: any[]) {
        // find observations missing from selection
        const currentJobStandardPhrases = uniqueArrayFromPropertyPath(
            this.job, 'JobStandardPhrase'
        );
        const missingJobStandardPhrases = currentJobStandardPhrases.filter((current) => {
            return standardPhrases.indexOf(current.cv_StandardPhrase) < 0;
        });
        // delete observations missing from selection
        for (const jobStandardPhrase of missingJobStandardPhrases) {
            this.jobService.deleteJobStandardPhrase(jobStandardPhrase);
        }
    }

    /**
     * Create new observations for those in selected that
     *   are not in current observation
     * @param standardPhrases - selected cv_ClinicalObservationStatuses
     */
    createNewJobStandardPhrases(standardPhrases: any[]) {
        // find standardPhrases in selection missing from current
        const currentJobStandardPhrases = uniqueArrayFromPropertyPath(
            this.job, 'JobStandardPhrase.cv_StandardPhrase'
        );
        const missingStandardPhrases = standardPhrases.filter((selected) => {
            return currentJobStandardPhrases.indexOf(selected) < 0;
        });

        // create jobStandardPhrases missing from current
        for (const standardPhrase of missingStandardPhrases) {
            this.jobService.createJobStandardPhrase(
                {
                    C_Job_key: this.job.C_Job_key,
                    C_StandardPhrase_key: standardPhrase.C_StandardPhrase_key
                }
            );
        }
    }

    /**
     * Find all protocols which have these placeholders and assign the corresponding cohorts to those protocols
     */
    findProtocolsAndAssignCohorts(placeholders: any[]) {
        // start the loading
        this.loading = true;
        // Steps ?
        // Find protocols and tasks with associated placeholders
        const placeholderMap = this.mapProtocolAndTasksToPlaceholders();
        const entities = placeholders.filter((pl: any) => pl.JobCohort !== null && !pl.ignore).map((pl: any) => pl.JobCohort.Cohort);
        const selectedCohortKeys = entities.map((cohort: any) => cohort.C_Cohort_key);
        const selectedPlaceholderKeys = placeholders.map((pl: any) => pl.C_Placeholder_key);
        // calculate total amount of entities to be created and decided if manual is needed or stored procedure
        let protocols: any[] = [];
        // Find the protocols we need to update
        Object.keys(placeholderMap.protocol).forEach((key: any) => {
            if (selectedPlaceholderKeys.includes(parseInt(key, 10))) {
                protocols = [...protocols, ...placeholderMap.protocol[key]];
            }
        });
        protocols = uniqueArrayOnProperty(protocols, 'C_ProtocolInstance_key');
        let tasks: any[] = [];
        // Find the tasks we need to update
        Object.keys(placeholderMap.tasks).forEach((key: any) => {
            if (selectedPlaceholderKeys.includes(parseInt(key, 10))) {
                tasks = [...tasks, ...placeholderMap.tasks[key]];
            }
        });

        tasks = uniqueArrayOnProperty(tasks, 'C_TaskInstance_key');
        const amount = this.getAmounts(protocols, tasks, entities);
        // if manual create in front end
        if (amount.Total <= this.AMOUNT_ENTITIES_LIMIT) {

            const taskInstancesToHandleSampleCreates: TaskInstance[] = [];
            const materialsToHandleSampleCreated: Material[] = [];

            // promises for task and protocol update
            const promises: any = [];

            // For tasks
            tasks.forEach((task: any) => {
                if (task.hasOwnProperty("cohortToAdd")) {
                    promises.push(this.jobPharmaDetailService.addCohortsToTask(this.job as Entity<Job>, task, task.cohortToAdd));
                    taskInstancesToHandleSampleCreates.push(task);
                    materialsToHandleSampleCreated.push(...task.cohortToAdd.flatMap((c: Cohort) => c.CohortMaterial).map((cm: CohortMaterial) => cm.Material));
                }
            });
            // For protocols
            protocols.forEach((protocol: any) => {
                if (protocol.hasOwnProperty("cohortToAdd")) {
                    promises.push(this.jobPharmaDetailService.addCohortsToProtocolInstance(this.job, protocol, protocol.cohortToAdd, false));
                    taskInstancesToHandleSampleCreates.push(...protocol.TaskInstance);
                    materialsToHandleSampleCreated.push(...protocol.cohortToAdd.flatMap((c: Cohort) => c.CohortMaterial).map((cm: CohortMaterial) => cm.Material));
                }
            });

            // wait for all promises to be resolved
            return Promise.all(promises).then(async () => {
                // Remove potential duplicates
                if (this.jobPharmaDetailService.isSampleGroupsEditableFlag()) {
                    const sampleGroups = await this.jobPharmaDetailService.getSampleGroupsWithSamples([...new Set(taskInstancesToHandleSampleCreates)]);
                    const materials = [...new Set(materialsToHandleSampleCreated)]
                    const sources = this.jobPharmaDetailService.getNonSampleSourceMaterials(sampleGroups, materials);
                    if (!isEmpty(sampleGroups) && !isEmpty(sources) && !this.jobPharmaDetailService.sampleGroupsTasksHasEndState(sampleGroups)) {
                        const totalNumberOfSamplesToCreate = this.jobPharmaDetailService.calculateTotalNumberOfSamples(sampleGroups, sources.length);
                        const confirm = await this.jobPharmaDetailService.showCreateSamplesModal(totalNumberOfSamplesToCreate);
                        if (!confirm) {
                            return;
                        }
    
                        await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, true);
                        await this.jobPharmaDetailService.handleSampleCreates(sampleGroups, sources);
                        return;
                    }
                }

                await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, true);
                return;
            });
        } else {
            // For tasks ?
            const taskPromises: any[] = [];
            tasks.forEach((task: any) => {
                if (task.hasOwnProperty("cohortToAdd")) {
                    taskPromises.push(
                        this.jobPharmaDetailService.addCohortsToTask(
                            this.job as Entity<Job>,
                            task, task.cohortToAdd.filter((cohort: any) => selectedCohortKeys.includes(cohort.C_Cohort_key))
                        )
                    );
                }
            });

            Promise.all(taskPromises).then(() => {
                // For protocols ?
                // prepare for store procedure calls
                const statuses: any[] = [];
                const finalProtocols: any[] = [];

                // Check if protocols already have cohorts associated and filter down which needs adding
                protocols.forEach((protocol: any) => {
                    if (protocol.hasOwnProperty("cohortToAdd")) {
                        statuses.push(this.getStatusCohortsToAdd(protocol, protocol.cohortToAdd.filter((cohort: any) => selectedCohortKeys.includes(cohort.C_Cohort_key))));
                    }
                    if (statuses[statuses.length - 1] > -1) {
                        finalProtocols.push(protocol);
                    }
                });
                if (statuses.every((status: any) => status === -1)) {
                    // All selected cohorts are duplicates for all the task in the protocol
                    return this.dialogService.confirmYes({
                        yesButtonTitle: 'Close',
                        title: 'Creating Task Records',
                        bodyText: 'Cohort(s) already been added, and duplicate records will not be created.',
                    }).then(() => undefined); // fix typescript 'boolean | void' issue
                } else {
                    // Some X cohorts need addition to some Y tasks of Z protocols
                    let bodyText = `Climb will create ${amount.Total} task ${pluralize(amount.Total, 'instance')}. This may take several minutes.`;
                    if (!statuses.every((status: any) => status === 1)) {
                        bodyText = `
                            At least one of these task instances already exists and duplicate records won't be created.
                            Climb will create and/or update ${amount.Total} task ${pluralize(amount.Total, 'instance')}. This may take several minutes.
                        `;
                    }
                    bodyText += '\nDo you wish to save changes and continue?';

                    return this.dialogService.confirmYesNo({
                        yesButtonTitle: 'Continue',
                        noButtonTitle: 'Discard Changes',
                        title: 'Creating Task Instances',
                        bodyText,
                    }).then((isContinue: boolean) => {
                        if (!isContinue) {
                            this.dataContext.cancel(true);
                            return;
                        }

                        this.loadingMessage = this.SAVING_MESSAGE;
                        this.setBusy(true);
                        const promises: any[] = [];
                        return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG)
                            .then(() => {
                                finalProtocols.forEach((protocol: any) => {
                                    if (protocol.hasOwnProperty('cohortToAdd')) {
                                        promises.push(this.saveCohorts(protocol, protocol.cohortToAdd.filter((cohort: any) => selectedCohortKeys.includes(cohort.C_Cohort_key))));
                                    }
                                });
                                Promise.all(promises).then(() => {
                                    return this.reloadData();
                                }).then(() => {
                                    this.jobPharmaDetailService.tabRefresh('tasks', 'list');
                                    this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
                                });
                            }).finally(() => {
                                this.loadingMessage = '';
                                this.setBusy(false);
                            });
                    });
                }
            });
        }
    }

    wholeProtocolInstanceHasPlaceholder(protocolInstance: any, taskPlaceholderKey: any): boolean {
        if (!protocolInstance || !protocolInstance.TaskInstance || protocolInstance.TaskInstance.length === 0) {
            return false;
        }
        let sum = 0;
        protocolInstance.TaskInstance.forEach((task: any) => {
            // Does this task has placeholder(s) created for it?
            if (task && task.TaskPlaceholder && task.TaskPlaceholder.length > 0) {
                const placeholders = task.TaskPlaceholder.filter((tp: any) => tp.C_Placeholder_key === taskPlaceholderKey);
                if (placeholders && placeholders.length === 1) {
                    sum += 1;
                }
            }
        });
        return sum === protocolInstance.TaskInstance.length;
    }

    mapProtocolAndTasksToPlaceholders(): any {
        const placeholderToProtocolMap = {};
        const placeholderToTasksMap = {};
        this.job.TaskJob.forEach((tj: any) => {
            // Does this task has placeholder(s) created for it?
            if (tj.TaskInstance && tj.TaskInstance.TaskPlaceholder && tj.TaskInstance.TaskPlaceholder.length > 0) {
                if (tj.TaskInstance.ProtocolInstance) {
                    // If its a protocol task add to protocol Map
                    tj.TaskInstance.TaskPlaceholder.forEach((tp: any) => {
                        if (tp.Placeholder && tp.Placeholder.JobCohort && tp.Placeholder.JobCohort.Cohort) {
                            if (this.wholeProtocolInstanceHasPlaceholder(tj.TaskInstance.ProtocolInstance, tp.C_Placeholder_key)) {
                                if (placeholderToProtocolMap[tp.C_Placeholder_key]) {
                                    // already taken this cohort?
                                    if (tj.TaskInstance.ProtocolInstance.hasOwnProperty("cohortToAdd")) {
                                        const indexCohort = tj.TaskInstance.ProtocolInstance.cohortToAdd
                                            .findIndex((cohort: any) => cohort.C_Cohort_key === tp.Placeholder.JobCohort.Cohort.C_Cohort_key);
                                        if (indexCohort === -1) {
                                            tj.TaskInstance.ProtocolInstance.cohortToAdd.push(tp.Placeholder.JobCohort.Cohort);
                                        }
                                    } else {
                                        tj.TaskInstance.ProtocolInstance.cohortToAdd = [tp.Placeholder.JobCohort.Cohort];
                                    }
                                    // already taken this protocol?
                                    const index = placeholderToProtocolMap[tp.C_Placeholder_key]
                                        .findIndex((protocol: any) => protocol.C_ProtocolInstance_key === tj.TaskInstance.ProtocolInstance.C_ProtocolInstance_key);
                                    if (index === -1) {
                                        placeholderToProtocolMap[tp.C_Placeholder_key].push(tj.TaskInstance.ProtocolInstance);
                                    }
                                } else {
                                    if (tj.TaskInstance.ProtocolInstance.hasOwnProperty("cohortToAdd")) {
                                        const indexCohort = tj.TaskInstance.ProtocolInstance.cohortToAdd
                                            .findIndex((cohort: any) => cohort.C_Cohort_key === tp.Placeholder.JobCohort.Cohort.C_Cohort_key);
                                        if (indexCohort === -1) {
                                            tj.TaskInstance.ProtocolInstance.cohortToAdd.push(tp.Placeholder.JobCohort.Cohort);
                                        }
                                    } else {
                                        tj.TaskInstance.ProtocolInstance.cohortToAdd = [tp.Placeholder.JobCohort.Cohort];
                                    }
                                    placeholderToProtocolMap[tp.C_Placeholder_key] = [tj.TaskInstance.ProtocolInstance];
                                }
                            } else {
                                if (tj.TaskInstance.hasOwnProperty("cohortToAdd")) {
                                    const indexCohort = tj.TaskInstance.cohortToAdd
                                        .findIndex((cohort: any) => cohort.C_Cohort_key === tp.Placeholder.JobCohort.Cohort.C_Cohort_key);
                                    if (indexCohort === -1) {
                                        tj.TaskInstance.cohortToAdd.push(tp.Placeholder.JobCohort.Cohort);
                                    }
                                } else {
                                    tj.TaskInstance.cohortToAdd = [tp.Placeholder.JobCohort.Cohort];
                                }
                                if (placeholderToTasksMap[tp.C_Placeholder_key]) {
                                    placeholderToTasksMap[tp.C_Placeholder_key].push(tj.TaskInstance);
                                } else {
                                    placeholderToTasksMap[tp.C_Placeholder_key] = [tj.TaskInstance];
                                }
                            }
                        }
                    });
                } else {
                    // Add to task map only
                    tj.TaskInstance.TaskPlaceholder.forEach((tp: any) => {
                        if (tp.Placeholder && tp.Placeholder.JobCohort && tp.Placeholder.JobCohort.Cohort) {
                            if (tj.TaskInstance.hasOwnProperty("cohortToAdd")) {
                                const indexCohort = tj.TaskInstance.cohortToAdd
                                    .findIndex((cohort: any) => cohort.C_Cohort_key === tp.Placeholder.JobCohort.Cohort.C_Cohort_key);
                                if (indexCohort === -1) {
                                    tj.TaskInstance.cohortToAdd.push(tp.Placeholder.JobCohort.Cohort);
                                }
                            } else {
                                tj.TaskInstance.cohortToAdd = [tp.Placeholder.JobCohort.Cohort];
                            }

                            if (placeholderToTasksMap[tp.C_Placeholder_key]) {
                                placeholderToTasksMap[tp.C_Placeholder_key].push(tj.TaskInstance);
                            } else {
                                placeholderToTasksMap[tp.C_Placeholder_key] = [tj.TaskInstance];
                            }
                        }
                    });
                }
            }
        });
        return {
            tasks: placeholderToTasksMap,
            protocol: placeholderToProtocolMap
        };
    }

    getAmounts = (protocolInstances: any[], taskInstances: any[], entities: any): any => {
        let amountAnimals = 0;

        let amountTaskInputs = 0;
        $.each(taskInstances, (i) => {
                amountTaskInputs = amountTaskInputs + taskInstances[i].TaskInput.length;
        });
        let amountTaskInstance = 0;
        let total = 0;
        const amountCohorts = entities.length;

        $.each(entities, (element) => {
            amountAnimals = amountAnimals + entities[element].CohortMaterial.length;
        });

        $.each(protocolInstances, (i) => {
            $.each(protocolInstances[i].TaskInstance, (element) => {
                amountTaskInputs = amountTaskInputs +
                    protocolInstances[i].TaskInstance[element].TaskInput.length;
            });
            amountTaskInstance += protocolInstances[i].TaskInstance.length;
            total += amountAnimals * amountTaskInstance * 4 +
                amountAnimals * amountTaskInputs +
                amountCohorts * amountTaskInputs +
                amountTaskInstance * amountCohorts;
        });

        amountTaskInstance += taskInstances.length;
        total += taskInstances.length * amountAnimals * 4;

        return {
            Total: total,
            Animals: amountAnimals,
            Cohorts: amountCohorts,
            TaskInputs: amountTaskInputs,
            TaskInstance: amountTaskInstance
        };
    }

    saveCohorts(protocolInstance: any, entities: any): Promise<any> {
        if (this.job.C_Job_key < 0 || protocolInstance.C_ProtocolInstance_key < 0) {
            return Promise.resolve();
        }
        const cohortsKeys: any[] = [];
        $.each(entities, (element) => {
            cohortsKeys.push(
                entities[element].C_Cohort_key
            );
        });
        const requestBody = {
            CreatedBy: this.authService.getCurrentUserName(),
            Workgroup: this.currentWorkgroupService.getWorkgroupName(),
            Job_key: this.job.C_Job_key,
            Protocol_key: protocolInstance.C_Protocol_key,
            ProtocolInstance_key:
                protocolInstance.C_ProtocolInstance_key,
            Cohorts_keys: cohortsKeys
        };

        return this.webApiService
            .postApi("api/ApiCohortsToProtocol/SaveCohortsToProtocol", requestBody, "application/json")
            .catch((error: any) => {
                console.error(error);
            });
    }

    /**
     * Get status cohorts that has to be added to a protocol
     */
    getStatusCohortsToAdd(protocolInstance: any, cohorts: any[]): any {
        const cohortsoAdd: any[] = [];
        let hasDuplicatedCohort = false;
        let alreadyHasCohort = false;

        for (const cohort of cohorts) {
            alreadyHasCohort = false;
            for (const task of protocolInstance.TaskInstance) {
                const taskCohortKeys = task.TaskCohort.map((taskCohort: any) => {
                    return taskCohort.C_Cohort_key;
                });
                alreadyHasCohort = taskCohortKeys.filter((item: any) => cohort.C_Cohort_key === item).length === 0;
                if (alreadyHasCohort) {
                    cohortsoAdd.push(cohort);
                } else {
                    hasDuplicatedCohort = true;
                }
            }
        }

        if (cohortsoAdd.length === 0) {
            return -1;
        } else {
            if (hasDuplicatedCohort) {
                return 0;
            } else {
                return 1;
            }
        }
    }

    taskDontHaveCohort(task: any, cohort: any) {
        const taskCohortKeys = task.TaskCohort.map((taskCohort: any) => {
            return taskCohort.C_Cohort_key;
        });
        return taskCohortKeys.filter((item: any) => cohort.C_Cohort_key === item).length === 0;
    }

    jobIDUpdated() {
        this.jobPharmaDetailService.updatePlaceholderNames(this.job);
    }

    /**
     * Updates all inputs matching same job characteristic type than the characteristic updated in Characteristics section
     * @param value
     * @param characteristicInstance
     */
    characteristicValueChanged(value: any, characteristicInstance: any) {
        if (!this.isCRO) {
            return;
        }

        if (!characteristicInstance.JobCharacteristic ||
            !characteristicInstance.JobCharacteristic.C_JobCharacteristicType_key) {
            return;
        }

        this.setBusy(true);

        const jobCharacteristicTypeKey = characteristicInstance.JobCharacteristic.C_JobCharacteristicType_key;

        for (const task of this.job.TaskJob) {
            if (!task.IsLocked && (!task.TaskInstance.cv_TaskStatus || !task.TaskInstance.cv_TaskStatus?.IsEndState)) {
                for (const taskInput of task.TaskInstance.TaskInput) {
                    if (taskInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                        taskInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey) {
                        taskInput.InputValue = value;
                    }
                }
                for (const taskCohort of task.TaskInstance.TaskCohort) {
                    for (const taskCohortInput of taskCohort.TaskCohortInput) {
                        if (taskCohortInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                            taskCohortInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey) {
                            taskCohortInput.InputValue = value;
                        }
                    }
                }
                for (const taskPlaceholder of task.TaskInstance.TaskPlaceholder) {
                    for (const taskPlaceholderInput of taskPlaceholder.TaskPlaceholderInput) {
                        if (taskPlaceholderInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                            taskPlaceholderInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey) {
                            taskPlaceholderInput.InputValue = value;
                        }
                    }
                }
            }
        }

        this.setBusy(false);
    }

    clearAndInitializeCharacteristicInputs(characteristicInstances: any[]) {
        this.setBusy(true);

        const typeKeys = characteristicInstances
            .filter((x: any) => x.JobCharacteristic.C_JobCharacteristicType_key)
            .map((x: any) => x.JobCharacteristic.C_JobCharacteristicType_key);

        if (typeKeys.length === 0) {
            // Clear job characteristic inputs in tasks
            for (const task of this.job.TaskJob) {
                if (!task.IsLocked && (!task.TaskInstance.cv_TaskStatus || !task.TaskInstance.cv_TaskStatus?.IsEndState)) {
                    for (const taskInput of task.TaskInstance.TaskInput) {
                        if (taskInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC) {
                            taskInput.InputValue = null;
                        }
                    }
                    for (const taskCohort of task.TaskInstance.TaskCohort) {
                        for (const taskCohortInput of taskCohort.TaskCohortInput) {
                            if (taskCohortInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC) {
                                taskCohortInput.InputValue = null;
                            }
                        }
                    }
                    for (const taskPlaceholder of task.TaskInstance.TaskPlaceholder) {
                        for (const taskPlaceholderInput of taskPlaceholder.TaskPlaceholderInput) {
                            if (taskPlaceholderInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC) {
                                taskPlaceholderInput.InputValue = null;
                            }
                        }
                    }
                }
            }
        }

        if (typeKeys.length > 0) {
            // Assign default values to job characteristic inputs
            for (const characteristicInstance of characteristicInstances) {
                if (!characteristicInstance.JobCharacteristic.C_JobCharacteristicType_key) {
                    continue;
                }
                const jobCharacteristicTypeKey = characteristicInstance.JobCharacteristic.C_JobCharacteristicType_key;
                const value = characteristicInstance.DefaultValue;

                for (const task of this.job.TaskJob) {
                    if (!task.IsLocked && (!task.TaskInstance.cv_TaskStatus || !task.TaskInstance.cv_TaskStatus?.IsEndState)) {
                        for (const taskInput of task.TaskInstance.TaskInput) {
                            if (taskInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                                taskInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey) {
                                taskInput.InputValue = value;
                            } else if (taskInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                                typeKeys.indexOf(taskInput.Input.C_JobCharacteristicType_key) < 0) {
                                taskInput.InputValue = null;
                            }
                        }
                        for (const taskCohort of task.TaskInstance.TaskCohort) {
                            for (const taskCohortInput of taskCohort.TaskCohortInput) {
                                if (taskCohortInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                                    taskCohortInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey) {
                                    taskCohortInput.InputValue = value;
                                } else if (taskCohortInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                                    typeKeys.indexOf(taskCohortInput.Input.C_JobCharacteristicType_key) < 0) {
                                    taskCohortInput.InputValue = null;
                                }
                            }
                        }
                        for (const taskPlaceholder of task.TaskInstance.TaskPlaceholder) {
                            for (const taskPlaceholderInput of taskPlaceholder.TaskPlaceholderInput) {
                                if (taskPlaceholderInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                                    taskPlaceholderInput.Input.C_JobCharacteristicType_key === jobCharacteristicTypeKey) {
                                    taskPlaceholderInput.InputValue = value;
                                } else if (taskPlaceholderInput.Input.cv_DataType.DataType === DataType.JOB_CHARACTERISTIC &&
                                    typeKeys.indexOf(taskPlaceholderInput.Input.C_JobCharacteristicType_key) < 0) {
                                    taskPlaceholderInput.InputValue = null;
                                }
                            }
                        }
                    }
                }
            }
        }

        this.setBusy(false);
    }

    /**
     * Updates standardPhrases based on new value of this.job.Imaging.
     * If imaging=true, then adds all standard phrases that are an "Imaging" category and have the specific job type and/or
     * subtype enabled as a default option. If imaging=false, then default phrases are reset with imaging phrases removed
     */
    imagingChanged() {
        if (this.isCRL) {
            this.updateStandardPhrases();
        }
    }

    /**
     * Tells back end to get the dotmatics test articles
     */
    getDotmaticsTestArticles(): Promise<any> {
        return this.dotmaticsService.getDotmaticsTestArticles(this.job.C_Job_key, null).then((results) => {
            this.dotmaticsTestArticles = results.data;
        });
    }

    refreshDtxTestArticles(institutionKey: any) {
        if (this.isDotmatics) {
            return this.dotmaticsService.getDotmaticsTestArticles(null, institutionKey).then((results) => {
                this.dotmaticsTestArticles = results.data;
            });
        }
    }

    async updateStudyDayTasks(): Promise<any> {
        // Don't update if isCRO is false or there are no tasks in the Job or DateStarted is null
        if (!this.isCRO || !this.job.TaskJob.length || !this.job.DateStarted) {
            this.previousDateStarted = this.job.DateStarted;
            return;
        }

        // to prevent show dialog twice when the discard button selected
        if (this.previousDateStarted === this.job.DateStarted) {
            return;
        }

        const hasStudyDayTasks = await this.getJobHasStudyDayTasks();
        if (!hasStudyDayTasks) {
            this.previousDateStarted = this.job.DateStarted;
            return;
        }

        const isContinue = await this.dialogService.confirmYesNo({
            yesButtonTitle: 'Continue',
            noButtonTitle: 'Cancel',
            title: 'Update Study Start Date',
            bodyText: `
                Climb will apply the requested changes for ${this.job.TaskJob.length} task ${pluralize(this.job.TaskJob.length, 'instance', 'instances')}. This may take several minutes.
                Do you wish to save changes and continue?
            `,
        });
        if (!isContinue) {
            const isJobChangeExist = this.dataContext.getChanges().findIndex(item => item?.C_Job_key === this.job.C_Job_key);
            if (isJobChangeExist > -1) {
                this.dataContext.cancelPropertyChange(this.job, 'DateStarted');
                if (this.job.DurationDays) {
                    this.dataContext.cancelPropertyChange(this.job, 'DateEnded');
                }
            }
            return;
        }

        try {
            this.setBusy(true);
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
            const requestBody = {
                JobKey: this.job.C_Job_key
            };

            this.previousDateStarted = this.job.DateStarted;
            await this.webApiService.postApi('api/jobdata/updateStartDateTasks', requestBody);
            await this.reloadData();
        } catch (error) {
            console.error(error);
        } finally {
            this.setBusy(false);
        }
    }

    async extendJob(event?: FocusEvent): Promise<any> {
        // prevent additional duration changes when focus on input was lost and then wait for one modal to be opened
        if (event) {
            this.disableDuration = true;
        }

        // Don't extend Job if there are no tasks in the Job or DurationDays is null
        if (!this.job.TaskJob.length || !this.job.DurationDays) {
            this.previousDurationDays = this.job.DurationDays as number;
            this.disableDuration = false;
            return Promise.resolve();
        }

        try {
            const previousDurationDays = await this.getStoredDurationDays();
            // Return if DurationDays is not increased
            if (previousDurationDays >= this.job.DurationDays) {
                return Promise.resolve();
            }

            const jobHasToEndTasks = await this.getJobHasToEndTasks();
            if (!jobHasToEndTasks) {
                this.previousDurationDays = this.job.DurationDays as number;
                return Promise.resolve();
            }

            const isContinue = await this.dialogService.confirmYesNo({
                yesButtonTitle: 'Continue',
                noButtonTitle: 'Cancel',
                title: 'Extend Study Duration',
                bodyText: `
                    To apply the requested changes, a system reload is needed. Manually reloading during this process may cause unexpected results. The system may need to create several thousand records. This may take several minutes.
                    Do you wish to save changes and continue?`,
            });

            // Set Duration to previous value if changes are discarded
            if (!isContinue) {
                this.dataContext.cancelPropertyChange(this.job, 'DurationDays');
                this.dataContext.cancelPropertyChange(this.job, 'DateEnded');
                return;
            }

            // Extend Job if Continue is selected
            this.setBusy(true);
            await this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);

            const requestBody = {
                JobKey: this.job.C_Job_key,
                PreviousDurationDays: this.previousDurationDays,
            };

            this.previousDurationDays = this.job.DurationDays as number;
            await this.webApiService.postApi('api/jobdata/extendJob', requestBody);
            await this.reloadData();
            this.jobPharmaDetailService.tabRefresh('tasks', 'list');
            this.jobPharmaDetailService.tabRefresh('tasks', 'outline');
        } catch (error) {
            this.loggingService.logError('Unexpected error has occurred', error, this.COMPONENT_LOG_TAG);
        } finally {
            this.setBusy(false);
            this.disableDuration = false;
        }
    }

    getStoredDurationDays(): Promise<any> {
        const apiUrl = 'api/jobdata/getStoredDurationDays/' + this.job.C_Job_key;
        return this.webApiService.callApi(apiUrl).then((response: any) => {
            return response.data || 0;
        });
    }

    getJobHasStudyDayTasks(): Promise<any> {
        const apiUrl = 'api/jobdata/jobHasStudyDayTasks/' + this.job.C_Job_key;
        return this.webApiService.callApi(apiUrl).then((response: any) => {
            return response.data;
        });
    }

    getJobHasToEndTasks(): Promise<any> {
        const apiUrl = 'api/jobdata/jobHasToEndTasks/' + this.job.C_Job_key;
        return this.webApiService.callApi(apiUrl).then((response: any) => {
            return response.data;
        });
    }

    private async actualizePlaceholderNamesAfterCopying() {
        if (this.isCopyed) {
            this.isCopyed = false;
            this.jobIDUpdated();
            this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG, false);
        }
    }

    requestEndOfStudyReport() {
        this.jobPharmaReportsTable.requestReport()
    }
}
