<ng-template #errorDetail>
    <h3>Something went wrong: we cannot load this type of workflow task.</h3>
    <button data-automation-id="list-view-button"
            type="button"
            class="btn btn-secondary"
            (click)="exitDetail()">
        Back To List View
    </button>
</ng-template>

<div class="facet">
    <facet-header caption="Workflow"
                  [facet]="facet">
        <button data-automation-id="filter-workflow-tasks-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="openFilter()"
                title="Filter Workflow Tasks">
            <svg [climbIcon]="icons.magnifier"></svg>
        </button>

        <button *ngIf="facetView === LIST_VIEW && isActiveWorkspaceFilterSupported()"
                climbFacetAction
                (click)="clickIgnoreWorkspaceFilter(); reloadTable();"
                [title]="ignoreWorkspaceFilter ? 'Apply Workspace Filter' : 'Ignore Workspace Filter'">
            <svg [climbIcon]="ignoreWorkspaceFilter ? icons.chain : icons.brokenChain"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === BULK_DATA_ENTRY_VIEW"
                climbFacetAction
                (click)="refreshDataBulkDataEntry()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>

        <button data-automation-id="refresh-data-button"
                *ngIf="facetView === LIST_VIEW"
                climbFacetAction
                (click)="refreshData()"
                title="Refresh Data">
            <svg [climbIcon]="icons.arrowClockwise"></svg>
        </button>
    </facet-header>
    <div class="facet-body" *ngIf="facet.Privilege !== 'None'">
        <!-- Define the screens this facet can view -->
        <loading-overlay *ngIf="loading" [message]="loadingMessage"></loading-overlay>

        <ng-container *ngIf="facetView === LIST_VIEW">
            <climb-facet-toolbar>
                <climb-switch dataAutomationId="grouped-rows-radio"
                              container="body"
                              placement="top-left"
                              openDelay="600"
                              ngbTooltip="Groups task instances if their values match in the selected columns.">
                    <input type="checkbox"
                           climbSwitch
                           [(ngModel)]="groupToggle"
                           (changed)="groupToggleChange()"/>
                    Grouped Rows
                </climb-switch>

                <climb-bulk-dropdown *ngIf="facet.Privilege === 'ReadWrite'">
                    <button climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="sendSelectedToBulkEdit()"
                            [appInsightsClick]="'click-bulk-edit-' + componentName">
                        Edit
                    </button>
                    <button data-automation-id="data-entry-item"
                            climbDropdownItem
                            [disabled]="!selectedRows || selectedRows?.length < 1"
                            (click)="clickBulkDataEntry()"
                            appInsightsClick="click-workflow-bulk-data-entry">
                        Data Entry
                    </button>
                </climb-bulk-dropdown>

                <climb-export-dropdown #exportDropdown
                                       [expandExport]="true"
                                       [options]="workflowTableOptions.options"
                                       [dataService]="dataService"
                                       [componentName]="componentName">
                </climb-export-dropdown>

                <climb-column-select [columnDef]="dataTableColumns$ | async"
                                     (onClose)="dataTable.columnSelectChanged($event)">
                </climb-column-select>
            </climb-facet-toolbar>
            <climb-data-table data-automation-id="workflow-table"
                              #dataTable
                              [dataService]="dataService"
                              [options]="workflowTableOptions.options"
                              [(gridStateJson)]="facet.GridState"
                              [(selectedRows)]="selectedRows"
                              (selectedColumnsChange)="selectedColumnsChange($event)"
                              (detailLinkClick)="detailLinkClick($event)"
                              (columnStateChanged)="dataTableColumns.next($event)"
                              (stateChange)="exportDropdown.tableState = $event">
            </climb-data-table>
        </ng-container>

        <ng-container *ngIf="facetView === DETAIL_VIEW"
                      [ngSwitch]="currentTaskInstance?.WorkflowTask?.cv_TaskType?.TaskType">

            <workflow-detail *ngSwitchCase="TaskType.Animal"
                             [facet]="facet"
                             [task]="currentTaskInstance"
                             [taskType]="TaskType.Animal"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></workflow-detail>

            <workflow-detail *ngSwitchCase="TaskType.Birth"
                             [facet]="facet"
                             [task]="currentTaskInstance"
                             [taskType]="TaskType.Birth"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></workflow-detail>

            <workflow-detail *ngSwitchCase="TaskType.Housing"
                             [facet]="facet"
                             [task]="currentTaskInstance"
                             [taskType]="TaskType.Housing"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></workflow-detail>

            <workflow-detail *ngSwitchCase="TaskType.Job"
                             [facet]="facet"
                             [task]="currentTaskInstance"
                             [taskType]="TaskType.Job"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></workflow-detail>

            <workflow-detail *ngSwitchCase="TaskType.Line"
                             [facet]="facet"
                             [task]="currentTaskInstance"
                             [taskType]="TaskType.Line"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></workflow-detail>

            <workflow-detail *ngSwitchCase="TaskType.Mating"
                             [facet]="facet"
                             [task]="currentTaskInstance"
                             [taskType]="TaskType.Mating"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></workflow-detail>

            <clinical-detail *ngSwitchCase="TaskType.HealthRecord"
                             [facet]="facet"
                             [animal]="healthRecordAnimal"
                             [pageState]="detailPaginator.pageState"
                             (exit)="exitDetail()"
                             (previous)="detailPreviousClicked()"
                             (next)="detailNextClicked()"></clinical-detail>

            <div *ngSwitchDefault>
                <ng-template [ngTemplateOutlet]="errorDetail"></ng-template>
            </div>
        </ng-container>

        <workflow-bulk-edit *ngIf="facetView === BULK_EDIT_VIEW"
                            [facet]="facet"
                            [tasks]="selectedTaskInstances"
                            (exit)="exitBulkEdit()"
                            #bulkEdit></workflow-bulk-edit>

        <workflow-bulk-data-entry *ngIf="facetView === BULK_DATA_ENTRY_VIEW"
                                  [facet]="facet"
                                  [taskKeys]="selectedTaskKeys"
                                  [paginationRandomId]="facetId"
                                  [readonly]="facet.Privilege === 'ReadOnly'"
                                  (exit)="exitBulkDataEntry()"
                                  #bulkDataEntry></workflow-bulk-data-entry>

    </div>
</div>
