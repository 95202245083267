import {
    Component,
    Input,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmService } from '../../common/confirm';
import { CopyBufferService } from '../../common/services/copy-buffer.service';
import { ProtocolService } from '../protocol.service';
import { SaveChangesService } from '../../services/save-changes.service';
import { TranslationService } from '../../services/translation.service';
import { WorkspaceFilterService } from '../../services/workspace-filter.service';

import {
    BaseFacet,
    BaseFacetService,
    IFacet
} from '../../common/facet';

import { ProtocolTableOptions } from '../protocol-table-options/protocol-table-options';
import { ProtocolFilterComponent } from '../protocol-filter/protocol-filter.component';

import {
    CellFormatterService,
    TableState,
    DataResponse,
    ColumnsState,
    TableColumnDef,
} from '@common/datatable';
import { QueryDef } from '../../services/query-def';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from "rxjs/operators";
import { DataContextService } from "@services/data-context.service";
import { arrowClockwise, magnifier, squareOnSquare } from '@icons';

@Component({
    selector: 'protocol-facet',
    templateUrl: './protocol-facet.component.html',
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class ProtocolFacetComponent extends BaseFacet
    implements OnInit, OnDestroy {
    @Input() facet: IFacet;

    readonly icons = { arrowClockwise, magnifier, squareOnSquare };

    componentName = 'protocol';

    protocolTableOptions: ProtocolTableOptions;

    readonly COMPONENT_LOG_TAG = 'protocol-facet';

    componentFilterSubscription: Subscription;
    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]>;
    dataTableColumns$: Observable<TableColumnDef[]>;

    constructor(
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private confirmService: ConfirmService,
        private copyBufferService: CopyBufferService,
        private protocolService: ProtocolService,
        private modalService: NgbModal,
        private saveChangesService: SaveChangesService,

        private dataContext: DataContextService,
        private translationService: TranslationService,
        workspaceFilterService: WorkspaceFilterService
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.protocolTableOptions = new ProtocolTableOptions(
            this.cellFormatterService,
            this.translationService
        );

        this.dataTableColumns = new BehaviorSubject(this.protocolTableOptions.options.columns);
        this.dataTableColumns$ = this.dataTableColumns.asObservable();

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadItemList(tableState);
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();
        this.initialize();
        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });
    }

    ngOnDestroy() {
        if (this.componentFilterSubscription) {
            this.componentFilterSubscription.unsubscribe();
        }
        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
    }

    refreshData() {
        this.initialize();
        this.dataTableComm.reloadTable();
    }

    restoreFilterState() {

        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (!this.filter) {
                this.filter = {};
            }
        }
    }

    async loadItemList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';
        const expands: any[] = [];

        this.setLoadingState(tableState.loadingMessage);

        const queryDef: QueryDef = {
            page,
            size: pageSize,
            sort,
            filter: this.getActiveFilter(),
            expands
        };

        try {
            const response = await this.protocolService.getProtocols(queryDef);

            const visibleColumns = this.getVisibleColumns(this.protocolTableOptions.options);
            await this.protocolService.ensureVisibleColumnsDataLoaded(response.results, visibleColumns);

            this.stopLoading();

            this.data = response.results;
            this.totalCount = response.inlineCount;
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    addItemClick() {
        if (this.privilegeService.readwrite) {
            this.setLoadingState();
            this.createNewProtocol().then((protocol: any) => {
                this.stopLoading();
                this.itemToEdit = protocol;
                this.changeView(this.DETAIL_VIEW);
            }).catch((error) => {
                this.loading = false;
                this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
            });
        }
    }

    deleteItemClick(protocol: any) {
        if (this.privilegeService.readwrite) {
            this.protocolService.isProtocolSafeToDelete(protocol).then((isSafe: boolean) => {
                if (isSafe) {
                    this._deleteItem(protocol);
                } else {
                    const message = 'Cannot delete ' +
                        this.translationService.translate('Protocol') +
                        ': it is associated with workflow data.';
                    const showToast = true;

                    this.loggingService.logWarning(
                        message,
                        null,
                        this.COMPONENT_LOG_TAG,
                        showToast
                    );
                }
            });
        }
    }

    /**
     * Prompts for delete then deletes protocol, saves changes, reloads grid.
     *
     * @param protocol
     */
    private _deleteItem(protocol: any): Promise<any> {
        const itemType = this.translationService.translate('Protocol');
        const itemName = protocol.ProtocolName;
        const modalTitle = 'Delete ' + itemType;
        const modalText = 'Delete "' + itemName + '"? This action cannot be undone.';

        return this.confirmService.confirmDelete(modalTitle, modalText).then(
            // confirmed
            () => {
                return this.protocolService.deleteProtocol(protocol).then(() => {
                    return this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
                }).then(() => {
                    this.reloadTable();
                });
            },
            // cancel
            () => { /* do nothing on cancel */ }
        );
    }

    copyProtocols() {
        this.copyBufferService.copy(this.selectedRows);
    }

    createNewProtocol(): Promise<any> {
        const newProtocol = this.protocolService.createProtocol();
        return Promise.resolve(newProtocol);
    }

    modelCopied(itemCopied: any) {
        this.itemToEdit = itemCopied;

        const showToast = true;
        this.loggingService.logSuccess(
            this.translationService.translate('Protocol') + ' copied',
            null,
            this.COMPONENT_LOG_TAG,
            showToast
        );
        this.changeView(this.DETAIL_VIEW);
    }

    openFilter() {
        const ref = this.modalService.open(ProtocolFilterComponent);
        const component = ref.componentInstance as ProtocolFilterComponent;
        component.filter = this.filter;
        this.componentFilterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    dragStart() {
        this.protocolService.draggedProtocols = this.draggedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.protocolService.draggedProtocols = [];
        }, 500);
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.protocolService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }
}

