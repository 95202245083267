import { ProtocolDateCalculator } from './../tasks/tables/protocol-date-calculator';
import { SaveChangesService } from './../services/save-changes.service';
import { ViewAddTaskComponentService } from './../tasks/add-task/view-add-task-component.service';
import { DataContextService } from './../services/data-context.service';
import { ConfirmService } from './../common/confirm/confirm.service';
import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AnimalService } from './services/animal.service';
import { CellFormatterService, ClimbDataTableComponent, ColumnsState, TableOptions } from '@common/datatable';
import { MaterialService } from '../services/material.service';
import { TranslationService, UNHANDLED_ERROR_MESSAGE } from '../services/translation.service';
import { VocabularyService } from '../vocabularies/vocabulary.service';

import { 
    BaseFacet,
    BaseFacetService, 
    FacetView,
    IFacet
} from '../common/facet';
import { AnimalFilterComponent } from './animal-filter/animal-filter.component';

import { AnimalTableOptions } from './animal-table-options';
import {
    TableState,
    DataResponse,
    TableColumnDef,
} from '@common/datatable/data-table.interface';
import { CopyBufferService } from '@common/services/copy-buffer.service';
import { WorkspaceFilterService } from '../services/workspace-filter.service';
import { WsFilterEvent } from '../services/ws-filter-event';

import { filterToDate, notEmpty, uniqueArrayFromPropertyPath, maxSequence, uniqueArray } from '../common/util';
import { TaskType } from '../tasks/models';
import { TaskService } from '../tasks/task.service';
import { ViewAddProtocolComponentService } from '../tasks/add-task';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ConfirmOptions } from '../common/confirm';
import { DotmaticsService } from '../dotmatics/dotmatics.service';
import { FeatureFlagService } from '../services/feature-flags.service';
import { WorkflowService } from '../workflow/services/workflow.service';
import { SettingService } from '../settings/setting.service';
import { WorkspaceService } from '../workspaces/workspace.service';
import { AnimalDetailComponent } from './animal-detail.component';
import { Animal, Entity, TaxonCharacteristic } from '../common/types';
import { map, takeUntil } from "rxjs/operators";
import { CharacteristicService } from '../characteristics/characteristic.service';
import { safelyParseJson } from '@common/util/safely-parse-json.util';
import { arrowClockwise, brokenChain, chain, magnifier, squareOnSquare, viceVersaArrowsHorizontal } from '@icons';
import { pluralize } from '@common/util/pluralize';

@Component({
    selector: 'animal-facet',
    templateUrl: './animal-facet.component.html',
    styles: [
        `
            .bolded {
                font-weight: bold;
            }
            .italicized {
                font-style: italic;
            }
        `
    ],
    providers: BaseFacet.BASE_COMPONENT_PROVIDERS
})
export class AnimalFacetComponent extends BaseFacet<Entity<Animal>>
    implements OnInit, OnDestroy {
    @Input() facet: IFacet;
    @ViewChild(AnimalDetailComponent) animalDetails!: AnimalDetailComponent;
    @ViewChild(ClimbDataTableComponent) dataTable: ClimbDataTableComponent;

    readonly icons = { arrowClockwise, brokenChain, chain, magnifier, squareOnSquare, viceVersaArrowsHorizontal };

    componentName = 'animal';
    animalTableOptions: BehaviorSubject<AnimalTableOptions> = new BehaviorSubject(new AnimalTableOptions(
        this.cellFormatterService,
        this.translationService,
        this.featureFlagService
    ));
    animalTableOptions$: Observable<TableOptions> = this.animalTableOptions.asObservable().pipe(
        map((tableOptions) => tableOptions.options),
    );

    filterSubscription: Subscription;
    syncSubscription: Subscription;
    isSyncItem: boolean;
    taskData: any; // Used to sync process
    deleteSubscripion: Subscription;

    isDotmatics: boolean; 
    isGLP: boolean;

    // Active and required fields set by facet settings
    activeFields: string[] = [];
    inactiveFields: string[] = [];
    requiredFields: string[] = [];

    readonly BULK_HOUSING_VIEW: FacetView = FacetView.BULK_HOUSING_VIEW;

    readonly COMPONENT_LOG_TAG = 'animal-facet';

    private notifier$ = new Subject<void>();

    dataTableColumns: BehaviorSubject<TableColumnDef[]> = new BehaviorSubject(this.animalTableOptions.value.options.columns);
    dataTableColumns$: Observable<TableColumnDef[]> = this.dataTableColumns.asObservable();

    constructor(
        private cdr: ChangeDetectorRef,
        private animalService: AnimalService,
        private baseFacetService: BaseFacetService,
        private cellFormatterService: CellFormatterService,
        private confirmService: ConfirmService,
        private copyBufferService: CopyBufferService,
        private characteristicService: CharacteristicService,
        private dataContext: DataContextService,
        private materialService: MaterialService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private modalService: NgbModal,
        private taskService: TaskService,
        private saveChangesService: SaveChangesService,
        private viewAddTaskComponentService: ViewAddTaskComponentService,
        private viewAddProtocolComponentService: ViewAddProtocolComponentService,
        workspaceFilterService: WorkspaceFilterService,
        private dotmaticsService: DotmaticsService,
        private featureFlagService: FeatureFlagService,
        private workflowService: WorkflowService,
        private settingService: SettingService,
        private workspaceService: WorkspaceService,
    ) {
        super(
            baseFacetService,
            workspaceFilterService
        );

        this.dataService = {
            run: (tableState: TableState) => {
                return this.loadAnimalsList(tableState);
            },
            preExport: (dataResponse: DataResponse<Animal>) => {
                const animals = dataResponse.results;
                if (!animals) {
                    return Promise.resolve(dataResponse);
                } else {
                    const visibleColumns = this.getVisibleColumns(this.animalTableOptions.value.options);
                    return this.animalService.ensureListViewAssociatedDataLoaded(
                        this.data, visibleColumns
                    ).then(() => {
                        return dataResponse;
                    });
                }
            }
        };
    }

    // lifecycle
    ngOnInit() {
        super.ngOnInit();

        this.getCharacteristicListViewColumns(safelyParseJson(this.facet.GridState)).then((columns: TableColumnDef[]) => {
            this.animalTableOptions.next(new AnimalTableOptions(
                this.cellFormatterService,
                this.translationService,
                this.featureFlagService,
                columns
            ));
            this.cdr.detectChanges(); // to prevent NG0100: ExpressionChangedAfterItHasBeenCheckedError
        });

        this.supportedWorkspaceFilters = ['job-filter'];

        this.initialize();

        this.createPaginator();

        this.deleteSubscripion = this.animalService.birthAnimalDeleted$.subscribe((a: any) => {
            this.refreshData();
        });

        this.dataContext.onCancel$.pipe(takeUntil(this.notifier$)).subscribe(() => {
            this.changeView(this.LIST_VIEW);
        });

        return this.settingService.getFacetSettingsByType('animal', undefined, this.isGLP).then((facetSettings) => {
            this.activeFields = this.settingService.getActiveFields(facetSettings);
            this.inactiveFields = this.settingService.getInactiveFields(facetSettings);
            this.requiredFields = this.settingService.getRequiredFields(facetSettings);
        }).then(() => {
            this.syncSubscription = this.workflowService.animalsSync$.subscribe((a: any) => {
                if (!a.animal) {
                    return;
                }
                this.detailLinkClick(a.animal);
                this.taskData = {
                    workflowTaskKey: a.taskOutputSet.TaskInstance.C_WorkflowTask_key,
                    taskType: a.taskOutputSet.TaskInstance.WorkflowTask.cv_TaskType.TaskType
                };
                this.isSyncItem = true;
            });
        });
    }

    ngOnDestroy() {
        if (this.filterSubscription) {
            this.filterSubscription.unsubscribe();
        }
        if (this.syncSubscription) {
            this.syncSubscription.unsubscribe();
        }
        if (this.deleteSubscripion) {
            this.deleteSubscripion.unsubscribe();
        }

        this.notifier$.next();
        this.notifier$.complete();
    }

    initialize() {
        this.restoreFilterState();
        this.changeView(this.LIST_VIEW);
        this.setIsDotmatics();
        this.initIsGLP();
    }


    initIsGLP() {
        this.isGLP = this.featureFlagService.isFlagOn('IsGLP');
    }

    refreshData() {
        this.initialize();
        this.reloadTable();
    }

    restoreFilterState() {
        // process any grid filters
        if (this.facet && this.facet.GridFilter) {
            try {
                this.filter = JSON.parse(this.facet.GridFilter);
            } catch (err) {
                console.error(err);
            }

            if (this.filter) {
                this.filter.dateBornStart = filterToDate(this.filter.dateBornStart);
                this.filter.dateBornEnd = filterToDate(this.filter.dateBornEnd);
                this.filter.dateExitStart = filterToDate(this.filter.dateExitStart);
                this.filter.dateExitEnd = filterToDate(this.filter.dateExitEnd);
                this.filter.dateWeanStart = filterToDate(this.filter.dateWeanStart);
                this.filter.dateWeanEnd = filterToDate(this.filter.dateWeanEnd);
                this.filter.dateOriginStart = filterToDate(this.filter.dateOriginStart);
                this.filter.dateOriginEnd = filterToDate(this.filter.dateOriginEnd);
                this.filter.dateCreatedStart = filterToDate(this.filter.dateCreatedStart);
                this.filter.dateCreatedEnd = filterToDate(this.filter.dateCreatedEnd);
            } else {
                this.filter = {};
            }
        }
    }

    async loadAnimalsList(tableState: TableState): Promise<DataResponse> {
        this.tableState = tableState;

        const page = tableState.pageNumber || 0;
        const pageSize = tableState.pageSize || 50;
        const sort = tableState.sort || 'DateCreated DESC';

        this.setLoadingState(tableState.loadingMessage);

        const visibleColumns = this.getVisibleColumns(this.animalTableOptions.value.options);

        try {
            const response = await this.animalService.getAnimals({
                page,
                size: pageSize,
                sort,
                filter: this.getActiveFilter()
            });

            this.data = response.results.filter((res) => res !== undefined ) as Entity<Animal>[];
            this.totalCount = response.inlineCount;

            // load associated list view data
            await Promise.all([
                this.animalService.ensureVisibleColumnsDataLoaded(this.data, visibleColumns),
                this.animalService.ensureListViewAssociatedDataLoaded(this.data, visibleColumns)
            ]);

            this.stopLoading();
            this.updatePageState();

            return {
                results: this.data,
                inlineCount: this.totalCount
            };
        } finally {
            this.stopLoading();
        }
    }

    async onRemoveFacet() {
        if (this.facetView === this.DETAIL_VIEW) {
            // Before closing a facet we have to show unsaved changes dialog
            const validationPassed = await this.animalDetails.handleUnsavedChangesOnExit();
            if (validationPassed) {
                this.workspaceService.deleteWorkspaceDetail(this.facet);
                this.workspaceService.autoSizeFacets();
            }
        } else {
            // TODO: else block should be completely removed after all views of animal facet will be integrated for
            //  facet level saving/discarding
            await this.saveChangesService.promptForUnsavedChanges(this.facet.FacetName);
            this.workspaceService.deleteWorkspaceDetail(this.facet);
            this.workspaceService.autoSizeFacets();
        }
    }

    dragStart() {
        this.animalService.draggedAnimals = this.selectedRows;
    }

    dragStop() {
        setTimeout(() => {
            this.animalService.draggedAnimals = [];
        }, 500);
    }

    addItemClick() {
        this.isSyncItem = false;
        this.taskData = null;
        this.loading = true;
        this.createNewAnimal().then((animal) => {
            this.loading = false;
            this.itemToEdit = animal;
            this.changeView(this.DETAIL_VIEW);
        }).catch((error) => {
            this.loading = false;
            this.loggingService.logError("An unexpected error occurred. Please try again", error, this.componentName, true);
        });
    }

    createNewAnimal(): Promise<any> {
        let newAnimal: any = null;
        return this.materialService.createAsType('Animal').then((newMaterial) => {
            newAnimal = this.animalService.create();
            newAnimal.Material = newMaterial;
            newAnimal.TaxonCharacteristics = [];
            newAnimal.DateOrigin = new Date();
            newMaterial.C_Taxon_key = null;
        }).then(() => {
            return Promise.all([
                this.vocabularyService.getCVDefault('cv_AnimalStatuses')
                    .then((value) => {
                        newAnimal.cv_AnimalStatus = value;
                    }),
                this.vocabularyService.getCVDefault('cv_AnimalUses')
                    .then((value) => {
                        newAnimal.cv_AnimalUse = value;
                    }),
                this.vocabularyService.getCVDefault('cv_BreedingStatuses')
                    .then((value) => {
                        newAnimal.cv_BreedingStatus = value;
                    }),
                this.vocabularyService.getCVDefault('cv_AnimalClassifications')
                    .then((value) => {
                        newAnimal.cv_AnimalClassification = value;
                    }),
                this.vocabularyService.getCVDefault('cv_AnimalMatingStatuses')
                    .then((value) => {
                        newAnimal.cv_AnimalMatingStatus = value;
                    }),
                this.vocabularyService.getCVDefault('cv_IACUCProtocols')
                    .then((value) => {
                        newAnimal.cv_IACUCProtocol = value;
                    }),
                this.vocabularyService.getCVDefault('cv_MaterialOrigins')
                    .then((value) => {
                        newAnimal.Material.cv_MaterialOrigin = value;
                    }),
                this.vocabularyService.getCVDefault('cv_PhysicalMarkerTypes')
                    .then((value) => {
                        newAnimal.cv_PhysicalMarkerType = value;
                    }),
                this.vocabularyService.getCVDefault('cv_Diets')
                    .then((value) => {
                        newAnimal.cv_Diet = value;
                    }),
            ]);
        }).then(() => {

            // vm.gridOptions.data.unshift(newAnimal);
            return newAnimal;
        });
    }

    openFilter() {
        const ref = this.modalService.open(AnimalFilterComponent, { size: 'lg' });
        const component = ref.componentInstance as AnimalFilterComponent;
        component.filter = this.filter;
        this.filterSubscription = component.onFilter.subscribe((filter: any) => {
            this.filter = filter;
            this.runFilter();
        });
    }

    copyAnimals() {
        this.copyBufferService.copy(this.selectedRows);
    }

    selectedRowsChange(rows: any[]) {
        if (this.workspaceFilterActive) {
            this.filterWorkspaceByAnimal();
        }
    }

    async selectedColumnsChange({ visible }: ColumnsState) {
        try {
            this.facetLoadingState.changeLoadingState(true);
            await this.animalService.ensureVisibleColumnsDataLoaded(this.data, visible);
        } finally {
            this.facetLoadingState.changeLoadingState(false);
        }
    }

    filterWorkspaceByAnimal() {
        const animalIds = this.selectedRows.map((animal) => animal.C_Material_key);
        this.workspaceFilterService.filterWorkspace(this.facetId, 'animal-filter', animalIds);
    }

    onWorkspaceFilterChange(wsFilterEvent: WsFilterEvent) {
        // TODO (kevin.stone): this might be ok to put in base-facet
        //   Need to test
        const oldFilterSupported = this.workspaceFilterSupported(wsFilterEvent.oldFilterKind);
        const newFilterSupported = this.workspaceFilterSupported(wsFilterEvent.filterKind);
        if (!this.ignoreWorkspaceFilter && (oldFilterSupported || newFilterSupported)) {
            this.reloadTable();
        }
    }

    openCageCardModal(cagecardmodal: TemplateRef<any>) {
        this.modalService.open(cagecardmodal);
    }

    async doBulkDelete() {
        let animalsToDelete: Entity<Animal>[];
        if (this.isDotmatics) {
            const syncedAnimals = this.checkSyncStatus(this.selectedRows);
            animalsToDelete = this.selectedRows.filter((animal) => {
                return !syncedAnimals.includes(animal);
            });
            try {
                await this.dotmaticsDeleteModal(syncedAnimals, animalsToDelete);
            } catch { 
                // Delete cancelled
                return;
            }
        } else {
            animalsToDelete = this.selectedRows;
        }

        await this.deleteAnimals(animalsToDelete);
    }

    private async deleteAnimals(animals: Entity<Animal>[]) {
        if (!animals?.length) {
            return;
        }
        
        const modalTitle = 'Delete ' + pluralize(animals.length, 'Animal');
        const modalText = 'Delete ' + animals.length + ' selected ' + pluralize(animals.length, 'animal') + '? This action cannot be undone.';
        try {
            await this.confirmService.confirmDelete(modalTitle, modalText);
        } catch { 
            // Delete cancelled
            return;
        }

        const result = await this.animalService.bulkDeleteAnimals(animals);
        if (result.data.HasAssociatedData) {
            const title = pluralize(result.data.Names.length, 'Animal') + ' with Data';
            const message = pluralize(result.data.Names.length, 'This animal has', 'These animals have') + ' associated data and cannot be removed:';
            const details = result.data.Names;
            const confirmOptions: ConfirmOptions = {
                title,
                message,
                yesButtonText: 'OK',
                onlyYes: true,
                details
            };

            return this.confirmService.confirm(confirmOptions);
        } else {
            this.facetLoadingState.changeLoadingState(true);
            for (const animal of animals) {
                const material = animal.Material;
                this.animalService.deleteAnimal(animal);
                this.materialService.deleteMaterial(material);
            }

            try {
                await this.dataContext.save()
                this.reloadTable();
            } catch (err) {
                this.loggingService.logError(UNHANDLED_ERROR_MESSAGE, err, this.COMPONENT_LOG_TAG, true);
            } finally {
                this.facetLoadingState.changeLoadingState(false);
            }
        }
    }

    clickCreateHousing() {
        const animalNames: any[] = [];
        const notEndStateAnimals: any[] = [];
        for (const animal of this.selectedRows) {
            if (animal.cv_AnimalStatus && animal.cv_AnimalStatus.IsExitStatus) {
                animalNames.push(animal.AnimalName);
            } else {
                notEndStateAnimals.push(animal);
            }
        }
        const names = uniqueArray(animalNames).join(', ');
        if (names) {
            let message = `${animalNames.length} of these animals have an end-state status. You must change the animal's statuses to a non-end-state option.`;
            if (message.length === 1) {
                message = message.replace("have", "has");
                message = message.replace("statuses", "status");
            }
            this.confirmService.confirm(
                {
                    title: "Animal's status",
                    message,
                    yesButtonText: 'Skip Animals',
                    noButtonText: 'Cancel',
                    titleDetails: 'Animals: ',
                    details: [
                        names
                    ]
                }
            ).then(
                () => {
                    // Yes             
                    if (notEndStateAnimals.length > 0) {
                        this.itemsToEdit = notEndStateAnimals;
                        this.changeView(this.BULK_HOUSING_VIEW);
                    }
                },
                () => {
                    // No
                });
        } else {
            this.itemsToEdit = this.selectedRows;
            this.changeView(this.BULK_HOUSING_VIEW);
        }
    }

    clickBulkAssignTasks(): Promise<any> {
        return this.viewAddTaskComponentService.openComponent(TaskType.Animal)
            .then((taskValues) => {
                this.facetLoadingState.changeLoadingState(true);
                return this.createTasksForSelectedAnimals(taskValues);
            }).then((newTaskInstances) => {
                if (notEmpty(newTaskInstances)) {
                    return this.saveChangesService.saveChanges(this.componentName).then(() => {
                        this.loggingService.logSuccess(
                            "Added new tasks for " + 
                            this.selectedRows.length +
                            " animals", 
                            null, this.componentName, true
                        );
                    });
                }
            }).then(() => {
                this.facetLoadingState.changeLoadingState(false);
            }).catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                throw error;
            });
    }

    clickBulkAssignProtocol(): Promise<any> {
        return this.viewAddProtocolComponentService.openComponent(TaskType.Animal)
            .then((taskValues) => {
                this.facetLoadingState.changeLoadingState(true);
                return this.createTasksForSelectedAnimals(taskValues);
            }).then((newTaskInstances) => {
                if (notEmpty(newTaskInstances)) {
                    return this.saveChangesService.saveChanges(this.componentName).then(() => {
                        this.loggingService.logSuccess(
                            "Added new tasks for " + 
                            this.selectedRows.length +
                            " animals", 
                            null, this.componentName, true
                        );
                        return newTaskInstances;
                    });
                }
            }).then((newTaskInstances) => {
                this.facetLoadingState.changeLoadingState(false);
                return newTaskInstances;
            }).catch((error) => {
                this.facetLoadingState.changeLoadingState(false);
                throw error;
            });
    }

    createTasksForSelectedAnimals(taskValues: any[]): Promise<any[]> {
        if (!notEmpty(taskValues)) {
            return Promise.resolve([]);
        }

        return this.animalService.ensureTasksLoaded(this.selectedRows).then(() => {
            return this.getDefaultTaskStatus();
        }).then((taskStatusDefault: any) => {
            const taskInstances: any[] = [];

            let promise: Promise<void> = Promise.resolve();

            for (const animal of this.selectedRows) {
                let protocolInstance: any = null;
                if (notEmpty(taskValues) && taskValues[0].protocol) {
                    protocolInstance = this.createProtocolInstance(
                        taskValues[0].protocol, animal
                    );
                }

                for (const taskInstanceValues of taskValues) {
                    if (taskStatusDefault) {
                        taskInstanceValues.C_TaskStatus_key =
                            taskStatusDefault.C_TaskStatus_key;
                    }

                    // copy values to edit for this animal
                    const initialTaskValues = { ... taskInstanceValues };
                    if (protocolInstance) {
                        initialTaskValues.C_ProtocolInstance_key = 
                            protocolInstance.C_ProtocolInstance_key;
                    }

                    promise = promise.then(() => {
                        return this.taskService.createTaskInstance(initialTaskValues);
                    }).then((newTaskInstance) => {
                        const taskMaterialValues = {
                            C_Material_key: animal.C_Material_key,
                            C_TaskInstance_key: newTaskInstance.C_TaskInstance_key,
                            Sequence: maxSequence(animal.Material.TaskMaterial) + 1
                        };
                        this.taskService.createTaskMaterial(taskMaterialValues);

                        taskInstances.push(newTaskInstance);
                    });
                }
            }

            return promise.then(() => {
                return taskInstances;
            });
        }).then((newTaskInstances) => {
            // need schedule types for calculating protocol dates
            return this.ensureDataCalculatorCVsLoaded().then(() => {
                this.calculateProtocolDates(newTaskInstances);

                return newTaskInstances;
            });
        });
    }
    
    createProtocolInstance(protocol: any, animal: any): any {
        const protocolInstances = uniqueArrayFromPropertyPath(
            animal, 'Material.TaskMaterial.TaskInstance.ProtocolInstance'
        );
        const protocolInitialValues = {
            C_Protocol_key: protocol.C_Protocol_key,
            ProtocolAlias: this.taskService.generateProtocolAlias(
                protocolInstances, protocol
            )
        };
        return this.taskService.createProtocolInstance(
            protocolInitialValues
        );
    }

    calculateProtocolDates(taskInstances: any[]) {
        if (!notEmpty(taskInstances) || 
            !taskInstances[0].C_ProtocolTask_key
        ) {
            // no protocol to process
            return;
        }
        const protocolDateCalculator = new ProtocolDateCalculator();
        for (const task of taskInstances) {
            protocolDateCalculator.scheduleMaterialDueDates(taskInstances, task);
        }
    }

    onDetailLinkClick(itemClicked: any): Promise<any> {
        this.isSyncItem = false;
        this.taskData = null;
        return Promise.resolve();
    }

    private getDefaultTaskStatus(): Promise<any> {
        const preferLocal = true;
        return this.vocabularyService.getCVDefault('cv_TaskStatuses', preferLocal);
    }

    private ensureDataCalculatorCVsLoaded(): Promise<any> {
        return Promise.all([
            this.vocabularyService.ensureCVLoaded('cv_ScheduleTypes'), 
            this.vocabularyService.ensureCVLoaded('cv_TimeRelations'), 
            this.vocabularyService.ensureCVLoaded('cv_TimeUnits'), 
        ]);
    }

    handleBulkHousingExit(editedItems: any[], alwaysReload = false) {
        if (alwaysReload ||
            this.anyItemsAddedOrDeleted(editedItems)
        ) {
            this.selectedRows = [];
            this.reloadTable();
        }
        this.changeView(this.LIST_VIEW);
    }

    /**
     * Sets isDotmatics flag
     */
    private setIsDotmatics() {
        this.isDotmatics = this.dotmaticsService.setIsDotmatics();
    }

    /**
     * Return a list of Dotmatics synced animals given a list of animals
     * @param animals
     */
    checkSyncStatus(animals: Entity<Animal>[]): Entity<Animal>[] {
        const syncedAnimals = [];
        for (const animal of animals) {
            const material = animal.Material;
            if (material.MaterialExternalSync.length) {
                syncedAnimals.push(animal);
            }
        }
        return syncedAnimals;
    }

    /**
     * Return a list of Dotmatics synced animals given a list of animals
     * @param syncedAnimals
     * @param animalsToDelete
     */
    dotmaticsDeleteModal(syncedAnimals: Entity<Animal>[], animalsToDelete: Entity<Animal>[]): Promise<any> {
        const modalTitle = 'Delete Animals';
        let modalText = '';

        if (syncedAnimals.length > 0 && animalsToDelete.length === 0) {
            modalText = 'None of the selected animals can be deleted because they have been synced with Dotmatics.';
            const confirmOptions: ConfirmOptions = {
                title: modalTitle,
                message: modalText,
                yesButtonText: 'OK',
                onlyYes: true
            };
            // use regular confirm modal because there's nothing to delete
            return this.confirmService.confirm(confirmOptions);
        } else if (syncedAnimals.length > 0 && animalsToDelete.length > 0) {
            modalText = 'Delete ' +
                animalsToDelete.length +
                ' selected ' + (animalsToDelete.length === 1 ? 'animal' : 'animals') + '? This action cannot be undone.';
            const animalNames: any[] = [];
            syncedAnimals.forEach((animal: any) => {
                animalNames.push(animal.AnimalName);
            });
            const animalNamesString = this.makeCommaSeparatedString(animalNames);
            const details = ['Note - The following ' +
                (syncedAnimals.length === 1 ? 'animal' : 'animals') +
                ' cannot be deleted because ' +
                (syncedAnimals.length === 1 ? 'it has' : 'they have') +
                ' been synced with Dotmatics: ' +
                animalNamesString
            ];
            return this.confirmService.confirmDeleteWithDetails(modalTitle, modalText, details);
        }

        return Promise.resolve();
    }

    /**
     * Join an array into a comma separated list string. For example, [a,b,c] to "a, b, and c"
     * @param arr
     */
    makeCommaSeparatedString(arr: any[]) {
        if (arr.length < 1) {
            return '';
        }

        if (arr.length === 1) {
            return arr.toString();
        }

        if (arr.length === 2) {
            return arr.join(' and ');
        }

        return arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
    }

    /**
     * Gets all Taxon Characteristics that have been set in the settings facet as "Workgroup Characteristics" and creates TableColumnDef objects out of them.
     * @returns
     */
    getCharacteristicListViewColumns(gridState: any): Promise<TableColumnDef[]> {
        return this.settingService.getTaxonCharacteristicsShownInListView().then((characteristics: TaxonCharacteristic[]) => {
            // okay so the field just needs to be formatted to find the taxon characteristic specified?
            const characteristicColumns: TableColumnDef[] = [];
            for (const characteristic of characteristics) {
                // This field name does not correspond with an actual element in the TaxonCharacteristicInstance list.
                // Instead, this is used to make each TaxonCharacteristicInstance column unique.
                const fieldName = 'TaxonCharacteristicInstance[' + characteristic.ListViewOrder + ']';

                let isColumnVisible: boolean;
                if (gridState && gridState.columns) {
                    const fieldColumn = gridState.columns.find((e: any) => e.field === fieldName);
                    if (fieldColumn) {
                        isColumnVisible = fieldColumn.visible;
                    } else {
                        isColumnVisible = false;
                    }
                } else {
                    isColumnVisible = false;
                }

                const column: TableColumnDef = {
                    displayName: characteristic.CharacteristicName,
                    field: fieldName,
                    visible: isColumnVisible,
                    formatter: (row: Animal, value: any) => {
                        return this.characteristicService.getTaxonCharacteristicInstanceValue(row.TaxonCharacteristicInstance, characteristic.C_TaxonCharacteristic_key);
                    },
                    exportFormatter: (row: Animal, value: any) => {
                        return this.characteristicService.getTaxonCharacteristicInstanceValue(row.TaxonCharacteristicInstance, characteristic.C_TaxonCharacteristic_key);
                    },
                    sortable: false
                };

                characteristicColumns.push(column);
            }
            return characteristicColumns;
        });
    }

}
