import { SaveChangesService } from './../services/save-changes.service';
import {
    Component,
    Input,
    OnInit,
} from '@angular/core';

import { MonitoringService } from './monitoring.service';
import { TranslationService } from './../services/translation.service';
import { VocabularyService } from './../vocabularies/vocabulary.service';

import {
    randomId,
    sortObjectArrayByProperty,
} from '../common/util';

import { ParameterName } from './models';
import { MonitoringServiceSubscriptionParameterModalService } from './monitoring-service-subscription-parameter-modal.service';

@Component({
    selector: 'monitoring-facet',
    templateUrl: './monitoring-facet.component.html',
    providers: [
        MonitoringServiceSubscriptionParameterModalService
    ],
    styles: [`
        .label-service-name {
            cursor: pointer;
        }
        .parameter-control {
            margin-left: 4px;
            display: inline-block;
        }
        .tabset::ng-deep ul {
            padding-left: 4px;
        }
    `]
})
export class MonitoringFacetComponent implements OnInit {
    @Input() facet: any;

    services: any[];
    serviceSubscriptions: any[];
    reverse = false;

    jobStatuses: any[];

    domIdAddition: string;

    // expose to template
    ParameterName = ParameterName;

    readonly COMPONENT_LOG_TAG = 'monitoring-facet';

    constructor(
        private saveChangesService: SaveChangesService,
        private monitoringService: MonitoringService,
        private translationService: TranslationService,
        private vocabularyService: VocabularyService,
        private parameterModalService: MonitoringServiceSubscriptionParameterModalService
    ) {
        this.monitoringService = monitoringService;
    }

    // TODO: convert monitoring.service.ts to use observables
    async ngOnInit() {
        this.domIdAddition = randomId();
        this.services = await this.monitoringService.getServices();
        this.serviceSubscriptions = await this.monitoringService.getMonitoringServiceSubscriptions();
        this.jobStatuses = await this.vocabularyService.getCV('cv_JobStatuses', 'JobStatus');
        this.initializeSelected();
        this.initializeSubscriptionParameters();
        this.sortServices();
    }

    initializeSelected() {
        for (const service of this.services) {
            this.translateServiceName(service);

            const subscription = this.serviceSubscriptions.find((s) => {
                return s.C_MonitoringService_key === service.C_MonitoringService_key;
            });

            if (subscription) {
                service.selected = true;
                service.MonitoringServiceSubscription = subscription;
                subscription.values = [];
            } else {
                service.selected = false;
            }
        }
    }

    // Translates service name, leaving original value intact for the back-end
    private translateServiceName(service: any) {
        service.ServiceNameTranslated =
            this.translationService.translateMessage(service.ServiceName);
    }

    // When the state of a service checkbox has been changed
    async checkServiceChanged(service: any) {
        service.selected = !service.selected;
        if (!service.selected) {
            this.monitoringService.deleteServiceSubscription(
                service.C_MonitoringService_key
            );
        } else {
            await this.monitoringService.createServiceSubscription(service);
            if (service.MonitoringServiceSubscription) {
                for (const param of service.MonitoringServiceSubscription.MonitoringServiceSubscriptionParameter) {
                    if (param.MonitoringServiceParameter.ParameterName === ParameterName.JobStatus) {
                        param.values = this.getControlValuesFromKeyList(param.ParameterValue);
                        await this.showModal(service, param);
                    }
                }
            }
        }
    }

    reverseSort() {
        this.reverse = !this.reverse;
        this.sortServices();
    }

    // Sort services by their names
    // TODO: pull this out into common directory function
    sortServices() {
        this.services = sortObjectArrayByProperty(
            this.services, 'ServiceNameTranslated', this.reverse
        );
    }

    /**
     * Initialize controls for any active subscription parameters
     *   (I.e. MonitoringServiceSubscriptionParameter)
     */
    initializeSubscriptionParameters() {
        if (!this.serviceSubscriptions) {
            return;
        }

        for (const subscription of this.serviceSubscriptions) {
            for (const param of subscription.MonitoringServiceSubscriptionParameter) {
                param.values = this.getControlValuesFromKeyList(param.ParameterValue);
            }
        }
    }

    getControlValuesFromKeyList(keyList: string): number[] {
        if (!keyList) {
            return [];
        }
        return keyList.split(',').map((key) => {
            return parseInt(key.trim(), 10);
        });
    }

    setParameterValue(param: any, keys: any[]) {
        if (param && param.MonitoringServiceParameter) {
            param.ParameterValue = keys.join(',');
        }
    }

    getSubscribeControlId(service: any) {
        return 'subscribe-' +
            service.C_MonitoringService_key +
            '-' +
            this.domIdAddition;
    }

    async showModal(service: any, param: any) {
        await this.parameterModalService.openParameterModal(service, param, this.jobStatuses);
        if (param.ParameterValue.length === 0) {
            await this.checkServiceChanged(service);
        }
    }

    onSaveMonitoring() {
        this.saveChangesService.saveChanges(this.COMPONENT_LOG_TAG);
    }
}
